import React, { PureComponent } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {Navigation} from "../Navigation";
import {myConfig} from "../../config";
import {TopBar} from "../TopBar";
import Modal from "react-bootstrap/Modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ReactPaginate from "react-paginate";
import {GetWebxLaravel, PostWebxLaravel} from "../PostCompnent";
import {CSVLink} from "react-csv";
import {Footer} from "../Footer";
import {SupportButton} from "../SupportButton";
import {NavigationSandbox} from "./NavigationSandbox";
import {TopBarSandbox} from "./TopBarSandbox";


export class CustomersSandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageCount: 0,
            actionModal:this.saveCustomer,
            countries_list:[],
            first_name_edit:'',
            last_name_edit:'',
            email_address_edit:'',
            phone_number_edit:'',
            website_url_edit:'',
            street_address_edit_1:'',
            street_address_edit_2:'',
            city_edit:'',
            country_edit:'',
            customer_id:'',
            customer_group_list_filters:0,
            modalShowCustomerEdit:false,
            setModalShowCustomerEdit:false,
            customerManage:this.saveCustomer,
            customers_list:[],
            customers_list_filter:[],
            customers_list_render:[],
            keyword:'',
            errors_cus:[],

        };
        this.onChanges = this.onChanges.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.addRemoveActive = this.addRemoveActive.bind(this);
        this.getCustomerDetail = this.getCustomerDetail.bind(this);
        this.getCountries = this.getCountries.bind(this);
        this.showModalCustomer = this.showModalCustomer.bind(this);
        this.closeModalCustomer = this.closeModalCustomer.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);
        this.updateCustomer = this.updateCustomer.bind(this);
        this.showModalEditCustomer = this.showModalEditCustomer.bind(this);
        this.filterList = this.filterList.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.validateCustomers = this.validateCustomers.bind(this);
    }
    handleCallback(start, end, label) {
        this.setState({
            startDate:moment(start).format('ll'),
            endDate:moment(end).format('ll'),
        });
        let start_dts=sessionStorage.getItem('session_st_date');
        let end_dts= sessionStorage.getItem('session_en_date');
        if (start_dts){
            sessionStorage.removeItem("session_st_date");
        }
        if(end_dts){
            sessionStorage.removeItem("session_en_date");
        }
        sessionStorage.setItem('session_st_date', moment(start).format('ll'));
        sessionStorage.setItem('session_en_date', moment(end).format('ll'));
        this.filterList(moment(start).format('ll'),moment(end).format('ll'));
    }
    validateCustomers(){
        let errors_cus = {};
        let formIsValid = true;
        //Email


        if(this.state.first_name_edit==""){
            formIsValid = false;
            errors_cus["first_name_edit"] = "Cannot be empty";
        }
        if(this.state.last_name_edit==""){
            formIsValid = false;
            errors_cus["last_name_edit"] = "Cannot be empty";
        }
        if(this.state.email_address_edit==""){
            formIsValid = false;
            errors_cus["email_address_edit"] = "Cannot be empty";
        }
        if(this.state.phone_number_edit==""){
            formIsValid = false;
            errors_cus["phone_number_edit"] = "Cannot be empty";
        }
        if(this.state.street_address_edit_1==""){
            formIsValid = false;
            errors_cus["street_address_edit_1"] = "Cannot be empty";
        }
        if(this.state.city_edit==""){
            formIsValid = false;
            errors_cus["city_edit"] = "Cannot be empty";
        }
        if(this.state.country_edit==""){
            formIsValid = false;
            errors_cus["country_edit"] = "Cannot be empty";
        }

        this.setState({errors_cus: errors_cus});
        return formIsValid;
    }

    componentWillMount() {
        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {
            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");
            this.setState({userToken: userToken});
        } else {
            this.setState({redirect: true});
        }
    }
    filterList(start_date,end_date){


        let keyword = this.state.keyword.toLowerCase();

        let formData = new FormData();    //formdata object


        formData.append('keyword', keyword);   //append the values with key, value pair

        this.handleToggleProgress();
        PostWebxLaravel("customers/get_customer_with_filters", formData).then(response => {
         this.setState({
             customers_list:response.data,
             customers_list_filter:response.data,
         },()=>{
             this.loadTable();
         });
        });

    }
    clearSearch(){
        GetWebxLaravel( "customers/get_customers/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    keyword:'',
                    customers_list:response.data,
                    customers_list_filter:response.data,
                },()=>{
                    this.loadTable();
                });
            }
        });
    }

    loadTable(){
        const list_data=this.state.customers_list_filter;
        const zeroPad = (num, places) => String(num).padStart(places, '0');
        const slice = list_data.slice(this.state.offset, this.state.offset + this.state.perPage);
        const trans=  list_data && list_data.length?(slice.map((list,key) =>
                <tr className='' data-toggle="modal" data-target="#modal-1">
                <td className='' data-title='#'>{zeroPad(key+1,3)}</td>
                <td className='' data-title='Customer Name'>{list.customer_first_name}
                </td>
                    <td className='' data-title='Customer Name'>{list.customer_last_name}
                    </td>

                    <td className='' data-title='Email Address'>{list.customer_email}
                </td>
                <td className='' data-title='Phone Number'>{list.customer_phone_number}</td>
              {/*  <td className='' data-title='Total Transactions'>
                      <NumberFormat
                                                                            value={(Math.round(list.lkr_total_amount * 100) / 100).toFixed(2)}
                                                                            displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
                    LKR 18,000.00
                </td>
                <td className='' data-title='Pending Transactions'>LKR
                    55,000.00
                </td>*/}
                <td className='' data-title='Active Since'>{moment(list.created_at).format('ll')}</td>

                <td className='' data-title=''>
                    <div className="dropdown">
                        <button className="btn dropdown-toggle dot-drop"
                                type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" onClick={()=>this.showModalEditCustomer(list.id)}>
                            ...
                        </button>
                        <div className="dropdown-menu table-drop"
                             aria-labelledby="dropdownMenuButton">
                            <button className="dropdown-item"><i
                                className="fas fa-power-off"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-pencil-alt"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-share"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-trash-alt"></i></button>

                        </div>
                    </div>
                </td>
            </tr>
        ) ):(
            <tr className=''>
                <td colSpan="8" className='' data-title='#'>No Results Found</td>
            </tr>
        )
        this.setState({
            pageCount: Math.ceil(list_data.length / this.state.perPage),
            customers_list_render:trans
        })
    }

    updateCustomer(){

        let formData = new FormData();    //formdata object

        let first_name_edit=this.state.first_name_edit;
        let last_name_edit=this.state.last_name_edit;
        let email_address=this.state.email_address_edit;
        let phone_number=this.state.phone_number_edit;
        let website_url=this.state.website_url_edit;
        let street_address_1=this.state.street_address_edit_1;
        let street_address_2=this.state.street_address_edit_2;
        let city=this.state.city_edit;
        let country=this.state.country_edit;

        formData.append('type_cus', true);   //append the values with key, value pair
        formData.append('customer_first_name', first_name_edit);   //append the values with key, value pair
        formData.append('customer_last_name', last_name_edit);   //append the values with key, value pair
        formData.append('customer_email', email_address);
        formData.append('customer_phone_number', phone_number);
        formData.append('customer_website_url', website_url);
        formData.append('customer_address_line_1', street_address_1);
        formData.append('customer_address_line_2', street_address_2);
        formData.append('customer_city', city);
        formData.append('customer_country_id', country);
        formData.append('_method', 'put');
        this.handleToggleProgress();
        PostWebxLaravel( "customers/update_customer/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+this.state.customer_id,formData).then(response=>{
            this.handleCloseProgress();
            if (response.status==200) {

                if (response.data.success == 200) {
                    this.setState({
                        modalShowCustomerEdit: false,
                        setModalShowCustomerEdit: false,
                        /*   modalShow: true,
                           setModalShow: true*/
                    }, () => {
                        this.getCustomerDetail();
                    });
                } else {
                    let errors_cus = {};
                    errors_cus["email_address_edit"] = response.data.message;

                    this.setState({errors_cus: errors_cus});
                }
            }

        });
    }

    saveCustomer(){
        if(this.validateCustomers()) {
            this.setState({
                modalShowCustomerEdit: false,
                setModalShowCustomerEdit: false,
                /*   modalShow: true,
                   setModalShow: true*/
            }, () => {
                this.getCustomerDetail();
            });
        }
    }
    editCustomer(){

    }

    showModalCustomer(){
        this.setState({
            customerManage:this.saveCustomer,
            modalShowCustomerEdit:true,
            setModalShowCustomerEdit:true,
            first_name_edit:'',
            last_name_edit:'',
            email_address_edit:'',
            phone_number_edit:'',
            website_url_edit:'',
            street_address_edit_1:'',
            street_address_edit_2:'',
            city_edit:'',
            country_edit:'',
        },() => {
            var modelD = document.getElementById("anis-modal");
            modelD.classList.add("ancd-mod");
        });
    }

    showModalEditCustomer(customer_id){
        this.handleToggleProgress();
        GetWebxLaravel("customers/get_single_customer/" + customer_id).then(response=>{
            if (response) {
                this.handleCloseProgress();
                let customer_details_edit = response.data;
                this.setState({
                    first_name_edit: customer_details_edit.customer_first_name,
                    last_name_edit: customer_details_edit.customer_last_name,
                    email_address_edit: customer_details_edit.customer_email,
                    phone_number_edit: customer_details_edit.customer_phone_number,
                    website_url_edit: customer_details_edit.customer_website_url,
                    street_address_edit_1: customer_details_edit.customer_address_line_1,
                    street_address_edit_2: customer_details_edit.customer_address_line_2,
                    city_edit: customer_details_edit.customer_city,
                    country_edit: customer_details_edit.customer_country_id
                });
            }
        });
        this.getCustomerDetail();
        this.setState({
            customerManage:this.updateCustomer,
            modalShowCustomerEdit:true,
            setModalShowCustomerEdit:true,
            customer_id:customer_id,
        },() => {
            var modelD = document.getElementById("anis-modal");
            modelD.classList.add("ancd-mod");

        });
    }

    closeModalCustomer(){
        this.setState({
            modalShowCustomerEdit:false,
            setModalShowCustomerEdit:false
        });
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadTable()
        });

    };

    getCustomerDetail(){
        this.handleToggleProgress();
        GetWebxLaravel( "customers/get_customers/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    customers_list:response.data,
                    customers_list_filter:response.data,
                },()=>{
                    this.loadTable();
                });
            }
        });
        this.handleToggleProgress();
        GetWebxLaravel("customer_group/get_groups/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            this.handleCloseProgress();
            if (response){
                this.setState({
                    customer_group_list_filters:response.data,
                });
            }

        });
    }
    getCountries(){
        this.handleToggleProgress();
        GetWebxLaravel("common_list/get_countries").then(response=>{
            this.handleCloseProgress();
            if (response){
                this.setState({
                    countries_list:response.data.countries,
                });
            }

        });

    }

    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };


    componentWillMount() {

        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");

            this.setState({userToken: userToken});

            this.getCountries();
            this.getCustomerDetail();

        } else {
            this.setState({redirect: true});
        }
    }

    addRemoveActive(){
        var plusButton = document.getElementById("plus-button");
        var plusMenu = document.getElementById("plus-menu");
        if (plusMenu.classList.contains('active')) {
            plusMenu.classList.remove("active");
            plusButton.classList.remove("active");
        } else {
            plusMenu.classList.add("active");
            plusButton.classList.add("active");
        }
        var myElement = document.getElementsByClassName('l-item');
        for (var i = 0; i < myElement.length; i++) {
            myElement[i].onclick = function() {
                var plusButton = document.getElementById("plus-button");
                var plusMenu = document.getElementById("plus-menu");
                plusMenu.classList.remove("active");
                plusButton.classList.remove("active");
            }
        }
    }



    render() {
        if (this.state.redirect) {
            return <Navigate to='/login'  />
        }
        const countries_list=this.state.countries_list;
        const customer_group_count=this.state.customer_group_list_filters.length;

        const customers_list=this.state.customers_list_render;
        let customers_length=0;
        if (this.state.customers_list){
             customers_length=this.state.customers_list.length;
        }


        const zeroPad = (num, places) => String(num).padStart(places, '0')
        return (
            <React.Fragment>
                <SupportButton/>
                <div id="plus-button" onClick={this.addRemoveActive}>
                    <i className="fas fa-plus"></i>
                </div>

                <Modal
                    show={this.state.modalShowCustomerEdit}
                    onHide={this.state.setModalShowCustomerEdit}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anis-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.closeModalCustomer}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh">Customer Details</h3>

                                    <div className="col-12 tdm-in p-0">

                                        <h6>First Name</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Customer First Name" name="first_name_edit" value={this.state.first_name_edit} onChange={this.onChanges} />
                                             <span className="error" style={{color: "red"}}>{this.state.errors_cus["first_name_edit"]}</span>
                                        </div>

                                        <h6>Last Name</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Customer Last Name" name="last_name_edit" value={this.state.last_name_edit} onChange={this.onChanges} />
                                             <span className="error" style={{color: "red"}}>{this.state.errors_cus["last_name_edit"]}</span>
                                        </div>

                                        <h6>Email Address</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Email Address" name="email_address_edit" value={this.state.email_address_edit} onChange={this.onChanges} />
                                             <span className="error" style={{color: "red"}}>{this.state.errors_cus["email_address_edit"]}</span>
                                        </div>

                                        <h6>Contact Number</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Phone Number" name="phone_number_edit" value={this.state.phone_number_edit} onChange={this.onChanges} />
                                             <span className="error" style={{color: "red"}}>{this.state.errors_cus["phone_number_edit"]}</span>
                                        </div>

                                        <h6>Website (Optional)</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Website URL" name="website_url_edit" value={this.state.website_url_edit} onChange={this.onChanges} />
                                        </div>

                                        <h6>Address Line 1</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Street Address Line 1" name="street_address_edit_1" value={this.state.street_address_edit_1} onChange={this.onChanges} />
                                             <span className="error" style={{color: "red"}}>{this.state.errors_cus["street_address_edit_1"]}</span>
                                        </div>
                                        <h6>Address Line 2</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Street Address Line 2" name="street_address_edit_2" value={this.state.street_address_edit_2} onChange={this.onChanges} />
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <h6>City</h6>
                                                <div className="form-group">
                                                    {/*   <select name="" id="" className="form-control">
                                                     <option value="">Select City</option>
                                                 </select>*/}
                                                    <input type="text" className="form-control" placeholder="Enter City" name="city_edit" value={this.state.city_edit} onChange={this.onChanges}/>
                                                     <span className="error" style={{color: "red"}}>{this.state.errors_cus["city_edit"]}</span>

                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <h6>Country</h6>
                                                <div className="form-group">
                                                    <select  id="" className="form-control" name="country_edit" value={this.state.country_edit} onChange={this.onChanges}>
                                                        <option value="" >Select</option>
                                                        {countries_list && countries_list.length?(
                                                            countries_list.map((list, key) =>
                                                            {

                                                                return (
                                                                    <option value={list.id} >{list.nicename}</option>
                                                                )
                                                            })
                                                        ):('')
                                                        }
                                                    </select>
                                                     <span className="error" style={{color: "red"}}>{this.state.errors_cus["country_edit"]}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <button type="button" className="nxt-btn" onClick={this.state.customerManage}>Save<span> </span> <i
                                        className="fas fa-save"></i></button>
                                  {/*  <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                        Help with the System?</a>*/}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="plus-menu">
                        <h4><i className="fas fa-plus"></i> Create New</h4>
                        <div className="pm-in">
                            <ul>
                                <li data-toggle="modal" data-target="#add-new-group-modal" className="l-item" onClick={this.showModalCustomer}>
                                    <div className="icn"><i className="fas fa-socks"></i></div>
                                    <p className="tp">Create New Customer</p>
                                    <p className="bp">Create easy to access product bundles</p>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div id="turn-box"></div>
                    <NavigationSandbox pathname="customer-sandbox"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBarSandbox pathname="customer-sandbox"/>
                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link className="nav-link active" id="tt-tab" data-toggle="tab" to={"/customer-sandbox"}  role="tab"
                                       aria-controls="tt" aria-selected="true"><span className="tt">All Customers</span><span
                                        className="amt">10 Customers</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" id="tr-tab" data-toggle="tab" to={"/customer-group-sandbox"} role="tab"
                                       aria-controls="tr" aria-selected="false"><span
                                        className="tt">Customer Groups</span><span className="amt">1 Groups</span></Link>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tt" role="tabpanel" aria-labelledby="tt-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <form className="col-md-8 col-12 tt-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group w50">
                                                            <label htmlFor="">Keywords</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Keyword(s)" name="keyword" onChange={this.onChanges} />
                                                        </div>
                                                        <div className="form-group">
                                                            <button className="srch-btn" type="button" onClick={this.filterList}>Search</button>

                                                        </div>
                                                        <div className="form-group">

                                                            <button className="srch-btn clear-btn" type="button"  onClick={this.clearSearch}>Clear</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form className="col-md-4 col-12 tr-form">
                                                    <div className="row row_clr">
                                                       {/* <div className="form-group">
                                                            <label htmlFor="">Filters & Reports</label>
                                                            <div className="in table-datepicker">
                                                                <DateRangePicker
                                                                    onEvent={this.handleEvent} onCallback={this.handleCallback}
                                                                >
                                                                    <button type="button" className="date-btn"> <i className="far fa-calendar-alt"></i>  {this.state.dayCount} Days <i
                                                                        className="fas fa-caret-down" aria-hidden="true"></i> </button>
                                                                </DateRangePicker>
                                                            </div>
                                                        </div>*/}
                                                        <div className="form-group">
                                                           {/* <CSVLink data={this.state.csvData} style={{textDecoration: "none !important",color:"#000"}}>
                                                                <i className="fas fa-download"></i>
                                                            </CSVLink>

                                                            <button type="button" onClick={() => this.exportPDF()}>Generate Report</button>*/}
                                                            {/*   <button className="dwn-btn"><i
                                                                className="fas fa-download"></i></button>*/}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className='rg-container'>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>First Name</th>
                                                        <th className=''>Last Name</th>
                                                        <th className=''>Email Address</th>
                                                        <th className=''>Phone Number</th>
                               {/*                         <th className=''>Total Transactions</th>
                                                        <th className=''>Pending Transactions</th>*/}
                                                        <th className=''>Active Since</th>

                                                        <th className=''></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="" data-toggle="modal" data-target="#modal-1">
                                                        <td className="" data-title="#">001</td>
                                                        <td className="" data-title="Customer Name">Yuga</td>
                                                        <td className="" data-title="Customer Name">Nava</td>
                                                        <td className=""
                                                            data-title="Email Address">yugan91@hotmail.com
                                                        </td>
                                                        <td className="" data-title="Phone Number">011929292</td>
                                                        <td className="" data-title="Active Since">Jul 9, 2021</td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">...
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-power-off"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-pencil-alt"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="" data-toggle="modal" data-target="#modal-1">
                                                        <td className="" data-title="#">002</td>
                                                        <td className="" data-title="Customer Name">Mubee</td>
                                                        <td className="" data-title="Customer Name">Centronixx</td>
                                                        <td className=""
                                                            data-title="Email Address">mubeen@centronixx.com
                                                        </td>
                                                        <td className="" data-title="Phone Number">0777488555</td>
                                                        <td className="" data-title="Active Since">Jul 9, 2021</td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">...
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-power-off"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-pencil-alt"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="" data-toggle="modal" data-target="#modal-1">
                                                        <td className="" data-title="#">003</td>
                                                        <td className="" data-title="Customer Name">Mubeen</td>
                                                        <td className="" data-title="Customer Name">New</td>
                                                        <td className=""
                                                            data-title="Email Address">Mubeen@liwecommunities.com
                                                        </td>
                                                        <td className="" data-title="Phone Number">0117468669</td>
                                                        <td className="" data-title="Active Since">Jul 9, 2021</td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">...
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-power-off"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-pencil-alt"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="" data-toggle="modal" data-target="#modal-1">
                                                        <td className="" data-title="#">004</td>
                                                        <td className="" data-title="Customer Name">Nile</td>
                                                        <td className="" data-title="Customer Name">Gani</td>
                                                        <td className="" data-title="Email Address">Nile.175@gmail.com
                                                        </td>
                                                        <td className="" data-title="Phone Number">0774589225</td>
                                                        <td className="" data-title="Active Since">Jul 9, 2021</td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">...
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-power-off"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-pencil-alt"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="" data-toggle="modal" data-target="#modal-1">
                                                        <td className="" data-title="#">005</td>
                                                        <td className="" data-title="Customer Name">Mubeen</td>
                                                        <td className="" data-title="Customer Name">Gani</td>
                                                        <td className="" data-title="Email Address">Nile.175@live.com
                                                        </td>
                                                        <td className="" data-title="Phone Number">07772256355</td>
                                                        <td className="" data-title="Active Since">Jul 9, 2021</td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">...
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-power-off"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-pencil-alt"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="" data-toggle="modal" data-target="#modal-1">
                                                        <td className="" data-title="#">006</td>
                                                        <td className="" data-title="Customer Name">hmmm</td>
                                                        <td className="" data-title="Customer Name">hmmm</td>
                                                        <td className="" data-title="Email Address">hmmm@fff.lk</td>
                                                        <td className="" data-title="Phone Number">33344</td>
                                                        <td className="" data-title="Active Since">May 20, 2021</td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">...
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-power-off"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-pencil-alt"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="" data-toggle="modal" data-target="#modal-1">
                                                        <td className="" data-title="#">007</td>
                                                        <td className="" data-title="Customer Name">sdfd</td>
                                                        <td className="" data-title="Customer Name">sfd</td>
                                                        <td className=""
                                                            data-title="Email Address">yugan91@gmail.comsss
                                                        </td>
                                                        <td className="" data-title="Phone Number">0777 478-913</td>
                                                        <td className="" data-title="Active Since">May 5, 2021</td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">...
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-power-off"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-pencil-alt"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="" data-toggle="modal" data-target="#modal-1">
                                                        <td className="" data-title="#">008</td>
                                                        <td className="" data-title="Customer Name">Shuaib</td>
                                                        <td className="" data-title="Customer Name">Mohamed</td>
                                                        <td className=""
                                                            data-title="Email Address">shuaib.mohamed@webxpay.comm
                                                        </td>
                                                        <td className="" data-title="Phone Number">0777755643</td>
                                                        <td className="" data-title="Active Since">Apr 21, 2021</td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">...
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-power-off"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-pencil-alt"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="" data-toggle="modal" data-target="#modal-1">
                                                        <td className="" data-title="#">009</td>
                                                        <td className="" data-title="Customer Name">Yuga</td>
                                                        <td className="" data-title="Customer Name">Navaa</td>
                                                        <td className=""
                                                            data-title="Email Address">yuganasdasdasd91@gmail.com
                                                        </td>
                                                        <td className="" data-title="Phone Number">0777478913</td>
                                                        <td className="" data-title="Active Since">Apr 19, 2021</td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">...
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-power-off"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-pencil-alt"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="" data-toggle="modal" data-target="#modal-1">
                                                        <td className="" data-title="#">010</td>
                                                        <td className="" data-title="Customer Name">asdas</td>
                                                        <td className="" data-title="Customer Name">dil</td>
                                                        <td className="" data-title="Email Address">dsad@afsad.lk</td>
                                                        <td className="" data-title="Phone Number">342423423</td>
                                                        <td className="" data-title="Active Since">Apr 8, 2021</td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false">...
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-power-off"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-pencil-alt"
                                                                        aria-hidden="true"></i></button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-trash-alt"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel={"PREV"}
                                                    nextLabel={"NEXT"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={1}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}/>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </React.Fragment>
        );
    }

}


