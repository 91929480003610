import Tab from "react-bootstrap/Tab";
import Tabs from 'react-bootstrap/Tabs';
import React, {useEffect, useState} from "react";
import Nav from "react-bootstrap/Nav";
import {PostCompnentWebx, PostWebxLaravel} from "../../PostCompnent";
import {ChangeSubscription} from "./ChangeSubscription";
import {ViewSubscription} from "./ViewSubscription";
import ReactPaginate from "react-paginate";
import {PaymentMethods} from "./PaymentMethods";


export function SubscriptionTabs(props) {
    const [subscriptionMainPackages, setSubscriptionMainPackage] = useState([]);
    const [subscriptionAddonUSD, setSubscriptionAddonUSD] = useState([]);
    const [subscriptionAddonEMI, setSubscriptionAddonEMI] = useState([]);
    const [currentSubscriptionMainPackage, setCurrentSubscriptionMainPackage] = useState([]);
    const [currentSubscriptionAddonUSD, setCurrentSubscriptionAddonUSD] = useState([]);
    const [currentSubscriptionAddonEMI, setCurrentSubscriptionAddonEMI] = useState([]);
    const [key, setKey] = useState('home');
    const [token_data,setTokenData]=useState('');
    const [currency,setCurrencyData]=useState('');
    const [secureUrl,secure3dResponseURL]=useState('');
    const [bankMid,setBankMid]=useState('');
    const [businessData,setBusinessData]=useState('');
    const [randomString,setRandomString]=useState('');
    useEffect(() => {
        getTokenData();
        loadSubscriptionList();
        loadCurrentSubscriptionData();
        loadCurrentBusinessData();
    }, []);

    function loadSubscriptionList(){
        let formData = new FormData();
        formData.append("store_id", sessionStorage.getItem("merchant_id"));


        PostWebxLaravel("subscription-api/subscription-packages", formData).then((response) => {
            setSubscriptionMainPackage(response.data.main_packages)
            setSubscriptionAddonUSD(response.data.package_currency)
            setSubscriptionAddonEMI(response.data.package_emis)

        });

    }

    function getTokenData(){
        let formDatas=new FormData();
        PostWebxLaravel("get-auth-token", formDatas).then(response => {

            setTokenData(response.data?response.data.token:'')
            setCurrencyData(response.data?response.data.currency:'')
            secure3dResponseURL(response.data?response.data.secure3dResponseURL:'')
            setBankMid(response.data?response.data.bankMID:'')
            setRandomString(response.data?response.data.reference:'')
        });
    }

    function loadCurrentBusinessData(){
        let formData=new FormData();
        PostCompnentWebx("getStoreBusinessInfo",formData).then(response=>{
            if (response){
                setBusinessData(response.data[0])
            }
        });
    }

    function loadCurrentSubscriptionData(){
        let formData = new FormData();
        formData.append("store_id", sessionStorage.getItem("merchant_id"));


        PostWebxLaravel("subscription-api/get-subscription", formData).then((response) => {
            setCurrentSubscriptionMainPackage(response.data.main_packages)
            setCurrentSubscriptionAddonUSD(response.data.package_currency)
            setCurrentSubscriptionAddonEMI(response.data.package_emis)
        });
    }



    let lend = 0;
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="one">
            <div className="col-12 lft-sd pt-0">
                <Nav variant="pills" className="nav nav-tabs" id="set-tab1">
                    <Nav.Item>
                        <Nav.Link eventKey="one" className="nav-link">Payment Methods</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="two" className="nav-link"> Subscription Details</Nav.Link>
                    </Nav.Item>



                   {/* <Nav.Item>
                        <Nav.Link eventKey="three" className="nav-link">Payment Details</Nav.Link>
                    </Nav.Item>*/}


                </Nav>
            </div>

            <div className="col-12 rht-sd" id="activation-modal">
                <div className="tab-content">
                    <Tab.Pane eventKey="one" id="v-pills-1">
                        <PaymentMethods
                            tokenData={token_data}
                            currencyData={currency}
                            secureUrl={secureUrl}
                            bankMid={bankMid}
                            businessData={businessData}
                            randomString={randomString}

                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="two" id="v-pills-1">
                        <ChangeSubscription subscriptionMainPackages={subscriptionMainPackages}
                                            subscriptionAddonUSD={subscriptionAddonUSD}
                                            subscriptionAddonEMI={subscriptionAddonEMI}
                                            currentSubscriptionMainPackage={currentSubscriptionMainPackage}
                                            currentSubscriptionAddonUSD={currentSubscriptionAddonUSD}
                                            currentSubscriptionAddonEMI={currentSubscriptionAddonEMI}

                        />

                    </Tab.Pane>

                    {/*<Tab.Pane eventKey="three" id="v-pills-1">


                    </Tab.Pane>*/}


                </div>
            </div>
        </Tab.Container>

    );
}
