import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import axios from "axios";
import {Link, Navigate} from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {myConfig} from "../config";
import {PostWebxLaravel} from "./PostCompnent";
import PasswordChecklist from "react-password-checklist"

function TransitionUp(props) {
    return <Slide {...props} direction="up"/>;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class VerifyCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
            reset_email: '',
            access_code: '',
            new_password: '',
            confirm_password: '',
            redirects: false,
            loading: false,
            hidden: true,
            open: false,
            openprogress: false,
            setOpenProgress: false,
            submitted: false,
            password_change: false,
            isValid: false,
            errors: [],
        };

        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
    }

    handleClickOpen() {
        this.setState({setOpen: true});
        this.setState({openDialog: true});
    };

    handleClose() {
        this.setState({setOpen: false});
        this.setState({openDialog: false});
    };

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    handleClick = Transition => () => {
        this.setState({open: true, Transition});
    };

    handle(Transition) {
        this.setState({open: true, Transition});
    }

    handleClose = () => {
        this.setState({open: false});
    };

    toggleShow() {
        this.setState({hidden: !this.state.hidden});
    }

    componentWillMount() {


        var userEmail = sessionStorage.getItem("userEmail");
        this.setState({reset_email: userEmail})
    }

    goToLogin() {
        this.setState({submitted: true})
    }

    handleValidation() {
        // let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Email
        if (this.state.reset_email == "") {
            formIsValid = false;
            errors["reset_email"] = "Reset email is required";
        }
        //Email
        if (this.state.access_code == "") {
            formIsValid = false;
            errors["access_code"] = "Access code is required";
        }
        //Email
        if (this.state.new_password == "") {
            formIsValid = false;
            errors["new_password"] = "New password is required";
        }
        //Email
        if (this.state.confirm_password == "") {
            formIsValid = false;
            errors["confirm_password"] = "Confirm password is required";
        }

        if (this.state.isValid == false) {
            formIsValid = false;
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    login(e) {

        this.setState({loading: true});
        e.preventDefault();

        if (this.handleValidation()) {
            this.handleToggleProgress();
            let BaseUrl = myConfig.laravelUrl+'server-post';
            let formData = new FormData();    //formdata object

            formData.append('reset_email', this.state.reset_email);   //append the values with key, value pair
            formData.append('access_code', this.state.access_code);
            formData.append('password', this.state.new_password);
            formData.append('confirm_password', this.state.confirm_password);

            const config = {
                headers: {'content-type': 'multipart/form-data'}
            };

            axios.post(BaseUrl + "/resetPasswordByCode", formData, config)

                .then(response => {
                    if (response.data.status == "Success") {
                        let formData = new FormData();    //formdata object
                        formData.append('reset_email', this.state.reset_email);   //append the values with key, value pair
                        formData.append('password', this.state.new_password);
                        PostWebxLaravel("reset_password", formData).then(response => {
                            this.handleCloseProgress();
                            this.handleClickOpen();
                        });
                    } else {
                        this.handle(TransitionUp);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        }
    }

    onChange(e) {
        if (e.target.name=="new_password"){
            this.setState({password_change:true});
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        /*    if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {
                return <Navigate to='/active-dashboard'  />
            }
            if (this.state.redirects) {
                return <Navigate to='/active-dashboard'  />
            }*/

        return (

            <div>
                {this.state.submitted ? (
                    <Navigate to="/login"/>
                ) : ('')}
                <Dialog
                    className="success-message"
                    open={this.state.openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">"Password Successfully Changed"</DialogTitle>
                    {/*<DialogContent>*/}
                    {/*    <DialogContentText id="alert-dialog-slide-description">*/}

                    {/*    </DialogContentText>*/}
                    {/*</DialogContent>*/}
                    <DialogActions>
                        <Button onClick={this.goToLogin} color="primary" className="suc-btn">
                            Got it
                        </Button>
                    </DialogActions>
                </Dialog>
                <div id="outer-container" className="container-fluid">
                    <div id="turn-box"></div>
                    <div id="inside-container" className="container">
                        <div className="row row_clr main-row">

                            <div className="col-sm-6 logo-txt">
                                <img src={require('../images/webxpay-logo.svg')} alt="logo" className="logo"/>

                                <h1>Welcome Back!</h1>
                                <h3>Reset your password with the link you have received</h3>

                            </div>

                            <div className="col sign-box">
                                <div className="row">
                                    <div className="col-12 sign-top">
                                        <img src={require('../images/sign-in-icon.svg')} alt="sign-in-icon"
                                             className="sign-icon"/>
                                        <h2 className="sign-head">Forgot Password</h2>
                                    </div>
                                    <div className="col-12 form-type-1">

                                        <form className="row row_clr">
                                            <div className="col-12 p-0">
                                                <div className="form-group"><input type="text" className="form-control"
                                                                                   id=""
                                                                                   name="reset_email"
                                                                                   placeholder="Enter Your E-mail Address"
                                                                                   onChange={this.onChange}
                                                                                   value={this.state.reset_email}/>
                                                </div>
                                                <span className="error" style={{color: "red"}}>{this.state.errors['reset_email']}</span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="form-group"><input type="text" className="form-control"
                                                                                   id=""
                                                                                   name="access_code"
                                                                                   placeholder="Enter Reset Access Code"
                                                                                   onChange={this.onChange}/>
                                                </div>
                                                <span className="error" style={{color: "red"}}>{this.state.errors['access_code']}</span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="form-group"><input type="password"
                                                                                   className="form-control" id=""
                                                                                   name="new_password"
                                                                                   placeholder="Enter New Password"
                                                                                   onChange={this.onChange}/>
                                                </div>
                                                <span className="error" style={{color: "red"}}>{this.state.errors['new_password']}</span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="form-group"><input type="password"
                                                                                   className="form-control" id=""
                                                                                   name="confirm_password"
                                                                                   placeholder="Enter Confirm Password"
                                                                                   onChange={this.onChange}/></div>
                                                <span className="error" style={{color: "red"}}>{this.state.errors['confirm_password']}</span>
                                            </div>
                                            {this.state.password_change == true ? (
                                                <PasswordChecklist
                                                    className="pr-list"
                                                    rules={["minLength", "specialChar", "number", "capital", "match"]}
                                                    minLength={10}
                                                    value={this.state.new_password}
                                                    valueAgain={this.state.confirm_password}
                                                    onChange={(isValid) => {
                                                        this.setState({isValid})
                                                    }}
                                                />) : ('')}
                                            <div className="col-12 no-pad submit">
                                                <input id="submit-btn" type="submit" value="Reset Password"
                                                       onClick={this.login}/>
                                            </div>
                                        </form>

                                    </div>
                                    <div className="col-12 no-account">
                                        <p className="no-account-p">Change Your Mind? <Link className="caccount"
                                                                                            to="../login">Go Back To
                                            Sign-In</Link></p>
                                    </div>
                                    <div className="col-12 copy-txt">
                                        {/*     <p className="cop-p">By Signing In or Registering on the system, you confirm that
                                            you agree to our <a href="#" className="green-txt">Terms of Service</a> and <a
                                                href="#" className="green-txt">Privacy Policy</a>.</p>*/}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Snackbar
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={this.state.Transition}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        autoHideDuration={3500}
                        message={<span id="message-id">Invalid Username or Password</span>}
                    />
                </div>
            </div>
        );
    }

}