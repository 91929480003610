import Tab from "react-bootstrap/Tab";
import Tabs from 'react-bootstrap/Tabs';
import React, {useEffect, useState} from "react";
import Nav from "react-bootstrap/Nav";
import {PostCompnentWebx, PostComponentToken, PostWebxLaravel} from "../../PostCompnent";
import {ChangeSubscription} from "./ChangeSubscription";
import {ViewSubscription} from "./ViewSubscription";
import ReactPaginate from "react-paginate";
import {HostedSession} from "../webxpay.hostedsession";
import {WebxpayTokenizeInit} from "../WebxpayTokenize";
import {myConfig} from "../../../config";
import moment from "moment/moment";
import Frame from 'react-frame-component';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function PaymentMethods(props) {
    let formDatas = new FormData();    //formdata object
    const [paymentRedirection,setPaymentRedirection]=useState('');
    function onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    function popupWindow(url, windowName, win, w, h) {
        const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
        const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
        return win.open(
            url,
            windowName,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
        );
    }

    useEffect(() => {
    }, []);


    useEffect(() => {
        let card;
        if (props.tokenData && props.currencyData && props.secureUrl && props.bankMid && props.businessData && props.randomString) {

            getSavedCards();
            card = {
                number: "#card-number",
                securityCode: "#security-code",
                expiryMonth: "#expiry-month",
                expiryYear: "#expiry-year",
                nameOnCard: "#cardholder-name",
            };
            const afterInit = GenerateSession => {
                const handleSaveCardClick = () => {

                    document.getElementById('save-card-button').disabled = true;

                    GenerateSession(
                        session => handleSuccess(session),
                        error => handleErrors(error)
                    );
                };

                document.getElementById('save-card-button').addEventListener('click', handleSaveCardClick);

                return () => {
                    document.getElementById('save-card-button').removeEventListener('click', handleSaveCardClick);
                };
            };

            if (window.PaymentSession) {
                const hostedSession = new HostedSession();
                hostedSession.configure(card, afterInit);
            } else {
                console.error("Bank's payment session not initialized, please check the sample codes webxpay provided");
            }
        }

    }, [props.tokenData,props.currencyData,props.secureUrl,props.bankMid,props.businessData,props.randomString]);

    const handleSuccess = sessionId => {
        console.log('session id', sessionId);


        var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");

        // this.handleToggleProgress();
        let BaseUrl = myConfig.apiUrl;

       // props.businessData.email_address
        console.log(props);
        let customer = {
            'id': sessionStorage.getItem("merchant_id"),
            'email':'yugxv32sss55aaan@sssd.com',
            'firstName': "james",
            'lastName': "gordan",
            'contactNumber': "colombo",
            'postalCode': "78151",
            'country': "srilanka",
        };

        let data = JSON.stringify({
            'customer': customer,
            'currency': props.currencyData,
            'bankMID': props.bankMid,
            'secure3dResponseURL': props.secureUrl+'?reference_number='+props.randomString,
            'session': sessionId,
        });

        //let formData = new FormData();    //formdata object

        // formData.append(JSON.stringify(data));


        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + ' ' + props.tokenData
            },

        };

        PostComponentToken("cards/save3ds", data, config).then(response => {
            //alert(response.data.error)
            if (response.data.error==true){
                if (response.data.type=="3ds"){
                    let BaseUrl = myConfig.laravelWeb;
                    let formData = new FormData();
                    formData.append('paymentHtml',JSON.stringify(response.data.html3ds));
                    formData.append('customer',JSON.stringify(customer));
                    formData.append('reference_number',props.randomString);

                    PostWebxLaravel("subscription-api/convert-payment-view", formData).then(response => {

                        popupWindow(
                            BaseUrl + "load-secure-otp/" + response.data,
                            "test",
                            window,
                            1000,
                            800
                        );
                        const timer = setInterval(() => {
                            let formData = new FormData(); //formdata object
                            formData.append("reference_number", props.randomString);
                            PostWebxLaravel("subscription-payment/get-payment-status", formData).then(
                                (response) => {

                                    if(response.data.status==1){

                                        clearInterval(timer);
                                        window.location.reload();
                                    }

                                }
                            );
                        }, 5000);

                    });
                }
                toast(response.data.explanation);
            }else{
                if (response.data['html3ds']) {
                    let BaseUrl = myConfig.laravelWeb;
                    let formData = new FormData();
                    formData.append('paymentHtml',JSON.stringify(response.data.html3ds));
                    formData.append('customer',JSON.stringify(customer));
                    formData.append('reference_number',props.randomString);

                    PostWebxLaravel("subscription-api/convert-payment-view", formData).then(response => {

                        popupWindow(
                            BaseUrl + "load-secure-otp/" + response.data,
                            "test",
                            window,
                            1000,
                            800
                        );

                    });
                };
            }


            document.getElementById('save-card-button').disabled = false;
            document.getElementById('save-card-button').innerHTML = 'Save';
        });
    }
    const handleErrors = error => {
        document.getElementById('save-card-button').disabled = false;
        document.getElementById('save-card-button').innerHTML = 'Save';

        document.querySelectorAll('.err').forEach(element => (element.innerHTML = ''));
        document.querySelectorAll('.general-error').forEach(element => (element.innerHTML = ''));

        switch (error.type) {
            case 'fields_in_error': {
                const { cardNumber, expiryMonth, expiryYear, securityCode } = error.details;

                if (cardNumber) {
                    if (cardNumber === 'missing') {
                        document.querySelector('.card-number-error').innerHTML = 'Enter valid card number';
                    }
                    if (cardNumber === 'invalid') {
                        document.querySelector('.card-number-error').innerHTML = 'Invalid card number';
                    }
                }
                if (expiryMonth) {
                    if (expiryMonth === 'missing') {
                        document.querySelector('.exp-month-error').innerHTML = 'Enter expiration month';
                    }
                    if (expiryMonth === 'invalid') {
                        document.querySelector('.exp-month-error').innerHTML = 'Invalid expiration month';
                    }
                }
                if (expiryYear) {
                    if (expiryYear === 'missing') {
                        document.querySelector('.exp-year-error').innerHTML = 'Enter expiration year';
                    }
                    if (expiryYear === 'invalid') {
                        document.querySelector('.exp-year-error').innerHTML = 'Invalid expiration year';
                    }
                }
                if (securityCode) {
                    if (securityCode === 'missing') {
                        document.querySelector('.cvv-error').innerHTML = 'Enter CVV';
                    }
                    if (securityCode === 'invalid') {
                        document.querySelector('.cvv-error').innerHTML = 'Invalid CVV';
                    }
                }
                console.error('missing card details', error.details);
                break;
            }
            case 'request_timeout': {
                document.querySelectorAll('.general-error').forEach(element => {
                    element.innerHTML = `<span class="text-decoration-uppercase">${error.details}</span>`;
                });
                console.error('request time out', error.details);
                break;
            }
            case 'system_error': {
                if (error.details === 'cvv missing') {
                    document.querySelectorAll('.general-error').forEach(element => {
                        element.innerHTML = 'Enter CVV details';
                    });
                } else {
                    document.querySelectorAll('.general-error').forEach(element => {
                        element.innerHTML = error.details;
                    });
                }
                console.error('system error', error.details);
                break;
            }
            default:
                break;
        }
    };


    function getSavedCards(){
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + ' ' + props.tokenData
            },

        };
        let customer = {
            'id': sessionStorage.getItem("merchant_id"),
            'email':'harshakadilan@yahoo.com'
        };

        let data = JSON.stringify({
            'customer': customer,
        });

        PostComponentToken("cards/get", data, config).then(response => {
                console.log(response.data)
        });
    }


    return (

        <div>
            <ToastContainer/>
            <div className="modal-body">
                <div className="rht">
                    <div className="row pay-opts">

                        <div className="col-12">
                            <div className="tab-pane fade show active" id="pay-online"
                                 role="tabpanel"
                                 aria-labelledby="nav-pay-online-tab">
                                <div className="row administrator-information">
                                    <div className="col-12">

                                        <p className="p-us">Use Any of the Following <img
                                            src="images/cards/4.png"
                                            alt=""/> or <img
                                            src="images/cards/13.png" alt=""/></p>

                                        <div className="rg-container">
                                            <table
                                                className="transactions-table"
                                                summary="Hed"
                                            >
                                                <thead>
                                                <tr>
                                                    <th className="">#</th>
                                                    <th className="">Card Number</th>
                                                    <th className="">Set Default</th>
                                                    <th className="">Delete</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                </tbody>
                                            </table>

                                        </div>

                                        <div className="row row_clr fm">
                                            <div className="col-12 fm-in">
                                                <p className="p-us">Add new Card
                                                    :</p>

                                            </div>
                                        </div>

                                        <form className="row administrator-information">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Cardholder Name:</label>
                                                    <input type="text" id="cardholder-name" className="form-control"
                                                           title="card-holder name"
                                                           onChange={onChanges}
                                                           readOnly/>
                                                    <span className="text-danger card-holder-error err"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Card Number:</label>
                                                    <input type="text" id="card-number" className="form-control"
                                                           title="card number"
                                                           onChange={onChanges}
                                                           readOnly/>
                                                    <span className="text-danger card-number-error err"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Expiry Month: <small>(MM)</small></label>
                                                    <input type="text" id="expiry-month" className="form-control"
                                                           title="expiry month"
                                                           onChange={onChanges}
                                                           readOnly/>
                                                    <span className="text-danger exp-month-error err"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Expiry Year: <small>(YYYY)</small></label>
                                                    <input type="text" id="expiry-year" className="form-control"
                                                           title="expiry year"
                                                           onChange={onChanges}
                                                           readOnly/>
                                                    <span className="text-danger exp-year-error err"></span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Security Code:</label>
                                                    <input type="text" id="security-code" className="form-control"
                                                           title="security code"
                                                           onChange={onChanges}
                                                           readOnly/>
                                                    <span className="text-danger cvv-error err"></span>
                                                </div>
                                            </div>

                                            <div className="col-md-4 pcnt1">
                                                <button className="sub-btn"
                                                        id="save-card-button">Add New Card
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
