import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import {Navigation} from "./Navigation";
import {myConfig} from "../config";
import {TopBar} from "./TopBar";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Scrollbars} from "react-custom-scrollbars";
import Switch from "react-switch";
import moment from 'moment';

import {
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import {GetWebxLaravel, PostCompnentWebx, PostWebxLaravel,} from "./PostCompnent";
import {Footer} from "./Footer";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {SupportButton} from "./SupportButton";
import DateRangePicker from "react-bootstrap-daterangepicker";

export class PaymentLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_amount: "",
            payment_amount_print: "",
            payment_link: "",
            payment_link_description: "",
            reference_number:"",
            expiry_date:"",
            allowed_attempt_count:"",
            toasts: "",
            payment_link_tray: [],
            payment_link_array_data_count: 0,
            payment_link_array_data_page: 1,
            errors_link: [],
            lkr_available: "",
            usd_available: "",
            gbp_available: "",
            checked: false,
            switchState: {},
            currency_code: "",
            currency_id: 5,
            aud_available: "",
            link_phone_number: "",
            emi_only_checkbox: false,
            emi_only: null,

        };
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.generateLink = this.generateLink.bind(this);
        this.onChanges = this.onChanges.bind(this);
        this.setMoney = this.setMoney.bind(this);
        this.getLinks = this.getLinks.bind(this);
        this.deleteLink = this.deleteLink.bind(this);
        this.validateLink = this.validateLink.bind(this);
        this.setMoneyPrint = this.setMoneyPrint.bind(this);
        this.notify = this.notify.bind(this);
        this.switchChange = this.switchChange.bind(this);
        this.setDynamicSwitchState = this.setDynamicSwitchState.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onPrevClick = this.onPrevClick.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }



    handleCallback(start){

        const expiry_date = moment(start).format("DD-MM-YYYY");
        this.setState({
            expiry_date:expiry_date
        })

    }

    handleChange(e){

        let emi_only=0;
        if (e.target.checked){
             emi_only=1;
        }else{
             emi_only=0;
        }
        this.setState({
            emi_only_checkbox:e.target.checked,
            emi_only:emi_only
        })
    }

    notify() {
        toast("Copied to Clipboard");
    }

    onNextClick() {
        if (
            this.state.payment_link_array_data_page <
            this.state.payment_link_array_data_count
        ) {
            this.setState(
                (prevState) => ({
                    payment_link_array_data_page:
                        prevState.payment_link_array_data_page + 1,
                }),
                () => {
                    this.getLinks();
                }
            );
        }
    }

    onPrevClick() {
        if (this.state.payment_link_array_data_page > 1) {
            this.setState(
                (prevState) => ({
                    payment_link_array_data_page:
                        prevState.payment_link_array_data_page - 1,
                }),
                () => {
                    this.getLinks();
                }
            );
        }
    }

    validateLink() {
        let errors_link = {};
        let formIsValid = true;
        //Email
        //const regex = /^[0-9a-zA-Z&.# ,?()-:(\-)]+$/;

        if (
            this.state.currency_id == "" ||
            typeof this.state.currency_id == "undefined"
        ) {
            formIsValid = false;
            errors_link["currency_id"] = "Currency is required";
        }
        if (this.state.payment_amount == "") {
            formIsValid = false;
            errors_link["payment_amount"] = "Payment amount is required";
        }

        if (this.state.payment_link_description == "") {
            formIsValid = false;
            errors_link["payment_link_description"] = "Payment link description field is required";
        }

        // if (!this.state.payment_link_description.match(regex) ) {
        //     formIsValid = false;
        //     errors_link["payment_link_description"] = "Only alphanumeric values allowed";
        // }

        this.setState({errors_link: errors_link});
        return formIsValid;
    }

    deleteLink(id) {
        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: sessionStorage.getItem("laravel_token"),
            },
        };
        let formData = new FormData(); //formdata object
        formData.append("id", id);
        formData.append("_method", "DELETE");
        this.handleToggleProgress();
        PostWebxLaravel(
            "links/delete_links/" +
            sessionStorage.getItem("merchant_id") +
            "|" +
            sessionStorage.getItem("encrypted_merchant_id") +
            "|" +
            id,
            formData
        ).then((response) => {
            this.handleCloseProgress();
            this.getLinks();
        });
    }

    getLinks() {
        this.handleToggleProgress();
        GetWebxLaravel(
            "links/get_links/" +
            sessionStorage.getItem("merchant_id") +
            "|" +
            sessionStorage.getItem("encrypted_merchant_id") +
            `/${this.state.payment_link_array_data_page}`
        ).then((response) => {
            const {data, link_data_count} = response.data;
            this.handleCloseProgress();
            if (response) {
                this.setDynamicSwitchState(data);
                this.setState(
                    {
                        payment_link_tray: data,
                        payment_link_array_data_count: link_data_count,
                    },
                    () => {
                        this.handleCloseProgress();
                    }
                );
                console.log("this state", this.state);
            }
        });
        let formData = new FormData(); //formdata object
        PostCompnentWebx("getStoreCurrency", formData).then((response) => {
            let LKR = false;
            let USD = false;
            let GBP = false;
            let AUD = false;
            if (response.data.indexOf("LKR") > -1) {
                LKR = true;
            }
            if (response.data.indexOf("USD") > -1) {
                USD = true;
            }
            if (response.data.indexOf("GBP") > -1) {
                GBP = true;
            }
            if (response.data.indexOf("AUD") > -1) {
                AUD = true;
            }
            this.setState({
                lkr_available: LKR,
                usd_available: USD,
                gbp_available: GBP,
                aud_available: AUD,
            });
            if (LKR == true) {
                this.setState({
                    currency_id: 5,
                });
            } else if(USD==true) {
                this.setState({
                    currency_id: 2,
                });
            }else if(GBP==true){
                this.setState({
                    currency_id: 1,
                });
            }else if(AUD==true){
                this.setState({
                    currency_id: 6,
                });
            }
        });
    }

    setMoney(payment_amount, payment_link_description, currency_id,currency_code,reference_number,allowed_attempt_count,expiry_date,link_phone_number,emi_only) {
        let emi_onl=true;
        if(emi_only==null){
            emi_onl=false;
            emi_only=null
        }else{
            if(emi_only==0){
                emi_onl=false;
            }else{
                emi_onl=true;
            }
        }
        this.setState(
            {
                payment_amount: payment_amount,
                payment_link_description: payment_link_description,
                currency_id: currency_id,
                currency_code: currency_code,
                reference_number: reference_number!==null?reference_number:"",
                allowed_attempt_count: allowed_attempt_count!==null?allowed_attempt_count:"",
                link_phone_number: link_phone_number!==null?link_phone_number:"",
                expiry_date: expiry_date!==null?expiry_date:"",
                emi_only_checkbox: emi_onl,
                emi_only: emi_only,
            },
            () => {
                this.generateLink();
            }
        );
    }

    setMoneyPrint() {
        this.setState({payment_amount_print: this.state.payment_amount});
    }

    generateLink() {
        let errors_link = {};
        this.setState({errors_link: errors_link});
        if (this.validateLink()) {
            let formData = new FormData(); //formdata object
            let payment_amount = this.state.payment_amount;
            let currency_id = this.state.currency_id;
            let payment_link_description = this.state.payment_link_description;
            let expiry_date = this.state.expiry_date;
            let reference_number = this.state.reference_number;
            let allowed_attempt_count = this.state.allowed_attempt_count;
            let link_phone_number = this.state.link_phone_number;
            let emi_only = this.state.emi_only;
            let created_by = sessionStorage.getItem("user_name");

            formData.append("link_amount", payment_amount);
            formData.append(
                "merchant_id",
                sessionStorage.getItem("merchant_id") +
                "|" +
                sessionStorage.getItem("encrypted_merchant_id")
            );
            formData.append("currency_id", currency_id);
            formData.append("payment_link_description", payment_link_description);
            formData.append("created_by", created_by); //append the values with key, value pair
            formData.append("type", "laptop"); //append the values with key, value pair
            formData.append("expiry_date", expiry_date); //append the values with key, value pair
            formData.append("reference_number", reference_number); //append the values with key, value pair
            formData.append("allowed_attempt_count", allowed_attempt_count); //append the values with key, value pair
            formData.append("link_phone_number", link_phone_number); //append the values with key, value pair
            formData.append("emi_only", emi_only); //append the values with key, value pair

            this.handleToggleProgress();
            PostWebxLaravel("links/create_link", formData).then((response) => {
                this.handleCloseProgress();

                if (response.data.status == 200) {
                    this.getLinks();
                    this.setMoneyPrint();
                    // let payment_link = myConfig.laravelWeb + 'payment_link/generate_payment/' + response.data;
                    this.setState({
                        payment_link: response.data.data,
                    });
                } else {
                    let errors_link = {};
                    let formIsValid = true;

                    if (response.data.message.payment_link_description) {
                        errors_link["payment_link_description"] =
                            response.data.message.payment_link_description
                                .map((item) => item)
                                .join(", ");
                    }

                    if (response.data.message.link_amount) {
                        errors_link["payment_amount"] = response.data.message.link_amount
                            .map((item) => item)
                            .join(", ");
                    }

                    if (response.data.message.currency_id) {
                        errors_link["currency_id"] = response.data.message.currency_id
                            .map((item) => item)
                            .join(", ");
                    }

                    this.setState({errors_link: errors_link});
                }
            });
        }
    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    onChanges(e) {
        if(e.target.name=="currency_id"){
            let selectedText = e.target.options[e.target.selectedIndex].text;
            this.setState({currency_code:selectedText})

        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    switchChange = (index, event, checked, id) => {
        const list = Object.assign({}, this.state.switchState);

        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: sessionStorage.getItem("laravel_token"),
            },
        };

        let formData = new FormData(); //formdata object
        formData.append("id", id);
        formData.append("status", event == true ? 1 : 0);
        formData.append(
            "merchant_id",
            sessionStorage.getItem("merchant_id") +
            "|" +
            sessionStorage.getItem("encrypted_merchant_id")
        );
        this.handleToggleProgress();
        PostWebxLaravel("links/update_status", formData).then((response) => {
            this.handleCloseProgress();
            this.getLinks();
        });

        list["switch-" + index] = event;

        this.setState({
            switchState: list,
        });
    };

    setDynamicSwitchState = (list) => {
        if (!list) {
            return;
        }

        const switchState = {};

        list.forEach((item, index) => {
            switchState["switch-" + index] = item.is_active == 1 ? true : false;
        });

        this.setState({
            switchState: switchState,
        });
    };

    componentWillMount() {
        if (
            sessionStorage.getItem("userToken") ||
            localStorage.getItem("userToken")
        ) {
            var userToken =
                sessionStorage.getItem("userToken") ||
                localStorage.getItem("userToken");

            this.setState({userToken: userToken});
            this.getLinks();
        } else {
            this.setState({redirect: true});
        }
    }

    render() {
        var React = require("react");
        var QRCode = require("qrcode.react");
        var payment_link_tray = this.state.payment_link_tray;
        let payment_link = this.state.payment_link;
        console.log(payment_link);
        if (this.state.redirect) {
            return <Navigate to="/login"/>;
        }
        const renderThumb = ({style, ...props}) => {
            const thumbStyle = {
                borderRadius: 6,
                backgroundColor: "rgba(35, 49, 86, 0.8)",
            };
            return <div style={{...style, ...thumbStyle}} {...props} />;
        };
        const renderThumbOther = ({style, ...props}) => {
            const thumbStyle = {
                borderRadius: 6,
                backgroundColor: "rgba(35, 49, 86, 0.8)",
            };
            return <div style={{...style, ...thumbStyle}} {...props} />;
        };
        const CustomScrollbars = (props) => (
            <Scrollbars
                // renderThumbHorizontal={renderThumb}
                renderThumbVertical={renderThumb}
                renderTrackHorizontal={renderThumbOther}
                {...props}
            />
        );
        return (
            <div>
                <SupportButton/>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit"/>
                </Backdrop>

                <div
                    id="outer-container"
                    className="container-fluid color-orange outer-container-inside-page"
                >
                    <div id="turn-box"></div>
                    <Navigation pathname="payment-links"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="payment-links"/>

                        <div
                            id="activation-modal"
                            className="modal-dialog modal-dialog-centered payment-link-modal plmd"
                            role="document"
                        >
                            <div className="modal-content">
                                <div className="modal-body modal-type-2">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-4 lft">
                                                {/*<h3 className="tdh">Invoice Details</h3>*/}

                                                <div
                                                    id="create-new-payment-link-model"
                                                    className="modal-dialog modal-dialog-centered"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-body modal-type-2">
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col-12 cd-frm">
                                                                        <h3 className="tdh">Create Payment Link</h3>

                                                                        <div className="col-12 tdm-in p-0">
                                                                            <h6>Payment Link Amount</h6>
                                                                            <div
                                                                                className="rg-container "
                                                                                style={{
                                                                                    height: 300,
                                                                                    marginBottom: "45px",
                                                                                }}
                                                                            >
                                                                                <CustomScrollbars
                                                                                    autoHide
                                                                                    autoHideTimeout={500}
                                                                                    autoHideDuration={200}
                                                                                >
                                                                                    {payment_link_tray &&
                                                                                    payment_link_tray.length
                                                                                        ? payment_link_tray.map(
                                                                                            (list, key) => {
                                                                                                return (
                                                                                                    <div>
                                                                                                        <React.Fragment>
                                                                                                            <div
                                                                                                                className="row row_clr pla-in">
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="nt-btn"
                                                                                                                    onClick={() =>
                                                                                                                        this.setMoney(
                                                                                                                            list.link_amount,
                                                                                                                            list.payment_link_description,
                                                                                                                            list.currency_id,
                                                                                                                            list.get_currency?list.get_currency.code:"",
                                                                                                                            list.reference_number,
                                                                                                                            list.allowed_attempt_count,
                                                                                                                            list.expiry_date,
                                                                                                                            list.link_phone_number,
                                                                                                                            list.emi_only,
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    {list.get_currency?list.get_currency.code+' ':""}
                                                                                                                    <NumberFormat
                                                                                                                        value={
                                                                                                                            list.link_amount
                                                                                                                        }
                                                                                                                        displayType={
                                                                                                                            "text"
                                                                                                                        }
                                                                                                                        thousandSeparator={
                                                                                                                            true
                                                                                                                        }
                                                                                                                        prefix={""}
                                                                                                                    />
                                                                                                                    <i className="fas fa-money"></i>
                                                                                                                </button>

                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="nt-btn del-btn"
                                                                                                                    onClick={() =>
                                                                                                                        this.deleteLink(
                                                                                                                            list.id
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    Delete{" "}
                                                                                                                    <i className="fas fa-trash"></i>
                                                                                                                </button>
                                                                                                                <p>
                                                                                                                    <strong>
                                                                                                                        {
                                                                                                                            list.payment_link_description
                                                                                                                        }
                                                                                                                    </strong>
                                                                                                                </p>
                                                                                                                <label>
                                                                                                                    <Switch
                                                                                                                        key={key}
                                                                                                                        checked={
                                                                                                                            this.state.switchState["switch-" + key]
                                                                                                                        }
                                                                                                                        onChange={(event, checked) =>
                                                                                                                            this.switchChange(
                                                                                                                                key,
                                                                                                                                event,
                                                                                                                                checked,
                                                                                                                                list.id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    />
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <hr/>
                                                                                                        </React.Fragment>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                        : ""}
                                                                                </CustomScrollbars>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        width: "100%",
                                                                                        justifyContent: "space-between",
                                                                                        fontWeight: "600",
                                                                                        padding: "5px",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        id="prev"
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color:
                                                                                                this.state
                                                                                                    .payment_link_array_data_page ===
                                                                                                1 && "grey",
                                                                                        }}
                                                                                        onClick={this.onPrevClick}
                                                                                    >
                                                                                        Prev
                                                                                    </div>
                                                                                    <div
                                                                                        id="next"
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            color:
                                                                                                this.state
                                                                                                    .payment_link_array_data_page ===
                                                                                                this.state
                                                                                                    .payment_link_array_data_count &&
                                                                                                "grey",
                                                                                        }}
                                                                                        onClick={this.onNextClick}
                                                                                    >
                                                                                        Next
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <h6 className="sh">Currency</h6>
                                                                                <select
                                                                                    name="currency_id"
                                                                                    id="currency_id"
                                                                                    value={this.state.currency_id}
                                                                                    onChange={this.onChanges}
                                                                                    className="form-control"
                                                                                >
                                                                                    {this.state.lkr_available?(<option value="5">LKR</option>):('')}
                                                                                    {this.state.usd_available?(<option value="2">USD</option>):('')}
                                                                                    {this.state.gbp_available?(<option value="1">GBP</option>):('')}
                                                                                    {this.state.aud_available?(<option value="6">AUD</option>):('')}
                                                                                    {this.state.inr_available?(<option value="7">INR</option>):('')}

                                                                                </select>
                                                                            </div>
                                                                            <span
                                                                                className="error"
                                                                                style={{color: "red"}}
                                                                            >
                                        {this.state.errors_link["currency_id"]}
                                      </span>
                                                                            <div className="form-group">
                                                                                {/*  <input type="text"
                                                                                       className="form-control"
                                                                                       name="payment_amount"
                                                                                       placeholder="Payment Amount"
                                                                                       value={this.state.payment_amount}
                                                                                       onChange={this.onChanges}/>*/}
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    prefix={""}
                                                                                    className="form-control"
                                                                                    name="payment_amount"
                                                                                    placeholder="Payment Amount"
                                                                                    value={this.state.payment_amount}
                                                                                    onValueChange={(values) => {
                                                                                        const {formattedValue, value} =
                                                                                            values;
                                                                                        this.setState({
                                                                                            payment_amount: value,
                                                                                        });
                                                                                    }}
                                                                                />

                                                                                <span
                                                                                    className="error"
                                                                                    style={{color: "red"}}
                                                                                >
                                          {
                                              this.state.errors_link[
                                                  "payment_amount"
                                                  ]
                                          }
                                        </span>
                                                                            </div>






                                                                                <div className="form-group">

                                                                                    <input type="text" className="form-control" placeholder="Payment reference " name="reference_number"   onChange={this.onChanges}   value={
                                                                                        this.state.reference_number
                                                                                    } />
                                                                                    <span className="error" style={{color: "red"}}> {this.state.errors_link["reference_number"]}</span>
                                                                                </div>

                                                                            <div className="form-group">

                                                                                <DateRangePicker
                                                                                    initialSettings={{
                                                                                        singleDatePicker: true,
                                                                                        showDropdowns: true,
                                                                                        autoApply: true,
                                                                                        minDate: moment(),
                                                                                        maxDate: moment().add(5, 'years')
                                                                                    }}


                                                                                    onCallback={(start) => this.handleCallback(start)}

                                                                                >

                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="expiry_date"
                                                                                            placeholder="Expiry Date"
                                                                                            value=""
                                                                                            autoComplete="off"
                                                                                            value={this.state.expiry_date}
                                                                                            style={{flex: '1'}}
                                                                                        />
                                                                                        <button
                                                                                            type="button"
                                                                                            className="date-btn"
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                   alignItems: 'center',
                                                                                                padding: '8px 12px',
                                                                                                backgroundColor: '#77b53c',
                                                                                                color: 'white',
                                                                                                border: 'none',
                                                                                             
                                                                                                marginRight: '5px',
                                                                                                cursor: 'pointer',
                                                                                                marginBottom:"12px"
                                                                                            }}
                                                                                        >
                                                                                            <i className="far fa-calendar-alt"
                                                                                               aria-hidden="true"></i>
                                                                                            <i className="fas fa-caret-down"
                                                                                               aria-hidden="true"></i>
                                                                                        </button>

                                                                                    </div>
                                                                                </DateRangePicker>
                                                                            </div>

                                                                            <div className="form-group">

                                                                                <input type="text"
                                                                                       className="form-control"
                                                                                       placeholder="Attempt Count "
                                                                                       name="allowed_attempt_count"
                                                                                       onChange={this.onChanges} value={
                                                                                    this.state.allowed_attempt_count
                                                                                }/>
                                                                                <span className="error"
                                                                                      style={{color: "red"}}></span>
                                                                            </div>
                                                                            {sessionStorage.getItem("sms_enabled")==1?(
                                                                                <div className="form-group">

                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           placeholder="Phone Number "
                                                                                           name="link_phone_number"
                                                                                           onChange={this.onChanges}
                                                                                           value={
                                                                                               this.state.link_phone_number
                                                                                           }/>
                                                                                    <span className="error"
                                                                                          style={{color: "red"}}></span>
                                                                                </div>
                                                                                ):('')
                                                                            }
                                                                            <div className="form-group">
                                                                                {/*  <input type="text"
                                                                                       className="form-control"
                                                                                       name="payment_amount"
                                                                                       placeholder="Payment Amount"
                                                                                       value={this.state.payment_amount}
                                                                                       onChange={this.onChanges}/>*/}



                                                                                <textarea
                                                                                    name="payment_link_description"
                                                                                    className="form-control tfc"
                                                                                    cols="30"
                                                                                    rows="3"
                                                                                    onChange={this.onChanges}
                                                                                    value={
                                                                                        this.state.payment_link_description
                                                                                    }
                                                                                    placeholder="Payment Description"
                                                                                ></textarea>

                                                                                <span
                                                                                    className="error"
                                                                                    style={{color: "red"}}
                                                                                >
                                          {
                                              this.state.errors_link[
                                                  "payment_link_description"
                                                  ]
                                          }
                                        </span>
                                                                            </div>

                                                                            {sessionStorage.getItem("emi_enabled")==1?(

                                                                                <div className="form-group">

                                                                                    <label key="emi_only_checkbox"><b>Xsplit &nbsp;</b>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            checked={this.state.emi_only_checkbox} // Convert undefined to false
                                                                                            onChange={this.handleChange}
                                                                                            name="emi_only"
                                                                                        />

                                                                                    </label>

                                                                                </div>
                                                                            ):('')
                                                                            }

                                                                        </div>

                                                                        <button
                                                                            type="button"
                                                                            className="nxt-btn"
                                                                            onClick={this.generateLink}
                                                                        >
                                                                            Generate Link &nbsp;&nbsp;
                                                                            <i className="fas fa-save"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 rht">
                                                <div className="row">
                                                    <div className="col-12 details-box d-inv-box">
                                                        <div className="col-12 dbx-in">
                                                            <h3>Payment Link Preview</h3>
                                                            {payment_link !== "" ? (
                                                                <div className="row row_clr dit-in">
                                                                    <div className="col-12 lht">
                                                                        <div className="row">
                                                                            {/*<div className="col-12 lgo">*/}
                                                                            {/*    <img src="images/invoice/l1.png" alt="" />*/}
                                                                            {/*</div>*/}
                                                                            <div className="col-md-6 col-12">
                                                                                <QRCode
                                                                                    value={payment_link}
                                                                                    size={220}
                                                                                />
                                                                                <p className="qrp">
                                                                                    <NumberFormat
                                                                                        value={(
                                                                                            Math.round(
                                                                                                this.state
                                                                                                    .payment_amount_print * 100
                                                                                            ) / 100
                                                                                        ).toFixed(2)}
                                                                                        displayType={"text"}
                                                                                        thousandSeparator={true}
                                                                                        prefix={
                                                                                           this.state.currency_code+' '
                                                                                        }
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                            <ToastContainer/>
                                                                            <div className="col-md-6 col-12">
                                                                                <div className="sd">
                                                                                    <h5>Share</h5>
                                                                                    <FacebookShareButton
                                                                                        url={payment_link}
                                                                                    >
                                                                                        <FacebookIcon
                                                                                            size={40}
                                                                                            logoFillColor="white"
                                                                                        />
                                                                                    </FacebookShareButton>
                                                                                    <TwitterShareButton
                                                                                        url={payment_link}
                                                                                    >
                                                                                        <TwitterIcon
                                                                                            size={40}
                                                                                            logoFillColor="white"
                                                                                        />
                                                                                    </TwitterShareButton>

                                                                                    <WhatsappShareButton
                                                                                        url={payment_link}
                                                                                    >
                                                                                        <WhatsappIcon
                                                                                            size={40}
                                                                                            logoFillColor="white"
                                                                                        />
                                                                                    </WhatsappShareButton>

                                                                                    <ViberShareButton
                                                                                        url={payment_link}>
                                                                                        <ViberIcon
                                                                                            size={40}
                                                                                            logoFillColor="white"
                                                                                        />
                                                                                    </ViberShareButton>

                                                                                    <TelegramShareButton
                                                                                        url={payment_link}
                                                                                    >
                                                                                        <TelegramIcon
                                                                                            size={40}
                                                                                            logoFillColor="white"
                                                                                        />
                                                                                    </TelegramShareButton>

                                                                                    <CopyToClipboard
                                                                                        size={40}
                                                                                        text={payment_link}
                                                                                    >
                                                                                        <button
                                                                                            onClick={this.notify}
                                                                                            className="cpy-btn"
                                                                                            style={{
                                                                                                transform: "translateY(1px)",
                                                                                            }}
                                                                                        >
                                                                                            <i className="far fa-copy"></i>
                                                                                        </button>
                                                                                    </CopyToClipboard>
                                                                                </div>

                                                                                <a
                                                                                    href={payment_link}
                                                                                    className="p-btn plpb"
                                                                                    target="_blank"
                                                                                >
                                                                                    Pay Now &nbsp;&nbsp;
                                                                                    <i className="fas fa-credit-card"></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>

                                                        <p className="det-p">
                                                            {this.state.payment_link_description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer/>
                    </div>
                </div>
            </div>
        );
    }
}
