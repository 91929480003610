import Modal from "react-bootstrap/Modal";
import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import '../../../../css/bootstrap.min.css';
import '../../../../css/supplier_modal/master-model.css';
import '../../../../css/supplier_modal/screen-model.css';
import '../../../../css/supplier_modal/switch.css';
import Nav from 'react-bootstrap/Nav';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Badge from "react-bootstrap/Badge";
import {AllSupplierTransactions} from "../TransactionManagement/AllSupplierTransactions";
import {SupplierCreationForm} from "../SupplierManagement/SupplierCreationForm";
import {SupplierLeftPane} from "../SupplierManagement/SupplierLeftPane";
import { useContext } from "react";
import { supplierIdContext } from "./LoadModalFunctions";
import {PostCompnentWebxBpsp, PostWebxLaravel} from "../../../PostCompnent";
import { useEffect } from "react";
import {AllSupplierInvoices} from "../TransactionManagement/AllSupplierInvoices";

export const supplierDetailContext=React.createContext([]);
export function SupplierDetailModal(props){

    const submitRef = useRef();
    const clickSupplier = useRef();
    const clickTransaction = useRef();
    const [supplierData, setSupplierData] = useState(props.supplier_data);
    const [modalShows, setModalShows] = React.useState(false);
    const supplier_id = useContext(supplierIdContext);
    const [bankData, setBankData] = useState(props.bankData);
    const [supplierTypes, setSupplierTypes] = useState(props.supplierTypes);



    return (
    <supplierDetailContext.Provider value={supplierData}>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="activation-modal"


        >
            <Modal.Body className="modal-type-2">
                <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={props.onHide}>X</div>

                <Tab.Container className="container-fluid" defaultActiveKey="first">
                    <div className="row">

                       <SupplierLeftPane submitRefs={submitRef} clickSupplier={clickSupplier} clickTransaction={clickTransaction} supplierData={props.supplier_data} />
                        <div className="col-md-8 rht">
                            <Tab.Content className="tab-content" id="v-pills-tabContent">

                                <SupplierCreationForm type="edit_supplier"  submitRef={submitRef} hideElement={props.onHide}
                                                      supplierData={props.supplier_data} onBoard={props.onBoard} setSupplierData={setSupplierData}
                                                      bankData={props.bankData}
                                                      supplierTypes={props.supplierTypes}
                                                      onReload={props.onReload}
                                />
                                <AllSupplierTransactions supplier_data={props.supplier_data} clickTransaction={clickTransaction} />
                                <AllSupplierInvoices supplier_data={props.supplier_data} clickSupplier={clickSupplier}  />

                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </Modal.Body>

        </Modal>
        </supplierDetailContext.Provider>
    )
}