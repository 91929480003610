import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import "../../../../css/bootstrap.min.css";
import "../../../../css/supplier_modal/master-model.css";
import "../../../../css/supplier_modal/screen-model.css";
import "../../../../css/supplier_modal/switch.css";
import NumberFormat from "react-number-format";
import {
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import LoadingSpinner from "../../../LoadingSpinner";
import { myConfig } from "../../../../config";
import {
  GetWebxLaravel,
  PostCompnentWebxBpsp,
  PostWebxLaravel,
} from "../../../PostCompnent";
import { useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import {decode} from "html-entities";

export function ViewSupplierTransactionReceipt(props) {

  useEffect(() => {

  }, []);
  function CallInvoice(){

  }


  let currency_id = "LKR";
  let invoiceDetail=props.invoiceDetail
  let invoiceLineItems=props.invoiceLineItems;
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="activation-modal"
      >
        <Modal.Body className="modal-type-2">
          <div
            className="colse-mdl"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            X
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 lft">
                 <h3 className="tdh"></h3>

                <div className="col-12 tdm-in">
                  <h6>Transaction Value</h6>
                  <h3 className="tdh">
                    <NumberFormat
                      value={invoiceDetail.transaction_amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"LKR "}
                    />
                  </h3>

                  <h6>Handling Fee</h6>
                  <h5 className="sh">
                    {invoiceDetail.supplier_fee==0.00?(''):(   <NumberFormat
                        value={invoiceDetail.supplier_fee}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"LKR "}
                    />)}
                    {invoiceDetail.merchant_fee==0.00?(''):(   <NumberFormat
                        value={invoiceDetail.merchant_fee}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"LKR "}
                    />)}
                  </h5>

                  <h6>Supplier Name</h6>
                  <h5 className="sh">{invoiceDetail.account_name}</h5>

                  <h6>Supplier Reference</h6>
                  <h5 className="sh">
                    {invoiceDetail.order_refference_number}
                  </h5>

                  <h6>Payment Method</h6>
                  <h5 className="sh">
                    {

                      (() => {
                        if(invoiceDetail.card_type){
                        let default_size = "35px";
                        let max_size = "63px";
                        if (invoiceDetail.card_type == "FriMi (LKR)" || invoiceDetail.card_type == "Genie Visa Master (LKR)" || invoiceDetail.card_type == "UPay (LKR)") {
                          default_size = "35px";

                        } else {
                          default_size = max_size;
                        }

                        if (invoiceDetail.card_type.toLowerCase() == "visa")
                          return <img style={{width: "35px"}} src={myConfig.laravelWeb + 'gateway_logos/visa.jpg'}
                                      alt=""/>;
                        if (invoiceDetail.card_type.toLowerCase() == "master" || invoiceDetail.card_type.toLowerCase() == "mastercard")
                          return <img style={{width: "35px"}}
                                      src={myConfig.laravelWeb + 'gateway_logos/master.jpg'} alt=""/>;
                        else
                          return <img style={{width: default_size}}
                                      src={myConfig.laravelWeb + 'gateway_logos/' + invoiceDetail.payment_gateway_id + '.jpg'}
                                      alt=""/>
                        }else {
                          return ('-')
                        }
                      })

                    }

                    {invoiceDetail.card_number_last_four!=="" && invoiceDetail.card_number_last_four!=="0"?("XXXX-"+invoiceDetail.card_number_last_four):('Not Available')}
                  </h5>

                  <h6>Clearance Status</h6>
                  <h5 className="sh">
                    {" "}
                    {invoiceDetail.supplier_settlement_status == 2 ? (
                      <>
                        <span className="dot green">
                          <i className="fas fa-circle" aria-hidden="true"></i>{" "}
                        </span>
                        Approved
                      </>
                    ) : (
                      ""
                    )}
                    {invoiceDetail.supplier_settlement_status == 3 ? (
                      <>
                        <span className="dot red">
                          <i className="fas fa-circle" aria-hidden="true"></i>{" "}
                        </span>
                        Declined
                      </>
                    ) : (
                      ""
                    )}
                    {invoiceDetail.supplier_settlement_status == 1 ? (
                      <>
                        <span className="dot yellow">
                          <i className="fas fa-circle" aria-hidden="true"></i>{" "}
                        </span>
                        Pending
                      </>
                    ) : (
                      ""
                    )}
                    {invoiceDetail.supplier_settlement_status ==
                    4 ? (
                        <>
                              <span className="dot yellow">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                          In-Progress
                        </>
                    ) : (
                        ""
                    )}
                  </h5>
                </div>

               {/*  <div className="sco-share">
                  <WhatsappShareButton url="www.google.com">
                    <WhatsappIcon size={40} logoFillColor="white" />
                  </WhatsappShareButton>

                  <ViberShareButton url="www.facebook.com">
                    <ViberIcon size={40} logoFillColor="white" />
                  </ViberShareButton>
                </div>
                <button type="button" className="nxt-btn">
                  SHARE RECEIPT &nbsp;&nbsp;
                  <i className="fas fa-envelope" aria-hidden="true"></i>
                </button>*/}


              </div>
              <div className="col-md-8 rht">
                <div className="row">
                  <div className="col-sm-3 col-12 tt-icns">
                    <div className="icon-box">
                      <img
                        src={require("../../../../images/transactions/money.svg")}
                        alt="money"
                      />
                    </div>
                    <p>Transaction Initialized</p>
                    <p className="sp">
                      {/* {moment(single_invoice_details.date_of_issuance).format('ll')}*/}
                    </p>
                  </div>
                  <div className="col-sm-3 col-12 tt-icns fu">
                    <div
                        className="icon-box full-filled"
                    >
                      <img
                        src={require("../../../../images/transactions/invoice.svg")}
                        alt="money"
                      />
                    </div>
                    <p>Transaction Completed</p>
                    <p className="sp">Completed</p>
                  </div>
                  <div className="col-sm-3 col-12 tt-icns fu">
                    <div
                      className={
                        invoiceDetail.supplier_settlement_status == 2
                            ? "icon-box full-filled"
                            : "icon-box half-filled"
                      }
                    >
                      <img
                        src={require("../../../../images/transactions/bank.svg")}
                        alt="money"
                      />
                    </div>
                    <p>
                      {invoiceDetail.supplier_settlement_status == 2?
                          ('Settled to Account'):('Settlement In-progress')}
                      <span style={{ color: "#e6562f" }}> </span>
                    </p>
                    <p className="sp">{currency_id == 2 ? "datte" : ""}</p>
                  </div>

                  <div className="col-12 details-box d-inv-box">
                    <div className="col-12 dbx-in">
                      <h3>Receipt </h3>

                      <div className="row row_clr dit-in">
                        <div className="col-md-8 lht">
                          <div className="row">
                              <div className="col-md-6 col-12">
                                <p style={{
                                  font: "10px/12px SFPD Bold",
                                  letterSpacing: "0.13px",
                                  color: "#292926",
                                  marginBottom: "10px"
                                }}>Invoice Reference</p>
                                <h4 className="total-num" style={{font: "16px/14px SFPD Bold",
                                  letterSpacing: 0,
                                  color: "#77b53c"}}>
                                  {props.invoiceReference}
                                </h4>
                              </div>



                          </div>
                        </div>
                        <div className="col-md-4 rhs">
                        

                          <p>Total Payable</p>
                          <h4 className="total-num">
                            <NumberFormat
                              value={invoiceDetail.transaction_amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"LKR "}
                            />
                          </h4>
                        </div>
                        <div className="col-sm-12 rht img-box p-0">
                          <div className="rg-container rg-container-type-2">
                            <div className="table-outer">
                            <table
                              className="transactions-table invoice-table tt-type-2"
                              summary="Hed"
                            >
                              <thead>
                                <tr>
                                  <th className="">#</th>
                                  <th className="">Items</th>
                                  <th className="">Attachment</th>
                                  <th className=""></th>
                                  <th className="">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoiceLineItems && invoiceLineItems.length
                                  ? invoiceLineItems.map((lineitem, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{lineitem.description}</td>
                                          <td>
                                            {lineitem.attachment!==null?(
                                                    <a
                                                        href={
                                                          myConfig.laravelWeb +
                                                          "uploads/bpsp_invoice_line_items/" +
                                                          lineitem.attachment
                                                        }
                                                        target="_blank"
                                                    >
                                                      {" "}
                                                      <i
                                                          className="fa fa-file-pdf-o"
                                                          aria-hidden="true"
                                                          style={{ color: "red" }}
                                                      ></i>
                                                    </a>
                                            ):
                                                ( '')}

                                          </td>
                                          <td></td>
                                          <td>
                                            <NumberFormat
                                                value={lineitem.price}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"LKR "}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : ""}

                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>SubTotal</td>
                                  <td>
                                    <NumberFormat
                                        value={invoiceDetail.subtotal}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"LKR "}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>Handling Fee {invoiceDetail.supplier_fee==0.00?(''):('(Supplier Fee) ')}  {invoiceDetail.merchant_fee==0.00?(''):('(Merchant Fee) ')}</td>
                                  <td>{invoiceDetail.supplier_fee==0.00?(''):(   <NumberFormat
                                      value={invoiceDetail.supplier_fee}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"LKR "}
                                  />)}
                                      {invoiceDetail.merchant_fee==0.00?(''):(   <NumberFormat
                                          value={invoiceDetail.merchant_fee}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={"LKR "}
                                      />)}
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>Total Payable</td>
                                  <td> <NumberFormat
                                      value={invoiceDetail.transaction_amount}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"LKR "}
                                  /></td>
                                </tr>
                              </tbody>
                            </table>
                            </div>
                            {/* <div className="tac">
                              <h6>Attachments</h6>
                              <p></p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <button ref={props.clickIndividualInvoice}  onClick={CallInvoice} style={{display:"none"}}></button>
    </>
  );
}
