import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "../src/css/bootstrap.min.css";
import "../src/css/element_css.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "../src/css/master.css";
import "../src/css/reset.css";
import "../src/css/master-new.css";
import "../src/css/suplliers.css";


import { MainScript } from "./MainScript";
import { ResponsivePage } from "./components/ResponsivePage";

import ReactGA from "react-ga";
import { BrowserRouter } from "react-router-dom";
const TRACKING_ID = "UA-177237897-2"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  window.onbeforeunload = function () {
    localStorage.clear();
  };

  return (
    <div className="App">

        <ResponsivePage />
        <MainScript />

    </div>
  );
}

export default App;
