import React, { PureComponent } from 'react';
import {myConfig} from "../config";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DataTable from "@bit/adeoy.utils.data-table";

import {
    PieChart, Pie, Sector, Cell, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,Legend
} from 'recharts';
import {Navigation} from "./Navigation";

import {TopBar} from "./TopBar";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import {GetWebxLaravel, PostCompnentWebx, PostWebxLaravel} from "./PostCompnent";
import Dropdown from "react-bootstrap/Dropdown";
import {Footer} from "./Footer";
import {CSVLink} from "react-csv";



const data = [
    { name: 'Genie', value: 400 },
    { name: 'Visa', value: 300 },
    { name: 'Master', value: 300 },
];



const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
                                   cx, cy, midAngle, innerRadius, outerRadius, percent, index,
                               }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const click = (row) => {

};

export class InvoiceProductServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
            items_data:[],
            product_data_filters:[],
            items_data_render:[],
            invoice_data:[],
            openprogress: false,
            setOpenProgress: false,
            modalShowAddInvoice:false,
            setModalShowAddInvoice:false,
            item_name:'',
            product_price:'',
            description:'',
            template_data:[],
            offset: 0,
            perPage: 10,
            currentPage: 0,
            product_id_search:'',
            product_name_search:'',
            product_price_search:'',
            productServiceTitle:'Add Product/Service',
            modalToggleFunction:this.addProductNew,
            item_id:'',
            save_button_text:'Please Wait...',
            validate_err:[],
            csvData:[],
            lkr_available:false,
            usd_available:false
        };
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.fetchDataFirst = this.fetchDataFirst.bind(this);
        this.addRemoveActive = this.addRemoveActive.bind(this);
        this.viewModalInvoice = this.viewModalInvoice.bind(this);
        this.modalCloseInvoice = this.modalCloseInvoice.bind(this);
        this.onChanges = this.onChanges.bind(this);
        this.addProductNew = this.addProductNew.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
        this.fetchInvoiceData = this.fetchInvoiceData.bind(this);
        this.loadTable = this.loadTable.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.clearItem = this.clearItem.bind(this);
        this.editProductNew = this.editProductNew.bind(this);
        this.saveEdited = this.saveEdited.bind(this);
        //validate product
        this.vaildateProduct=this.vaildateProduct.bind(this);
        this.deleteProduct=this.deleteProduct.bind(this);
        this.generateCSV=this.generateCSV.bind(this);
    }

    deleteProduct(product_id){

            let BaseUrl = myConfig.laravelUrl;
            let lar_config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': sessionStorage.getItem("laravel_token"),

                },
            };

            let formData = new FormData();    //formdata object


            formData.append('_method', 'delete');

            this.handleToggleProgress();
            PostWebxLaravel("products/delete_product/" + sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+product_id, formData).then(response => {

                this.handleCloseProgress();
                this.fetchDataFirst();

            });

    }

    vaildateProduct(){
        let validate_err = {};
        let formIsValid = true;
        //Email

        if(this.state.item_name==""){
            formIsValid = false;
            validate_err["item_name"] = "Cannot be empty";
        }
        if(this.state.product_price==""){
            formIsValid = false;
            validate_err["product_price"] = "Cannot be empty";
        }
        if(this.state.description==""){
            formIsValid = false;
            validate_err["description"] = "Cannot be empty";
        }

        this.setState({validate_err: validate_err});
        return formIsValid;
    }
    saveEdited(){
        if (this.vaildateProduct()) {
            this.handleToggleProgress();
            let BaseUrl = myConfig.laravelUrl;
            let lar_config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': sessionStorage.getItem("laravel_token"),

                },
            };

            let formData = new FormData();    //formdata object


            formData.append('item_name', this.state.item_name);   //append the values with key, value pair
            formData.append('product_price', this.state.product_price);
            formData.append('description', this.state.description);
            formData.append('_method', 'put');

            this.handleToggleProgress();
            PostWebxLaravel("products/update_product/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+ this.state.item_id, formData).then(response => {
                this.modalCloseInvoice();
                this.handleCloseProgress();
                this.setState({
                    product_price: '',
                    item_name: '',
                    description: '',
                }, () => {
                    this.fetchDataFirst();
                });

            });
        }
    }
    editProductNew(list_id){
          let BaseUrl = myConfig.laravelUrl;   let lar_config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': sessionStorage.getItem("laravel_token"),

            },
        };
        this.handleToggleProgress();
        GetWebxLaravel("products/get_single_product/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+list_id).then(response=>{

            if (typeof response !== 'undefined') {
                this.handleCloseProgress();
                let data = response.data;
                this.setState({
                    save_button_text: 'Update Product',
                    item_name: data.item_name,
                    product_price: data.product_price,
                    description: data.description,
                    item_id: list_id,
                })
            }
        });

        this.setState({
            modalShowAddInvoice:true,
            setModalShowAddInvoice:true,
            modalToggleFunction:this.saveEdited,
            validate_err:[],

        }, () => {
            var modelD = document.getElementById("ani-modal");
            modelD.classList.add("ancd-mod");

        });


    }
    generateCSV(response){
        const csvData = [
            ["PRODUCT NAME", "PRODUCT PRICE","DESCRIPTION"]
        ];
        response.data.map((value, index) => {
                csvData.push([value.item_name,value.product_price,value.description,]);
        });
        this.setState({
            csvData: csvData,
        });
    }
    searchItems(){
        let formData = new FormData();    //formdata object
        let product_id_search = this.state.product_id_search;
        let product_name_search = this.state.product_name_search;
        let product_price_search = this.state.product_price_search;

        formData.append('item_id', product_id_search);
        formData.append('item_name', product_name_search);
        formData.append('item_price', product_price_search);

        this.handleToggleProgress();
        PostWebxLaravel("products/get_products_with_filters", formData).then(response => {
            this.setState({
                items_data: response.data,
                product_data_filters: response.data,
                //   single_invoice_details:response.data,
            }, () => {
                this.handleCloseProgress();
                this.loadTable();
                this.generateCSV(response)
            });
        });
    }
    clearItem(){
        GetWebxLaravel("products/get_products/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response) {
                this.handleCloseProgress();
                this.setState({
                    items_data: response.data,
                    product_data_filters: response.data,
                    //   single_invoice_details:response.data,
                }, () => {
                    this.handleCloseProgress();
                    this.loadTable();
                    this.generateCSV(response)

                });
            }
        });
        this.setState({
            product_id_search:'',
            product_name_search:'',
            product_price_search:''
        }, () => {
            this.loadTable()
        });
    }
    modalCloseInvoice(){
        this.setState({
            modalShowAddInvoice:false,
            setModalShowAddInvoice:false
        })
        if ( document.getElementById("activation-modal")) {
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'visible';
        }
    }
    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    myFunc(e){

        var val = e;
        var re = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)$/g;
        var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
        if (re.test(val)) {
            //do something here

        } else {
            val = re1.exec(val);
            if (val) {
                e = val[0];
            } else {
                e = "";
            }
        }
        return e;
    }
    onChangeNumber(e){
        if (e.target.name == "product_price") {
            let new_val=this.myFunc(e.target.value)
            this.setState({  [e.target.name]: new_val})
        }else{
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                this.setState({[e.target.name]: e.target.value})
            }
        }
    }
    addRemoveActive(){
        var plusButton = document.getElementById("plus-button");
        var plusMenu = document.getElementById("plus-menu");
        if (plusMenu.classList.contains('active')) {
            plusMenu.classList.remove("active");
            plusButton.classList.remove("active");
        } else {
            plusMenu.classList.add("active");
            plusButton.classList.add("active");
        }
        var myElement = document.getElementsByClassName('l-item');
        for (var i = 0; i < myElement.length; i++) {
            myElement[i].onclick = function() {
                var plusButton = document.getElementById("plus-button");
                var plusMenu = document.getElementById("plus-menu");
                plusMenu.classList.remove("active");
                plusButton.classList.remove("active");
            }
        }
    }

    handleCallback(start, end, label) {
        this.setState({
            startDate:moment(start).subtract(30, 'days').format('ll'),
            endDate:moment(end).format('ll'),
        });
        this.fetchData(end,start);
    }

    addProductNew(){
        if (this.vaildateProduct()) {
            this.handleToggleProgress();
            let BaseUrl = myConfig.laravelUrl;
            let lar_config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': sessionStorage.getItem("laravel_token"),

                },
            };
            let formData = new FormData();    //formdata object


            formData.append('item_name', this.state.item_name);   //append the values with key, value pair
            formData.append('product_price', this.state.product_price);
            formData.append('description', this.state.description);
            formData.append('merchant_id', sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"));
            this.handleToggleProgress();
            PostWebxLaravel("products/create_product", formData).then(response => {
                this.handleCloseProgress();
                this.modalCloseInvoice();
                this.handleCloseProgress();
                this.setState({
                    product_price: '',
                    item_name: '',
                    description: '',

                }, () => {
                    this.fetchDataFirst();
                });
            });
        }

    }
    fetchInvoiceData(){
        this.handleToggleProgress();
        GetWebxLaravel( "invoice_detail/get_invoice/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{

            if (response) {
                this.handleCloseProgress();
                this.setState({
                    invoice_data: response.data,
                });
            }
        });
        this.handleToggleProgress();
        GetWebxLaravel( "template_detail/get_template/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{

            if (response) {
                this.handleCloseProgress();
                this.setState({
                    template_data: response.data,
                }, () => {
                    // this.loadTable();
                });
            }
        });

        PostCompnentWebx("getStoreCurrency").then(response=>{
            let LKR=false;
            let USD=false;
            if (response.data.indexOf("LKR") > -1){
                LKR=true;
            }
            if (response.data.indexOf("USD") > -1){
                USD=true;
            }
            this.setState({
                lkr_available:LKR,
                usd_available:USD
            });
        });

    }

    fetchDataFirst(){

            this.handleToggleProgress();
            GetWebxLaravel("products/get_products/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
               if (response) {
                   this.handleCloseProgress();
                   this.setState({
                       items_data: response.data,
                       product_data_filters: response.data,
                       //   single_invoice_details:response.data,
                   }, () => {
                       this.handleCloseProgress();
                       this.loadTable();
                       this.generateCSV(response)
                   });
               }
            });

    }
    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadTable()
        });

    };

    loadTable(){
        const list_data=this.state.product_data_filters;
        const slice = list_data.slice(this.state.offset, this.state.offset + this.state.perPage);
        const trans=  list_data && list_data.length?(slice.map((list, key) =>
            <tr className=''>
                <td className='' data-title='#'>{key+1}</td>
                <td className=''
                    data-title='Product ID'>{list.item_id}</td>
                <td className=''
                    data-title='Product Name'>{list.item_name}</td>
                <td className=''
                    data-title='Product Price'><NumberFormat
                    value={(Math.round(list.product_price * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={' '}/> </td>
                <td className=''
                    data-title='Description'>{list.description}</td>
                <td className='' data-title=''>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" className="btn dropdown-toggle dot-drop"
                                         drop="left"
                                          style={{background:"transparent",border:"0",color:"#c3c6d1"}}
                        >
                            ...
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="table-drop">
                            <button className="dropdown-item" onClick={()=>this.editProductNew(list.id)}><i
                                className="fas fa-pencil-alt"></i></button>
                            <button className="dropdown-item"  onClick={()=>this.deleteProduct(list.id)}><i
                                className="fas fa-trash-alt"></i></button>
                        </Dropdown.Menu>
                    </Dropdown>
                   {/* <div className="dropdown">
                        <button className="btn dropdown-toggle dot-drop"
                                type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" >
                            ...
                        </button>
                        <div className="dropdown-menu table-drop"
                             aria-labelledby="dropdownMenuButton">

                        </div>
                    </div>*/}
                </td>

            </tr>
        ) ):(
            <tr className=''>
                <td colSpan="7" className='' data-title='#'></td>
            </tr>

        )
        this.setState({
            pageCount: Math.ceil(list_data.length / this.state.perPage),
            items_data_render:trans,
        });
    }


    viewModalInvoice(){

        this.setState({

            item_name:'',
            validate_err:[],
            save_button_text:'Add New Product',
            product_price:'',
            description:'',
            modalShowAddInvoice:true,
            setModalShowAddInvoice:true,
            modalToggleFunction:this.addProductNew
        }, () => {
            var modelD = document.getElementById("ani-modal");
            modelD.classList.add("ancd-mod");
        });
        if ( document.getElementById("activation-modal")){
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'hidden';
        }

    };

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    componentWillMount() {

        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");
            this.handleToggleProgress();
            this.setState({userToken: userToken});
            this.fetchInvoiceData();

            this.fetchDataFirst(this.state.endDate,this.state.startDate);
        } else {
            this.setState({redirect: true});
        }
    }



    render() {
        if (this.state.redirect) {
            return <Navigate to='/login'  />
        }
        const items_data=this.state.product_data_filters;
        let invoice_data=this.state.invoice_data;
        const total_invoice_sub=invoice_data.reduce((total, currentValue) => total = total + parseFloat(currentValue.sub_total),0);
        let additional_crg=0;
        for (let i=0;i<invoice_data.length;i++){
            let additional_data=invoice_data[i].get_additional;
            for (let x=0;x<additional_data.length;x++){
                    additional_crg+=additional_data[x].amount;
            }
        }
        let total_invoice_usd=0;
        let total_invoice_lkr=0;
        for (let i=0;i<invoice_data.length;i++){
            if (invoice_data[i].currency_id==2){
                total_invoice_usd+=parseFloat(invoice_data[i].total);
            }else{
                total_invoice_lkr+=parseFloat(invoice_data[i].total);
            }
        }

        const total_items=items_data.length;
        const total_templates=this.state.template_data.length;

        return (

            <React.Fragment>

                <Modal
                    show={this.state.modalShowAddInvoice}
                    onHide={this.state.setModalShowAddInvoice}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="ani-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalCloseInvoice}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh">{this.state.productServiceTitle}</h3>

                                    <div className="col-12 tdm-in p-0">
                                        <h6>Product Name</h6>
                                        <div className="form-group">
                                            <input type="text" name="item_name" value={this.state.item_name} className="form-control" placeholder="Enter Product Name" onChange={this.onChanges} />
                                             <span className="error" style={{color: "red"}}>{this.state.validate_err["item_name"]}</span>
                                        </div>



                                        <h6>Product Price</h6>
                                        <div className="form-group">
                                            <input type="text" name="product_price" value={this.state.product_price} className="form-control"  placeholder="Enter Product Price" onChange={this.onChangeNumber}/>
                                             <span className="error" style={{color: "red"}}>{this.state.validate_err["product_price"]}</span>
                                        </div>

                                        <h6>Product Description</h6>
                                        <div className="form-group">
                                            <input type="text" name="description" value={this.state.description} className="form-control" placeholder="Enter Product Description" onChange={this.onChanges} />
                                             <span className="error" style={{color: "red"}}>{this.state.validate_err["description"]}</span>
                                        </div>
                                    </div>

                                    <button type="button" className="nxt-btn" onClick={this.state.modalToggleFunction}>{this.state.save_button_text} &nbsp;&nbsp;<i
                                        className="fas fa-save"></i></button>
                                  {/*  <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                        Help with the System?</a>*/}`
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <div id="plus-button" onClick={this.addRemoveActive}>
                    <i className="fas fa-plus"></i>
                </div>

                <div id="plus-menu">
                    <h4><i className="fas fa-plus"></i> Create New</h4>
                    <div className="pm-in">
                        <ul>
                            <li data-toggle="modal" data-target="#add-new-item-modal" className="l-item" onClick={this.viewModalInvoice}>
                                <div className="icn"><i className="fas fa-socks"></i></div>
                                <p className="tp">Add New Item</p>
                                <p className="bp">Add a new item to your store or portfolio</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <Navigation pathname="all-invoices"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="all-invoices"/>
                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link className="nav-link " id="tt-tab" data-toggle="tab" to="../all-invoices" role="tab"
                                       aria-controls="tt" aria-selected="true"><span className="tt">All Invoices</span><span
                                        className="amt"><NumberFormat
                                        value={(Math.round(total_invoice_lkr * 100) / 100).toFixed(2)}
                                        displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>{this.state.usd_available?(<> | <NumberFormat
                                        value={(Math.round(total_invoice_usd * 100) / 100).toFixed(2)}
                                        displayType={'text'} thousandSeparator={true} prefix={'USD '}/></>):('')}</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link active" id="tr-tab" data-toggle="tab" to="../invoice-product-service" role="tab"
                                       aria-controls="tr" aria-selected="false"><span
                                        className="tt">Products</span><span
                                        className="amt">{total_items} Items</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" id="tp-tab" data-toggle="tab" to="../invoice-templetes" role="tab"
                                       aria-controls="tr" aria-selected="false"><span
                                        className="tt">Templates</span><span className="amt">{total_templates} Templates</span></Link>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tr" role="tabpanel" aria-labelledby="tr-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <form className="col-md-8 col-12 tt-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <label htmlFor="">Product ID</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Product ID" name="product_id_search" value={this.state.product_id_search} onChange={this.onChanges}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Product Name</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Name" name="product_name_search" value={this.state.product_name_search} onChange={this.onChanges}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Price</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Price"  name="product_price_search" value={this.state.product_price_search} onChange={this.onChanges}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn" onClick={this.searchItems}>Search</button>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn clear-btn" onClick={this.clearItem}>Clear</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form className="col-md-4 col-12 tr-form">
                                                    <div className="row row_clr">
                                                        {/*<div className="form-group">*/}
                                                        {/*    <label htmlFor="">Filters & Reports</label>*/}
                                                        {/*    <div className="slct">*/}
                                                        {/*        <i className="far fa-calendar-alt"></i>*/}
                                                        {/*        <select name="" id="c2" className="form-control">*/}
                                                        {/*            <option value="LKR &amp; USD">Daily</option>*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className="form-group" style={{opacity:'0', pointerEvents:'none'}}>
                                                            <label htmlFor="">Filters & Reports</label>
                                                            <div className="slct">

                                                                <i className="fas fa-filter"></i>
                                                                <select name="" id="c3" className="form-control">
                                                                    <option value="LKR &amp; USD">By Total Volume
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            {/*<button className="btn no-pad"><i
                                                                className="fas fa-download"></i></button>*/}
                                                          {/*  <CSVLink filename={"InvoiceProducts.csv"} data={this.state.csvData} style={{textDecoration: "none !important",color:"#000"}} className="dropdown-item" >
                                                                <i className="fas fa-download"></i> CSV Download
                                                            </CSVLink>*/}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className='rg-container'>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Item ID</th>
                                                        <th className=''>Item Name</th>

                                                        <th className=''>Amount</th>
                                                        <th className=''>Description</th>
                                                        <th className=''></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.items_data_render}
                                                    </tbody>
                                                </table>
                                                {this.state.items_data_render.length>0?(
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={this.state.pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                                    ):('')
                                                }
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                       <Footer />

                    </div>
                </div>
            </React.Fragment>

        );
    }

}


