import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import NumberFormat from "react-number-format";

export function ActiveSuppliers(props) {

  const zeroPad = (num, places) => String(num).padStart(places, '0')
  return (
    <div>
      <div className="top-fx-section">
        <div className="lft">
          <h3 className="title">Top Performing Supplier Details</h3>
        </div>
        <div className="rht">
          <span className="date">
            <span className="ot">
              {/* <DropdownButton
                id="dropdown-basic-button"
                title="By Total Volume"
                className="filter-dropdown-toggle"
              >
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </DropdownButton> */}
            </span>
          </span>
        </div>
      </div>

      <div className="rg-container">
        {/*  <DataTable
                                            data={transaction_lists}
                                            columns={columns}
                                            striped={true}
                                            hover={true}
                                            responsive={true}
                                            onClickRow={click}
                                        />*/}
        <table className="transactions-table" summary="Hed">
          <thead>
            <tr>
              <th className=''>#</th>
              <th className=''>Supplier Name</th>
              <th className=''>Email Address</th>
              <th className=''>Phone Number</th>

              <th className=''>Pending Settlements</th>
              <th className=''>Status</th>

            </tr>
          </thead>
          <tbody>
         {props.list_data && props.list_data.length ? (props.list_data.map((list, key) =>
         {if (key < 6) {
           return (
               <tr key={key}>
               <td>{zeroPad(key + 1, 3)}</td>
               <td>{list.account_name}</td>
               <td>{list.business_email}</td>
               <td>{list.phone_number}</td>

               <td>
                 <NumberFormat
                     value={(Math.round(list.total_settlement_pending * 100) / 100).toFixed(2)}
                     displayType={'text'} thousandSeparator={true}
                     prefix='LKR '/>

               </td>
               <td>{list.status == 1 ? (
                   <><span className="dot green"><i className="fas fa-circle"
                                                    aria-hidden="true"></i></span> Active</>) : (
                   <><span className="dot red"><i className="fas fa-circle"
                                                  aria-hidden="true"></i></span> Inactive</>)}
               </td>

             </tr>)}}


          )) : (
          ''
          )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
