import React, { useState } from "react";
import {PostCompnentWebx, PostWebxLaravel} from "../../../PostCompnent";
import { useForm } from "react-hook-form";
import Popout from "react-popout";
import { CreateTransactionModal } from "./CreateTransaction";
import { myConfig } from "../../../../config";
import { supplierDetailContext } from "../SupplierManagement/SupplierModal";
import {Modal} from "react-bootstrap";
import {SettlementInformation} from "./SettlementInformation";

export function ToggleTransactionCreation() {
  // const [show,setShow]=useState(false);
  return (
    <>
      <Popout url="popout.html" title="Window title">
        <div>Popped out content!</div>
      </Popout>
    </>
  );
}

export const PayNow = (props) => {


  const supplier_detail = React.useContext(supplierDetailContext);
  let text = "dfdgdfgfdgdfgdfg" + "|" + 1000;


  const [state, setState] = useState({
    first_name: "BPSPFName",
    last_name: "BPSPLN",
    email: "bpsp@webxpay.com",
    contact_number: "0111234567",
    address_line_one: "BPSP Address1",
    address_line_two: "BPSP Address2",
    custom_fields: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function popupWindow(url, windowName, win, w, h) {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  }
  const [showModal, setShowModal] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(false);
  const handleClose = () => {
    setShowModal(false)
    props.hideModal();
    props.setTransactionAmount(0.00)
    props.setSettlementAmount(0.00)
    props.setMerchantFee(0.00)
    props.setSupplierFee(0.00)
    props.setconvenienceFee(0.00)
    props.setTotalValue(0.00)
    props.setConvinceFeeAcceptedBy("")
  };
  const handleCloseOnly = () => {
    setShowModal(false)

  };
  const handleShow = () => setShowModal(true);

  function saveFeilds() {

    let formData = new FormData(); //formdata object


    formData.append("first_name",state.first_name );
    formData.append("last_name",state.last_name );
    formData.append("email",state.email );
    formData.append("contact_number",state.contact_number );
    formData.append("address_line_one",state.address_line_one );
    formData.append("address_line_two",state.address_line_two );
    formData.append("custom_fields",state.custom_fields );


    formData.append("supplier_id", props.supplierData.supplier_id);
    formData.append("supplier_first_name", props.supplierData.username);
    formData.append("supplier_phone", props.supplierData.phone_number);
    formData.append("supplier_email", props.supplierData.business_email);
    formData.append("convince_fee_bearer", props.convinceFeeAcceptedBy);
    formData.append("sub_total", props.subTotal);
    formData.append("transaction_amount", props.transactionAmount);
    formData.append("settlement_amount", props.settlementAmount);
    formData.append("merchant_fee", props.merchantFee);
    formData.append("supplier_fee", props.supplierFee);
    formData.append("convince_fee", props.convenienceFee);
    formData.append("invoice_id", props.invoiceId);
    formData.append("mdr_percentage", props.mdrPercentage);
    formData.append("merchant_dba", sessionStorage.getItem("doing_business_name"));


    PostWebxLaravel("payment-process", formData).then((response) => {
      if (response.data.success == 200) {
        handleClose()

      }
    })

  }

  const onSubmit = (paymentData) => {


    let formData = new FormData(); //formdata object

    Object.keys(state).map(function (key) {
      formData.append(key, paymentData[key]);
    });
    formData.append("supplier_id", props.supplierData.supplier_id);
    formData.append("supplier_first_name", props.supplierData.username);
    formData.append("supplier_phone", props.supplierData.phone_number);
    formData.append("supplier_email", props.supplierData.business_email);
    formData.append("convince_fee_bearer", props.convinceFeeAcceptedBy);
    formData.append("sub_total", props.subTotal);
    formData.append("transaction_amount", props.transactionAmount);
    formData.append("settlement_amount", props.settlementAmount);
    formData.append("merchant_fee", props.merchantFee);
    formData.append("supplier_fee", props.supplierFee);
    formData.append("convince_fee", props.convenienceFee);
    formData.append("invoice_id", props.invoiceId);
    formData.append("mdr_percentage", props.mdrPercentage);
    formData.append("merchant_dba", sessionStorage.getItem("doing_business_name"));


    PostWebxLaravel("payment-process", formData).then((response) => {
      if (response.data.success == 200) {
        let BaseUrl = myConfig.laravelWeb;
        popupWindow(
          BaseUrl + "load-payment/" + response.data.bpsp_payment_id,
          "test",
          window,
          1000,
          800
        );
        const timer = setInterval(() => {
          let formData = new FormData(); //formdata object
          formData.append("invoice_id", props.invoiceId);
          PostWebxLaravel("bpsp/check-payment-status-process", formData).then(
            (response) => {

              if(response.data.paid_status==2){
                setTransactionStatus("Payment was successful")
                handleShow()


                 clearInterval(timer);
                setTimeout(
                    handleClose(),
                    5000
                );
              }else if(response.data.paid_status==3){
                setTransactionStatus("Payment Failed Try Again")
                handleShow()

                clearInterval(timer);
                setTimeout(
                    handleCloseOnly(),
                    5000
                );
              }
            }
          );
        }, 5000);
      }
    });
  };

  return (
    <>
      <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="ani-modal"
      >
        <Modal.Body
            className="modal-type-2"
            style={{ backgroundColor: "#cccccc" }}
        >
         {/* <div
              className="colse-mdl"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowModal(false)}
          >
            X
          </div>*/}

          <div className="container-fluid">
            <p>{transactionStatus}</p>
          </div>
        </Modal.Body>
      </Modal>
      <div className="jcsb-pay-button">
      <form onSubmit={handleSubmit(onSubmit)} id="black">
        <input
          type="hidden"
          name="first_name"
          {...register("first_name")}
          value={state.first_name}
        />
        <input
          type="hidden"
          name="last_name"
          {...register("last_name")}
          value={state.last_name}
        />
        <input
          type="hidden"
          name="email"
          {...register("email")}
          value={state.email}
        />
        <input
          type="hidden"
          name="contact_number"
          {...register("contact_number")}
          value={state.contact_number}
        />
        <input
          type="hidden"
          name="address_line_one"
          {...register("address_line_one")}
          value={state.address_line_one}
        />
        <input
          type="hidden"
          name="process_currency"
          {...register("process_currency")}
          value={state.process_currency}
        />
        <input
          type="hidden"
          name="secret_key"
          {...register("secret_key")}
          value={state.secret_key}
        />
        <input
          type="hidden"
          name="custom_fields"
          {...register("custom_fields")}
          value={state.custom_fields}
        />
        <input
          type="hidden"
          name="payment"
          {...register("payment")}
          value={state.payment}
        />
        <button type="submit">Pay with Visa</button>

      </form>
      <button onClick={saveFeilds}>Pay Later</button>
      </div>
    
    </>
  );
};
