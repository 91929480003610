import React, { PureComponent } from "react";

export class ResponsivePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="row row_clr responsive-massage">
        <div className="txt">
          <img
            src={require("../images/webxpay-dashboard-logo.svg")}
            className="logo-img"
            alt=""
          />
          <p>
            Please download the WEBXPAY Mobile App for an amazing user
            experience and functionalities.
          </p>
          <p className="p2">Now available on</p>
          <ul>
            <li>
              <a
                href="https://apps.apple.com/us/app/webxpay-xpos/id1525742151"
                target="_blank"
              >
                <img src={require("../images/apple-store.jpg")} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.webxpaypack.webxpayapp"
                target="_blank"
              >
                <img src={require("../images/google-play.jpg")} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
