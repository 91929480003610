export function HostedSession() {
    let successCallBack = () => {};
    let errorCallBack = () => {};

    function configure(card, readyCallBack) {
        window.PaymentSession.configure({
            fields: { card: card },
            frameEmbeddingMitigation: ["javascript"],
            callbacks: {
                initialized: function(response) {
                    readyCallBack(GenerateSession);
                },
                formSessionUpdate: function(response) {
                    if (response.status) {
                        if ("ok" === response.status) {
                            if (response.sourceOfFunds.provided.card.securityCode) {
                                successCallBack(response.session.id);
                            } else {
                                errorCallBack({
                                    type: "system_error",
                                    details: "cvv missing"
                                });
                            }
                        } else if ("fields_in_error" === response.status) {
                            errorCallBack({
                                type: "fields_in_error",
                                details: response.errors
                            });
                        } else if ("request_timeout" === response.status) {
                            errorCallBack({
                                type: "request_timeout",
                                details: response.errors.message
                            });
                        } else if ("system_error" === response.status) {
                            errorCallBack({
                                type: "system_error",
                                details: response.errors.message
                            });
                        }
                    } else {
                        errorCallBack({
                            type: "fields_in_error",
                            details: response
                        });
                    }
                }
            },
            interaction: {
                displayControl: {
                    formatCard: "EMBOSSED",
                    invalidFieldCharacters: "REJECT"
                }
            }
        });
    }

    function GenerateSession(callback, error) {
        successCallBack = callback;
        errorCallBack = error;

        window.PaymentSession.updateSessionFromForm("card");
    }

    return {
        configure: configure,
    };
}