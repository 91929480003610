import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import {Link, Navigate,useNavigate } from "react-router-dom";
import {myConfig} from "../config";
function TransitionUp(props) {
    return <Slide {...props} direction="up"/>;
}
export class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
            forgot_email: '',
            redirects: false,
            loading: false,
            hidden: true,
            open: false,
            openprogress: false,
            setOpenProgress: false,
            errors:[],
            submitted : false,
        };
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }
    handleValidation(){
        // let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;


        //Email
        if(this.state.forgot_email==""){
            formIsValid = false;
            errors["email"] = "Email is required";


        }else{
            if(typeof this.state.forgot_email !== "undefined"){
                let lastAtPos = this.state.forgot_email.lastIndexOf('@');
                let lastDotPos = this.state.forgot_email.lastIndexOf('.');

                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.forgot_email.indexOf('@@') == -1 && lastDotPos > 2 && (this.state.forgot_email.length - lastDotPos) > 2)) {
                    formIsValid = false;
                    errors["email"] = "Invalid E-Mail Address";
                }
            }
        }



        this.setState({errors: errors});
        return formIsValid;
    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    handleClick = Transition => () => {
        this.setState({open: true, Transition});
    };

    handle(Transition) {
        this.setState({open: true, Transition});
    }

    handleClose = () => {
        this.setState({open: false});
    };

    toggleShow() {
        this.setState({hidden: !this.state.hidden});
    }

    login(e) {
        this.setState({loading: true});
        e.preventDefault();
        if(this.handleValidation()) {
            if (this.state.forgot_email) {
                sessionStorage.setItem('userEmail', this.state.forgot_email);
                this.handleToggleProgress();
                let BaseUrl = myConfig.laravelUrl+'server-post';
                let formData = new FormData();    //formdata object

                formData.append('forgot_email', this.state.forgot_email);   //append the values with key, value pair

                const config = {
                    headers: {'content-type': 'multipart/form-data'}
                };

                axios.post(BaseUrl + "/forgotPassword", formData, config)

                    .then(response => {
                        this.handleCloseProgress();
                        if (response.data.status == "Success") {
                            this.setState({submitted: true});
                        } else {
                            this.setState({submitted: true});
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
        }
    }

    onChange(e) {

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
    /*    if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {
            return <Navigate to='/active-dashboard'  />
        }
        if (this.state.redirects) {
            return <Navigate to='/active-dashboard'  />
        }*/


        return (
            <div>
                {this.state.submitted?(
                    <Navigate to="/verify-code"/>
                ):('')}
                <div id="outer-container" className="container-fluid">
                    <div id="turn-box"></div>
                    <div id="inside-container" className="container">
                        <div className="row row_clr main-row" style={{alignItems:"center"}}>

                            <div className="col-sm-6 logo-txt">
                                <img src={require('../images/webxpay-logo.svg')} alt="logo" className="logo" />

                                <h1>Welcome Back!</h1>
                                <h3>Reset password to your account</h3>
                             {/*   <a href="#" className="help"><i className="far fa-life-ring"></i> Need a hand figuring
                                    it out?</a>*/}
                            </div>

                            <div className="col sign-box">
                                <div className="row">
                                    <div className="col-12 sign-top">
                                        <img src={require('../images/sign-in-icon.svg')} alt="sign-in-icon" className="sign-icon" />
                                        <h2 className="sign-head">Forgot Password</h2>
                                    </div>
                                    <div className="col-12 form-type-1">

                                        <form className="row row_clr">
                                            <div className="col-12 p-0">
                                                <div className="form-group"><input type="text" className="form-control" name="forgot_email"
                                                                                   id="u-name"
                                                                                   placeholder="Enter Email address"  onChange={this.onChange}/>
                                                </div>
                                                <span className="error" style={{color: "red"}}>{this.state.errors['email']}</span>
                                            </div>
                                           {/* <div className="col-6 p-0 cbox">
                                                <label className="cont">
                                                    <input type="checkbox" checked="" />
                                                    <span className="checkmark"></span>
                                                    Remember Me
                                                </label>
                                            </div>*/}
                                          {/*  <div className="col-6 p-0 fogp">
                                                <a href="#" className="fp">Forgot Password?</a>
                                            </div>*/}
                                            <div className="col-12 no-pad submit">
                                                <input id="submit-btn" type="submit" value="Send Reset Link"
                                                       onClick={this.login}/>
                                            </div>
                                        </form>

                                    </div>
                                    <div className="col-12 no-account">
                                        <p className="no-account-p">Changed Your Mind?  <Link className="caccount" to="../../login">Go Back To Sign-In</Link></p>
                                    </div>
                                    <div className="col-12 copy-txt">
                                   {/*     <p className="cop-p">By Signing In or Registering on the system, you confirm that
                                            you agree to our <a href="#" className="green-txt">Terms of Service</a> and <a
                                                href="#" className="green-txt">Privacy Policy</a>.</p>*/}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Snackbar
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={this.state.Transition}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        autoHideDuration={3500}
                        message={<span id="message-id">Invalid E-Mail Address</span>}
                    />
                </div>
            </div>
        );
    }

}