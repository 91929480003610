import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import {Link, Navigate} from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {myConfig} from "../config";
import {PostCompnentWebx, PostWebxLaravel} from "./PostCompnent";
import Modal from "react-bootstrap/Modal";

function TransitionUp(props) {
    return <Slide {...props} direction="up"/>;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
            errors_password: [],
            old_password: '',
            new_password: '',
            confirm_password: '',
            redirects: false,
            loading: false,
            hidden: true,
            open: false,
            openprogress: false,
            setOpenProgress: false,
            modalShowCloseConfirmation:false,
            setModalShowCloseConfimation:false
        };
        this.resetPassword = this.resetPassword.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen=this.handleClickOpen.bind(this);
        this.handleValidationPassword= this.handleValidationPassword.bind(this);
        this.closeModalPasswordConfirm= this.closeModalPasswordConfirm.bind(this);
        this.openModelPasswordConfirm= this.openModelPasswordConfirm.bind(this);
    }
    handleClickOpen() {
        this.setState({setOpen:true});
        this.setState({openDialog:true});
    };
    openModelPasswordConfirm(status) {
        this.setState({
            modalShowCloseConfirmation: true,
            setModalShowCloseConfimation: true
        });
        if (status==true){
            sessionStorage.removeItem('userToken');
            localStorage.removeItem('userToken');
            sessionStorage.removeItem("change_password");
        }

    }
    closeModalPasswordConfirm() {
        this.setState({
            modalShowCloseConfirmation: false,
            setModalShowCloseConfimation: false
        });
        this.props.history.push('/login')
    }
    handleClose() {
        this.setState({setOpen:false});
        this.setState({openDialog:false});
    };
    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    handleClick = Transition => () => {
        this.setState({open: true, Transition});
    };

    handle(Transition) {
        this.setState({open: true, Transition});
    }

    handleClose = () => {
        this.setState({open: false});
    };

    toggleShow() {
        this.setState({hidden: !this.state.hidden});
    }
    componentWillMount() {


       sessionStorage.setItem("change_password",false)
    }
    goToLogin() {
        this.props.history.push('/login')
    }

    handleValidationPassword() {
        let errors_password = {};
        let formIsValid = true;
        //Email

        if (this.state.old_password == "") {
            formIsValid = false;
            errors_password["old_password"] = "Required Field";
        }
        if (this.state.new_password == "") {
            formIsValid = false;
            errors_password["new_password"] = "Required Field";
        }
        if (this.state.confirm_password == "") {
            formIsValid = false;
            errors_password["confirm_password"] = "Required Field";
        }
        if (this.state.new_password !== "" && this.state.confirm_password !== "") {
            if (this.state.new_password !== this.state.confirm_password) {
                formIsValid = false;
                errors_password["confirm_password"] = "The password and confirmation password do not match";
            }
        }
        if (this.state.new_password !== "" && this.state.old_password !== "") {
            if (this.state.new_password === this.state.old_password) {
                formIsValid = false;
                errors_password["new_password"] = "Your new password cannot be the same as your old password";
            }
        }
        this.setState({errors_password: errors_password});
        return formIsValid;
    }

    resetPassword() {

        if (this.handleValidationPassword()) {
            this.setState({loading: true});
            let formData = new FormData();    //formdata object
            formData.append('previous_password', this.state.old_password);
            formData.append('password', this.state.new_password);
            formData.append('confirm_password', this.state.confirm_password);

            PostCompnentWebx("updatePassword", formData).then(response => {
                this.handleCloseProgress();

                if (response.data.status==1){
                    this.setState({
                        password_change_message: response.data.explaination,
                        old_password: '',
                        loading: false,
                        new_password: '',
                        confirm_password: '',
                    }, () => {
                        this.openModelPasswordConfirm(true);
                    });


                }else{
                    this.setState({
                        password_change_message: response.data.explaination,
                        old_password: '',
                        loading: false,
                        new_password: '',
                        confirm_password: '',
                    }, () => {
                        this.openModelPasswordConfirm(false);
                    });
                }
            });
        }
    }

    onChange(e) {

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        /*    if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {
                return <Navigate to='/active-dashboard'  />
            }
            if (this.state.redirects) {
                return <Navigate to='/active-dashboard'  />
            }*/
        return (
            <div>
                <Modal
                    show={this.state.modalShowCloseConfirmation}
                    onHide={this.state.setModalShowCloseConfimation}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="info-modal"
                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            {/* <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>*/}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 lft">
                                        <h5 className="in-h">{this.state.password_change_message}</h5>
                                        <button className="btn btn-danger close-btn"
                                                onClick={this.closeModalPasswordConfirm}>Close
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>

                <Dialog
                    className="success-message"
                    open={this.state.openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">"Password Successfully Changed"</DialogTitle>
                    {/*<DialogContent>*/}
                    {/*    <DialogContentText id="alert-dialog-slide-description">*/}

                    {/*    </DialogContentText>*/}
                    {/*</DialogContent>*/}
                    <DialogActions>
                        <Button onClick={this.goToLogin} color="primary" className="suc-btn">
                            Got it
                        </Button>
                    </DialogActions>
                </Dialog>
                <div id="outer-container" className="container-fluid">
                    <div id="turn-box"></div>
                    <div id="inside-container" className="container">
                        <div className="row row_clr main-row" style={{alignItems:"center"}}>

                            <div className="col-sm-6 logo-txt">
                                <img src={require('../images/webxpay-logo.svg')} alt="logo" className="logo" />

                                <h1>Welcome Back!</h1>
                                <h3>Reset password to your account</h3>
                                {/*    <a href="#" className="help"><i className="far fa-life-ring"></i> Need a hand figuring
                                    it out?</a>*/}
                            </div>

                            <div className="col sign-box">
                                <div className="row">
                                    <div className="col-12 sign-top">
                                        <img src={require('../images/sign-in-icon.svg')} alt="sign-in-icon" className="sign-icon" />
                                        <h2 className="sign-head">Forgot Password</h2>
                                    </div>
                                    <div className="col-12 form-type-1">

                                        <form className="row row_clr">
                                            <div className="col-12 p-0">
                                                <div className="form-group"><input type="password" className="form-control" id=""
                                                                                   name="old_password"
                                                                                   placeholder="Enter Old Password"
                                                                                   onChange={this.onChange}/>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_password['old_password']}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="form-group"><input type="password" className="form-control" id=""
                                                                                   name="new_password"
                                                                                   placeholder="Enter New Password"
                                                                                   onChange={this.onChange}/></div>
                                                <span className="error" style={{color: "red"}}>{this.state.errors_password['new_password']}</span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="form-group"><input type="password" className="form-control" id=""
                                                                                   name="confirm_password"
                                                                                   placeholder="Enter Confirm Password"
                                                                                   onChange={this.onChange}/></div>
                                                <span className="error" style={{color: "red"}}>{this.state.errors_password['confirm_password']}</span>
                                            </div>

                                            <div className="col-12 no-pad submit">
                                                <input id="submit-btn" type="button" value="Reset Password"
                                                       onClick={this.resetPassword}/>
                                            </div>
                                        </form>

                                    </div>
                                    <div className="col-12 no-account">
                                        <p className="no-account-p">Change Your Mind?  <Link className="caccount" to="../login">Go Back To Sign-In</Link></p>
                                    </div>
                                    <div className="col-12 copy-txt">
                                        {/*     <p className="cop-p">By Signing In or Registering on the system, you confirm that
                                            you agree to our <a href="#" className="green-txt">Terms of Service</a> and <a
                                                href="#" className="green-txt">Privacy Policy</a>.</p>*/}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Snackbar
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={this.state.Transition}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        autoHideDuration={3500}
                        message={<span id="message-id">Invalid Username or Password</span>}
                    />
                </div>
            </div>
        );
    }

}