import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {
    Area,
    AreaChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {Navigation} from "./Navigation";
import {myConfig} from "../config";
import {TopBar} from "./TopBar";
import {PostCompnentWebx, PostWebxLaravel} from "./PostCompnent";
import {Footer} from "./Footer";
import {SupportButton} from "./SupportButton";
import {fadeInLeft, fadeInRight, flipInX} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import {Modal} from "react-bootstrap";
import PasswordChecklist from "react-password-checklist";
import LoadingSpinner from "./LoadingSpinner";
import axios from "axios";


export class ActiveDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
            last_paid_amount_lkr: 0,
            last_paid_amount_usd: 0,
            last_paid_date: '',
            lkr_transaction_total: 0,
            lkr_refund_total: 0,
            usd_refund_total: 0,
            gbp_refund_total: 0,
            usd_transaction_total: 0,
            gbp_transaction_total: 0,
            total_due: 0,
            total_due_usd: 0,
            transaction_lists: [],
            transaction_lists_graph_present: [],
            data_line: [],
            openprogress: false,
            setOpenProgress: false,
            openPasswordConfirmation: false,
            startDate: moment().subtract(30, 'days').format('ll'),
            endDate: moment().format('ll'),
            dayCount: 30,
            //pie chart
            COLORS: ['#5C70BF', '#DB003B', '#24B42D', '#25E9B4', '#F38619', '#8CDB41', '#A5FD95'],
            data_pie: [],
            RADIAN: Math.PI / 180,

            old_password: '',
            new_password: '',
            confirm_password: '',
            modalShowCloseConfirmation: false,
            modalShowPasswordChange: false,
            password_change_message: '',
            isValid:false,
            errors_password:[],
            modalShowPasswordConfirm:false,
            errors_user_password:'',
            user_password:'',
            password_message:'The password you are using should be changed once every 90\n' +
                '                                    days for security reasons. if you wish to use old password click on use my\n' +
                '                                    old password or click on change password to change the password',

            renderCustomizedLabel: ({
                                        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
                                    }) => {
                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                const x = cx + radius * Math.cos(-midAngle * this.state.RADIAN);
                const y = cy + radius * Math.sin(-midAngle * this.state.RADIAN);

                return (
                    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="hanging">

                    </text>
                );
            },
        };
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.fetchDataFirst = this.fetchDataFirst.bind(this);
        this.selectChart = this.selectChart.bind(this);
        this.openModalPasswordChange = this.openModalPasswordChange.bind(this);

        this.resetPassword = this.resetPassword.bind(this);
        this.handleValidationPassword = this.handleValidationPassword.bind(this);
        this.openModelPasswordConfirm = this.openModelPasswordConfirm.bind(this);
        this.closeModalPasswordConfirm = this.closeModalPasswordConfirm.bind(this);
        this.onChanges = this.onChanges.bind(this);
        this.closePasswordModal = this.closePasswordModal.bind(this);
        this.continueOld = this.continueOld.bind(this);
        this.showConfirmPasswordModal = this.showConfirmPasswordModal.bind(this);
        this.handleValidationConfirmPassword = this.handleValidationConfirmPassword.bind(this);

    }

    continueOld(){
        if (this.handleValidationConfirmPassword()) {
            // this.handleToggleProgress();
            this.setState({loading: true});


                this.handleToggleProgress();
            let BaseUrl = myConfig.redirectUrl;
            let formData = new FormData();    //formdata object
            let email = sessionStorage.getItem("user_email");    //formdata object
            let password = this.state.user_password;    //formdata object


            formData.append('email', email);   //append the values with key, value pair
            formData.append('password', password);


            axios.post(BaseUrl + "users/create_user", formData)
                .then(response => {
                    console.log(response.data.success)
                    if (response.data.success!==400){
                        this.handleCloseProgress();
                        this.setState({
                            modalShowCloseConfirmation:true,
                            password_change_message:"Confirmation successful",
                            loading: false,
                            modalShowPasswordConfirm:false
                        })

                            let formDatas = new FormData();    //formdata object
                            PostWebxLaravel("users/update_password_change_date", formDatas).then(response => {
                                this.setState({openPasswordConfirmation:false})
                                this.closePasswordModal();
                                sessionStorage.setItem("password_change",false)
                            });


                    }else{
                        this.handleCloseProgress();
                        this.setState({
                            modalShowCloseConfirmation:true,
                            password_change_message:"Password is not valid",
                            loading: false,
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        }
    }

    showConfirmPasswordModal(){
        this.setState({modalShowPasswordConfirm:true})
    }
    closePasswordModal() {
        this.setState({
            modalShowPasswordChange: false,
        })
    }

// Open password change modal
    openModalPasswordChange(){
        this.setState({modalShowPasswordChange:true})
    }

// Open password confirmation modal
    openModelPasswordConfirm() {
        this.setState({
            modalShowCloseConfirmation: true
        });
    }

    closeModalPasswordConfirm() {
        this.setState({
            modalShowCloseConfirmation: false,
            setModalShowCloseConfimation: false
        });
    }
//Password onchange
    onChanges(e) {

        if (e.target.name == "new_password") {
            this.setState({password_change: true});
        }

        if (e.target.name == "bin_percentage") {

            let percentage = e.target.value;
            if (percentage.length < 6) {
                if (e.target.value == 0) {
                    e.target.value = 0;
                    this.setState({
                        bin_percentage: e.target.value
                    });

                } else {
                    this.setState({
                        bin_percentage: -Math.abs(percentage)
                    });
                }
            }


        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }

    }

// Validate Password Change
    handleValidationConfirmPassword() {
        let errors_password = {};
        let formIsValid = true;
        //Email

        if (this.state.user_password == "") {
            formIsValid = false;
            errors_password["user_password"] = "Required Field";
        }

        this.setState({errors_user_password: errors_password});

        return formIsValid;
    }


    handleValidationPassword() {
        let errors_password = {};
        let formIsValid = true;
        //Email

        if (this.state.old_password == "") {
            formIsValid = false;
            errors_password["old_password"] = "Required Field";
        }
        if (this.state.new_password == "") {
            formIsValid = false;
            errors_password["new_password"] = "Required Field";
        }
        if (this.state.confirm_password == "") {
            formIsValid = false;
            errors_password["confirm_password"] = "Required Field";
        }

        if (this.state.new_password !== "" && this.state.old_password !== "") {
            if (this.state.new_password === this.state.old_password) {
                formIsValid = false;
                errors_password["new_password"] = "Your new password cannot be the same as your old password";
            }
        }
        this.setState({errors_password: errors_password});

        if (this.state.isValid == false) {
            formIsValid = false;
        }


        return formIsValid;
    }

// Reset Password
    resetPassword() {

        if (this.handleValidationPassword()) {
            // this.handleToggleProgress();
            this.setState({loading: true});
            let formData = new FormData();    //formdata object
            formData.append('previous_password', this.state.old_password);
            formData.append('password', this.state.new_password);
            formData.append('confirm_password', this.state.confirm_password);

            PostCompnentWebx("updatePassword", formData).then(response => {
                this.handleCloseProgress();

                if (response.data.status==1){
                    let formDatas = new FormData();    //formdata object
                    PostWebxLaravel("users/update_password_change_date", formDatas).then(response => {
                        this.setState({openPasswordConfirmation:false})
                        this.closePasswordModal();
                        sessionStorage.setItem("password_change",false)
                    });
                }
                this.setState({
                    password_change_message: response.data.explaination,
                    old_password: '',
                    loading: false,
                    new_password: '',
                    confirm_password: '',

                }, () => {
                    this.openModelPasswordConfirm();

                })

            });
        }
    }

    selectChart() {
        let type_chart = document.getElementById("chart-selector").value;
        if (type_chart === "usd") {
            document.getElementById("chart_usd").style.display = "block";
            document.getElementById("chart_lkr").style.display = "none";
        } else {
            document.getElementById("chart_usd").style.display = "none";
            document.getElementById("chart_lkr").style.display = "block";
        }
    }

    handleCallback(start, end, label) {
        this.setState({
            startDate: moment(start).format('ll'),
            endDate: moment(end).format('ll'),
        });
        this.fetchData(end, start);
    }

    loadTotalDue() {
        this.handleToggleProgress();

        PostCompnentWebx("getMerchantDue", null, null).then(response => {
            this.handleCloseProgress();
            if (response.data.lkr_total !== undefined) {
                this.setState({total_due: response.data.lkr_total});
            }
            if (response.data.usd_total !== undefined) {
                this.setState({total_due_usd: response.data.usd_total});
            }
        });


    }

    fetchDataFirst(end, start) {

        var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");
        if (end && start) {
            this.handleToggleProgress();
            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');


            var a = moment(end);
            var b = moment(start);
            let days_count = a.diff(b, 'days');   // =1
            days_count = parseInt(days_count) + parseInt(1);
            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);
            formData.append('expand_view', true);   //append the values with key, value pair

            PostCompnentWebx("getStoreTransactionAndRefundTotal", formData).then(response => {
                //days_count
                this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
                this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
                this.setState({usd_refund_total: response.data.totals.usd_refund_total});
                this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});

                this.setState({gbp_refund_total: response.data.totals.gbp_refund_total});
                this.setState({gbp_transaction_total: response.data.totals.gbp_transaction_total});
                //this.setState({transaction_lists:response.data.transactions});


            });
            PostCompnentWebx("getStoreTransactions", formData).then(response => {
                //days_count

                this.setState({transaction_lists: response.data});

                const rows = [];


                response.data.forEach((value, index) => {
                    rows.push({
                        order_id: value.order_id,
                        order_refference_number: value.order_refference_number,
                        total_amount: value.total_amount,
                        date_added: value.date_added,
                        order_status: value.order_status,
                    });
                });

                this.setState({transaction_lists: response.data, rows});
            });

            PostCompnentWebx("getStoreApprovedAndBackdatedTransactionsByDay", formData).then(response => {
                this.handleCloseProgress();
                const data_line = [];

                let old_date = '';
                let new_date = '';

                if (response.data.actual_data[0]) {
                    new_date = moment(response.data.actual_data[0].date_added).format('DD-MM-YYYY');
                }
                if (response.data.backdated_data[0]) {
                    old_date = moment(response.data.backdated_data[0].date_added).format('DD-MM-YYYY');
                }
                for (let x = 0; x < days_count; x++) {
                    var new_dates = moment(old_date, "DD-MM-YYYY").add(x, 'days').format('DD-MM-YYYY');
                    var old_dates = moment(new_date, "DD-MM-YYYY").add(x, 'days').format('DD-MM-YYYY');
                    data_line.push({
                        name: x,
                        date: '',
                        LKR: parseFloat(0),
                        USD: parseFloat(0),
                        LKR_PREV_MON: parseFloat(0),
                        USD_PREV_MON: parseFloat(0),
                    })
                }

                for (let i = 0; i < days_count; i++) {

                    var new_dates = moment(new_date, "DD-MM-YYYY").add(i, 'days').format('DD-MM-YYYY');
                    var old_dates = moment(old_date, "DD-MM-YYYY").add(i, 'days').format('DD-MM-YYYY');


                    response.data.actual_data.forEach((value, index) => {
                        if (new_dates == moment(value.date_added).format('DD-MM-YYYY')) {
                            data_line[i]["LKR"] = parseFloat(value.lkr_total);
                            data_line[i]["USD"] = parseFloat(value.usd_total);
                            data_line[i]["date"] = new_dates;
                        }
                    });
                    response.data.backdated_data.forEach((val, index) => {
                        if (old_dates == moment(val.date_added).format('DD-MM-YYYY')) {
                            data_line[i]["LKR_PREV_MON"] = parseFloat(val.lkr_total);
                            data_line[i]["USD_PREV_MON"] = parseFloat(val.usd_total);
                            data_line[i]["date"] = old_dates;
                        }
                    });
                }


                this.setState({transaction_lists_graph_present: data_line, dayCount: 31});
            });

            PostCompnentWebx("getStoreApprovedTransactionTotalByGatewayCategory", formData).then(response => {
                this.handleCloseProgress();
                const data_line = [];
                var compactArray = [];
                for (var i in response.data) {
                    compactArray.push(response.data[i]);
                }
                let total = compactArray.reduce(function (prev, current) {
                    return prev + +current.total_amount
                }, 0);
                compactArray.forEach((value, index) => {
                    let percentage = parseFloat(value.total_amount) / parseFloat(total) * 100;
                    data_line.push({
                        name: value.display_name + '-' + percentage.toFixed(2) + '%',
                        value: parseFloat(value.total_amount),
                    })
                });
                this.setState({data_pie: data_line});
            });

        }
    }

    fetchData(end, start) {

        var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");
        if (end && start) {
            this.handleToggleProgress();
            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');
            var a = moment(end);
            var b = moment(start);
            let days_count = a.diff(b, 'days');  // =1
            days_count = parseInt(days_count) + parseInt(1);

            let start_dt = new Date(start).toJSON().slice(0, 10);
            let end_dt = new Date(end).toJSON().slice(0, 10);
            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);

            PostCompnentWebx("getStoreApprovedAndBackdatedTransactionsByDay", formData).then(response => {
                this.handleCloseProgress();
                const data_line = [];
                let old_date = '';
                let new_date = '';

                if (response.data.actual_data[0]) {
                    new_date = moment(response.data.actual_data[0].date_added).format('DD-MM-YYYY');
                }
                if (response.data.backdated_data[0]) {
                    old_date = moment(response.data.backdated_data[0].date_added).format('DD-MM-YYYY');
                }
                for (let x = 0; x < days_count; x++) {
                    var new_dates = moment(old_date, "DD-MM-YYYY").add(x, 'days').format('DD-MM-YYYY');
                    var old_dates = moment(new_date, "DD-MM-YYYY").add(x, 'days').format('DD-MM-YYYY');
                    data_line.push({
                        name: x,
                        date: '',
                        LKR: parseFloat(0),
                        USD: parseFloat(0),
                        LKR_PREV_MON: parseFloat(0),
                        USD_PREV_MON: parseFloat(0),
                    })
                }

                for (let i = 0; i < days_count; i++) {

                    var new_dates = moment(new_date, "DD-MM-YYYY").add(i, 'days').format('DD-MM-YYYY');
                    var old_dates = moment(old_date, "DD-MM-YYYY").add(i, 'days').format('DD-MM-YYYY');


                    response.data.actual_data.forEach((value, index) => {
                        if (new_dates == moment(value.date_added).format('DD-MM-YYYY')) {
                            data_line[i]["LKR"] = parseFloat(value.lkr_total);
                            data_line[i]["USD"] = parseFloat(value.usd_total);
                            data_line[i]["date"] = new_dates;
                        }
                    });
                    response.data.backdated_data.forEach((val, index) => {
                        if (old_dates == moment(val.date_added).format('DD-MM-YYYY')) {
                            data_line[i]["LKR_PREV_MON"] = parseFloat(val.lkr_total);
                            data_line[i]["USD_PREV_MON"] = parseFloat(val.usd_total);
                            data_line[i]["date"] = old_dates;
                        }
                    });
                }


                this.setState({transaction_lists_graph_present: data_line, dayCount: days_count});
            });

            PostCompnentWebx("getStoreTransactionAndRefundTotal", formData).then(response => {
                this.handleCloseProgress();
                this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
                this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
                this.setState({usd_refund_total: response.data.totals.usd_refund_total});
                this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});

                this.setState({gbp_refund_total: response.data.totals.gbp_refund_total});
                this.setState({gbp_transaction_total: response.data.totals.gbp_transaction_total});
            });

        }
    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    componentWillMount() {

        if (sessionStorage.getItem("userToken") || localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");

            this.setState({userToken: userToken});

            this.fetchDataFirst(this.state.endDate, this.state.startDate);
            /*    this.loadPageData(userToken);
               */
            this.loadLastPaid(userToken);
            this.loadTotalDue(userToken);

            let password_change=sessionStorage.getItem("password_change");

            if (password_change=="true"){

                this.setState({openPasswordConfirmation:true})
                if (sessionStorage.getItem("new_password")=="true"){
                    this.setState({password_message:"Please change your password to proceed"})
                } else
                    {
                this.setState({password_message:"The password you are using should be changed once every 90\n" +
                        "                                    days for security reasons. if you wish to use old password click on use my\n" +
                        "                                    old password or click on change password to change the password"})
                }

            }

        } else {
            this.setState({redirect: true});
        }
    }

    loadLastPaid(userToken) {
        this.handleToggleProgress();
        PostCompnentWebx("getRecentDepositSummery", null).then(response => {
            this.handleCloseProgress();

            this.setState({last_paid_amount_lkr: response.data.lkr_total});
            this.setState({last_paid_amount_usd: response.data.usd_total});
            this.setState({last_paid_date: response.data.recent_deposit_date});
        });
    }

    render() {

        const styles = {
            bounce: {
                animation: 'x 3s',
                animationName: Radium.keyframes(flipInX, 'flipInX')
            },
            fadeInLeft: {
                animation: 'x 2s',
                animationName: Radium.keyframes(fadeInLeft, 'fadeInLeft')
            },
            fadeInRight: {
                animation: 'x 2s',
                animationName: Radium.keyframes(fadeInRight, 'fadeInRight')
            }
        };
        const transaction_lists = this.state.transaction_lists;
        //const transaction_lists=order_change.sort((a, b) => (b.order_id - a.order_id));


        const NumberMask = (value) => (
            <NumberFormat
                value={(Math.round(value * 100) / 100).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={' '}/>

        );


        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }
        return (
            <div>
                <Modal
                    show={this.state.modalShowCloseConfirmation}
                    onHide={this.state.setModalShowCloseConfimation}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="info-modal"
                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            {/* <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>*/}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 lft">
                                        <h5 className="in-h">{this.state.password_change_message}</h5>
                                        <button className="btn btn-danger close-btn"
                                                onClick={this.closeModalPasswordConfirm}>Close
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
                <Modal
                    show={this.state.openPasswordConfirmation}

                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="info-modal"
                    className="info-modal-class"
                >
                    <Modal.Body className="modal-type-2">
                        {/*  <button onClick={this.closeModal} className="cls-btn">X</button>*/}
                        <div className="row log-sec">
                            <div className="col-12 suc-outer">

                                <h4 className="in-h mb-4">{this.state.password_message}</h4>
                            </div>

                            {sessionStorage.getItem("new_password")=="false"?(
                                <>
                                <div className="col-6 submit">
                                    <div id="submit-btn" className="btn btn-primary" onClick={this.openModalPasswordChange}><i className="fas fa-check"></i>Change password</div>
                                </div>                            <div className="col-6 submit">
                                <div id="submit-btn" className="btn btn-primary nbtn"
                                onClick={this.showConfirmPasswordModal}><i className="fas fa-times"></i> Use old password </div>
                            </div></>):(      <div className="col-12 submit">
                                <div id="submit-btn" className="btn btn-primary" onClick={this.openModalPasswordChange}><i className="fas fa-check"></i>Change password</div>
                            </div>   )}
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.modalShowPasswordChange}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anis-modal"
                    className="refund-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div>

                            <h1>Password Reset</h1>

                            <p><strong>Enter Old Password</strong></p>
                            <div className="form-group">
                                <input type="password" className="form-control" value={this.state.old_password}
                                       name="old_password" placeholder="Enter Old Password"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_password["old_password"]}</span>
                            </div>
                            <p><strong>Choose a New Password</strong></p>
                            <div className="form-group">
                                <input type="password" className="form-control" value={this.state.new_password}
                                       name="new_password" placeholder="Choose a New Password"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_password["new_password"]}</span>
                            </div>
                            <p><strong>Confirm Your New Password</strong></p>
                            <div className="form-group">
                                <input type="password" className="form-control" value={this.state.confirm_password}
                                       name="confirm_password" placeholder="Confirm Your New Password"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_password["confirm_password"]}</span>
                            </div>

                            {this.state.password_change == true ? (
                                <PasswordChecklist
                                    rules={["minLength", "specialChar", "number", "capital", "match"]}
                                    minLength={10}
                                    value={this.state.new_password}
                                    valueAgain={this.state.confirm_password}
                                    onChange={(isValid) => {
                                        this.setState({isValid})
                                    }}
                                />) : ('')}

                        {/*    <button className="btn btn-danger" onClick={this.closePasswordModal}>Cancel</button>*/}
                            {this.state.loading ? <LoadingSpinner/> :
                                <button className="btn btn-success" onClick={this.resetPassword}>Reset</button>}
                        </div>
                    </Modal.Body>

                </Modal>

                <Modal
                    show={this.state.modalShowPasswordConfirm}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anis-modal"
                    className="refund-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div>

                            <h1>Password Reset</h1>
                            <p>You are keeping this at your own risk. please enter your password to confirm</p>
                            <p><strong>Enter Password</strong></p>
                            <div className="form-group">
                                <input type="password" className="form-control" value={this.state.user_password}
                                       name="user_password" placeholder="Enter Password"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_user_password["user_password"]}</span>
                            </div>

                            {/*    <button className="btn btn-danger" onClick={this.closePasswordModal}>Cancel</button>*/}
                            {this.state.loading ? <LoadingSpinner/> :
                                <button className="btn btn-success" onClick={this.continueOld}>Confirm</button>}
                        </div>
                    </Modal.Body>

                </Modal>

              {/*  <Modal
                    show={this.state.transactionModelOpen}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anis-modal"
                    className="refund-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div>

                            <h1>Password Reset</h1>

                            <p><strong>Enter Old Password</strong></p>
                            <div className="form-group">
                                <input type="password" className="form-control" value={this.state.old_password}
                                       name="old_password" placeholder="Enter Old Password"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_password["old_password"]}</span>
                            </div>
                            <p><strong>Choose a New Password</strong></p>
                            <div className="form-group">
                                <input type="password" className="form-control" value={this.state.new_password}
                                       name="new_password" placeholder="Choose a New Password"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_password["new_password"]}</span>
                            </div>
                            <p><strong>Confirm Your New Password</strong></p>
                            <div className="form-group">
                                <input type="password" className="form-control" value={this.state.confirm_password}
                                       name="confirm_password" placeholder="Confirm Your New Password"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_password["confirm_password"]}</span>
                            </div>

                            {this.state.password_change == true ? (
                                <PasswordChecklist
                                    rules={["minLength", "specialChar", "number", "capital", "match"]}
                                    minLength={5}
                                    value={this.state.new_password}
                                    valueAgain={this.state.confirm_password}
                                    onChange={(isValid) => {
                                        this.setState({isValid})
                                    }}
                                />) : ('')}

                            <button className="btn btn-danger" onClick={this.closePasswordModal}>Cancel</button>
                            {this.state.loading ? <LoadingSpinner/> :
                                <button className="btn btn-success" onClick={this.resetPassword}>Reset</button>}
                        </div>
                    </Modal.Body>

                </Modal>*/}

                <SupportButton/>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <Navigation pathname="active-dashboard"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="active-dashboard"/>
                        <div className="col-12 balance-line">
                            <div className="row row_clr">
                                <div className="lft">
                                    <div className="in">
                                        {this.state.last_paid_date !== "" ? (
                                            <>
                                                <h5>Last Settled Amount</h5>
                                                <h3><NumberFormat
                                                    value={(Math.round(this.state.last_paid_amount_lkr * 100) / 100).toFixed(2)}
                                                    displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></h3>
                                                {
                                                    this.state.last_paid_amount_usd == 0.00 ? '' :
                                                        <h3 className="amount-head-2"><NumberFormat
                                                            value={(Math.round(this.state.last_paid_amount_usd * 100) / 100).toFixed(2)}
                                                            displayType={'text'} thousandSeparator={true}
                                                            prefix={'USD '}/></h3>
                                                }
                                            </>) : ('')}
                                    </div>
                                    <div className="in">
                                        {this.state.last_paid_date !== "" ? (<h5>Last Settlement Date </h5>) : ('')}
                                        <h3 className="amount-head-2">{this.state.last_paid_date !== "" ? moment(this.state.last_paid_date).format('ll') : ''}</h3>
                                    </div>
                                </div>
                                <div className="rht">
                                    <div className="in">
                                        <DateRangePicker
                                            onEvent={this.handleEvent} onCallback={this.handleCallback}
                                            initialSettings={{
                                                startDate: moment(this.state.startDate).format('M/DD/YYYY'),
                                                endDate: moment(this.state.endDate).format('M/DD/YYYY'),
                                                maxDate: moment().format('M/DD/YYYY'),
                                                autoApply: true
                                            }}
                                        >
                                            <button className="date-btn"><i
                                                className="far fa-calendar-alt"></i> {this.state.dayCount} Days <i
                                                className="fas fa-caret-down" aria-hidden="true"></i></button>
                                        </DateRangePicker>
                                    </div>

                                    <div className="in">
                                        <h5>From</h5>
                                        <h3>{this.state.startDate}</h3>
                                    </div>
                                    <div className="in">
                                        <h5>Until</h5>
                                        <h3>{this.state.endDate}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 transactions">
                            <div className="row row_clr">

                                <div className="col-sm-3 lft">
                                    <ul className="t-list">
                                        <li className="active">
                                            <StyleRoot style={styles.bounce}>
                                                <h5>Total Transactions</h5>
                                                <h3>LKR {this.state.lkr_transaction_total}</h3>
                                                {
                                                    this.state.usd_transaction_total == 0.00 ? '' :
                                                        <h3 className="amount-head-2">USD {this.state.usd_transaction_total}</h3>
                                                }

                                                {
                                                    this.state.gbp_transaction_total == 0.00 ? '' :
                                                        <h3 className="amount-head-2">GBP {this.state.gbp_transaction_total}</h3>
                                                }


                                                                                            </StyleRoot>


                                            {/*  <i className="fas fa-caret-right" aria-hidden="true"></i>*/}
                                        </li>
                                        <li>
                                            <StyleRoot style={styles.bounce}>
                                                <h5>Total Refunds</h5>
                                                {
                                                    this.state.lkr_refund_total == 0.00 ? 'LKR 0.00' :
                                                        <h3 className="amount-head-2">LKR {this.state.lkr_refund_total}</h3>
                                                }

                                                {
                                                    this.state.usd_refund_total == 0.00 ? '' :
                                                        <h3 className="amount-head-2">USD {this.state.usd_refund_total}</h3>
                                                }

                                                {
                                                    this.state.usd_refund_total == 0.00 ? '' :
                                                        <h3 className="amount-head-2">GBP {this.state.gbp_refund_total}</h3>
                                                }


                                            </StyleRoot>
                                        </li>
                                        <li>
                                            <StyleRoot style={styles.bounce}>
                                                <h5>Total Due</h5>
                                                <h3><NumberFormat
                                                    value={this.state.total_due !== "undefined" ? this.state.total_due.toFixed(2) : 0.00}
                                                    displayType={'text'}
                                                    thousandSeparator={true} prefix={'LKR '}/></h3>
                                                {
                                                    this.state.total_due_usd == 0.00 ? '' :
                                                        <h3 className="amount-head-2"><NumberFormat
                                                            value={this.state.total_due_usd !== "undefined" ? this.state.total_due_usd.toFixed(2) : 0.00}
                                                            displayType={'text'}
                                                            thousandSeparator={true} prefix={'USD '}/></h3>
                                                }
                                            </StyleRoot>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-9 rht img-box">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group sct-fg">
                                                <label htmlFor="exampleFormControlSelect1">Select Currency Type</label>
                                                <select className="form-control" id="chart-selector"
                                                        onChange={this.selectChart}>
                                                    <option value="lkr">LKR</option>
                                                    <option value="usd">USD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<img src={require('../images/uh.png')} alt="" className="mi" />*/}
                                    <div id="chart_lkr">
                                        <ResponsiveContainer width="100%" height={250}>
                                            <AreaChart data={this.state.transaction_lists_graph_present}
                                                       margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                                <defs>
                                                    {/* <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#77b53c" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#77b53c" stopOpacity={0}/>
                                        </linearGradient>*/}
                                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                    </linearGradient>
                                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                                    </linearGradient>
                                                </defs>
                                                <XAxis dataKey="key" tick={true}/>
                                                <YAxis allowDecimals={true}/>
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <Tooltip formatter={NumberMask}/>
                                                <Legend/>
                                                {/* <Area type="monotone" dataKey="LKR" stroke="#77b53c" fillOpacity={1} fill="url(#colorUv)" />*/}
                                                <Area type="monotone" dataKey="LKR" stroke="#8884d8" fillOpacity={1}
                                                      fill="url(#colorUv)"/>
                                                <Area type="monotone" dataKey="LKR_PREV_MON" name="LKR COMPARISON DATA"
                                                      stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)"/>
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div id="chart_usd" style={{display: "none"}}>
                                        <ResponsiveContainer width="100%" height={250}>
                                            <AreaChart data={this.state.transaction_lists_graph_present}
                                                       margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                                <defs>
                                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                    </linearGradient>
                                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                                    </linearGradient>
                                                </defs>
                                                <XAxis dataKey="key" tick={true}/>
                                                <YAxis allowDecimals={true}/>
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <Tooltip formatter={NumberMask}/>
                                                <Legend/>
                                                <Area type="monotone" dataKey="USD" stroke="#8884d8" fillOpacity={1}
                                                      fill="url(#colorUv)"/>
                                                <Area type="monotone" dataKey="USD_PREV_MON" name="USD COMPARISON DATA"
                                                      stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)"/>
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-12 mid-gate-line w-boxes">
                            <div className="row row_clr">
                                <div className="col-sm-8 gate-tool">
                                    <StyleRoot style={styles.fadeInLeft}>
                                        <h3>Recent Activity: <span>Transactions </span></h3>
                                    </StyleRoot>
                                    <div className="col-sm-12 rht img-box p-0">
                                        {transaction_lists && transaction_lists.length ? (
                                            <div className='rg-container'>
                                                {/*  <DataTable
                                            data={transaction_lists}
                                            columns={columns}
                                            striped={true}
                                            hover={true}
                                            responsive={true}
                                            onClickRow={click}
                                        />*/}
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Transaction ID</th>
                                                        <th className=''>Transaction Amount</th>
                                                        <th className=''>Payment Type</th>
                                                        <th className=''>Date of Transaction</th>
                                                        <th className=''>Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {transaction_lists && transaction_lists.length ? (
                                                        transaction_lists.map((list, key) => {
                                                            if (key < 5) {
                                                                return (
                                                                    <tr className=''>
                                                                        <td className=''
                                                                            data-title='#'>{list.order_id}</td>
                                                                        <td className=''
                                                                            data-title='Transaction ID'>{list.order_refference_number}</td>
                                                                        <td className=''
                                                                            data-title='Transaction Value'>
                                                                            <NumberFormat
                                                                                value={(Math.round(list.total_amount * 100) / 100).toFixed(2)}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                prefix={list.payment_currency_code+' '}/>
                                                                        </td>
                                                                        <td className=''
                                                                            data-title='Transaction ID'>     {
                                                                            (() => {
                                                                                let default_size = "35px";
                                                                                let max_size = "63px";
                                                                                if (list.payment_gateway_id == 35 || list.payment_gateway_id == 42 || list.payment_gateway_id == 45 || list.payment_gateway_id == 2 || list.payment_gateway_id == 3 || list.payment_gateway_id == 16) {
                                                                                    default_size = "35px";
                                                                                } else {
                                                                                    default_size = max_size;
                                                                                }

                                                                                if (list.card_type.toLowerCase() == "visa")
                                                                                    return <img style={{width: "35px"}}
                                                                                                src={myConfig.laravelWeb + 'gateway_logos/visa.jpg'}
                                                                                                alt=""/>;
                                                                                if (list.card_type.toLowerCase() == "master" || list.card_type.toLowerCase() == "mastercard")
                                                                                    return <img style={{width: "35px"}}
                                                                                                src={myConfig.laravelWeb + 'gateway_logos/master.jpg'}
                                                                                                alt=""/>;
                                                                                else
                                                                                    return <img
                                                                                        style={{width: default_size}}
                                                                                        src={myConfig.laravelWeb + 'gateway_logos/' + list.payment_gateway_id + '.jpg'}
                                                                                        alt=""/>

                                                                            })()
                                                                        }</td>
                                                                        <td className=''
                                                                            data-title='Date of Transaction'>{moment(list.date_added).format('ll')}</td>
                                                                        <td className='' data-title='Status'>
                                                                            {list.payment_status_id == 2 ? (<><span
                                                                                className="dot green"><i
                                                                                className="fas fa-circle"
                                                                                aria-hidden="true"></i> </span>Approved</>) : ('')}
                                                                            {list.payment_status_id == 3 ? (<><span
                                                                                className="dot red"><i
                                                                                className="fas fa-circle"
                                                                                aria-hidden="true"></i> </span>Declined</>) : ('')}

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                    ) : (
                                                        <tr className=''>
                                                            <td colSpan="6" className='' data-title='#'>001</td>
                                                        </tr>

                                                    )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <div className="col-sm-12 rht img-box">
                                                <img src={require('../images/uh_no.png')} alt="" className="mi"/>

                                            </div>
                                        )
                                        }
                                    </div>

                                </div>
                                <div className="col-sm-4 personalize-db">
                                    <StyleRoot style={styles.fadeInRight}>
                                        <h3>Payment Methods</h3>
                                    </StyleRoot>
                                    {this.state.data_pie.length > 0 ? (
                                        <div className="col-sm-12 rht img-box">
                                            {/*       <img src={require('../images/uh.png')} alt="" className="mi" />*/}
                                            <ResponsiveContainer width={300} height="100%">
                                                <PieChart>
                                                    <Pie
                                                        data={this.state.data_pie}
                                                        cx={145}
                                                        cy={145}
                                                        labelLine={false}
                                                        label={this.state.renderCustomizedLabel}
                                                        outerRadius={110}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                    >
                                                        {
                                                            this.state.data_pie.map((entry, index) => <Cell
                                                                fill={this.state.COLORS[index % this.state.COLORS.length]}/>)
                                                        }
                                                    </Pie>
                                                    <Legend/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    ) : (
                                        <div className="col-sm-12 rht img-box">
                                            <img src={require('../images/uh_no.png')} alt="" className="mi"/>

                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <Footer/>

                    </div>
                </div>
            </div>

        );
    }

}


