import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {myConfig} from "../config";
import axios from "axios";
import NumberFormat from "react-number-format";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import LinearDeterminate from "./ProgressBar";
import moment from 'moment';

export class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    render() {

        return (


            <div className="col-12 footer">
                <p>© {moment().format('YYYY')} Copyright WEBXPAY (Pvt)
                    Ltd. {/*<a href="#"  target="_blank">Merchant Agreement</a>*/} {/*· <a href="#">Terms
                    of Use</a> <a href="https://webxpay.co/index.php/privacy-policy/" target="_blank">Privacy
                        Policy</a>*/}</p>
            </div>

        );
    }
}