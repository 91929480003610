import React from 'react';
import {SupplierCreation} from './SupplierManagement/SupplierCreation'
import {SupplierDetailModal} from "./SupplierManagement/SupplierModal";

export function PlusButton(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return <>
        <div id="plus-button"  onClick={() => setModalShow(true)}>
            <i className="fas fa-plus"></i>
        </div>
        <SupplierCreation
            show={modalShow}
            onHide={() => setModalShow(false)  }
            onReload={props.onReload}
            supplierTypes={props.supplierTypes}
            bankData={props.bankData}
        />

    </>
}
