import Tab from "react-bootstrap/Tab";
import React, { useContext, useEffect, useState } from "react";
import { ViewSupplierTransactionReceipt } from "./ViewSupplierTransactionReceipt";
import { Iframe } from "../../../common/Iframe";
import { PayNow } from "./PaymentHandler";
import { supplierIdContext } from "../SupplierManagement/LoadModalFunctions";
import {SettlementInformation} from "./SettlementInformation";

export function CreateNewTransaction() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div onClick={() => setModalShow(true)}>
        <div style={{ color: "#e6562fe6" }}>
          <strong> + Add New Transaction </strong>
        </div>
      </div>

      <ViewSupplierTransactionReceipt
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export function LoadIndividualTransaction() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div onClick={() => setModalShow(true)}>...</div>

      <ViewSupplierTransactionReceipt
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export function PaymentInformation(props) {
  const supplier_id = useContext(supplierIdContext);

  let lend = 0;
  return (
    <Tab.Pane eventKey="second">
      <div className="row pay-opts">
        <div className="col-12">
          <h5>Payment Information</h5>
        </div>
        <div className="col-12">
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="pay-online"
              role="tabpanel"
              aria-labelledby="nav-pay-online-tab"
            >
              <div className="row administrator-information">
                <div className="col-12">
                  {/*    <p className="p-us">Pay Using <img src="images/cards/4.png" alt=""/> or <img
                                    src="images/cards/13.png" alt=""/></p>

                               <form className="row administrator-information">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control gfc"
                                                   placeholder="Cardholder Name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control gfc" placeholder="Card Number"/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input type="text" className="form-control gfc"
                                                   placeholder="Expiration (MM/YY)"/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input type="text" className="form-control gfc" placeholder="CVV Code"/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input type="text" className="form-control gfc" placeholder="Promo Code"/>
                                        </div>
                                    </div>
                                    <div className="col-md-8 pcnt1">
                                        <div className="row">
                                            <div className="col-12 pcond">
                                                <p>Save my card details for future transactions</p>
                                                <label className="cont">
                                                    <input type="checkbox"/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="col-12 pcond">
                                                <p>I have read and agree to the <a href="#">Terms & Conditions</a></p>
                                                <label className="cont">
                                                    <input type="checkbox"/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 pcnt1">
                                        <button className="sub-btn">Make Payment</button>
                                    </div>
                                </form>*/}

                  <div className="row row_clr fm">
                    <div class="col-12 fm-in">
                      <p class="p-us">
                        You may proceed to make payment by selecting the 'Pay with Visa' option or opt out by selecting the 'Pay Later' option and make an payment at your convenience.
                      </p>
                      {/* <ul class="clist">
                        <li>
                          <img
                            src={require("../../../../images/cards/1.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/2.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/3.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/4.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/5.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/6.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/7.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/8.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/9.png")}
                            alt=""
                          />
                        </li>
                        <li>
                          <img
                            src={require("../../../../images/cards/10.png")}
                            alt=""
                          />
                        </li>
                      </ul> */}
                    </div>
                  </div>
                  <PayNow
                    productList={props.productList}
                    convinceFeeAcceptedBy={props.convinceFeeAcceptedBy}
                    subTotal={props.subTotal}
                    transactionAmount={props.transactionAmount}
                    settlementAmount={props.settlementAmount}
                    merchantFee={props.merchantFee}
                    supplierFee={props.supplierFee}
                    convenienceFee={props.convenienceFee}
                    supplierData={props.supplierData}
                    invoiceId={props.invoiceId}
                    mdrPercentage={props.mdrPercentage}
                    hideModal={props.hideModal}

                    setTransactionAmount={props.setTransactionAmount}
                    setSettlementAmount={props.setSettlementAmount}
                    setMerchantFee={props.setMerchantFee}
                    setSupplierFee={props.setSupplierFee}
                    setconvenienceFee={props.setconvenienceFee}
                    setTotalValue={props.setTotalValue}
                    setConvinceFeeAcceptedBy={props.setConvinceFeeAcceptedBy}
                  />
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pay-offline"
              role="tabpanel"
              aria-labelledby="nav-pay-offline-tab"
            >
              <div className="row offline-pay">
                <div className="col-12 oft">
                  <h4>For Cheque Payments</h4>
                </div>
                <div className="col-md-6">
                  <p>Please make the cheque in favor of</p>
                  <h3>WEBXPAY (Pvt) Ltd.</h3>
                </div>
                <div className="col-md-6">
                  <p>Send the cheque via Registered Post to</p>
                  <p>
                    <b>
                      5th Floor, Green Lanka Tower, No. 46/46, Navam Mawatha,
                      Colombo 02, Sri Lanka.
                    </b>
                  </p>
                  <p>
                    <b>
                      Or <a href="tel:+94117441900">Call (+94) 117 441 900</a>{" "}
                      to Collect
                    </b>
                  </p>
                </div>
                <div className="col-12 oft mt-4">
                  <h4>For Direct Debits or Wire Transfers</h4>
                  <p className="mb-3">
                    Please find the account information as follows:
                  </p>
                </div>
                <div className="col-md-6">
                  <h3>1007 5396 0039</h3>
                  <h4 className="mb-2">WEBXPAY (Pvt) Ltd.</h4>
                  <p>
                    5th Floor, Green Lanka Tower,
                    <br />
                    No. 46/46, Navam Mawatha,
                    <br />
                    Colombo 02, Sri Lanka.
                  </p>
                </div>
                <div className="col-md-6">
                  <ul className="b-list">
                    <li>
                      <span>Bank Name</span>: Sampath Bank PLC
                    </li>
                    <li>
                      <span>Address</span>: No. 19 A1, Kandy
                    </li>
                    <li>
                      <span>Bank Code</span>: 7278
                    </li>
                    <li>
                      <span>Branch No</span>: 007
                    </li>
                    <li>
                      <span>SWIFT Code</span>: BSAMLKLX
                    </li>
                  </ul>
                </div>
                <div className="col-12 oft mt-4">
                  <h4>Proof of Payment</h4>
                  <div className="upload">
                    <img src="images/upload.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tab.Pane>
  );
}
