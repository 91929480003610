import Tab from "react-bootstrap/Tab";
import React, { useContext, useEffect, useState } from "react";
import { LoadIndividualTransaction } from "./PaymentInformation";
import { FormControl, FormLabel, Modal } from "react-bootstrap";

import { SetShowItems } from "./SetShowItems";
import NumberFormat from "react-number-format";
import { PostWebxLaravel } from "../../../PostCompnent";
import { supplierIdContext } from "../SupplierManagement/LoadModalFunctions";
import {
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

export function SettlementInformation(props) {
  const supplier_id = useContext(supplierIdContext);

  const [loderStatePro, setLoaderState] = useState(false);
  function setCalculations(convinceFeeAcceptedBy) {
    let mdr_p = props.mdrPercentage;

    if (convinceFeeAcceptedBy == "1") {
      //merchant

      let transaction_amount = (props.subTotal * 100) / (100 - mdr_p);
      let merchant_fee = transaction_amount - props.subTotal;
      let settlement_amount = props.subTotal;
      props.setTransactionAmount(
        (Math.round(transaction_amount * 100) / 100).toFixed(2)
      );
      props.setSettlementAmount(
        (Math.round(settlement_amount * 100) / 100).toFixed(2)
      );
      props.setSupplierFee(0.00)
      props.setMerchantFee((Math.round(merchant_fee * 100) / 100).toFixed(2));
      props.setconvenienceFee(
        (Math.round(merchant_fee * 100) / 100).toFixed(2)
      );
    } else if (convinceFeeAcceptedBy == "2") {
      let transaction_amount = props.subTotal;
      let supplier_fee = (transaction_amount * mdr_p) / 100;
      let settlement_amount = transaction_amount - supplier_fee;
      props.setTransactionAmount(
        (Math.round(transaction_amount * 100) / 100).toFixed(2)
      );
      props.setSettlementAmount(
        (Math.round(settlement_amount * 100) / 100).toFixed(2)
      );
      props.setMerchantFee(0.00)
      props.setSupplierFee((Math.round(supplier_fee * 100) / 100).toFixed(2));
      props.setconvenienceFee(
        (Math.round(supplier_fee * 100) / 100).toFixed(2)
      );
    } else {
      let transaction_amount = props.subTotal;
      let supplier_fee = (transaction_amount * mdr_p) / 100;
      let settlement_amount = transaction_amount - supplier_fee;
      props.setTransactionAmount(
        (Math.round(transaction_amount * 100) / 100).toFixed(2)
      );
      props.setSettlementAmount(
        (Math.round(settlement_amount * 100) / 100).toFixed(2)
      );
      props.setSupplierFee((Math.round(supplier_fee * 100) / 100).toFixed(2));
    }
  }

  function setCalculations1(convinceFeeAcceptedBy, subTotal) {
    let mdr_p = props.mdrPercentage;

    if (convinceFeeAcceptedBy == "1") {
      //merchant

      let transaction_amount = (subTotal * 100) / (100 - mdr_p);
      let merchant_fee = transaction_amount - subTotal;
      let settlement_amount = subTotal;
      props.setTransactionAmount(
        (Math.round(transaction_amount * 100) / 100).toFixed(2)
      );
      props.setSettlementAmount(
        (Math.round(settlement_amount * 100) / 100).toFixed(2)
      );
      props.setSupplierFee(0.00)
      props.setMerchantFee((Math.round(merchant_fee * 100) / 100).toFixed(2));
      props.setconvenienceFee(
        (Math.round(merchant_fee * 100) / 100).toFixed(2)
      );
    } else if (convinceFeeAcceptedBy == "2") {
      let transaction_amount = subTotal;
      let supplier_fee = (transaction_amount * mdr_p) / 100;
      let settlement_amount = transaction_amount - supplier_fee;
      props.setTransactionAmount(
        (Math.round(transaction_amount * 100) / 100).toFixed(2)
      );
      props.setSettlementAmount(
        (Math.round(settlement_amount * 100) / 100).toFixed(2)
      );
      props.setMerchantFee(0.00)
      props.setSupplierFee((Math.round(supplier_fee * 100) / 100).toFixed(2));
      props.setconvenienceFee(
        (Math.round(supplier_fee * 100) / 100).toFixed(2)
      );
    } else {
      let transaction_amount = subTotal;
      let supplier_fee = (transaction_amount * mdr_p) / 100;
      let settlement_amount = transaction_amount - supplier_fee;
      props.setTransactionAmount(
        (Math.round(transaction_amount * 100) / 100).toFixed(2)
      );
      props.setSettlementAmount(
        (Math.round(settlement_amount * 100) / 100).toFixed(2)
      );

      props.setSupplierFee((Math.round(supplier_fee * 100) / 100).toFixed(2));
    }
  }

  const changeValue = (e) => {
    //alert(e.target.value);
    props.setConvinceFeeAcceptedBy(e.target.value);

    setCalculations(e.target.value);
  };

  const runFunction = () => {
    console.log(props);
    if (props.convinceFeeAcceptedBy == "") {
      alert("Please Select Bearer");
    } else if (props.productList.length == 0) {
      alert("Please Add Products");
    } else {
      setLoaderState(true);
      let formData = new FormData(); //formdata object

      if (props.productList && props.productList.length) {
        props.productList.map((products, index) => {
          formData.append(
            "line_item[" + index + "][attachment]",
            products.attachment[0]
          ); //append the values with key, value pair
          formData.append(
            "line_item[" + index + "][description]",
            products.product_name
          ); //append the values with key, value pair
          formData.append(
            "line_item[" + index + "][price]",
            products.product_rate
          ); //append the values with key, value pair
        });

        formData.append("bearer", props.convinceFeeAcceptedBy); //append the values with key, value pair
        formData.append("subtotal", props.subTotal); //append the values with key, value pair
        formData.append("supplier_id", props.supplierData.supplier_id); //append the values with key, value pair
        formData.append("product_list", props.productList);
        formData.append("convince_fee_bearer", props.convinceFeeAcceptedBy);
        formData.append("sub_total", props.subTotal);
        formData.append("transaction_amount", props.transactionAmount);
        formData.append("settlement_amount", props.settlementAmount);
        formData.append("merchant_fee", props.merchantFee);
        formData.append("supplier_fee", props.supplierFee);
        formData.append("convince_fee", props.convenienceFee);
        formData.append("invoice_id", props.invoiceId);
        formData.append("mdr_percentage", props.mdrPercentage);
      }

      PostWebxLaravel("bpsp/create-invoice", formData).then((response) => {
        props.setInvoiceId(response.data.invoice_id);
        setLoaderState(false);
        setEvent();
      });
    }
  };

  const setEvent = () => {
    props.setEventName("Payment Information");
    props.setEventK("second");
  };

  const removeItem = (index) => {
    var array = [...props.productList]; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
      props.setToList(array);
    }

    let total = 0;
    for (let i = 0; i < array.length; i++) {
      total += parseFloat(array[i].product_rate);
      // * newArr[i].product_quantity
    }

    props.setTotalValue(total);
    setCalculations1(props.convinceFeeAcceptedBy, total);
  };

  return (
    <>
      <Backdrop className="backdrop-loading" open={loderStatePro}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Tab.Pane className="tab-pane fade show " eventKey="first">
        <div className="th-ot">
        <h4>Invoice Creation</h4>

        <SetShowItems
          productList={props.productList}
          setToList={props.setToList}
          setTotalValue={props.setTotalValue}
        />
        </div>
        <div className="rg-container rg-container-type-2 ">
          <div class="table-outer">
          <table className="transactions-table invoice-table tt-type-2 create-transactions-table" summary="Hed">
            <thead>
              <tr>
                <th className="">#</th>
                <th className="">Items</th>
                <th className="">Rate</th>
                {/* <th className="">Qty</th> */}
                <th className="">
                  <i className="fa fa-paperclip" aria-hidden="true"></i>
                </th>
                <th className="">Value</th>
                <th className="">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {props.productList && props.productList.length
                ? props.productList.map((products, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{products.product_name}</td>
                        <td>
                          <NumberFormat
                              value={Math.round(products.product_rate).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"LKR "}
                          />
                          </td>
                        {/* <td>{products.product_quantity}</td> */}
                        <td>
                          {products.attachment.length!==0?
                              (<i
                            className="fa fa-file-pdf-o"
                            aria-hidden="true"
                            style={{ color: "red" }}
                          ></i>):('')}
                        </td>
                        <td>
                          <NumberFormat
                            value={Math.round(products.product_rate).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"LKR "}
                          />
                        </td>
                        <td>
                          <span onClick={() => removeItem(index)}>
                            <i class="fa fa-trash r-icon" aria-hidden="true"></i>
                          </span>
                        </td>
                      </tr>
                    );
                  })
                : ""}

              <tr>
                <td></td>
                <td></td>
                {/* <td></td> */}
                <td></td>
                <td>Sub Total</td>

                <td>
                  <NumberFormat
                    value={Math.round(props.subTotal).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"LKR "}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                {/* <td></td> */}
                <td></td>
                <td>Handling Fee</td>
                <td>
                  <NumberFormat
                    value={props.convenienceFee}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"LKR "}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                {/* <td></td> */}
                <td></td>
                <td>Total Payable</td>
                <td>
                  <NumberFormat
                    value={props.transactionAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"LKR "}
                  />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          </div>
          <div className="tac">
            {/* <h6>Select Bearer</h6>
            <input
              type="radio"
              value="2"
              name="accepted_by"
              onChange={changeValue}
            />
            Supplier
            <input
              type="radio"
              value="1"
              name="accepted_by"
              onChange={changeValue}
            />
            Merchant */}
            {/* <div className="tac">
              <h6>Attachments</h6>
              <p></p>
            </div> */}

            {props.productList && props.productList.length
                ? (
                   <>
                     <h6 className="bare">Who bares the handling fee?</h6>
                     <div className="r-btns">
                       <p>
                         <input type="radio" id="test1" name="radio-group" value="2" onChange={changeValue}/>
                         <label htmlFor="test1">Supplier</label>
                       </p>
                       <p>
                         <input type="radio" id="test2" name="radio-group" value="1" onChange={changeValue}/>
                         <label htmlFor="test2">Buyer</label>
                       </p>

                     </div>
                   </>
                  ):(
                    <>
                      <h6 className="bare">Who bares the handling fee?</h6>
                      <div className="r-btns">
                        <p>
                          <input type="radio" id="test1" name="radio-group" value="2" disabled/>
                          <label htmlFor="test1">Supplier</label>
                        </p>
                        <p>
                          <input type="radio" id="test2" name="radio-group" value="1" disabled/>
                          <label htmlFor="test2">Buyer</label>
                        </p>

                      </div>
                    </>
                )

                }


          </div>
        </div>
      </Tab.Pane>
      <div ref={props.myfuncc} onClick={runFunction}></div>
    </>
  );
}
