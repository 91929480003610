import React, {PureComponent} from 'react';

import {Link, Navigate} from "react-router-dom";
import {Scrollbars} from 'react-custom-scrollbars';
import {Navigation} from "../Navigation";
import moment from 'moment';
import {myConfig} from "../../config";
import {TopBar} from "../TopBar";
import ReactPaginate from "react-paginate";

import Modal from "react-bootstrap/Modal";
import {GetWebxLaravel, PostCompnentWebx, PostWebxLaravel} from "../PostCompnent";
import {Footer} from "../Footer";

import {SupportButton} from "../SupportButton";
import PasswordChecklist from "react-password-checklist"

import 'suneditor/dist/css/suneditor.min.css';
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SunEditor from "suneditor-react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Switch from "react-switch";
import {SubscriptionTabs} from "./components/SubscriptionTabs";


export class SubscriptionSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {


        };



    }


    render() {



        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }
        return (
            <div>{/*    <div id="plus-button" onClick={this.addRemoveActive}>
                    <i className="fas fa-plus"></i>
                </div>*/}
                <SupportButton/>
                {/*Tax Modal*/}



                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">

                    <div id="turn-box"></div>
                    <Navigation pathname="settings-general"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="settings-general"/>
                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <Link className="nav-link" id="tt-tab" to="../settings-general"><span
                                        className="tt">Business Configuration</span><span className="amt">General</span></Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" id="tp-tab" to="../invoice-settings"><span
                                        className="tt">Invoices, Payment Links & Taxes</span><span
                                        className="amt">Invoices</span></Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" id="tp-tab" to="../settings-user"><span
                                        className="tt">Roles, Profiles, and Permissions</span><span
                                        className="amt">User Accounts</span></Link>
                                </li>

                              {/*  <li className="nav-item">
                                    <Link className="nav-link active" id="tp-tab" to="../subscription-settings">
                                        <span className="tt">Payments, History, Upcoming Payments and Package Change</span>
                                        <span className="amt">Subscriptions</span>
                                    </Link>
                                </li>*/}

                            </ul>

                            <div className="row row_clr settings-outer">
                                <SubscriptionTabs/>
                            </div>

                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>

        );
    }

}


