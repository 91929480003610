import React, {useEffect} from "react";
import {PostCompnentWebxBpsp, PostWebxLaravel} from "../../../PostCompnent";
import {Navigation} from "../../../Navigation";
import {TopBar} from "../../../TopBar";
import {Footer} from "../../../Footer";
import moment from 'moment';
import NumberFormat from "react-number-format";
import {LoadModalFunctions} from "../SupplierManagement/LoadModalFunctions";
import {exportPDFDownload} from "../../../ExportPdf";
import ReactPaginate from "react-paginate";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Dropdown from "react-bootstrap/Dropdown";
import {CSVLink} from "react-csv";

export function TransactionListTable() {
    const [transactionData, setTransactionData] = React.useState([]);

    const [transaction_lists, setTransactionList] = React.useState([]);
    const [transaction_lists_render, setTransactionListRender] = React.useState([]);
    const [transaction_lists_filters, setTransactionListFilters] = React.useState([]);
    const [offsetTransactions, setOffsetTransactions] = React.useState(0);
    const [perPageTransactions, setPerPageTransactions] = React.useState(10);
    const [currentPageTransactions, setCurrentPageTransactions] = React.useState(0);
    const [pageCountTransactions, setPageCountTransactions] = React.useState(0);
    const [transaction_keyword_value, setTransactionKeywordValue] = React.useState('');
    const [csvDataTransaction, setCsvDataTransaction] = React.useState([]);

    useEffect(() => {

        GenerateTransactionList();
    }, []);

    useEffect(() => {

        loadTableTransactions();
    }, [transaction_lists_filters,offsetTransactions,
        currentPageTransactions,]);

    //Customers List
   const GenerateTransactionList=()=> {

        let formData = new FormData();    //formdata object
        formData.append("store_id", sessionStorage.getItem("merchant_id"));
        PostCompnentWebxBpsp("getMerchantBpspOrders", formData).then(response => {

            setTransactionList(response.data);
            setTransactionListFilters(response.data);
            csvGenerateTransactions(response.data)
           // loadTableTransactions(response.data);

        }).then(()=>{

        });
    }

    const loadTableTransactions=()=> {
        const list_data =transaction_lists_filters;

        const zeroPad = (num, places) => String(num).padStart(places, '0');

        const slice = list_data.slice(offsetTransactions, offsetTransactions + perPageTransactions
        );


        
        const trans = list_data && list_data.length ? (slice.map((list, key) =>
            <tr>
                <td>{zeroPad(offsetTransactions+key+1, 3)}</td>
                <td>{list.order_refference_number}</td>
                <td>
                    <NumberFormat
                        value={list.transaction_amount}
                        displayType={'text'} thousandSeparator={true}
                        prefix={ 'LKR '}/>
                </td>
                <td>{moment(list.date_added).format('ll')}</td>
                <td>{list.settlement_date_time!==null?moment(list.settlement_date_time).format('ll'):''}</td>
                <td>   {list.supplier_settlement_status ==
                2 ? (
                    <>
                        {list.payment_status_id ==
                        2 ?( <>
                              <span className="dot green">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            Settled
                        </>):('')}

                    </>
                ) : (
                    ""
                )}
                    {list.supplier_settlement_status ==
                    3 ? (
                        <>
                              <span className="dot red">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                           Settlement Declined
                        </>
                    ) : (
                        ""
                    )}
                    {list.supplier_settlement_status ==
                    1 ? (
                        <>
                        {list.payment_status_id ==
                            1 ?( <>
                              <span className="dot yellow">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                               Payment Pending
                            </>):('')}
                            {list.payment_status_id ==
                            2 ?( <>
                              <span className="dot green">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                               Payment Approved
                            </>):('')}
                            {list.payment_status_id ==
                            3 ?( <>
                              <span className="dot red">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                               Payment Declined
                            </>):('')}
                      </>
                    ) : (
                        ""
                    )}
                    {list.supplier_settlement_status ==
                    4 ? (
                        <>
                            {list.payment_status_id ==
                            2 ?( <>
                              <span className="dot yellow">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                               Settelement In-Progress
                            </>):('')}

                        </>
                    ) : (
                        ""
                    )}
                </td>
            </tr>
        )) : (
            <tr className=''>
                <td colSpan="6" className='' data-title='#'><strong>No results found, for the selected date range /
                    filter</strong></td>
            </tr>
        );
        setPageCountTransactions(Math.ceil(list_data.length / perPageTransactions))
        setTransactionListRender(trans);
    }

    const handlePageClickTransactions = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPageTransactions;
        setCurrentPageTransactions(selectedPage)
        setOffsetTransactions(offset)
     //   loadTableTransactions();

    };


    const csvGenerateTransactions=(response) =>{

        const csvData = [];

        csvData.push([
            "", "", "TRANSACTION REPORT"
        ]);
        csvData.push([]);

        csvData.push([
            "TRANSACTION REF",
            "AMOUNT",
            "DATE OF TRANSACTION",
            "STATUS",


        ]);
        response.map((value, index) => {
            let status = "";
            if (value.supplier_settlement_status==1){
                if (value.payment_status_id==1){
                    status="Payment Pending"
                }else if(value.payment_status_id==2){
                    status="Payment Approved"
                }else{
                    status="Payment Declined"
                }
            }else if(value.supplier_settlement_status==2){
                if (value.payment_status_id==2){
                    status="Settlement Approved";
                }
            }else if(value.supplier_settlement_status==4){
                status="Settlement In-progress";
            }else{
                status="Settlement Declined";
            }
            csvData.push([
                value.order_refference_number,
                value.transaction_amount,
                value.date_added,
                status])
        });
        setCsvDataTransaction(csvData)

    }

    const clearPagination=()=> {
        var second = document.querySelector(".pagination > li:nth-child(2) > a");
        if (second !== null) {
            second.click();
        }
    }

    const exportPDF=(type, csvData) =>{
        let title = "";
        let pdf_header = "";
        let document_name = "";
        let pdf_filtered_data = [];
        let pdf_data = csvData;


        switch (type) {
            case 'transaction_data':
                title = "Transaction Data";
                document_name = "Transaction Data.pdf";
                pdf_header = [pdf_data[2]];
                let pdf_data_suppliers = pdf_data;
                let pdf_data_supplier = pdf_data_suppliers.filter((pdf_data_supplier, index) => index > 2)
                pdf_filtered_data = pdf_data_supplier;
                break;


            default:
        }

        exportPDFDownload(title, pdf_header, pdf_filtered_data, document_name);
    }

    //End Of List Generation Supplier





    return (
        <>
        <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
            <div id="turn-box"></div>
            <Navigation pathname="bpsp-transaction-list"/>
            <div id="main-content" className="row row_clr dashboard-live-mode">
                <TopBar pathname="transactions"/>

                <div className="col-12 transaction-top">

                    <div className="col-12 tab-content">
                        <div className="row row_clr tt-ttp" style={{justifyContent:"flex-end"}}>

                            <form className="col-md-4 col-12 tr-form">
                                <div className="row row_clr">

                                    <div className="form-group">

                                        <Dropdown>
                                            <Dropdown.Toggle variant="none"
                                                             id="settlement-download-dropdown">
                                                 <i className="fas fa-download"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <CSVLink data={csvDataTransaction} style={{
                                                    textDecoration: "none !important",
                                                    color: "#000"
                                                }} className="dropdown-item"
                                                         filename={"Suppliers.csv"}>
                                                    <i className="fas fa-download"></i> CSV Download
                                                </CSVLink>
                                                <button type="button"
                                                        onClick={() => exportPDF("transaction_data", csvDataTransaction)}
                                                        className="dropdown-item"><i
                                                    className="fas fa-file-pdf"></i>Generate Report
                                                </button>

                                            </Dropdown.Menu>

                                        </Dropdown>


                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>

                    <table className="transactions-table" summary="Hed">
                        <thead>
                        <tr>
                            <th className="">#</th>
                            <th className="">Transaction Reference</th>
                            <th className="">Transaction Amount</th>
                            <th className="">Date of Transaction</th>
                            <th className="">Date of Settlement</th>
                            <th className="">Status</th>
                          {/*  <th className="">
                                <i className="fa fa-cog" aria-hidden="true"></i>
                            </th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        {transaction_lists_render}
                        
                        </tbody>
                    </table>
                    <ReactPaginate
                            previousLabel={"PREV"}
                            nextLabel={"NEXT"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCountTransactions}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClickTransactions}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}/>
                </div>

                <Footer/>

            </div>
        </div>


        </>
    );
}