import React from "react";

export class PasswordConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
        };
    }

    render() {
        return (
            <div id="outer-container" className="container-fluid">
                <div id="turn-box"></div>
                <div id="inside-container" className="container">
                    <div className="row row_clr main-row">

                        <div className="col-sm-6 logo-txt">
                            <img src={require('../images/webxpay-logo.svg')} alt="logo" className="logo" />

                                <h1>Welcome <span>Viraj Adhihetty</span></h1>
                                <h3>Thank you for verifying your account.</h3>
                                <h3 className="mt-3">Add in your new password and you'll be ready to go!</h3>
                              {/*  <a href="#" className="help"><i className="far fa-life-ring"></i> Need a hand figuring
                                    it out?</a>*/}
                        </div>

                        <div className="col sign-box">
                            <div className="row">
                                <div className="col-12 sign-top">
                                    <img src={require('../images/password-confirmation-icon.svg')} alt="sign-in-icon"
                                         className="sign-icon" />
                                        <h2 className="sign-head">Confirm Password</h2>
                                        <p className="time-left">Enter a Strong Password and Store it Safe</p>
                                </div>
                                <div className="col-12 form-type-1">

                                    <form className="row row_clr pin-ver-form">
                                        <div className="col-12 p-0">

                                            <div className="form-group">
                                                <input type="password" className="form-control password" id="password"
                                                       placeholder="Account Password" />
                                                    <button type='button' id="password-txt" className="password-txt"
                                                            onClick="myFunction()"><img src={require('../images/visibility.svg')}
                                                                                        alt="visibility" /></button>
                                            </div>

                                            <div className="form-group">
                                                <input type="password" className="form-control password"
                                                       id="re-password" placeholder="Reenter Password" />
                                                    <button type='button' id="re-password-txt" className="password-txt"
                                                            onClick="myFunction2()"><img src={require('../images/visibility.svg')}
                                                                                         alt="visibility" /></button>
                                            </div>
                                        </div>

                                        <div className="col-12 no-pad submit">
                                            <input id="submit-btn" type="submit" value="Go to Dashboard" />
                                        </div>
                                    </form>

                                </div>
                                <div className="col-12 no-account">
                                    <p className="go-back"><a href="sign-up.html"><i
                                        className="far fa-arrow-alt-circle-left"></i> Go Back to Previous Step</a></p>
                                </div>
                                <div className="col-12 copy-txt">
                                    <p className="cop-p">By Signing In or Registering on the system, you confirm that
                                        you agree to our <a href="#" className="green-txt">Terms of Service</a> and <a
                                            href="#" className="green-txt">Privacy Policy</a>.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}