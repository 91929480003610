import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";

import {Navigation} from "../Navigation";
import {TopBar} from "../TopBar";
import {PostCompnentWebxBpsp} from "../PostCompnent";
import {Footer} from "../Footer";
import {SupportButton} from "../SupportButton";
import {fadeInLeft, fadeInRight, flipInX} from "react-animations";
import Radium from "radium";
import {PaymentMethodChart} from "./DashboardIncludes/PaymentMethodsChart";
import {AllTransactionsChart} from "./DashboardIncludes/AllTransactionsChart";
import {ActiveSuppliers} from "./DashboardIncludes/ActiveSuppliers";
import {LastTransactions} from "./DashboardIncludes/LastTransactions";
import {ResponsiveContainer} from "recharts";

export class BpspDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userToken: [],
      openprogress: false,
      setOpenProgress: false,
      supplierList: [],
      transactionList: [],
      supplierListTotals: [],
      monthlySummeryMonth: [],
      monthlySummeryTransaction: [],

    };
    this.handleCloseProgress = this.handleCloseProgress.bind(this);
    this.handleToggleProgress = this.handleToggleProgress.bind(this);
    this.loadTransactionsAndSuppliers = this.loadTransactionsAndSuppliers.bind(this);
  }

  handleCloseProgress = () => {
    this.setState({setOpenProgress: false});
    this.setState({openprogress: false});
  };
  handleToggleProgress = () => {
    this.setState({setOpenProgress: true});
    this.setState({openprogress: true});
  };

  loadTransactionsAndSuppliers() {
    let formData = new FormData();    //formdata object
    formData.append("store_id", sessionStorage.getItem("merchant_id"));
    formData.append("sort_desc_total_transaction", "");
    PostCompnentWebxBpsp("getMerchantBpspSuppliersWithTransactionTotal", formData).then(response => {
      console.log("response")
      console.log(response.data)
      this.handleCloseProgress();
      var dataArr = [];
      for (let i = 0; i < response.data.length; i++) {
        if (i < 6) {
          dataArr[i] = response.data[i].total_transaction
        }

      }
      console.log("response!!")
      console.log(dataArr)
      this.setState({
        supplierList: response.data,
        supplierListTotals: dataArr
      }, () => {
      });
    });
    PostCompnentWebxBpsp("getMerchantBpspOrders", formData).then((response) => {
      this.handleCloseProgress();
      this.setState({
        transactionList: response.data,
      }, () => {
      });
    });

    PostCompnentWebxBpsp("getMonthlyTransactionTotalSummery", formData).then((response) => {
      this.handleCloseProgress();

      var dataArr = [];
      for (let i = 0; i < response.data.length; i++) {

        dataArr[i] = response.data[i].month_name
      }

      var dataArr2 = [];
      for (let i = 0; i < response.data.length; i++) {

        dataArr2[i] = response.data[i].total_transaction
      }
      this.setState({
        monthlySummeryMonth: dataArr,
        monthlySummeryTransaction: dataArr2
      }, () => {
      });
    });


  }

  componentWillMount() {
    if (
        sessionStorage.getItem("userToken") ||
        localStorage.getItem("userToken")
    ) {
      var userToken =
          sessionStorage.getItem("userToken") ||
          localStorage.getItem("userToken");
      this.loadTransactionsAndSuppliers();
      this.setState({userToken: userToken});
    } else {
      this.setState({redirect: true});
    }
  }

  render() {
    const styles = {
      bounce: {
        animation: "x 3s",
        animationName: Radium.keyframes(flipInX, "flipInX"),
      },
      fadeInLeft: {
        animation: "x 2s",
        animationName: Radium.keyframes(fadeInLeft, "fadeInLeft"),
      },
      fadeInRight: {
        animation: "x 2s",
        animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
      },
    };
    const transaction_lists = this.state.transaction_lists;
    //const transaction_lists=order_change.sort((a, b) => (b.order_id - a.order_id));

    const NumberMask = (value) => (
        <NumberFormat
            value={(Math.round(value * 100) / 100).toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={" "}
        />
    );

    if (this.state.redirect) {
      return <Navigate to="/login"/>;
    }

    let colors = [
      '#ff6384',
      '#36a2eb',
      '#ffce56',
      '#9966ff',
      '#ffcf9f',
      '#d13d05',
    ]

    return (
        <div>
          <SupportButton/>
          <Backdrop className="backdrop-loading" open={this.state.openprogress}>
            <CircularProgress color="inherit"/>
          </Backdrop>

          <div
              id="outer-container"
              className="container-fluid color-orange outer-container-inside-page"
          >
            <div id="turn-box"></div>
            <Navigation pathname="bpsp-dashboard"/>
            <div id="main-content" className="row row_clr dashboard-live-mode">
              <TopBar pathname="active-dashboard"/>

              <div className="col-12 mid-gate-line w-boxes sup-db-w-boxes">
                <div className="row row_clr">
                  <div className="col-sm-7 gate-tool">
                    <div className="w-box">
                      <div className="top-section">
                        <div className="lft">
                          <h3 className="title">
                            Transactions
                            {/* <span className="gt">
                            <i className="fas fa-chart-line"></i> 56% Increase
                          </span> */}
                          </h3>
                        </div>
                        <div className="rht">
                        <span className="date">
                          {/* <span className="ot">
                            <i className="fas fa-calendar-alt"></i>
                            Jan.202 to Jan.2022
                            <i className="fas fa-caret-down"></i>
                          </span> */}
                        </span>
                        </div>
                      </div>
                      <ResponsiveContainer width="100%" height="100%">
                        <AllTransactionsChart
                            monthlySummeryMonth={this.state.monthlySummeryMonth}
                            monthlySummeryTransaction={this.state.monthlySummeryTransaction}
                        />
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div className="col-sm-5 personalize-db">
                    <div className="w-box">
                      <div className="top-section">
                        <div className="lft">
                          <h3 className="title">Highest Settled</h3>
                        </div>
                        <div className="rht">
                        <span className="date">
                          <span className="ot">
                            {/* <DropdownButton
                              id="dropdown-basic-button"
                              title="All Methodes"
                              className="filter-dropdown-toggle"
                            >
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Something else
                              </Dropdown.Item>
                            </DropdownButton> */}
                          </span>
                        </span>
                        </div>
                      </div>
                      <div className="cir-chart-container">
                        <div className="text">
                          <table>
                            <tbody>
                            {this.state.supplierList && this.state.supplierList.length ? (this.state.supplierList.map((list, key) => {
                                  if (key < 6) {
                                    return (<tr>
                                      <td><span className="dot" style={{"--background-color": colors[key]}}></span></td>
                                      <td>{list.username}</td>
                                      <td><NumberFormat
                                          value={(Math.round(list.total_transaction * 100) / 100).toFixed(2)}
                                          displayType={'text'} thousandSeparator={true}
                                          prefix='LKR '/></td>
                                    </tr>)
                                  }
                                }
                            )) : ('')}
                            </tbody>
                          </table>
                        </div>
                        <div className="cir-chart-outer">
                          <ResponsiveContainer width="100%" height="100%">
                            <PaymentMethodChart supplierList={this.state.supplierListTotals}/>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mid-gate-line w-boxes">
                <div className="row row_clr">
                  <div className="col-sm-7 gate-tool">
                    <ActiveSuppliers list_data={this.state.supplierList}/>
                  </div>
                  <div className="col-sm-5 personalize-db">
                    <LastTransactions supplierTransactions={this.state.transactionList}/>
                  </div>
                </div>
              </div>

              <Footer/>
            </div>
          </div>
        </div>
    );
  }
}
