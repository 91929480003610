import React, {PureComponent} from 'react';

import {Link, Navigate} from "react-router-dom";
import {Scrollbars} from 'react-custom-scrollbars';
import {Navigation} from "./Navigation";
import moment from 'moment';
import {myConfig} from "../config";
import {TopBar} from "./TopBar";
import ReactPaginate from "react-paginate";

import Modal from "react-bootstrap/Modal";
import {GetWebxLaravel, PostCompnentWebx, PostWebxLaravel} from "./PostCompnent";
import {Footer} from "./Footer";

import {SupportButton} from "./SupportButton";
import PasswordChecklist from "react-password-checklist"

import 'suneditor/dist/css/suneditor.min.css';
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SunEditor from "suneditor-react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Switch from "react-switch";

export class SettingsUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            user_id: '',
            role_type: '',
            userButton: this.saveUser,
            modelShowUserEdit: false,
            setModelShowUserEdit: false,
            offsetUserData: 0,
            pageCountUserData: 0,
            currentPageUserData: 0,
            perPageUserData: 10,
            errors_cus: [],
            user_data: [],
            user_data_list_filters: [],
            user_data_list_render: [],
            isValid: false,
            setOpenProgress: false,
            openprogress: false,
            ButtonName: 'Save',
            error_message: '',
            error_message_role: '',
            password_change:false,
//========================================================
            role_description: '',
            role_active: '',
            roleButton: this.saveRole,
            modalShowRoleEdit: false,
            setModalShowRoleEdit: false,
            offsetRoleData: 0,
            pageCountRoleData: 0,
            currentPageRoleData: 0,
            perPageRoleData: 10,
            errors_role: [],
            role_data: [],
            role_data_list_filters: [],
            role_data_list_render: [],
            isValidRole: false,
            component_list: [],
            component_list_data: {},
            component_ids:[],
            isChecked: true,
            languages: {},
            role_list: [],
            edit_role_id: '',
            switchState: {},
            checked: false,
            role_list_options:[],
            modalShowConfirmUser:false,
            setModalShowConfirmUser:false,
            openModelUserMessage:'',
            filter_description:'',
            filter_active_status:'',
            filter_user_role:'',
            main_user_id:'',


        };
        this.openModalUser = this.openModalUser.bind(this);
        this.closeModalUser = this.closeModalUser.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.loadUsers = this.loadUsers.bind(this);
        this.loadTableUsers = this.loadTableUsers.bind(this);
        this.loadUserDataSingle = this.loadUserDataSingle.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.handlePageClickUsers = this.handlePageClickUsers.bind(this);

        this.validateUser = this.validateUser.bind(this);
        this.addRemoveActive = this.addRemoveActive.bind(this);
        this.onChanges = this.onChanges.bind(this);
        this.onChangeValid = this.onChangeValid.bind(this);
        this.switchChange = this.switchChange.bind(this);
        this.setDynamicSwitchState = this.setDynamicSwitchState.bind(this);
        //======================================================================

        this.openModalRole = this.openModalRole.bind(this);
        this.closeModalRole = this.closeModalRole.bind(this);
        this.saveRole = this.saveRole.bind(this);
        this.loadRoles = this.loadRoles.bind(this);
        this.loadTableRoles = this.loadTableRoles.bind(this);
        this.loadRoleDataSingle = this.loadRoleDataSingle.bind(this);
        this.updateRole = this.updateRole.bind(this);
        this.handlePageClickRole = this.handlePageClickRole.bind(this);
        this.addPermissionToArray = this.addPermissionToArray.bind(this);


        this.addRemoveActive = this.addRemoveActive.bind(this);
        this.validateRole = this.validateRole.bind(this);
        this.loadRoleData = this.loadRoleData.bind(this);
        this.loadRoleListOptions = this.loadRoleListOptions.bind(this);




        //======================================================================

        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.closeModelUserUpdate = this.closeModelUserUpdate.bind(this);
        this.openModelUserUpdate = this.openModelUserUpdate.bind(this);
        this.filterUsers = this.filterUsers.bind(this);
        this.clearUserFilter = this.clearUserFilter.bind(this);


    }

    filterUsers(){

        let filter_description = this.state.filter_description;
        let filter_active_status = this.state.filter_active_status;
        let filter_user_role = this.state.filter_user_role;
        let formData = new FormData();    //formdata object

        formData.append('filter_description', filter_description);
        formData.append('filter_active_status', filter_active_status);
        formData.append('filter_user_role', filter_user_role);   //append the values with key, value pair
        formData.append('merchant_id', sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"));

        PostWebxLaravel("sub_users/get_filtered_users", formData).then(response => {

           this.handleCloseProgress();

            this.setDynamicSwitchState(response.data);
            this.setState({
              user_data: response.data,
              user_data_list_filters: response.data,
            }, () => {
                this.loadTableUsers()
            });
        });

    }

    clearUserFilter(){
        this.setState({
            filter_description:'',
            filter_active_status:'',
            filter_user_role:''
        })
        this.loadUsers();
    }
    closeModelUserUpdate(){
        this.setState({
            modalShowConfirmUser:false,
            setModalShowConfirmUser:false
        })
    }
    openModelUserUpdate(){
        this.setState({
            modalShowConfirmUser:true,
            setModalShowConfirmUser:true
        })
    }
    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    onChanges(e) {
        if (e.target.name=="password"){
            this.setState({password_change:true});
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    setDynamicSwitchState = (list) => {

        if (!list) {
            return
        }

        const switchState = {};

        list.forEach((item, index) => {
            switchState['switch-' + item.key_index] = item.is_active==1?true:false;
        });

        this.setState({
            switchState: switchState
        })
    }


    switchChange = (index, event, checked,id) => {

        const list = Object.assign({}, this.state.switchState);


        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': sessionStorage.getItem("laravel_token"),

            },
        };

        let formData = new FormData();    //formdata object
        formData.append('id', id);
        formData.append('status', event==true?1:0);
        formData.append('merchant_id', sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"));
        this.handleToggleProgress();

        PostWebxLaravel("sub_users/update_status", formData).then(response => {
            this.handleCloseProgress();
            this.loadUsers();
        });

        list['switch-' + index] = event;

        this.setState({
            switchState: list
        });
    }

    componentWillMount() {

        if (sessionStorage.getItem("userToken") || localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");

            this.setState({userToken: userToken});
            this.loadUsers();
            this.loadRoles();
            this.loadRoleData();
            this.loadRoleListOptions();
        } else {
            this.setState({redirect: true});
        }
    }

    loadUsers() {
        GetWebxLaravel("sub_users/get_sub_users/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            this.handleCloseProgress();

            this.setDynamicSwitchState(response.data);
            this.setState({
                user_data: response.data,
                user_data_list_filters: response.data,
            }, () => {
                this.loadTableUsers();
            });
        });
    }

    addRemoveActive() {
        var plusButton = document.getElementById("plus-button");
        var plusMenu = document.getElementById("plus-menu");
        if (plusMenu.classList.contains('active')) {
            plusMenu.classList.remove("active");
            plusButton.classList.remove("active");
        } else {
            plusMenu.classList.add("active");
            plusButton.classList.add("active");
        }
        var myElement = document.getElementsByClassName('l-item');
        for (var i = 0; i < myElement.length; i++) {
            myElement[i].onclick = function () {
                var plusButton = document.getElementById("plus-button");
                var plusMenu = document.getElementById("plus-menu");
                plusMenu.classList.remove("active");
                plusButton.classList.remove("active");
            }
        }
    }

    saveUser() {
        if (this.validateUser()) {
            this.setState({
                error_message: ''
            });

            let formData = new FormData();    //formdata object

            let first_name = this.state.first_name;
            let last_name = this.state.last_name;
            let email = this.state.email;
            let password = this.state.password;
            let role_type = this.state.role_type;
            let main_user_id = this.state.main_user_id;
            let merchant_id = sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id");

            formData.append('first_name', first_name);   //append the values with key, value pair
            formData.append('last_name', last_name);
            formData.append('email', email);
            formData.append('password', password);
            formData.append('merchant_id', merchant_id);
            formData.append('role', role_type);
            formData.append('user_id', main_user_id);

            this.handleToggleProgress();

            PostWebxLaravel("sub_users/create_sub_user", formData).then(response => {
                this.handleCloseProgress();
                let response_data = response.data;
                if (response_data.success == true) {
                    this.closeModalUser();
                    this.loadUsers();
                    this.setState({
                        openModelUserMessage:'The user account created successfully',
                    })
                    this.openModelUserUpdate();
                } else {
                    this.setState({
                        error_message: response_data.message
                    });
                }
            });
        }
    }

    handlePageClickUsers(e) {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageUserData;
        this.setState({
            currentPageUserData: selectedPage,
            offsetUserData: offset
        }, () => {
            this.loadTableUsers();
        });
    }

    loadTableUsers() {

        const user_data = this.state.user_data_list_filters;

        const zeroPad = (num, places) => String(num).padStart(places, '0');
        let keys=0;
        const slice = user_data.slice(this.state.offsetUserData, this.state.offsetUserData + this.state.perPageUserData);
        const trans = user_data && user_data.length ? (slice.map((list, key) =>
            <tr className="">
                <td className="">
                    {list.first_name}
                </td>
                <td className="">
                    {list.last_name}
                </td>
                <td className="">
                    {list.email}
                </td>
                <td className="">
                    {list.role_description}
                </td>
                <td className="">
                    <label>
                        <Switch  key={list.key_index}
                                checked={this.state.switchState['switch-' + list.key_index]}
                                onChange={(event, checked) => this.switchChange(list.key_index, event, checked,list.id)}
                        />
                    </label>
                </td>

                <td>
                    <button className="btn dot-drop mr-2"
                            onClick={() => this.loadUserDataSingle(list.id, list.first_name, list.last_name, list.email,list.id,list.role)}>
                        <i className="fa fa-pencil"
                           aria-hidden="true"></i>
                    </button>

                </td>
            </tr>
        )) : (
            <tr className=''>
                <td colSpan="4" className='' data-title='#'>No Results Found</td>
            </tr>
        );
        this.setState({
            pageCountUserData: Math.ceil(user_data.length / this.state.perPageUserData),
            user_data_list_render: trans,
        });
    }

    loadUserDataSingle(user_id, first_name, last_name, email,id,role) {

        this.setState({
            text_additional: 'Edit Sub User',
            modelShowUserEdit: true,
            setModelShowUserEdit: true,
            user_id: user_id,
            first_name: first_name,
            last_name: last_name,
            email: email,
            role_type: role,
            password:'',
            confirm_password:'',
            userButton: this.updateUser,
            ButtonName: 'Update',
            password_change:false
        }, () => {
            var modelD = document.getElementById("anis-modal");
            modelD.classList.add("ancd-mod");
            this.loadRoleListOptions();
        });
    }

    updateUser() {
        if (this.validateUser()) {
            this.setState({
                error_message: ''
            });
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            let BaseUrl = myConfig.laravelUrl;
            let lar_config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': sessionStorage.getItem("laravel_token"),

                },
            };
            let formData = new FormData();    //formdata object

            let first_name = this.state.first_name;
            let last_name = this.state.last_name;
            let email = this.state.email;
            let password = this.state.password;
            let user_id = this.state.user_id;
            let role_type = this.state.role_type;
            let merchant_id = sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id");

            formData.append('first_name', first_name);   //append the values with key, value pair
            formData.append('last_name', last_name);
            formData.append('email', email);
            formData.append('password', password);
            formData.append('merchant_id', merchant_id);
            formData.append('role',role_type);
            formData.append('_method', 'put');

            this.handleToggleProgress();
            PostWebxLaravel("sub_users/update_sub_user/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id") + '|' + this.state.user_id, formData).then(response => {
                this.handleCloseProgress();


                let response_data = response.data;

                if (response_data.success == true) {
                    this.setState({
                        modelShowUserEdit: false,
                        setModelShowUserEdit: false,
                        first_name: '',
                        last_name: '',
                        email: '',
                        password: '',
                        confirm_password: '',
                        openModelUserMessage:'The user account updated successfully',
                    }, () => {
                        this.loadUsers();
                        this.openModelUserUpdate();
                    });

                } else {
                    let errors_cus = {};
                    errors_cus["email"] = response.data.message;

                    this.setState({errors_cus: errors_cus});
                }


            });
        }
    }

    onChangeValid(e) {

    }

    validateRole() {
        let errors_role = {};
        let formIsValid = true;

        if (this.state.role_description == "") {
            formIsValid = false;
            errors_role["role_description"] = "Please Enter Description Validation";
        }

        if (this.state.component_ids.length == 0) {
            formIsValid = false;
            errors_role["component_ids"] = "Please Select One or More Permission Types";
        }

        this.setState({errors_role: errors_role});
        return formIsValid;
    }

    validateUser() {
        let errors_cus = {};
        let formIsValid = true;

        if (this.state.first_name == "") {
            formIsValid = false;
            errors_cus["first_name"] = "Please Enter First Name";
        }
        if (this.state.last_name == "") {
            formIsValid = false;
            errors_cus["last_name"] = "Please Enter Last Name";
        }

        if (this.state.email == "") {
            formIsValid = false;
            errors_cus["email"] = "Cannot be empty";
        }

        if (this.state.role_type == "") {
            formIsValid = false;
            errors_cus["role"] = "Please Select User Role Type";
        }


        if (typeof this.state.email !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(this.state.email)) {

                formIsValid = false;

                errors_cus["email"] = "Please Enter Valid Email Address.";

            }
        }
        if (this.state.ButtonName == "Save") {
            if (this.state.isValid == false) {
                formIsValid = false;
            }
        }


        this.setState({errors_cus: errors_cus});
        return formIsValid;
    }

    openModalUser() {
        this.setState({
            text_additional: 'Edit Sub User',
            modelShowUserEdit: true,
            setModelShowUserEdit: true,
            first_name:'',
            last_name:'',
            email:'',
            password:'',
            confirm_password:'',
            role_type:'',
            userButton: this.saveUser,
            ButtonName: 'Save',
            password_change:false
        }, () => {
            var modelD = document.getElementById("anis-modal");
            modelD.classList.add("ancd-mod");
            this.loadRoleListOptions();


            GetWebxLaravel("user").then(response=>{
                this.setState({main_user_id:response.data.id})
            });

        });
    }

    closeModalUser() {
        this.setState({
            modelShowUserEdit: false,
            setModelShowUserEdit: false
        });
    }

//========================= User Roles ==========================================
    saveRole() {

        if (this.validateRole()) {
            this.setState({
                error_message_role: ''
            });
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            let BaseUrl = myConfig.laravelUrl;
            let lar_config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': sessionStorage.getItem("laravel_token"),

                },
            };
            let formData = new FormData();    //formdata object

            let role_description = this.state.role_description;
            let component_ids = JSON.stringify(this.state.component_ids);
            let merchant_id = sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id");

            formData.append('role_description', role_description);   //append the values with key, value pair
            formData.append('component_ids', component_ids);
            formData.append('merchant_id', merchant_id);

            this.handleToggleProgress();
            PostWebxLaravel("user_roles/save_role", formData).then(response => {
                this.handleCloseProgress();
                let response_data = response.data;
                if (response_data.success == 200) {
                    this.closeModalRole();
                    this.loadRoleData();
                    this.loadRoleListOptions();
                    this.setState({
                        openModelUserMessage:'The user role has been created successfully',
                    })
                    this.openModelUserUpdate();
                } else {
                    this.setState({
                        error_message_role: response_data.message
                    });
                }
            });
        }
    }

    openModalRole() {
        this.setState({
            text_additional: 'Add User Role',
            modalShowRoleEdit: true,
            setModalShowRoleEdit: true,
            roleButton: this.saveRole,
            component_list_data: {},
            role_description: "",
            ButtonName: 'Save',
        }, () => {
            var modelD = document.getElementById("anis-modal-role");
            modelD.classList.add("ancd-mod");
        });
    }

    closeModalRole() {
        this.setState({
            modalShowRoleEdit: false,
            setModalShowRoleEdit: false,
        });
    }

    loadRoles() {
        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': sessionStorage.getItem("laravel_token"),
            },
        };
        this.handleToggleProgress();
        GetWebxLaravel("user_roles/get_components/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            this.handleCloseProgress();
            this.setState({
                component_list: response.data,
            });
        });
    }

    loadRoleData(){
        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': sessionStorage.getItem("laravel_token"),

            },
        };
        this.handleToggleProgress();
        GetWebxLaravel("user_roles/get_user_roles/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            this.handleCloseProgress();
            this.setState({
                role_list: response.data,
                role_data_list_filters: response.data,
            }, () => {
                this.loadTableRoles();
            });
        });
    }

    loadRoleListOptions(){
        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': sessionStorage.getItem("laravel_token"),

            },
        };
        this.handleToggleProgress();
        GetWebxLaravel("user_roles/get_user_roles/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            this.handleCloseProgress();
            this.setState({
                role_list_options: response.data,

            });
        });
    }

    loadTableRoles() {
        const role_list = this.state.role_data_list_filters;

        const zeroPad = (num, places) => String(num).padStart(places, '0');

        const slice = role_list.slice(this.state.offsetRoleData, this.state.offsetRoleData + this.state.perPageRoleData);
        const trans = role_list && role_list.length ? (slice.map((list, key) =>
            <tr className="">
                <td className="">
                    {list.role_description}
                </td>

                <td className="">
                    {moment(list.created_at).format('ll')}
                </td>
                <td>
                    <button className="btn dot-drop mr-2"
                            onClick={() => this.loadRoleDataSingle(list.id)}>
                        <i className="fa fa-pencil"
                           aria-hidden="true"></i>
                    </button>

                </td>
            </tr>
        )) : (
            <tr className=''>
                <td colSpan="4" className='' data-title='#'>No Results Found</td>
            </tr>
        );
        this.setState({
            pageCountRoleData: Math.ceil(role_list.length / this.state.perPageRoleData),
            role_data_list_render: trans,
        });
    }

    loadRoleDataSingle(role_id) {

        let itemsArr = [];

        this.handleToggleProgress();
        GetWebxLaravel("user_roles/get_single_role/" + sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id")+'|'+role_id).then(response => {
            this.handleCloseProgress();

            this.setState({
                component_list_data:response.data.role_ids_array,
                role_description:response.data.user_roles
            },()=>{
                this.removeUnwanted();
            });

            this.setState({
                role_single_list: response.data,
            }, () => {
                this.loadTableRoles();
            });
        });
        this.setState({
            text_additional: 'Edit User Role',
            modalShowRoleEdit: true,
            setModalShowRoleEdit: true,
            edit_role_id: role_id,
            roleButton: this.updateRole,
            ButtonName: 'Update',
        }, () => {
            var modelD = document.getElementById("anis-modal-role");
            modelD.classList.add("ancd-mod");
        });
    }


    updateRole() {
        if (this.validateRole()) {

            let formData = new FormData();    //formdata object

            let role_description = this.state.role_description;
            let edit_role_id = this.state.edit_role_id;
            let component_ids = JSON.stringify(this.state.component_ids);
            let merchant_id = sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id");

            formData.append('role_description', role_description);   //append the values with key, value pair
            formData.append('component_ids', component_ids);
            formData.append('merchant_id', merchant_id);
            formData.append('_method', 'put');

            console.log(edit_role_id);

            this.handleToggleProgress();

            PostWebxLaravel("user_roles/update_role/" + edit_role_id, formData).then(response => {
                this.handleCloseProgress();
                if (response.status == 200) {
                    this.setState({
                        openModelUserMessage:'The user role has been updated successfully',
                    })
                    this.closeModalRole();
                    this.openModelUserUpdate();
                    this.loadRoleData();
                }
            });
        }
    }

    handlePageClickRole(e) {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageRoleData;
        this.setState({
            currentPageRoleData: selectedPage,
            offsetRoleData: offset
        }, () => {
            this.loadTableRoles();
        });
    }



    addPermissionToArray(i){
        this.setState({
            component_list_data: {
                ...this.state.component_list_data, ...{[i]: !this.state.component_list_data[i]}
            }

        },()=>{
            this.removeUnwanted();
        });

    }
    removeUnwanted(){
        let itemsArr = [];
        let invoice_array=this.state.component_list_data;

        Object.keys(invoice_array).forEach(function(key) {
            if (invoice_array[key]==true){
                itemsArr.push(key)
            }
        });
        this.setState({
            component_ids:itemsArr
        });
    }
    render() {

        const users_list = this.state.user_data_list_render;
        const component_list = this.state.component_list;
        const role_data = this.state.role_data_list_render;

        const renderThumb = ({style, ...props}) => {
            const thumbStyle = {
                borderRadius: 6,
                backgroundColor: 'rgba(35, 49, 86, 0.8)'
            };
            return <div style={{...style, ...thumbStyle}} {...props} />;
        };
        const CustomScrollbars = props => (
            <Scrollbars
                renderThumbHorizontal={renderThumb}
                renderThumbVertical={renderThumb}
                {...props}
            />
        );

        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }
        return (
            <div>{/*    <div id="plus-button" onClick={this.addRemoveActive}>
                    <i className="fas fa-plus"></i>
                </div>*/}
                <SupportButton/>
                {/*Tax Modal*/}
                <Modal
                    show={this.state.modalShowConfirmUser}
                    onHide={this.state.setModalShowConfirmUser}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="info-modal"
                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            {/* <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>*/}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 lft">
                                        <h5 className="in-h">{this.state.openModelUserMessage}</h5>
                                        <button className="btn btn-danger close-btn"
                                                onClick={this.closeModelUserUpdate}>Close
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
                <Modal
                    show={this.state.modelShowUserEdit}
                    onHide={this.state.setModelShowUserEdit}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anis-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close"
                             onClick={this.closeModalUser}>X
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh">User Details</h3>

                                    <div className="col-12 tdm-in p-0">
                                        <h6>First Name</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter First Name"
                                                   name="first_name" value={this.state.first_name}
                                                   onChange={this.onChanges} autoComplete="new-password"/>
                                            <span className="error"
                                                  style={{color: "red"}}>{this.state.errors_cus["first_name"]}</span>
                                        </div>

                                        <h6>Last Name</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Last Name"
                                                   name="last_name" value={this.state.last_name}
                                                   onChange={this.onChanges} autoComplete="new-password"/>
                                            <span className="error"
                                                  style={{color: "red"}}>{this.state.errors_cus["last_name"]}</span>
                                        </div>


                                        <h6>Email Address</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                   placeholder="Enter Email Address" name="email"
                                                   value={this.state.email} onChange={this.onChanges}
                                                   autoComplete="new-password"/>
                                            <span className="error"
                                                  style={{color: "red"}}>{this.state.errors_cus["email"]}</span>
                                            <span className="error"
                                                  style={{color: "red"}}>{this.state.error_message}</span>

                                        </div>

                                        <h6>Select User Role Type</h6>
                                        <div className="form-group">
                                            <div className="form-group" >
                                                <div className="slct">
                                                    <select name="role_type" id="c1" className="form-control"
                                                            value={this.state.role_type}
                                                            onChange={this.onChanges}>
                                                        <option value="">Select</option>
                                                        {this.state.role_list_options &&
                                                        this.state.role_list_options.map((option, index) => (
                                                            <option value={option.id}> {option.role_description}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <span className="error"
                                                  style={{color: "red"}}>{this.state.errors_cus["role"]}</span>


                                        </div>

                                        <h6>Password</h6>
                                        <div className="form-group">
                                            <input type="password" className="form-control" placeholder="Enter Password"
                                                   name="password" value={this.state.password} onChange={this.onChanges}
                                                   autoComplete="new-password"/>
                                            <span className="error"
                                                  style={{color: "red"}}>{this.state.errors_cus["password"]}</span>
                                        </div>

                                        <h6>Confirm Password</h6>
                                        <div className="form-group">
                                            <input type="password" className="form-control" placeholder="Enter Password"
                                                   name="confirm_password" value={this.state.confirm_password}
                                                   onChange={this.onChanges} autoComplete="new-password"/>
                                            <span className="error"
                                                  style={{color: "red"}}>{this.state.errors_cus["confirm_password"]}</span>
                                        </div>
                                    </div>
                                </div>
                                {this.state.password_change==true?(
                                <PasswordChecklist
                                    rules={["minLength", "specialChar", "number", "capital", "match"]}
                                    minLength={10}
                                    value={this.state.password}
                                    valueAgain={this.state.confirm_password}
                                    onChange={(isValid) => {
                                        this.setState({isValid})
                                    }}
                                />):('')}

                                <button type="button" className="nxt-btn"
                                        onClick={this.state.userButton}>{this.state.ButtonName}<span> </span> <i
                                    className="fas fa-save"></i></button>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

                <Modal
                    show={this.state.modalShowRoleEdit}
                    onHide={this.state.setModalShowRoleEdit}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anis-modal-role"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close"
                             onClick={this.closeModalRole}>X
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh"></h3>

                                    <div className="col-12 tdm-in p-0">
                                        <h6>Description</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                   placeholder="Enter Description" name="role_description"
                                                   value={this.state.role_description} onChange={this.onChanges}
                                                   autoComplete="new-password"/>
                                            <span className="error"
                                                  style={{color: "red"}}>{this.state.errors_role["role_description"]}</span>
                                        </div>

                                        <h6 style={{marginBottom: "10px"}}></h6>
                                        <div className="form-group">
                                            <table className="transactions-table invoice-table iinvt rldt" summary="Hed">
                                                <thead>

                                                <tr>
                                                    <th className="">Permission Type</th>
                                                    <th className=""></th>
                                                </tr>


                                                </thead>
                                                <tbody >
                                                <div style={{height: 200,width:"100%"}}>
                                                <CustomScrollbars autoHide
                                                                  autoHideTimeout={500}
                                                                  autoHideDuration={200}>
                                                {component_list &&
                                                component_list.map((list, index) => (
                                                    <tr className="">
                                                        <td className="" data-title="">{list.component_name}</td>
                                                        <td className="" data-title="">
                                                            <div className="form-check" style={{marginBottom: "22px"}}>
                                                                <input className="form-check-input" type="checkbox"
                                                                     name={"component"+list.id} checked={this.state.component_list_data[list.id] || false}  onChange={()=>this.addPermissionToArray(list.id)}/></div>
                                                        </td>
                                                    </tr>
                                                ))}

                                                </CustomScrollbars>
                                                </div>


                                                </tbody>
                                            </table>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_role["component_ids"]}</span>
                                            <span className="error" style={{color: "red"}}>{this.state.error_message_role}</span>
                                        </div>
                                    </div>


                                    <button type="button" className="nxt-btn"
                                            onClick={this.state.roleButton}>{this.state.ButtonName}<span> </span> <i
                                        className="fas fa-save"></i></button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">

                    <div id="turn-box"></div>
                    <Navigation pathname="settings-general"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="settings-general"/>
                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <Link className="nav-link" id="tt-tab" to="../settings-general"><span
                                        className="tt">Business Configuration</span><span className="amt">General</span></Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" id="tp-tab" to="../invoice-settings"><span
                                        className="tt">Invoices, Payment Links & Taxes</span><span
                                        className="amt">Invoices</span></Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link active" id="tp-tab" to="../settings-user"><span
                                        className="tt">Roles, Profiles, and Permissions</span><span
                                        className="amt">User Accounts</span></Link>
                                </li>

                              {/*  <li className="nav-item">
                                    <Link className="nav-link" id="tp-tab" to="../subscription-settings">
                                        <span className="tt">Payments, History, Upcoming Payments and Package Change</span>
                                        <span className="amt">Subscriptions</span>
                                    </Link>
                                </li>*/}

                            </ul>

                            <div className="row row_clr settings-outer">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                                    <div className="col-12 lft-sd pt-0">
                                        <Nav variant="pills" className="nav nav-tabs" id="set-tab1">
                                            <Nav.Item>
                                                <Nav.Link eventKey="one" className="nav-link">User Profiles</Nav.Link>
                                            </Nav.Item>

                                            <Nav.Item>
                                                <Nav.Link eventKey="two" className="nav-link">User Roles & Permission</Nav.Link>
                                            </Nav.Item>

                                        </Nav>
                                    </div>

                                    <div className="col-12 rht-sd">
                                        <div className="tab-content">
                                            <Tab.Pane eventKey="one" id="v-pills-1">
                                                <div className="tab-pane fade show active" id="tt" role="tabpanel"
                                                     aria-labelledby="tt-tab">
                                                    <div className="row row_clr">
                                                        <div className="col-12">
                                                            <h3 className="tdh"></h3>
                                                        </div>
                                                        <div className="col-12 bin-info-form">
                                                            <div className="row">


                                                                {/*<div className="col-3">
                                                                    <label>Keyword Search</label>
                                                                    <div className="form-group">
                                                                        <input type="text"
                                                                               className="form-control"
                                                                               name="filter_description"
                                                                               placeholder="i.e. : First Name, Last Name, E-Mail"
                                                                               onChange={this.onChanges}
                                                                               value={this.state.filter_description}
                                                                              />
                                                                    </div>
                                                                </div>*/}
                                                                <div className="col-3">
                                                                    <label>Status</label>
                                                                    <div className="form-group">
                                                                        <select name="filter_active_status"
                                                                                id=""
                                                                                value={this.state.filter_active_status}
                                                                                className="form-control"
                                                                                onChange={this.onChanges}>
                                                                            <option value=""> --Select--
                                                                            </option>
                                                                            <option value="1">Active
                                                                            </option>
                                                                            <option value="0">Inactive
                                                                            </option>
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <label>User Type</label>
                                                                    <div className="form-group">
                                                                        <select name="filter_user_role"
                                                                                id=""
                                                                                value={this.state.filter_user_role}
                                                                                className="form-control"
                                                                                onChange={this.onChanges}>
                                                                            <option value=""> --Select--</option>
                                                                            {this.state.role_list_options &&
                                                                            this.state.role_list_options.map((option, index) => (
                                                                                <option value={option.id}> {option.role_description}</option>
                                                                            ))}
                                                                        </select>


                                                                    </div>
                                                                </div>


                                                                <div className="col-3">
                                                                    <button type="button"
                                                                            className="nxt-btn" style={{
                                                                        float: "left",
                                                                        marginTop: "-5px",
                                                                        marginBottom: "15px",
                                                                        border: "2px solid #2b2935",
                                                                        background: "#ffffff",
                                                                        color: "#2b2935",
                                                                    }}
                                                                            onClick={this.filterUsers}>Search &nbsp;&nbsp; </button>
                                                                </div>
                                                                <div className="col-3">
                                                                    <button type="button"
                                                                            className="nxt-btn" style={{
                                                                        float: "left",
                                                                        marginTop: "-5px",
                                                                        marginBottom: "15px"
                                                                    }}
                                                                            onClick={this.clearUserFilter}>Clear &nbsp;&nbsp; </button>
                                                                </div>
                                                            </div>

                                                            <div className="row row_clr">
                                                                <button type="button" className="nxt-btn"
                                                                        style={{
                                                                            float: "left",
                                                                            marginTop: "5px",
                                                                            marginBottom: "20px"
                                                                        }} onClick={this.openModalUser}>Create New
                                                                    User &nbsp;&nbsp; <i
                                                                        className="fas fa-plus-circle"
                                                                        aria-hidden="true"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            fontSize: "20px"
                                                                            , transform: "translateY(2px)"
                                                                        }}
                                                                        data-toggle="modal"
                                                                        data-target="#add-new-tax-modal"

                                                                    ></i></button>
                                                            </div>

                                                        </div>


                                                        <div className="col-12">
                                                            <div className='rg-container'>
                                                                <table className='transactions-table' summary='Hed'>
                                                                    <thead>
                                                                    <tr>
                                                                        <th className=''>First Name</th>
                                                                        <th className=''>Last Name</th>
                                                                        <th className=''>Email Address</th>
                                                                        <th className=''>User Role</th>
                                                                        <th className=''>Status</th>
                                                                        <th className=''></th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {users_list}
                                                                    </tbody>
                                                                </table>
                                                                <ReactPaginate
                                                                    previousLabel={"PREV"}
                                                                    nextLabel={"NEXT"}
                                                                    breakLabel={"..."}
                                                                    breakClassName={"break-me"}
                                                                    pageCount={this.state.pageCountUserData}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={this.handlePageClickUsers}
                                                                    containerClassName={"pagination"}
                                                                    subContainerClassName={"pages pagination"}
                                                                    activeClassName={"active"}/>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="two" id="v-pills-1">
                                                <div className="tab-pane fade show active" id="tt" role="tabpanel"
                                                     aria-labelledby="tt-tab">
                                                    <div className="row row_clr">
                                                        <div className="col-12">
                                                            <h3 className="tdh"></h3>
                                                        </div>
                                                        <div className="col-12 bin-info-form">

                                                            <div className="row row_clr">
                                                                <button type="button" className="nxt-btn"
                                                                        style={{
                                                                            float: "left",
                                                                            marginTop: "5px",
                                                                            marginBottom: "20px"
                                                                        }} onClick={this.openModalRole}>Create &nbsp;&nbsp; <i
                                                                        className="fas fa-plus-circle"
                                                                        aria-hidden="true"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            fontSize: "20px"
                                                                            , transform: "translateY(2px)"
                                                                        }}
                                                                        data-toggle="modal"
                                                                        data-target="#add-new-tax-modal"

                                                                    ></i></button>
                                                            </div>

                                                        </div>


                                                        <div className="col-12">
                                                            <div className='rg-container'>
                                                                <table className='transactions-table' summary='Hed'>
                                                                    <thead>
                                                                    <tr>
                                                                        <th className=''>User Role Type</th>
                                                                        <th className=''>Created Date</th>
                                                                        <th className=''></th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {role_data}
                                                                    </tbody>
                                                                </table>
                                                                <ReactPaginate
                                                                    previousLabel={"PREV"}
                                                                    nextLabel={"NEXT"}
                                                                    breakLabel={"..."}
                                                                    breakClassName={"break-me"}
                                                                    pageCount={this.state.pageCountRoleData}
                                                                    marginPagesDisplayed={2}
                                                                    pageRangeDisplayed={5}
                                                                    onPageChange={this.handlePageClickRole}
                                                                    containerClassName={"pagination"}
                                                                    subContainerClassName={"pages pagination"}
                                                                    activeClassName={"active"}/>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="three" id="v-pills-1">

                                            </Tab.Pane>

                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>

                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>

        );
    }

}


