import React,{ useEffect, useState,render } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import {myConfig} from "../config";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Nav from "react-bootstrap/Nav";

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="activation-modal"
        >

            <Modal.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="row row_clr">
                        <div className="col-md-4 lft">
                            <h4 className="modal-title" id="activation-modal-label">Account Activation</h4>
                            <p>Complete and submit the form to start doing live transactions on the account.</p>
                            <Nav variant="pills" className="flex-column" id="v-pills-tab">
                                <Nav.Item>
                                    <Nav.Link  eventKey="first">Business
                                        Information <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Business
                                        Information <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <div className="sf-box">
                                <h5>Your Subscription Fee</h5>
                                <p><span>Registration Fee</span>: LKR 15,000.00</p>
                                <p><span>Currency Options</span>: LKR 5,000.00 (USD & LKR)</p>
                                <p><span>Subscription Plan</span>: LKR 23,880‬.00 (LKR 1,990.00 x 12)</p>
                                <p className="ptp">Total Payable</p>
                                <p className="am">LKR 43,880.00</p>
                            </div>
                        </div>
                        <div className="col-md-8 rht">
                            <Tab.Content  id="v-pills-tabContent">
                                <Tab.Pane eventKey="first" id="v-pills-1">
                                    <div className="row t-in">
                                        <div className="col-md-6">
                                            <div className="row row_clr t-in">
                                                <div className="col-12 sp-box selected">
                                                    <h3 className="s-name">X-Gateway</h3>
                                                    <p>One Time Registration Fee</p>
                                                    <h4 className="fee">LKR 15,000.00</h4>
                                                    <div className="line"></div>
                                                    <p>Currency Options</p>
                                                    <div className="slct">
                                                        <select name="" id="c1">
                                                            <option value="LKR & USD">LKR & USD</option>
                                                        </select>
                                                    </div>
                                                    <p className="fp">One Time Charge of
                                                        <span>
                                                                <nobr>LKR 5,000</nobr>
                                                            </span>
                                                        for USD Currency Accounts
                                                    </p>
                                                    <div className="line"></div>
                                                    <p>Subscription Plan</p>
                                                    <div className="slct">
                                                        <select name="" id="c2">
                                                            <option value="12 Months">12 Months</option>
                                                        </select>
                                                    </div>
                                                    <p className="fp">
                                                        Monthly Fee <span>
                                                                <nobr>LKR 1,990.00</nobr>
                                                            </span> Total Payable <span>
                                                                <nobr>LKR 23,880‬.00</nobr>
                                                            </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row row_clr t-in">
                                                <div className="col-12 sp-box">
                                                    <h3 className="s-name">X-Gateway</h3>
                                                    <p>One Time Registration Fee</p>
                                                    <h4 className="fee">LKR 15,000.00</h4>
                                                    <div className="line"></div>
                                                    <p>Currency Options</p>
                                                    <div className="slct">
                                                        <select name="" id="c1">
                                                            <option value="LKR & USD">LKR & USD</option>
                                                        </select>
                                                    </div>
                                                    <p className="fp">One Time Charge of
                                                        <span>
                                                                <nobr>LKR 5,000</nobr>
                                                            </span>
                                                        for USD Currency Accounts
                                                    </p>
                                                    <div className="line"></div>
                                                    <p>Subscription Plan</p>
                                                    <div className="slct">
                                                        <select name="" id="c2">
                                                            <option value="12 Months">12 Months</option>
                                                        </select>
                                                    </div>
                                                    <p className="fp">
                                                        Monthly Fee <span>
                                                                <nobr>LKR 1,990.00</nobr>
                                                            </span> Total Payable <span>
                                                                <nobr>LKR 23,880‬.00</nobr>
                                                            </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 sp-bot">
                                            <p>For More Information View the <a href="#">Complete Pricing Table</a></p>
                                            <p>** All Settlements are Processed in LKR **</p>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    2
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </Modal.Body>

        </Modal>
    );
}

function ModalCheck() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Launch vertically centered modal
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}
export default ModalCheck;

/*
<div className="modal fade" id="activation-modal" tabIndex="-1" role="dialog"
     aria-labelledby="activation-modal-label" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">

            <div className="modal-body">

            </div>
        </div>
    </div>
</div>*!/*/
