import React,{ useEffect, useState } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import {Link, Navigate} from "react-router-dom";
import {myConfig} from "../config";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import ModalCheck from '../components/ModalCheck';
import ModalCheckTrans from "./CheckTransectionModal";


export class CheckModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
            forgot_email: '',
            redirects: false,
            loading: false,
            hidden: true,
            open: false,
            openprogress: false,
            setOpenProgress: false,
        };

    }

    render() {
        return(
            <ModalCheck/>
            )

    }

}