import React from "react";
import { Link } from "react-router-dom";

import TopBarCollapse from "./TopBarCollapse";
import { PostCompnentWebx } from "./PostCompnent";
import Collapse from "react-bootstrap/Collapse";
import { myConfig } from "../config";
import { decode } from "html-entities";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

export class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userToken: [],
      greeting: "",
      doing_business_name: "",
      open: false,
      collapse_status: null,
      merchant_id: "",
      merchant_type: "",
      merchant_view: "IPG",
    };

    this.timerSetup = this.timerSetup.bind(this);
    this.getUser = this.getUser.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.setClose = this.setClose.bind(this);
    this.getCustomerData = this.getCustomerData.bind(this);
    this.onChanges = this.onChanges.bind(this);
  }

  onChanges(e) {

    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.value == "ipg") {
      this.setState({ merchant_view: "IPG" });
      sessionStorage.setItem("merchant_view", "IPG");
      window.location.replace('active-dashboard');
      //history.push('/active-dashboard', {replace: true})

    } else if (e.target.value == "bpsp") {
      this.setState({ merchant_view: "BPSP" });
      sessionStorage.setItem("merchant_view", "BPSP");
      window.location.replace('bpsp-dashboard');
     // history.push('/bpsp-dashboard', {replace: true})


    }
  }

  getCustomerData(userToken) {
    let BaseUrl = myConfig.apiUrl;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer" + " " + userToken,
      },
    };
    let formData = new FormData();
    PostCompnentWebx("getStoreData", formData).then((response) => {
      sessionStorage.setItem(
        "doing_business_name",
        response.data[0].doing_business_name
      );
      sessionStorage.setItem(
        "merchant_number",
        response.data[0].merchant_number
      );
      sessionStorage.setItem("bpsp_status", response.data[0].bpsp_status);
      sessionStorage.setItem("bpsp_mdr_rate", response.data[0].bpsp_mdr_rate);
      // qr only =1 , qr with link =2 , ipg enabled =3
      // qr only =2 , qr with link =1 , ipg enabled =0
      sessionStorage.setItem("qr_enable", response.data[0].allowed_transaction_type);
      this.setState({
        doing_business_name: response.data[0].doing_business_name,
      });
    });
  }
  setOpen(set_open_status) {
    if (localStorage.getItem("collapsed") == null) {
      if (set_open_status == true) {
        this.setState({
          open: false,
          collapse_status: true,
        });
        localStorage.setItem("collapsed", this.state.collapse_status);
      } else {
        this.setState({
          open: true,
        });
      }
    }
  }
  setClose() {}
  componentDidMount() {
    this.timerSetup();
    this.getUser();
    this.setOpen();

    if (sessionStorage.getItem("doing_business_name") == null) {
      this.getCustomerData(sessionStorage.getItem("userToken"));
    } else if (
      typeof sessionStorage.getItem("doing_business_name") == "undefined"
    ) {
      this.getCustomerData(sessionStorage.getItem("userToken"));

    }
  }
  getUser() {
    this.setState({
      doing_business_name: sessionStorage.getItem("doing_business_name"),
    });
  }

  timerSetup() {
    var data = [
        /* [0, 4, "Good Night"],*/
        [0, 11, "Good Morning"], //Store messages in an array
        [12, 16, "Good Afternoon"],
        [16, 24, "Good Evening"],
      ],
      hr = new Date().getHours();

    for (var i = 0; i < data.length; i++) {
      if (hr >= data[i][0] && hr <= data[i][1]) {
        this.setState({ greeting: data[i][2] });
      }
    }
  }
  render() {

    let is_admin = sessionStorage.getItem("admin_status");
    let user_name = sessionStorage.getItem("user_name");
    let name;
    if (is_admin == "false") {
      name = user_name;
    } else {
      name = decode(this.state.doing_business_name);
    }

    return (
      <React.Fragment>
        <div className="col-12 top-line">
          <p className="tp-dfp">
            <span className="lt">
              {this.state.greeting}{" "}
              <span className="name">
                {" "}
                {name} (Merchant ID :{" "}
                {sessionStorage.getItem("merchant_number")})
              </span>
            </span>

            <span className="tp-dfsp">
              <span  onClick={() => window.location.reload()}>
                <i className="fas fa-sync-alt" aria-hidden="true"></i>{" "}
              </span>
              {/*<DropdownButton*/}
              {/*  id=""*/}
              {/*  title="XGateway"*/}
              {/*  className="filter-dropdown-toggle"*/}
              {/*>*/}
              {/*  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>*/}
              {/*  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>*/}
              {/*  <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>*/}
              {/*</DropdownButton>*/}
              <span className="tb-lgs-outer">

              <select name="merchant_type" onChange={this.onChanges} >
              {sessionStorage.getItem("bpsp_status") == 1 ? (
                  <>
                    <option
                        value="ipg"
                        selected={sessionStorage.getItem("merchant_view") == "IPG" || sessionStorage.getItem("merchant_view") ==null}
                    >
                      IPG
                    </option>
                    <option
                        value="bpsp"
                        selected={
                          sessionStorage.getItem("merchant_view") == "BPSP"
                        }
                    >
                      BPSP
                    </option>

                  </>
              ) : (
                  <option
                      value="ipg"
                      selected={sessionStorage.getItem("merchant_view") === "IPG"}
                  >
                    IPG
                  </option>
              )}
            </select>
              </span>
            </span>
          </p>
          <div className="notification-bell">
            <img src={require("../images/notification.png")} alt="" />
          </div>
          <div>
            {/* bpsp_mdr_rate */}

          </div>
          <div className="sandbox">
            <div className="l-circle"></div>
            Live Mode
          </div>
        </div>
      </React.Fragment>
    );
  }
}
