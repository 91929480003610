import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

export function SetShowItems(props) {
  const [showItems, setShowItems] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
 
  const onSubmit = (supplierData) => {
    
    supplierData.attachment = Array.prototype.slice.call(supplierData.attachment);
    let newArr = [...props.productList];
    newArr[props.productList.length] = supplierData;
    props.setToList(newArr);

    let total = 0;
    for (let i = 0; i < newArr.length; i++) {
     
      total += parseFloat(newArr[i].product_rate) ;
      // * newArr[i].product_quantity
      props.setTotalValue(total)
    }

    reset({
      product_name: "",
      product_rate: "",
      //product_quantity: "",
      attachment: "",
    });
    handleClose();
  };

  const handleClose = () => setShowItems(false);
  const handleShow = () => setShowItems(true);
  return (
    <>
      <Modal
        show={showItems}
        onHide={() => setShowItems(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ancd-mod ancd-t2"
        id="ani-modal"
      >
        <Modal.Body
          className="modal-type-2"
        >
          <div
            className="colse-mdl"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            X
          </div>

          <div className="container-fluid">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 cd-frm">
                  <h3 className="tdh">Add Items</h3>

                  <div className="col-12 tdm-in p-0">
                    <h6 className="mb-1">Item Name</h6>
                    <div className="form-group">
                      <input
                        type="text"
                        name="product_name"
                        className="form-control"
                        placeholder="Enter Item"
                        {...register("product_name", { required: true })}
                      />
                      {errors.product_name &&
                        errors.product_name.type === "required" && (
                          <span className="error" style={{ color: "red" }}>
                            Enter Item Name
                          </span>
                        )}
                    </div>

                    <h6 className="mb-1">Amount</h6>
                    <div className="form-group">
                      <input
                        type="text"
                        name="product_rate"
                        className="form-control"
                        placeholder="Enter Amount"
                        {...register("product_rate", { required: true,pattern: {
                          value: /^(0|[0-9]\d*)(\.\d+)?$/
                      } })}
                      />
                      {errors.product_rate &&
                        errors.product_rate.type === "required" && (
                          <span className="error" style={{ color: "red" }}>
                            Enter Amount
                          </span>
                        )}
                          {errors.product_rate && errors.product_rate.type === "pattern" && (
                        <p className="error" style={{ color: "red" }}> Please enter only numbers</p>
                    )}
                    </div>

                    {/* <h6>Qty</h6>
                    <div className="form-group">
                      <input
                        type="text"
                        name="product_quantity"
                        className="form-control"
                        placeholder="Enter Quantity"
                        {...register("product_quantity", { required: true })}
                      />
                      {errors.product_quantity &&
                        errors.product_quantity.type === "required" && (
                          <span className="error" style={{ color: "red" }}>
                            Enter Product Rate
                          </span>
                        )}
                    </div> */}

                    <h6 className="mb-1">Upload invoice in attachment</h6>
                    <div className="form-group">
                      <input
                        type="file"
                        name="attachment"
                        {...register("attachment")}
                      />
                    </div>

                    <button type="submit" className="nxt-btn">
                      Create &nbsp;&nbsp;
                      <i className="fas fa-save"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <h6>
        <span
          className="btn"
          onClick={handleShow}
        >
          Add Item +
        </span>
      </h6>
    </>
  );
}
