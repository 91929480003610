import React, {useRef, useState} from "react";
import {SupplierDetailModal} from "./SupplierModal";
import {PostWebxLaravel} from "../../../PostCompnent";
import {myConfig} from "../../../../config";
export const supplierIdContext=React.createContext("");
export function LoadModalFunctions(props){

    const [modalShows, setModalShows] = React.useState(false);

    return <>
    <div style={{cursor:"pointer"}} onClick={() =>{
        setModalShows(true)
    }}>
        ...
    </div>
    <supplierIdContext.Provider value={props.supplier_id}>
    <SupplierDetailModal
        show={modalShows}
        onHide={() => setModalShows(false)}
        onBoard={props.onBoard}
        supplierData={props.supplierData}
        supplierTypes={props.supplierTypes}
        bankData={props.bankData}
    />
    </supplierIdContext.Provider>
    </>
}
