import React, {PureComponent} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {Navigation} from "./Navigation";
import {myConfig} from "../config";
import {TopBar} from "./TopBar";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    FacebookShareCount,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    WhatsappIcon, LinkedinIcon, MailruIcon, PinterestIcon, TelegramIcon, TwitterIcon, ViberIcon
} from "react-share";
import {GetWebxLaravel, PostWebxLaravel} from "./PostCompnent";
import {Footer} from "./Footer";
import {CopyToClipboard} from "react-copy-to-clipboard";

export class Errorpage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_amount: '',

        };

        this.handleCloseProgress=this.handleCloseProgress.bind(this);
        this.handleToggleProgress=this.handleToggleProgress.bind(this);
        this.onChanges=this.onChanges.bind(this);
    }


    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    componentWillMount() {


    }


    render() {

        return (
            <div>

                <h1>Something Went wrong</h1>
                <h1>Retry</h1>

                <a href={"#/"+sessionStorage.getItem("last_url")} >button</a>
            </div>
        );
    }

}


