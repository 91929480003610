import React, {PureComponent} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DataTable from "@bit/adeoy.utils.data-table";
import {Navigation} from "../Navigation";
import {myConfig} from "../../config";
import ReactPaginate from 'react-paginate';
import {
    PieChart, Pie, Sector, Cell, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import {TopBar} from "../TopBar";
import {jsPDF} from "jspdf";
import {PostCompnentWebx} from "../PostCompnent";
import Modal from "react-bootstrap/Modal";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {Footer} from "../Footer";
import Dropdown from "react-bootstrap/Dropdown";
import {CSVLink} from "react-csv";
import {SupportButton} from "../SupportButton";
import LoadingSpinner from "../LoadingSpinner";
import {NavigationSandbox} from "./NavigationSandbox";
import {TopBarSandbox} from "./TopBarSandbox";



export class TransectionsRefundSandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate:moment().subtract(30, 'days').format('ll'),
            endDate: moment().format('ll'),
            transaction_lists: [],
            transaction_lists_render: [],
            transaction_lists_filters:[],
            lkr_transaction_total: 0,
            lkr_refund_total: 0,
            usd_refund_total: 0,
            usd_transaction_total: 0,
            offset: 0,
            perPage: 10,
            currentPage: 0,
            order_ref_number:'',
            status:'',
            refund_amount:'',
            errors_cancellation:[],
            dayCount:31,
            csvData:[],
            refund_id:'',
            refund_password:'',
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.fetchDataFirst = this.fetchDataFirst.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.loadTable = this.loadTable.bind(this);
        this.filterList = this.filterList.bind(this);

        this.onChanges = this.onChanges.bind(this);
        this.exportPDF = this.exportPDF.bind(this);

        this.showModalConfirmation = this.showModalConfirmation.bind(this);
        this.closeModalConfirmation = this.closeModalConfirmation.bind(this);
        this.approveCancellation = this.approveCancellation.bind(this);
        this.handleValidation = this.handleValidation.bind(this);

        this.openModalRefundConfirm = this.openModalRefundConfirm.bind(this);
        this.closeModalRefundConfirm = this.closeModalRefundConfirm.bind(this);
    }
    openModalRefundConfirm() {
       
        this.setState({
            modalShowCloseConfirmation: true,
            setModalShowCloseConfimation: true,
        });

    }

    closeModalRefundConfirm() {
        this.setState({
            modalShowCloseConfirmation: false,
            setModalShowCloseConfimation: false,
            //modalShow: true,
            // setModalShow: true
        });
    }
    handleValidation() {

        let errors_cancellation = {};
        let formIsValid = true;
        //Email

        if (this.state.refund_password == "") {
            formIsValid = false;
            errors_cancellation["refund_password"] = "Required Field";
        }

        this.setState({errors_cancellation: errors_cancellation});
        return formIsValid;
    }

    approveCancellation() {
        if (this.handleValidation()) {
            this.handleToggleProgress();
            this.setState({loading:true});
            let formData = new FormData();    //formdata object
            formData.append('refund_id', this.state.refund_id);   //append the values with key, value pair
            formData.append('user_password', this.state.refund_password);
            PostCompnentWebx("cancelRefundRequest", formData).then(response => {
                this.handleCloseProgress();
                this.closeModalConfirmation();

                this.setState({
                    refund_amount:'',
                    loading:false,
                    refund_reason:'',
                    refund_reason_other:'',
                    refund_password:'',
                    refund_message: response.data.explaination
                }, () => {
                    this.openModalRefundConfirm();

                })

            });
        }
    }

    showModalConfirmation(transaction_reference, total_amount,processing_currency,refund_id) {
        this.setState({
            refund_id: refund_id,
            transaction_reference: transaction_reference,
            total_amount: total_amount,
            refund_processing_currency: processing_currency,
            transactionModelOpen: true,
            setTransactionModelOpen: true,
            modalShow: false,
            setModalShow: false
        }, () => {
            var modelD = document.getElementById("anis-modal");
            modelD.classList.add("ancd-mod");
        });
    }

    closeModalConfirmation() {
        this.setState({
            transactionModelOpen: false,
            setTransactionModelOpen: false,
        });
    }
    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    imgToBase64(url, callback) {
        if (!window.FileReader) {
            callback(null);
            return;
        }
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result.replace('text/xml', 'image/jpeg'));
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.send();
    }

    exportPDF = () => {
        let transections=this.state.transaction_lists_filters;

        const image=require('../../images/webxlogo_png.png');
        this.imgToBase64(image, function(base64) {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
            const logo_file=""
            doc.setFontSize(15);


            doc.addImage(base64, 'JPEG',450, 10, 100, 50);
            const title = "REFUND REPORT";
            const headers = [["TRANSACTION ID", "TRANSACTION VALUE","REFUND REQUEST DATE","REFUND VALUE","DATE OF TRANSACTION","STATUS"]];

            const data = transections.map(value=> [value.order_refference_number,value.total_amount,moment(value.refund_request_date).format("ll"),value.refund_amount,moment(value.order_date).format("MMMM Do YYYY"),value.order_status]);

            let content = {
                startY: 50,
                head: headers,
                body: data,
                headStyles: { fillColor:'#77b53c' },
            };

            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("Transaction Refunds.pdf")

        });

    }
    filterList() {
        let order_ref_no = this.state.order_ref_number.toLowerCase();
        let status = this.state.status.toLowerCase();
        let refund_amount = this.state.refund_amount.toLowerCase();
        let transaction_currency = this.state.transaction_currency;
        let transaction_lists = this.state.transaction_lists, transaction_lists_filters=[];



        let startDate= moment(this.state.startDate).format('YYYY-MM-DD');
        let endDate= moment(this.state.endDate).format('YYYY-MM-DD');
        if (this.state.startDate=="") {
            startDate=moment().subtract(30, 'days').format('YYYY-MM-DD');
        }
        if (this.state.endDate==""){
            endDate= moment().format('YYYY-MM-DD');
        }

        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);
        let formData=new FormData();
        formData.append('filter_date_start', startDate);   //append the values with key, value pair
        formData.append('filter_date_end', endDate);
        formData.append('refund_amount', refund_amount);
        formData.append('processing_currency_id', transaction_currency);
        formData.append('order_refference_number', order_ref_no);
        formData.append('status', status);

        this.handleToggleProgress();

            PostCompnentWebx("getStoreRefunds",formData).then(response=>{

                this.processCsvData(response);
                this.handleCloseProgress();
                this.setState({
                    transaction_lists: response.data,
                    transaction_lists_filters:response.data,
                    dayCount: days_count
                }, () => {
                    this.loadTable()
                });
            });
            this.handleToggleProgress();
            PostCompnentWebx("getStoreTransactionAndRefundTotal",formData).then(response=>{
                this.handleCloseProgress();
                this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
                this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
                this.setState({usd_refund_total: response.data.totals.usd_refund_total});
                this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
            });




    }


    handlePageClick = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadTable()
        });

    };
    handleCallback(start, end, label) {
        this.setState({
            startDate:moment(start).format('ll'),
            endDate:moment(end).format('ll'),
        });


        this.filterList();

    }

    fetchDataFirst(end, start) {


        var a = moment(end);
        var b = moment(start);
        let days_count=a.diff(b, 'days');   // =1
        days_count=parseInt(days_count)+parseInt(1);
        var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");
        if (end && start) {
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');

            this.handleToggleProgress();
            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);
            PostCompnentWebx("getStoreRefunds",formData).then(response=>{
                this.processCsvData(response);

                this.handleCloseProgress();
                this.setState({
                    transaction_lists: response.data,
                    transaction_lists_filters:response.data,
                    dayCount: days_count
                }, () => {
                    this.loadTable()
                });
            });
            this.handleToggleProgress();
            PostCompnentWebx("getStoreTransactionAndRefundTotal",formData).then(response=>{

                this.handleCloseProgress();
                this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
                this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
                this.setState({usd_refund_total: response.data.totals.usd_refund_total});
                this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
            });


        }
    }
    processCsvData(response){
        const csvData = [
            ["DATE OF TRANSACTION","STORE REFERENCE","TRANSACTION ID","TRANSACTION CURRENCY", "TRANSACTION AMOUNT","REFUND REQUEST DATE","REFUND APPROVED DATE","REFUND AMOUNT","STATUS"]
        ];
        response.data.map((value, index) => {
                csvData.push([moment(value.order_date).format("MMMM Do YYYY"),value.gateway_order_id,value.order_refference_number,value.processing_currency_id==5?"LKR":"USD",value.total_amount,moment(value.refund_request_date).format("ll"),moment(value.refund_request_date).format("ll"),value.refund_amount,value.order_status=="Abendend"?"Pending Transaction":value.order_status]);

        });
        this.setState({
            csvData: csvData
        });
    }

    loadTable() {
        const list_data = this.state.transaction_lists_filters;

        const slice = list_data.slice(this.state.offset, this.state.offset + this.state.perPage);
        const trans = list_data && list_data.length ? (slice.map(list => <tr className=''>
            <td className='' data-title='#'>{list.order_id}</td>
            <td className='' data-title='Transaction ID'>{list.order_refference_number}
            </td>
            <td className=''
                data-title='Transaction Value'>
                <NumberFormat
                    value={(Math.round(list.total_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={list.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
            </td>
            <td className=''
                data-title='Refund Value'><NumberFormat
                value={(Math.round(list.refund_amount * 100) / 100).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={list.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
            </td>
            <td className='' data-title='Date of Transaction'>
                {moment(list.order_date).format('ll')}
            </td>
            {/*    <td className='' data-title='Contact'>Sebastian Vettel</td>
                                                        <td className='' data-title='Email Address'>seb@formula1.com
                                                        </td>*/}
            <td className='pop-td' data-toggle="popover"
                data-trigger="hover" data-placement="auto"
                data-content="Bank was Unable to Authenticate Transaction">
                               {list.status == 2 ? (<span className="dot green"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                               {list.status == 1 ? (<span className="dot yellow"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                               {list.status == 3 ? (<span className="dot red"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                               {list.status == 4 ? (<span className="dot red"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                               {list.status == 1 ? "Pending" : ''}
                               {list.status == 2 ? "Refunded" : ''}
                               {list.status == 3 ? "Rejected" : ''}
                               {list.status == 4 ? "Cancelled" : ''}
            </td>
            <td className='pop-td'
                data-trigger="hover"
                >

                {list.status == 1 ? ( <a style={{cursor:"pointer"}} onClick={()=>this.showModalConfirmation(list.order_refference_number,
                    list.refund_amount,
                    list.processing_currency_id == 5 ? 'LKR ' : 'USD ',list.refund_id)}> <i className="fas fa-window-close" style={{color:"#e6562fe6",fontSize:"20px"}}></i></a>) : ''}

            </td>
        </tr>)) : (
            <tr className=''>
                <td colSpan="7" className='' data-title='#'><strong>No Results Found for the seleted</strong></td>
            </tr>

        )
        this.setState({
            pageCount: Math.ceil(list_data.length / this.state.perPage),
            transaction_lists_render: trans
        })
    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    componentWillMount() {

        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");

            this.setState({userToken: userToken});

            this.fetchDataFirst(this.state.endDate, this.state.startDate);
            /*    this.loadPageData(userToken);
               */
            //this.loadLastPaid(userToken);
        } else {
            this.setState({redirect: true});
        }
    }

    render() {
        const transaction_lists = this.state.transaction_lists_render;

        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }
        return (

            <React.Fragment>
                <SupportButton/>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Modal
                    show={this.state.modalShowCloseConfirmation}
                    onHide={this.state.setModalShowCloseConfimation}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="info-modal"
                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            {/* <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>*/}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 lft">
                                        <h5 className="in-h">{this.state.refund_message}</h5>
                                        <button className="btn btn-danger close-btn"
                                                onClick={this.closeModalRefundConfirm}>Close
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
                <Modal
                    show={this.state.transactionModelOpen}
                    onHide={this.state.setTransactionModelOpen}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anis-modal"
                    className="refund-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div>

                            <h1>Are you sure you want to cancel the refund request?</h1>

                            <p><strong>Refund Reference No :</strong>  {this.state.transaction_reference}</p>
                            <p className="lst"><strong>Amount :</strong><NumberFormat
                                value={(Math.round(this.state.total_amount * 100) / 100).toFixed(2)}
                                displayType={'text'} thousandSeparator={true} prefix={this.state.refund_processing_currency}/></p>

                            <p><strong>Enter Login Password</strong></p>
                            <div className="form-group">
                                <input type="password" className="form-control" value={this.state.refund_password}
                                       name="refund_password" placeholder="Enter Login Password"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_cancellation["refund_password"]}</span>
                            </div>


                            <button className="btn btn-danger" onClick={this.closeModalConfirmation}>Cancel</button>
                            {this.state.loading ? <LoadingSpinner /> :<button className="btn btn-success" onClick={this.approveCancellation}>Submit</button>}
                        </div>
                    </Modal.Body>

                </Modal>

                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <NavigationSandbox pathname="transactions-sandbox"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBarSandbox pathname="transactions"/>

                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="../transactions-sandbox" className="nav-link" id="tt-tab" data-toggle="tab"
                                          role="tab"
                                          aria-controls="tt" aria-selected="true"><span
                                        className="tt">Total Transactions</span> <span
                                        className="amt">LKR 100.00 | USD 10.00</span>
                                       </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="../refund-sandbox" className="nav-link active" id="tt-tab"
                                          data-toggle="tab" role="tab"
                                          aria-controls="tr" aria-selected="false"><span
                                        className="tt">Total Refunds</span> <span
                                        className="amt">LKR 10.00 | USD 10.00</span>
                                     </Link>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tr" role="tabpanel"
                                     aria-labelledby="tr-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <form className="col-md-8 col-12 tt-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <label htmlFor="">Transaction ID</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Transaction ID" name="order_ref_number" value={this.state.order_ref_number}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                    {/*    <div className="form-group">
                                                            <label htmlFor="">Refund ID</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Any Special Notes"/>
                                                        </div>*/}
                                                        <div className="form-group">
                                                            <label htmlFor="">Refund Value</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Refund Value" name="refund_amount" value={this.state.refund_amount}  onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Status</label>
                                                            <div className="slct">
                                                                <select name="status" id="c1" className="form-control" value={this.state.status} onChange={this.onChanges}>
                                                                    <option value="">Select Status</option>
                                                                    <option value="1">Pending</option>
                                                                    <option value="2">Refunded</option>
                                                                    <option value="3">Rejected</option>
                                                                    <option value="4">Cancelled</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Transaction Currency</label>
                                                            <div className="slct">
                                                                <select name="transaction_currency" id="c1" className="form-control"
                                                                        value={this.state.transaction_currency}
                                                                        onChange={this.onChanges}>
                                                                    <option value="">Select Currency</option>
                                                                    <option value="5">LKR</option>
                                                                    <option value="2">USD</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn" >Search</button>
                                                        </div>

                                                    </div>
                                                </form>
                                                <form className="col-md-4 col-12 tr-form inv-tr">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn clear-btn" >Clear </button>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Filters & Reports</label>
                                                            <div className="in table-datepicker">
                                                            <DateRangePicker
                                                                onEvent={this.handleEvent}
                                                                initialSettings={{
                                                                    startDate:moment(this.state.startDate).format('M/DD/YYYY'),
                                                                    endDate:moment(this.state.endDate).format('M/DD/YYYY'),
                                                                    maxDate: moment().format('M/DD/YYYY'),
                                                                    autoApply:true
                                                                }}
                                                            >
                                                                <button type="button" className="date-btn"><i className="far fa-calendar-alt"></i>  {this.state.dayCount} Days <i
                                                                    className="fas fa-caret-down" aria-hidden="true"></i></button>
                                                            </DateRangePicker>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="none" id="settlement-download-dropdown">
                                                                    <i className="fas fa-download"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>

                                                                  {/*  <button type="button" className="dropdown-item" onClick={() => this.exportPDF()}><i
                                                                        className="fas fa-file-pdf"></i>Generate Report</button>*/}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className='rg-container'>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Transaction ID</th>
                                                        {/*      <th className=''>Refund ID</th>*/}
                                                        <th className=''>Transaction Value</th>
                                                        <th className=''>Refund Value</th>
                                                        <th className=''>Date of Transaction</th>
                                                        {/*   <th className=''>Contact</th>
                                                        <th className=''>Email Address</th>*/}
                                                        <th className=''>Status</th>
                                                        <th className=''></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="">
                                                        <td className="" data-title="#">797476</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7974762021I051655
                                                        </td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 18.00</span></td>
                                                        <td className="" data-title="Refund Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot yellow"><i className="fas fa-circle"
                                                                                            aria-hidden="true"></i></span>Pending
                                                        </td>
                                                        <td className="pop-td" data-trigger="hover"><a
                                                            style={{cursor: "pointer"}}> <i className="fas fa-window-close"
                                                                                         aria-hidden="true"
                                                                                         style={{color:"#e6562fe6",fontSize:"20px"}}></i></a>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">784145</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7841452021I291647
                                                        </td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 18.00</span></td>
                                                        <td className="" data-title="Refund Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td className="" data-title="Date of Transaction">Jun 29, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot yellow"><i className="fas fa-circle"
                                                                                            aria-hidden="true"></i></span>Pending
                                                        </td>
                                                        <td className="pop-td" data-trigger="hover"><a
                                                            style={{cursor: "pointer"}}> <i className="fas fa-window-close"
                                                                                         aria-hidden="true"
                                                                                         style={{color:"#e6562fe6",fontSize:"20px"}}></i></a>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">778412</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7784122021I251614
                                                        </td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 12.00</span></td>
                                                        <td className="" data-title="Refund Value">
                                                            <span>LKR 12.00</span></td>
                                                        <td className="" data-title="Date of Transaction">Jun 25, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Cancelled
                                                        </td>
                                                        <td className="pop-td" data-trigger="hover"></td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">778434</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7784342021I251618
                                                        </td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 1.00</span></td>
                                                        <td className="" data-title="Refund Value"><span>LKR 1.00</span>
                                                        </td>
                                                        <td className="" data-title="Date of Transaction">Jun 25, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot green"><i className="fas fa-circle"
                                                                                           aria-hidden="true"></i></span>Refunded
                                                        </td>
                                                        <td className="pop-td" data-trigger="hover"></td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">778439</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7784392021I251619
                                                        </td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 2.00</span></td>
                                                        <td className="" data-title="Refund Value"><span>LKR 2.00</span>
                                                        </td>
                                                        <td className="" data-title="Date of Transaction">Jun 25, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot green"><i className="fas fa-circle"
                                                                                           aria-hidden="true"></i></span>Refunded
                                                        </td>
                                                        <td className="pop-td" data-trigger="hover"></td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">778403</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7784032021I251613
                                                        </td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 9.00</span></td>
                                                        <td className="" data-title="Refund Value"><span>LKR 9.00</span>
                                                        </td>
                                                        <td className="" data-title="Date of Transaction">Jun 25, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Cancelled
                                                        </td>
                                                        <td className="pop-td" data-trigger="hover"></td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">774163</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7741632021I231596
                                                        </td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 12.00</span></td>
                                                        <td className="" data-title="Refund Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td className="" data-title="Date of Transaction">Jun 23, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Cancelled
                                                        </td>
                                                        <td className="pop-td" data-trigger="hover"></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                {transaction_lists.length?(
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={this.state.pageCount}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                                ):('')}
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>


                        <Footer />
                    </div>
                    </div>
                </React.Fragment>

        );
    }

}


