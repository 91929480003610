import Modal from "react-bootstrap/Modal";
import React, { useContext, useState } from "react";
import "../../../../css/bootstrap.min.css";
import "../../../../css/supplier_modal/master-model.css";
import "../../../../css/supplier_modal/screen-model.css";
import "../../../../css/supplier_modal/switch.css";
import Nav from "react-bootstrap/Nav";
import Alert from "react-bootstrap/Alert";
import Tab from "react-bootstrap/Tab";
import Badge from "react-bootstrap/Badge";
import { AllSupplierTransactions } from "../TransactionManagement/AllSupplierTransactions";

import { CreateTransactionLeftPane } from "../TransactionManagement/CreateTransactionLeftPane";
import { SettlementInformation } from "../TransactionManagement/SettlementInformation";
import { PaymentInformation } from "../TransactionManagement/PaymentInformation";
import { useRef } from "react";

export function CreateTransactionModal(props) {


  const [mdrPercentage, setMdrPercentage] = useState(sessionStorage.getItem("bpsp_mdr_rate"));
  const [subTotal, setTotalValue] = useState(0.00);
  const [transactionAmount, setTransactionAmount] = useState(0.00);
  const [settlementAmount, setSettlementAmount] = useState(0.00);
  const [merchantFee, setMerchantFee] = useState(0.00);
  const [supplierFee, setSupplierFee] = useState(0.00);
  const [convenienceFee, setconvenienceFee] = useState(0.00);

  const [invoiceId, setInvoiceId] = useState("");

  const myfunc=useRef();  



  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="activation-modal"
      >
        <Modal.Body className="modal-type-2">
          <div
            className="colse-mdl"
            data-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            X
          </div>

          <Tab.Container className="container-fluid" defaultActiveKey={props.eventK}  activeKey={props.eventK}>
            <div className="row">
              <CreateTransactionLeftPane   transactionAmount={transactionAmount}  myfunction={myfunc}  evnentName={props.evnentName}  convenienceFee={convenienceFee}  supplierData={props.supplierData}/>
              <div className="col-md-8 rht">
                <Tab.Content className="tab-content" id="v-pills-tabContent">
                  <SettlementInformation

                    setToList={props.setToList}
                    setConvinceFeeAcceptedBy={props.setConvinceFeeAcceptedBy}
                    setTotalValue={setTotalValue}
                    setEventName={props.setEventName}
                    setEventK={props.setEventK}
                    setTransactionAmount={setTransactionAmount}
                    setSettlementAmount={setSettlementAmount}
                    setMerchantFee={setMerchantFee}
                    setSupplierFee={setSupplierFee}
                    setconvenienceFee={setconvenienceFee}
                    setInvoiceId={setInvoiceId}
                    productList={props.productList}
                    convinceFeeAcceptedBy={props.convinceFeeAcceptedBy}
                    subTotal={subTotal}
                    transactionAmount={transactionAmount}
                    settlementAmount={settlementAmount}
                    merchantFee={merchantFee}
                    supplierFee={supplierFee}
                    convenienceFee={convenienceFee}
                    mdrPercentage={mdrPercentage}
                    supplierData={props.supplierData}
                  myfuncc={myfunc}
            
                  />
                   <div style={{display:"none"}} onClick={() => myfunc.current.click()}>Save & Process</div>
                  <PaymentInformation 
                       productList={props.productList}
                       convinceFeeAcceptedBy={props.convinceFeeAcceptedBy}
                       subTotal={subTotal}
                       transactionAmount={transactionAmount}
                       settlementAmount={settlementAmount}
                       merchantFee={merchantFee}
                       supplierFee={supplierFee}
                       convenienceFee={convenienceFee}
                       invoiceId={invoiceId}
                       mdrPercentage={mdrPercentage}
                       hideModal={props.onHide}
                       supplierData={props.supplierData}

                       setTransactionAmount={setTransactionAmount}
                       setSettlementAmount={setSettlementAmount}
                       setMerchantFee={setMerchantFee}
                       setSupplierFee={setSupplierFee}
                       setconvenienceFee={setconvenienceFee}
                       setTotalValue={setTotalValue}
                       setConvinceFeeAcceptedBy={props.setConvinceFeeAcceptedBy}
                  />
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
