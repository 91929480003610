import React, { PureComponent } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DataTable from "@bit/adeoy.utils.data-table";
import {Navigation} from "./Navigation";

import {
    PieChart, Pie, Sector, Cell, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,Legend
} from 'recharts';
import {myConfig} from "../config";
import {TopBar} from "./TopBar";
import ReactPaginate from "react-paginate";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import {PostCompnentWebx} from "./PostCompnent";
import {Footer} from "./Footer";

export class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bank_name: '',
            settlement_status: '',
            business_information: [],
            setOpenProgress:false,
            openprogress:false,
            password:'',
            confirm_password:'',
            errors_user:'',
        };
        this.onChanges = this.onChanges.bind(this);
        this.handleCloseProgress=this.handleCloseProgress.bind(this);
        this.handleToggleProgress=this.handleToggleProgress.bind(this);
        this.getBusinessInformation=this.getBusinessInformation.bind(this);
        this.saveUserPassword=this.saveUserPassword.bind(this);
        this.handleValidation=this.handleValidation.bind(this);
    }
    handleValidation(){

        let errors_user = {};
        let formIsValid = true;
        //Email
        if(this.state.password==""){
            formIsValid = false;
            errors_user["password"] = "Cannot be empty";
        }
        if(this.state.confirm_password==""){
            formIsValid = false;
            errors_user["confirm_password"] = "Cannot be empty";
        }

        if(this.state.password!==this.state.confirm_password){
            formIsValid = false;
            errors_user["confirm_password"] = "Passwords not matching";
        }
        this.setState({errors_user: errors_user});
        return formIsValid;
    }
    saveUserPassword(){
        if (this.handleValidation()) {
            this.handleToggleProgress();
            let formData = new FormData();    //formdata object
            formData.append("password", this.state.password);    //formdata object
            formData.append("confirm_password", this.state.confirm_password);    //formdata object


            PostCompnentWebx("updateUserPassword", formData).then(response => {
                if (response) {
                    this.handleCloseProgress();
                }
            });
        }
    }
    getBusinessInformation(){
        this.handleToggleProgress();
        let formData = new FormData();    //formdata object

        PostCompnentWebx("getStoreBusinessInfo",formData).then(response=>{

            if (response){
                this.handleCloseProgress();
                console.log(response);
                this.setState({
                    business_information: response.data[0],
                });
            }

        });
    }

    onChanges(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    }

    componentWillMount() {

        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");
            this.getBusinessInformation();
            this.setState({userToken: userToken});
        } else {
            this.setState({redirect: true});
        }
    }


    render() {
        const business_information=this.state.business_information;
        if (this.state.redirect) {
            return <Navigate to='/login'  />
        }
        return (
            <div>

                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <Navigation />
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar/>
                        <div className="col-12 transaction-top">
                            <div className="row row_clr settings-outer">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                                    <div className="col-12 lft-sd">
                                        <div className="col-12 lfs-in">
                                            <img src={require("../images/settings/user-owl.png")} alt="" />
                                            <button type="button" className="nxt-btn" onClick={this.saveUserPassword}>Save New Customer &nbsp;&nbsp;<i
                                                className="fas fa-save" aria-hidden="true"></i></button>
                                            <button type="button" className="ext-btn ext-t2">Remove logo &nbsp;&nbsp;<i
                                                className="fas fa-trash-alt" aria-hidden="true"></i></button>
                                        </div>


                                        <Nav variant="pills" className="nav nav-tabs" id="set-tab1">
                                            <Nav.Item>
                                                <Nav.Link  eventKey="one" className="nav-link">Business
                                                    Personal Information</Nav.Link>
                                            </Nav.Item>

                                        </Nav>
                                    </div>
                                    <div className="col-12 rht-sd">

                                        <div className="tab-content">
                                            <Tab.Content  id="v-pills-tabContent">
                                                <Tab.Pane eventKey="one" id="v-pills-1">

                                                    <div className="tab-pane fade show active" id="to" role="tabpanel"
                                                         aria-labelledby="tr-tab">
                                                        <div className="row row_clr profile-form-section">
                                                            <div className="col-12">
                                                                <h3 className="tdh">Personal Information</h3>
                                                                <h4 className="sth">Administrator Information</h4>
                                                            </div>
                                                            <div className="col-md-6 col-xs-12">
                                                                <h6 className="sh">Full Name</h6>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-xs-12">
                                                                <h6 className="sh">Email Address</h6>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-xs-12">
                                                                <h6 className="sh">Phone Number</h6>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-xs-12">
                                                                <h6 className="sh">Date of Birth</h6>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-xs-12">
                                                                <h6 className="sh">Gender</h6>
                                                                <div className="form-group">
                                                                    <select className="form-control">
                                                                        <option>Male</option>
                                                                        <option>Female</option>
                                                                    </select>
                                                                </div>
                                                            </div>


                                                            <div className="col-12">
                                                                <h4 className="sth">Address Details</h4>
                                                            </div>

                                                            <div className="col-12 no-pad">
                                                                <div className="col-md-6 col-xs-12">
                                                                    <h6 className="sh">Street Address</h6>
                                                                    <div className="form-group">
                                                                        <input type="text" className="form-control"
                                                                               placeholder="" name="street_address"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-xs-12">
                                                                <h6 className="sh">City</h6>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="" name="city"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-xs-12">
                                                                <h6 className="sh">ZIP Code</h6>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-xs-12">
                                                                <h6 className="sh">Country</h6>
                                                                <div className="form-group">
                                                                    <select className="form-control">
                                                                        <option>Sri Lanka</option>
                                                                    </select>
                                                                </div>
                                                            </div>


                                                            <div className="col-12">
                                                                <h4 className="sth">Password Information</h4>
                                                            </div>

                                                            <div className="col-md-6 col-xs-12">
                                                                <h6 className="sh">Enter Password</h6>
                                                                <div className="form-group">
                                                                    <input type="password" name="password" className="form-control"
                                                                           placeholder="Enter New Password" onChange={this.onChanges} />
                                                                    <span className="error" style={{color: "red"}}>{this.state.errors_user["password"]}</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 col-xs-12">
                                                                <h6 className="sh">Re-Enter Password</h6>
                                                                <div className="form-group">
                                                                    <input type="password" name="confirm_password" className="form-control"
                                                                           placeholder="Re-Enter New Password" onChange={this.onChanges} />
                                                                    <span className="error" style={{color: "red"}}>{this.state.errors_user["confirm_password"]}</span>
                                                                </div>
                                                            </div>


                                                            {/*<div className="col-12">
                                                                <h4 className="sth">Social Media Accounts</h4>
                                                            </div>

                                                            <div className="col-md-4 soc-in col-xs-12">
                                                                <img src={require("../images/settings/facebook.svg")} alt="" />
                                                                <h6 className="sh">Facebook</h6>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Add Facebook Link" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 soc-in col-xs-12">
                                                                <img src={require("../images/settings/linkedin.svg")} alt="" />
                                                                <h6 className="sh">Instagram</h6>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Add Instagram Link" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 soc-in col-xs-12">
                                                                <img src={require("../images/settings/twitter.svg")} alt="" />
                                                                <h6 className="sh">Twitter</h6>
                                                                <div className="form-group">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Add Twitter Link" />
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <button type="button" className="nxt-btn">Save Social
                                                                    Media Accounts &nbsp;&nbsp;<i
                                                                        className="fas fa-save" aria-hidden="true"></i>
                                                                </button>
                                                            </div>*/}

                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                            {/*     <div className="tab-pane fade show active" id="to" role="tabpanel"
                                             aria-labelledby="tr-tab">

                                        </div>
                                        <div className="tab-pane fade" id="tr" role="tabpanel" aria-labelledby="tr-tab">

                                        </div>
                                        <div className="tab-pane fade" id="th" role="tabpanel" aria-labelledby="tr-tab">

                                        </div>*/}
                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>

                        </div>

                        <Footer />
                    </div>
                </div>
            </div>

        );
    }

}


