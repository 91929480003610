import React, {PureComponent} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DataTable from "@bit/adeoy.utils.data-table";
import {Navigation} from "../Navigation";

import {
    PieChart, Pie, Sector, Cell, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import {myConfig} from "../../config";
import {TopBar} from "../TopBar";
import ReactPaginate from "react-paginate";
import {CSVLink} from "react-csv";
import {addZeroes, GetWebxLaravel, PostCompnentWebx} from "../PostCompnent";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import {Footer} from "../Footer";
import {SupportButton} from "../SupportButton";
import {NavigationSandbox} from "./NavigationSandbox";
import {TopBarSandbox} from "./TopBarSandbox";

export class SettlementsSandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(30, 'days').format('ll'),
            endDate: moment().format('ll'),
            settlement_lists: [],
            settlement_lists_filters: [],
            settlement_lists_render: [],
            lkr_transaction_total: 0,
            lkr_refund_total: 0,
            usd_refund_total: 0,
            usd_transaction_total: 0,
            offset: 0,
            perPage: 10,
            currentPage: 0,
            due_lists: [0],
            payout_reference: '',
            total_settlement: '',
            bank_name: '',
            settlement_status: '',
            csvData: [],
            csvDataTransaction: [],
            point_of_transaction: '',
            dayCount:  31,
            settlementModal: false,
            setSettlementModal: false,
            settlementBreakdown: [],
            modalShow: false,
            setModalShow: false,
            transaction_individual_data: [],
            showMessageModal: false,
            csvSettlementStartDate: moment().subtract(30, 'days').format('ll'),
            csvSettlementEndDate: moment().format('ll'),
        };
        this.onChanges = this.onChanges.bind(this);
        this.filterList = this.filterList.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.showSettlementModal = this.showSettlementModal.bind(this);
        this.closeSettlementModal = this.closeSettlementModal.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.closeMessageModal = this.closeMessageModal.bind(this);
        this.openMessageModal = this.openMessageModal.bind(this);
        this.generateSettlementCSV = this.generateSettlementCSV.bind(this);
    }
    openMessageModal(){
        this.setState({
            showMessageModal:true,
        })
    }
    closeMessageModal(){
        this.setState({
            showMessageModal:false,
        })

    }
    handlePageClick(e){
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadTable();
        });
    }
    modalOpen(transaction_id) {


        let formData = new FormData();    //formdata objec

        formData.append('order_refference_number', transaction_id);   //append the values with key, value pair

        this.handleToggleProgress();

        GetWebxLaravel("common_list/get_point_of_transaction/"+transaction_id).then(response=>{
            let data_type=response.data.type;
            let point_of_transaction='';
            if (data_type===0){
                point_of_transaction="X GATEWAY";
            }else if(data_type===1){
                point_of_transaction="X DIRECT"
            }else{
                point_of_transaction="X SELL";
            }
            this.setState({
                point_of_transaction:point_of_transaction
            });
        });

        PostCompnentWebx("getTransactionData", formData).then(response => {
            this.handleCloseProgress();
            this.setState({
                transaction_individual_data: response.data,
                modalShow: true,
                setModalShow: true,
                settlementModal: false,
                setSettlementModal: false,
            });

        });

    }

    modalClose() {
        this.setState({
            modalShow: false,
            setModalShow: false,
            settlementModal: true,
            setSettlementModal: true,
        })
    }

    showSettlementModal(payout_deposit_summery_id) {
        let formData = new FormData();    //formdata object
        formData.append('payout_deposit_summery_id', payout_deposit_summery_id);   //append the values with key, value pair
        this.handleToggleProgress();
        PostCompnentWebx("getStoreSettlementBreakDown", formData).then(response => {

            const csvDataTransaction = [
                ["Settlement ID",response.data.payout_reference," ","Total LKR Settlement",response.data.lkr_transaction_amount," ","Total USD Settlement",response.data.usd_transaction_amount]
            ];
            csvDataTransaction.push(["Settlement Date",response.data.settlement_date!==""?moment(response.data.settlement_date).format('ll'):''," ","LKR Deduction",response.data.lkr_discount_amount," ","USD Deduction",response.data.usd_discount_amount]);

            csvDataTransaction.push(["","","","LKR Amount Settled",response.data.total_lkr_settlement," ","USD Amount Settled",response.data.total_usd_settlement],[""]);

            csvDataTransaction.push(["Transaction / Refund Date","Store Order ID","Customer First Name","Customer Last Name","Transaction ID","Processing Currency","Transaction Amount","Gateway Discount","Transaction Net","Refund Charge","Payment Currency","Settlement Amount"]);
                response.data.transaction_list.map((value, index) => {
                csvDataTransaction.push([moment(value.date_added).format('ll'), value.gateway_order_id,  value.first_name, value.last_name,value.order_refference_number, value.processing_currency_id == 5 ? "LKR" : "USD", value.transaction_amount, value.discount_amount, value.transaction_net, "",value.payment_currency_id == 5 ? "LKR" : "USD", value.payment_amount]);
            });
            response.data.refund_list.map((value, index) => {
                csvDataTransaction.push([moment(value.date_added).format('ll'),value.gateway_order_id, value.first_name, value.last_name,value.order_refference_number,value.processing_currency_id == 5 ? "LKR" : "USD", value.refund_amount, value.discount_amount, value.refund_net,value.refund_charge, value.payment_currency_id == 5 ? "LKR" : "USD", value.refund_payment_amount]);
            });

            this.setState({
                settlementBreakdown: response.data,
                settlementModal: true,
                setSettlementModal: true,
                csvDataTransaction: csvDataTransaction,
            });
            this.handleCloseProgress();

            //

        });

    }

    closeSettlementModal() {
        this.setState({
            settlementModal: false,
            setSettlementModal: false,
        });
    }

    handleCallback(start, end, label) {
        this.setState({
            startDate: moment(start).format('ll'),
            endDate: moment(end).format('ll'),
        });
        this.filterList(end, start);
    }

    filterList() {

        let payout_reference = this.state.payout_reference.toLowerCase();
        let total_settlement = this.state.total_settlement.toLowerCase();
        let bank_name = this.state.bank_name.toLowerCase();
        let settlement_lists = this.state.settlement_lists, settlement_lists_filters = [];


        let startDate= moment(this.state.startDate).format('YYYY-MM-DD');
        let endDate= moment(this.state.endDate).format('YYYY-MM-DD');
        if (this.state.startDate=="") {
            startDate=moment().subtract(30, 'days').format('YYYY-MM-DD')
        }
        if (this.state.endDate==""){
            endDate= moment().format('YYYY-MM-DD');
        }

        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        let formData = new FormData();    //formdata object
        formData.append('filter_date_start', startDate);
        formData.append('filter_date_end', endDate);
        formData.append('payout_reference', payout_reference);
        formData.append('bank_name', bank_name);


        this.handleToggleProgress();
        PostCompnentWebx("getStoreSettlements", formData).then(response => {
            this.handleCloseProgress();
            this.generateSettlementCSV(response);
            this.setState({
                settlement_lists: response.data,
                settlement_lists_filters: response.data,
                dayCount: days_count
            }, () => {
                this.loadTable();
            })
            // this.setState({transaction_lists:response.data});

        });
        this.handleToggleProgress();
        PostCompnentWebx("getMerchantDueTransactionsAndRefunds", formData).then(response => {
            this.handleCloseProgress();
            this.setState({
                due_lists: response.data,
            });
        });
        this.handleToggleProgress();
        PostCompnentWebx("getStoreTransactionAndRefundTotal", formData).then(response => {
            this.handleCloseProgress();
            this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
            this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
            this.setState({usd_refund_total: response.data.totals.usd_refund_total});
            this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
        });

    }

    clearSearch() {

        let startDate=moment().subtract(30, 'days').format('YYYY-MM-DD');
        let endDate= moment().format('YYYY-MM-DD');

        if (this.state.endDate==""){
            endDate= moment().format('YYYY-MM-DD');
        }

        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        this.setState({
            csvSettlementStartDate: moment(endDate).format('ll'),
            csvSettlementEndDate: moment(startDate).format('ll')
        })



        let formData = new FormData();    //formdata object
        formData.append('filter_date_start', startDate);
        formData.append('filter_date_end', endDate);
        this.handleToggleProgress();
        PostCompnentWebx("getStoreSettlements", formData).then(response => {
            this.handleCloseProgress();

            this.generateSettlementCSV(response)
            this.setState({
                settlement_lists: response.data,
                settlement_lists_filters: response.data,
                dayCount: days_count
            }, () => {
                this.loadTable();
            })
            // this.setState({transaction_lists:response.data});

        });
        this.handleToggleProgress();
        PostCompnentWebx("getMerchantDueTransactionsAndRefunds", formData).then(response => {
            this.handleCloseProgress();
            this.setState({
                due_lists: response.data,
           /*     dayCount: days_count*/
            });
        });
        this.handleToggleProgress();
        PostCompnentWebx("getStoreTransactionAndRefundTotal", formData).then(response => {
            this.handleCloseProgress();
            this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
            this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
            this.setState({usd_refund_total: response.data.totals.usd_refund_total});
            this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
        });
        this.setState({
            payout_reference:'',
            total_settlement:'',
            bank_name:''
        }, () => {
            this.loadTable()
        });
    }

    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    generateSettlementCSV(response){


        const csvData = [[
            "","","Settlement Report","From "+this.state.csvSettlementStartDate +" To "+this.state.csvSettlementEndDate
        ]];
        csvData.push([]);
        csvData.push(
            [ "Settlement ID", "Total Order Value- LKR", "Total Order Value-USD", "Gateway Deduction LKR", "Gateway Deduction USD","Refund","Settlement Date","Settlement Amount","Deposited Account"]
        );
        csvData.push([])
        response.data.map((value, index) => {
            csvData.push([value.payout_reference, value.lkr_transaction_amount, value.usd_transaction_amount, value.lkr_discount_amount,value.usd_discount_amount,value.lkr_refund_payment_amount, moment(value.settlement_date).format('ll'),value.total_lkr_settlement, value.bank_name]);
        });

        this.setState({
            csvData: csvData,
        });
    }

    fetchDataFirst(end, start) {

        var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");
        if (end && start) {
            this.handleToggleProgress();
            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');
            var a = moment(end);
            var b = moment(start);
            let days_count = a.diff(b, 'days');   // =1
            days_count=parseInt(days_count)+parseInt(1);
            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);

            PostCompnentWebx("getStoreSettlements", formData).then(response => {
                if (response){
                    this.handleCloseProgress();
                    this.generateSettlementCSV(response);
                    this.setState({
                        settlement_lists: response.data,
                        settlement_lists_filters: response.data,
                        dayCount: days_count
                    }, () => {
                        this.loadTable();
                    })
                }

                // this.setState({transaction_lists:response.data});

            });

            PostCompnentWebx("getMerchantDueTransactionsAndRefunds", formData).then(response => {
                this.setState({
                    due_lists: response.data,
                });
            });

            PostCompnentWebx("getStoreTransactionAndRefundTotal", formData).then(response => {
                this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
                this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
                this.setState({usd_refund_total: response.data.totals.usd_refund_total});
                this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
            });
        }
    }



    handleCloseProgress = () => {
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({openprogress: true});
    };

    loadTable() {

        const list_data = this.state.settlement_lists_filters;

        const slice = list_data.slice(this.state.offset, this.state.offset + this.state.perPage);
        const trans = list_data && list_data.length ? (slice.map((list, key) => <tr className=''
                                                                                    >
                <td className='' data-title='#'>{key + 1}</td>
                <td className='' data-title='Settlement ID'>{list.payout_reference}
                </td>

                <td className='' data-title='Total Settlement'><NumberFormat
                    value={(Math.round(list.lkr_transaction_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
                </td>
                <td className='' data-title='Total Settlement'><NumberFormat
                    value={(Math.round(list.usd_transaction_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={'USD '}/>
                </td>

                <td className='' data-title='Deductions'><NumberFormat
                    value={(Math.round(list.lkr_discount_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></td>
                <td className='' data-title='Amount Settled'><NumberFormat
                    value={(Math.round(list.usd_discount_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={'USD '}/>
                </td>
            <td className='' data-title='Amount Settled'><NumberFormat
                value={(Math.round(list.lkr_refund_payment_amount * 100) / 100).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
            </td>
            <td className='' data-title='Amount Settled'><NumberFormat
                value={(Math.round(list.total_lkr_settlement * 100) / 100).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
            </td>
                <td className='' data-title='Settlement Date'>{moment(list.settlement_date).format('ll')}
                </td>
                <td className='' data-title='Account Deposited'>{list.bank_name}
                </td>
                <td className='' data-title='Show'>
                    <button
                        onClick={() => this.showSettlementModal(list.payout_deposit_summery_id)}
                        style={{cursor: "pointer"}} className="btn dot-drop">
                        <i className="fas fa-eye"></i></button>
                 </td>
                {/*  <td className='pop-td' data-toggle="popover"
                    data-trigger="hover" data-placement="auto"
                    data-content="Your Settlement will be Credited within 72 Hours">
                                                            <span className="dot yellow"><i className="fas fa-circle"
                                                                                            aria-hidden="true"></i></span> Settlement
                    Pending
                </td>*/}
            </tr>
        )) : (
            <tr className=''>
                <td colSpan="11" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
            </tr>
        );
        this.setState({
            pageCount: Math.ceil(list_data.length / this.state.perPage),
            settlement_lists_render: trans
        })
    }


    componentWillMount() {

        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");

            this.setState({userToken: userToken});

            this.fetchDataFirst(this.state.endDate, this.state.startDate);
            this.loadTable();

        } else {
            this.setState({redirect: true});
        }
    }


    render() {
        const renderThumb = ({ style, ...props }) => {
            const thumbStyle = {
                borderRadius: 6,
                backgroundColor: 'rgba(35, 49, 86, 0.8)'
            };
            return <div style={{ ...style, ...thumbStyle }} {...props} />;
        };
        const CustomScrollbars = props => (
            <Scrollbars
                renderThumbHorizontal={renderThumb}
                renderThumbVertical={renderThumb}
                {...props}
            />
        );

        const transaction_individual_data = this.state.transaction_individual_data;

        let settlement_sum = 0;
        const zeroPad = (num, places) => String(num).padStart(places, '0');
        let settlementBreakdown = this.state.settlementBreakdown;
        const settlement_lists_render = this.state.settlement_lists_render;
        if (this.state.settlement_lists) {
            settlement_sum = this.state.settlement_lists.reduce(function (prev, current) {
                return prev + +current.total_lkr_settlement
            }, 0);
        }

        let due_list_sum = this.state.due_lists.reduce(function (prev, current) {
            return prev + +current.payment_amount
        }, 0);

        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }

        let settlement_breakdown=settlementBreakdown.refund_list;
        let undi=true;
        console.log(settlement_breakdown);
        if (typeof settlement_breakdown=="undefined"){
            undi=false;
        }
        console.log(undi);
        return (
            <div>
                <SupportButton/>
                <Modal
                    show={this.state.showMessageModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="help-modal"

                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            {/* <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>*/}
                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col-12">
                                        <h6 className="sh">Name of Company/Business</h6>
                                        <h5 className="mh">Harshaka Dilan</h5>
                                    </div>
                                    <div className="col-12">
                                        <h6 className="sh">Name of Company/Business</h6>
                                        <h5 className="mh">Harshaka Dilan</h5>
                                    </div>

                                    <div className="col-md-12 lft" style={{textAlign:'right'}}>

                                        <button className="btn btn-danger close-btn"
                                                onClick={this.closeMessageModal}>Close
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>

                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Modal
                    show={this.state.modalShow}
                    onHide={this.state.setModalShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="activation-modal"
                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            <div className="colse-mdl" data-dismiss="modal" aria-label="Close"
                                 onClick={this.modalClose}>X
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 lft">
                                        <h3 className="tdh">Transaction Details</h3>

                                        <div className="col-12 tdm-in">

                                            <h6>Transaction Amount</h6>
                                            <h3 className="tdh"><NumberFormat
                                                value={(Math.round(transaction_individual_data.total_amount * 100) / 100).toFixed(2)}
                                                displayType={'text'} thousandSeparator={true}
                                                prefix={transaction_individual_data.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
                                            </h3>

                                            <h6>Date of Transaction</h6>
                                            <h5 className="sh">{moment(transaction_individual_data.date_time_transaction).format("ll")}</h5>

                                            <h6>Transaction ID</h6>
                                            <h5 className="sh">{transaction_individual_data.order_refference_number}</h5>
                                            <h6>Store Reference</h6>
                                            <h5 className="sh">{transaction_individual_data.gateway_order_id}</h5>
                                            <h6>Clearance Status</h6>
                                            <h5 className="sh">
                                                {transaction_individual_data.clearance_status == "Settlement Pending" ? (
                                                    <span className="dot yellow"><i className="fas fa-circle"
                                                                                    aria-hidden="true"></i></span>) : ('')}
                                                {transaction_individual_data.clearance_status == "Settled" ? (
                                                    <span className="dot green"><i className="fas fa-circle"
                                                                                   aria-hidden="true"></i></span>) : ('')} {transaction_individual_data.clearance_status=="No Settlement"?(<><span style={{color:"red"}}>Payment Declined</span> < p className = "sp" style={{fontSize:12,fontWeight: 600,color:"#dc5937",padding: "10px 0 0 0"}} > {transaction_individual_data.response_text}</p></>):transaction_individual_data.clearance_status}
                                            </h5>
                                            <h6>Payment Gateway</h6>
                                            <h5 className="sh">{transaction_individual_data.payment_gateway}</h5>
                                            <h6>Point of Transaction</h6>
                                            <h5 className="sh">{this.state.point_of_transaction}</h5>

                                            {transaction_individual_data.card_type=="Visa" || transaction_individual_data.card_type=="Master"||transaction_individual_data.card_type=="Mastercard"?(<><h6>Card Type</h6><h5 className="sh">{transaction_individual_data.card_type}</h5></>):('')}
                                            {/*   <h6>Notes</h6>
                                        <h5 className="sh">REF 12323/1223</h5>*/}

                                        </div>

                                        {/*<a href={myConfig.laravelWeb + "reports/print_transaction/" + transaction_individual_data.order_refference_number + '/' + sessionStorage.getItem("userToken") || localStorage.getItem("userToken")}
                                           type="button" className="nxt-btn anb" target="_blank">Print Receipt</a>*/}
                                        {transaction_individual_data.payment_status_id == 2 ? (
                                            transaction_individual_data.order_status_id==17 || transaction_individual_data.order_status_id==11?(''):(<button type="button" className="ext-btn ext2"
                                                                                                                                                             onClick={() => this.showModalConfirmation(transaction_individual_data.order_refference_number, transaction_individual_data.total_amount,transaction_individual_data.processing_currency_id)}>Refund
                                                Amount</button>)
                                        ) : ('')}
                                        { transaction_individual_data.order_status_id==17?(<button type="button" className="ext-btn ext2" disabled style={{backgroundColor:"#cccccc",color:"#000000",borderColor:"#000000"}}>Refund Requested</button>):''}
                                        { transaction_individual_data.order_status_id==11?<button type="button" className="ext-btn ext2" disabled style={{backgroundColor:"#cccccc",color:"#000000",borderColor:"#000000"}}>Refunded</button>:''}
                                        {/*   <a href="#" className="help"><i className="far fa-life-ring"
                                                                    aria-hidden="true"></i> Need a
                                        hand figuring it out?</a>*/}
                                    </div>
                                    <div className="col-md-8 rht">

                                        <h3 className="tdh">Transaction Timeline</h3>

                                        <div className="row">
                                            <div className="col-sm-3 col-12 tt-icns">
                                                <div className="icon-box">
                                                    <img src={require('../../images/transactions/money.svg')} alt="money"/>
                                                </div>
                                                <p>Payment Initiated</p>
                                                <p className="sp">{moment(transaction_individual_data.date_time_transaction).format('ll')}</p>
                                            </div>

                                            {transaction_individual_data.clearance_status_id !== "33" ? (
                                                <React.Fragment>

                                                    <div className="col-sm-3 col-12 tt-icns">
                                                        <div className="icon-box">
                                                            <img src={require('../../images/transactions/invoice.svg')}
                                                                 alt="money"/>
                                                        </div>
                                                        <p>Payment Successful</p>
                                                        <p className="sp">{moment(transaction_individual_data.date_time_settlement).format('ll')}</p>
                                                    </div>
                                                    {transaction_individual_data.clearance_status_id == "22"  ?
                                                        (
                                                            <div className="col-sm-3 col-12 tt-icns">
                                                                <div className="icon-box">
                                                                    <img
                                                                        src={require('../../images/transactions/bank.svg')}
                                                                        alt="money"/>
                                                                </div>
                                                                <p>Settlement Completed</p>
                                                                <p className="sp">{moment(transaction_individual_data.settlement_date).format('ll')}</p>
                                                            </div>

                                                        ) : (
                                                            <>
                                                                <div className="col-sm-3 col-12 tt-icns">
                                                                    <div className="icon-box half-filled">
                                                                        <img
                                                                            src={require('../../images/transactions/time-is-money.svg')}
                                                                            alt="money"/>
                                                                    </div>
                                                                    <p>{transaction_individual_data.clearance_status}</p>
                                                                    {transaction_individual_data.settlement_date?transaction_individual_data.settlement_date!==""?
                                                                        ( <p className="sp">Your Funds will be credited to your
                                                                            account on <span>{transaction_individual_data.settlement_date!==""?moment(transaction_individual_data.settlement_date).format('ll'):''}</span>
                                                                        </p>):(''):('')}

                                                                </div>
                                                            </>
                                                        )}
                                                </React.Fragment>
                                            ) : (
                                                <div className="col-sm-3 col-12 tt-icns">
                                                    <div className="icon-box">
                                                        <img
                                                            src={require('../../images/transactions/payment_declined.svg')}
                                                            alt="money"/>
                                                    </div>
                                                    <p>Payment Declined</p>
                                                    <p className="sp">Sorry the payment has been declined</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-12 details-box">
                                                <div className="col-12 dbx-in">
                                                    <h3>Payee Details</h3>

                                                    <h6>Payee Name</h6>
                                                    <h5 className="sh">{transaction_individual_data.first_name} {transaction_individual_data.last_name}</h5>


                                                    <h6>Payee Telephone</h6>
                                                    <h5 className="sh">{transaction_individual_data.contact_number}</h5>

                                                    <h6>Payee Address</h6>
                                                    <h5 className="sh">{transaction_individual_data.address_line_one}, {transaction_individual_data.address_line_two=="null"?"":transaction_individual_data.address_line_two+', '} {transaction_individual_data.city}</h5>

                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-12 details-box">
                                                <div className="col-12 dbx-in">
                                                    <h3>Payment Breakdown</h3>

                                                    <h6>Total Amount</h6>
                                                    <h5 className="sh">
                                                        <NumberFormat
                                                            value={(Math.round(transaction_individual_data.total_amount * 100) / 100).toFixed(2)}
                                                            displayType={'text'} thousandSeparator={true}
                                                            prefix={transaction_individual_data.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
                                                    </h5>


                                                    <h6>WEBXPAY Charges</h6>
                                                    <h5 className="sh">
                                                        <NumberFormat
                                                            value={(Math.round(transaction_individual_data.discount_amount * 100) / 100).toFixed(2)}
                                                            displayType={'text'} thousandSeparator={true}
                                                            prefix={transaction_individual_data.processing_currency_id == 5 ? '- LKR ' : '- USD '}/>({transaction_individual_data.payment_gateway_rate} %)
                                                    </h5>


                                                    <h6>Settlement Amount</h6>
                                                    <h5 className="sh">
                                                        <NumberFormat
                                                            value={(Math.round(transaction_individual_data.payment_amount * 100) / 100).toFixed(2)}
                                                            displayType={'text'} thousandSeparator={true}
                                                            prefix={transaction_individual_data.payment_currency_id == 5 ? 'LKR ' : 'USD '}/>
                                                    </h5>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
                <Modal
                    show={this.state.settlementModal}
                    onHide={this.state.setSettlementModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="activation-modal"
                >
                    <Modal.Body className=" modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close"
                             onClick={this.closeSettlementModal}>X
                        </div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 lft">
                                    <h3 className="tdh">Settlement Details</h3>

                                    <div className="col-12 tdm-in">

                                        <h6>Settlement Value</h6>
                                        <h3 className="tdh"><NumberFormat
                                            value={(Math.round(settlementBreakdown.total_lkr_settlement * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></h3>
                                        {settlementBreakdown.total_usd_settlement !== 0 ? (
                                            <h3 className="tdh"><NumberFormat
                                                value={(Math.round(settlementBreakdown.total_usd_settlement * 100) / 100).toFixed(2)}
                                                displayType={'text'} thousandSeparator={true} prefix={'USD '}/></h3>
                                        ) : ('')}
                                        <h6>Total Sales Amount</h6>
                                        {settlementBreakdown.lkr_transaction_amount !== 0 ? (
                                        <h5 className="sh"><NumberFormat
                                            value={(Math.round(settlementBreakdown.lkr_transaction_amount * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></h5>):('')}
                                        {settlementBreakdown.usd_transaction_amount !== 0 ? (
                                            <h5 className="sh"><NumberFormat
                                                value={(Math.round(settlementBreakdown.usd_transaction_amount * 100) / 100).toFixed(2)}
                                                displayType={'text'} thousandSeparator={true} prefix={'USD '}/></h5>
                                        ) : ('')}


                                        <h6>Total Refunds</h6>

                                        <h5 className="sh"><NumberFormat
                                            value={(Math.round(settlementBreakdown.lkr_refund_payment_amount * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></h5>
                                        {settlementBreakdown.usd_refund_payment_amount !== 0 ? (
                                            <h5 className="sh"><NumberFormat
                                                value={(Math.round(settlementBreakdown.usd_refund_payment_amount * 100) / 100).toFixed(2)}
                                                displayType={'text'} thousandSeparator={true} prefix={'USD '}/></h5>
                                        ) : ('')}
                                        <h6>WEBXPAY Charges</h6>
                                        {settlementBreakdown.lkr_discount_amount !== 0 ? (
                                        <h5 className="sh">- <NumberFormat
                                            value={(Math.round(settlementBreakdown.lkr_discount_amount * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></h5>):('')}
                                        {settlementBreakdown.usd_discount_amount !== 0 ? (
                                            <h5 className="sh">- <NumberFormat
                                                value={(Math.round(settlementBreakdown.usd_discount_amount * 100) / 100).toFixed(2)}
                                                displayType={'text'} thousandSeparator={true} prefix={'USD '}/></h5>
                                        ) : ('')}
                                        {/* <h6>VAT, NBT & Other Taxes</h6>
                                            <h5 className="sh">- 0‬.00 (0%)</h5>*/}

                                        <h6>Date of Settlement</h6>
                                        <h5 className="sh">{moment(settlementBreakdown.date_time_added).format('ll')}</h5>

                                        <h6>Settlement ID</h6>
                                        <h5 className="sh">{settlementBreakdown.payout_reference}</h5>

                                        <h6>Clearance Status</h6>
                                        <h5 className="sh">
                                            {settlementBreakdown.clearance_status=="Settled"||"Settlement Completed"?( <span className="dot green"><i className="fas fa-circle"  aria-hidden="true"></i> </span> ):
                                                (<span className="dot yellow"><i className="fas fa-circle"  aria-hidden="true"></i> </span> ) }
                                                {settlementBreakdown.clearance_status}</h5>
                                    </div>

                                    {/*<button type="button" className="nxt-btn">Print Receipt</button>*/}

                                {/*    <a onClick={this.openMessageModal} className="help"><i className="far fa-life-ring"
                                                                    aria-hidden="true"></i> Need Help with the
                                        System?</a>*/}
                                </div>
                                <div className="col-md-8 rht">

                                    <h3 className="tdh">Settlement Timeline</h3>

                                    <div className="row">

                                        {settlementBreakdown.clearance_status_id == "22" ?
                                            (
                                                <>
                                                    <div className="col-sm-3 col-12 tt-icns">
                                                        <div className="icon-box">
                                                            <img
                                                                src={require('../../images/transactions/invoice_email.svg')}
                                                                alt="money"/>
                                                        </div>
                                                        <p>Settlement Initiated</p>
                                                     {/*   <p className="sp">{moment(settlementBreakdown.date_time_added).format('ll')}
                                                        </p>*/}
                                                    </div>
                                                    <div className="col-sm-3 col-12 tt-icns">
                                                        <div className="icon-box">
                                                            <img
                                                                src={require('../../images/transactions/bank.svg')}
                                                                alt="money"/>
                                                        </div>
                                                        <p>Settled to Account</p>
                                                        <p className="sp" >{moment(settlementBreakdown.settlement_date).format('ll')}
                                                            <span>
                                            </span></p>
                                                    </div>

                                                </>

                                            ) : (
                                                <>
                                                    <div className="col-sm-3 col-12 tt-icns">
                                                        <div className="icon-box">
                                                            <img
                                                                src={require('../../images/transactions/invoice_email.svg')}
                                                                alt="money"/>
                                                        </div>
                                                        <p>Settlement Initiated</p>
                                                        <p className="sp">{moment(settlementBreakdown.date_time_added).format('ll')}
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-3 col-12 tt-icns">
                                                        <div className="icon-box half-filled">
                                                            <img
                                                                src={require('../../images/transactions/time-is-money.svg')}
                                                                alt="money"/>
                                                        </div>
                                                        <p>Settlement In Progress</p>
                                                        <p className="sp">{settlementBreakdown.settlement_date!==""?moment(settlementBreakdown.settlement_date).format('ll'):""}
                                                            <span>

                                            </span></p>
                                                    </div>

                                                </>
                                            )}
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 col-12 details-box">
                                            <div className="col-12 dbx-in">
                                                <h3>Account Details</h3>

                                                <h6>Settlement Account</h6>
                                                <h5 className="sh">{settlementBreakdown.bank_name}</h5>

                                                <h6>Account Number</h6>
                                                <h5 className="sh">{settlementBreakdown.account_number}</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-12 details-box">
                                            <div className="col-12 dbx-in">
                                                <h3>Settlement Summary</h3>

                                                <h6>Total Transactions</h6>
                                                <h5 className="sh">{zeroPad(parseFloat(settlementBreakdown.lkr_transaction_count) + parseFloat(settlementBreakdown.usd_transaction_count), 2)}</h5>

                                                <h6>Total Refunds</h6>

                                                <h5 className="sh">{zeroPad(parseFloat(settlementBreakdown.usd_refund_count) + parseFloat(settlementBreakdown.lkr_refund_count), 2)}</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 details-box dbx-full">
                                            <div className="col-12 dbx-in">

                                                <h3>Transactions List
                                                    <CSVLink data={this.state.csvDataTransaction} style={{textDecoration: "none !important",color:"#fff",marginLeft:"15px"}} filename={"Settlement Breakdown "+settlementBreakdown.payout_reference+".csv"}>
                                                        <i className="fas fa-download"></i>
                                                    </CSVLink>
                                                </h3>
                                                <div className='rg-container' style={{height:300}}>
                                                    <CustomScrollbars autoHideTimeout={500} autoHideDuration={200}>
                                                    <table className='transactions-table' summary='Hed'>

                                                        <tbody>

                                                        {settlementBreakdown.transaction_list ? (settlementBreakdown.transaction_list.map((list, key) =>
                                                            <tr className=''>
                                                                <td className='' data-title='#'>{key + 1}</td>
                                                                <td className=''
                                                                    data-title='Transaction ID'>{list.order_refference_number}
                                                                </td>
                                                                <td className='' data-title='Transaction Value'>
                                                                    <NumberFormat
                                                                        value={(Math.round(settlementBreakdown.amount * 100) / 100).toFixed(2)}
                                                                        displayType={'text'} thousandSeparator={true}
                                                                        prefix={list.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
                                                                </td>
                                                                <td className=''
                                                                    data-title='Date of Transaction'>{moment(list.date_time_transaction).format('ll')}
                                                                </td>
                                                                <td className=''
                                                                    data-title='Contact'>{list.first_name} {list.last_name}
                                                                </td>
                                                                <td className='' data-title='Contact'>
                                                                    <button
                                                                        onClick={() => this.modalOpen(list.order_refference_number)} >
                                                                        <i className="fas fa-eye"></i></button>
                                                                </td>
                                                            </tr>
                                                        )) : (
                                                            <tr className=''>
                                                                <td colSpan="6" className='' data-title='#'></td>
                                                            </tr>

                                                        )}

                                                        </tbody>

                                                    </table>
                                                         </CustomScrollbars>
                                                </div>
                                            </div>
                                        </div>
                                        {settlementBreakdown.refund_list ? (
                                            <div className="col-12 details-box dbx-full">
                                                {settlementBreakdown.refund_list.length>0 ? (
                                                <div className="col-12 dbx-in">
                                                    <h3>Refund List</h3>
                                                    <div className='rg-container' style={{height:300}}>

                                                        <CustomScrollbars autoHideTimeout={500} autoHideDuration={200}>
                                                        <table className='transactions-table' summary='Hed'>
                                                            <tbody>
                                                            {settlementBreakdown.refund_list.map((list, key) =>
                                                            <tr className=''>
                                                                <td className='' data-title='#'>{key + 1}</td>
                                                                <td className=''
                                                                    data-title='Transaction ID'>{list.order_refference_number}
                                                                </td>
                                                                <td className='' data-title='Transaction Value'>
                                                                    <NumberFormat
                                                                        value={(Math.round(settlementBreakdown.amount * 100) / 100).toFixed(2)}
                                                                        displayType={'text'} thousandSeparator={true}
                                                                        prefix={list.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
                                                                </td>
                                                                <td className=''
                                                                    data-title='Date of Transaction'>{moment(list.date_added).format('ll')}
                                                                </td>
                                                                <td className=''
                                                                    data-title='Contact'>{list.first_name} {list.last_name}
                                                                </td>
                                                                <td className='' data-title='Contact'>
                                                                    <NumberFormat
                                                                        value={(Math.round(list.amount * 100) / 100).toFixed(2)}
                                                                        displayType={'text'} thousandSeparator={true}
                                                                        prefix={list.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
                                                                </td>
                                                            </tr>
                                                            ) }

                                                            </tbody>
                                                        </table>
                                                        </CustomScrollbars>

                                                    </div>
                                                </div>
                                                    ):('')}
                                            </div>
                                        ) : ('')}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                </Modal>
                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <NavigationSandbox pathname="settlement-sandbox"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBarSandbox pathname="settlements"/>
                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="../settlement-sandbox" className="nav-link active" id="tt-tab" data-toggle="tab"
                                          role="tab"
                                          aria-controls="tt" aria-selected="true"><span
                                        className="tt">Total Settlements</span>
                                        <span className="amt">
                                           LKR 20.00</span><br/>
                                    </Link>

                                </li>
                                <li className="nav-item">
                                    <Link to="../settlement-due-sandbox" className="nav-link " id="tt-tab" data-toggle="tab"
                                          role="tab"
                                          aria-controls="tr" aria-selected="false"><span
                                        className="tt">Total Due</span>
                                        <span className="amt">
                                            LKR 20.00
                                        </span>
                                        <br/>
                                    </Link>
                                </li>
                            </ul>

                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tt" role="tabpanel"
                                     aria-labelledby="tt-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <form className="col-md-8 col-12 tt-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <label htmlFor="">Settlement ID</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Settlement ID" name="payout_reference"
                                                                   onChange={this.onChanges}
                                                                   value={this.state.payout_reference}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />
                                                        </div>

                                                       {/* <div className="form-group">
                                                            <label htmlFor="">Settlement Value</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Settlement Value" name="total_settlement"
                                                                   value={this.state.total_settlement}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />
                                                        </div>*/}
                                                        <div className="form-group">
                                                            <label htmlFor="">Bank Account</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Account Name" name="bank_name"
                                                                   onChange={this.onChanges}
                                                                   value={this.state.bank_name}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn"
                                                                  >Search
                                                            </button>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn clear-btn"
                                                                    >Clear
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form className="col-md-4 col-12 tr-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <label htmlFor="">Filters & Reports</label>
                                                            <div className="in table-datepicker">
                                                                <DateRangePicker
                                                                    onEvent={this.handleEvent}


                                                                    initialSettings={{
                                                                        startDate:moment(this.state.startDate).format('M/DD/YYYY'),
                                                                        endDate:moment(this.state.endDate).format('M/DD/YYYY'),
                                                                        maxDate: moment().format('M/DD/YYYY'),
                                                                        autoApply:true
                                                                    }}
                                                                >
                                                                    <button type="button" className="date-btn"><i
                                                                        className="far fa-calendar-alt"></i> {this.state.dayCount} Days <i
                                                                        className="fas fa-caret-down"
                                                                        aria-hidden="true"></i></button>
                                                                </DateRangePicker>
                                                            </div>
                                                        </div>
                                                        {/*<div className="form-group">*/}
                                                        {/*    <CSVLink data={this.state.csvData} style={{textDecoration: "none !important",color:"#000"}}>*/}
                                                        {/*        <i className="fas fa-download"></i>*F/}
                                                        {/*    </CSVLink>*/}
                                                        {/*</div>*/}
                                                        <div className="form-group">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="none"
                                                                                 id="settlement-download-dropdown">
                                                                    <i className="fas fa-download"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>


                                                                  {/*  <Dropdown.Item href="#/action-2"><i
                                                                        className="fas fa-file-pdf"></i> PDF Download
                                                                    </Dropdown.Item>*/}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className='rg-container'>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Settlement ID</th>
                                                        <th className=''>Total LKR Sales</th>
                                                        <th className=''>Total USD Sales</th>
                                                        <th className=''>Deduction LKR</th>
                                                        <th className=''>Deduction USD</th>
                                                        <th className=''>Refund</th>
                                                        <th className=''>Amount Settled</th>
                                                        <th className=''>Settlement Date </th>
                                                        <th className=''>Account Deposited</th>
                                                        <th className=''></th>
                                                        {/* <th className=''>Status</th>*/}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="">
                                                        <td className="" data-title="#">1</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107061421-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 24,990.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 691.73</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 24,298.27</span></td>
                                                        <td className="" data-title="Settlement Date">Jul 6, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                        <td className="" data-title="Show">
                                                            <button className="btn dot-drop" style={{cursor: "pointer"}}><i
                                                                className="fas fa-eye" aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">2</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107051553-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 81,262.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 243.70</span></td>
                                                        <td className="" data-title="Deductions">
                                                            <span>LKR 2,248.65</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 8.29</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled"><span>LKR 125,461.86</span>
                                                        </td>
                                                        <td className="" data-title="Settlement Date">Jul 5, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                        <td className="" data-title="Show">
                                                            <button className="btn dot-drop" style={{cursor: "pointer"}}><i
                                                                className="fas fa-eye" aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">3</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107021245-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 16,195.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 169.63</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 453.46</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 5.77</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 48,072.59</span></td>
                                                        <td className="" data-title="Settlement Date">Jul 2, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                        <td className="" data-title="Show">
                                                            <button className="btn dot-drop" style={{cursor: "pointer"}}><i
                                                                className="fas fa-eye" aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">4</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107012112-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 9,290.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 260.12</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 9,029.88</span></td>
                                                        <td className="" data-title="Settlement Date">Jul 1, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                        <td className="" data-title="Show">
                                                            <button className="btn dot-drop" style={{cursor: "pointer"}}><i
                                                                className="fas fa-eye" aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">5</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202106301554-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 33,785.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 945.98</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 32,839.02</span></td>
                                                        <td className="" data-title="Settlement Date">Jun 30, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                        <td className="" data-title="Show">
                                                            <button className="btn dot-drop" style={{cursor: "pointer"}}><i
                                                                className="fas fa-eye" aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">6</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202106291347-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 22,675.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 592.72</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 22,082.28</span></td>
                                                        <td className="" data-title="Settlement Date">Jun 29, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                        <td className="" data-title="Show">
                                                            <button className="btn dot-drop" style={{cursor: "pointer"}}><i
                                                                className="fas fa-eye" aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">7</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202106281502-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 63,250.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 14.48</span></td>
                                                        <td className="" data-title="Deductions">
                                                            <span>LKR 1,845.58</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.49</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 64,164.77</span></td>
                                                        <td className="" data-title="Settlement Date">Jun 28, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                        <td className="" data-title="Show">
                                                            <button className="btn dot-drop" style={{cursor: "pointer"}}><i
                                                                className="fas fa-eye" aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">8</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202106251510-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 21,140.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 591.92</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 20,548.08</span></td>
                                                        <td className="" data-title="Settlement Date">Jun 25, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                        <td className="" data-title="Show">
                                                            <button className="btn dot-drop" style={{cursor: "pointer"}}><i
                                                                className="fas fa-eye" aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">9</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202106231541-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 21,564.30</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 595.74</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 20,968.56</span></td>
                                                        <td className="" data-title="Settlement Date">Jun 23, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                        <td className="" data-title="Show">
                                                            <button className="btn dot-drop" style={{cursor: "pointer"}}><i
                                                                className="fas fa-eye" aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">10</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202106221511-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 26,390.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 119.03</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 738.92</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 4.05</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 48,337.67</span></td>
                                                        <td className="" data-title="Settlement Date">Jun 22, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                        <td className="" data-title="Show">
                                                            <button className="btn dot-drop" style={{cursor: "pointer"}}><i
                                                                className="fas fa-eye" aria-hidden="true"></i></button>
                                                        </td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel={"PREV"}
                                                    nextLabel={"NEXT"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={1}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                />
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>

                    </div>
                </div>
            </div>

        );
    }

}


