import React from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {Navigate,Link} from "react-router-dom";
import {useHistory} from "react-router";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import {myConfig} from "../config";
function TransitionUp(props) {
    return <Slide {...props} direction="up"/>;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone_number: '',
            email: '',

            redirects: false,
            loading: false,
            hidden: true,
            setOpen: false,
            openDialog: false,
            open: false,
            openprogress: false,
            setOpenProgress: false,
            errors_signup:[],


        };
        this.signup= this.signup.bind(this);
        this.onChange = this.onChange.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.makeid = this.makeid.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }

    handleValidation(){

        let errors_signup = {};
        let formIsValid = true;
        //Email
        if(this.state.name==""){
            formIsValid = false;
            errors_signup["name"] = "Cannot be empty";
        }

        if(this.state.phone_number==""){
            formIsValid = false;
            errors_signup["phone_number"] = "Cannot be empty";
        }

        if(this.state.email==""){
            formIsValid = false;
            errors_signup["email"] = "Cannot be empty";
        }

        this.setState({errors_signup: errors_signup});
        return formIsValid;
    }
    goToLogin() {
        this.props.history.push('/verification')
    }




    handleClickOpen() {
        this.setState({setOpen: true});
        this.setState({openDialog: true});
    };

    handleClose() {
        this.setState({setOpen: false});
        this.setState({openDialog: false});

    };

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    handleClick = Transition => () => {
        this.setState({open: true, Transition});
    };

    handle(Transition) {
        this.setState({open: true, Transition});
    }

    handleClose = () => {
        this.setState({open: false});
    };

    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
     makeid(length) {
        var result           = '';
        var characters       = '0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }



    toggleShow() {
        this.setState({hidden: !this.state.hidden});
    }

    signup(e) {
        if (this.handleValidation()){
        this.setState({loading: true});
        e.preventDefault();
        if (this.state.name && this.state.phone_number && this.state.email ) {
            const Cryptr = require('cryptr');
            const cryptr = new Cryptr('myTotalySecretKey');

            this.handleToggleProgress();
            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object
            let otp_time=this.makeid(6);
            const encryptedString = cryptr.encrypt(otp_time);
            //formData.append('signup_name', this.state.name);   //append the values with key, value pair
            formData.append('mobile_number', this.state.phone_number);
            formData.append('otp',otp_time );
            sessionStorage.setItem('one_time', encryptedString);
            sessionStorage.setItem('person_name', this.state.name);
           // formData.append('signup_email', this.state.email);


            const config = {
                headers: {'content-type': 'multipart/form-data'}
            };

            axios.post(BaseUrl + "signupSms", formData, config)

                .then(response => {
                    this.handleCloseProgress();
                    if (response.data.status == "Success") {
                        this.handleClickOpen();
                    } else {
                        // this.handle(TransitionUp);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        }
    }
    }


    render() {
        return (
            <div>

                <Dialog
                    className="success-message"
                    open={this.state.openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">"Signup request successfully submitted"</DialogTitle>
                    {/*<DialogContent>*/}
                    {/*    <DialogContentText id="alert-dialog-slide-description">*/}

                    {/*    </DialogContentText>*/}
                    {/*</DialogContent>*/}
                    <DialogActions>
                        <Button onClick={this.goToLogin} color="primary" className="suc-btn">
                            Got it
                        </Button>
                    </DialogActions>
                </Dialog>

                <div id="outer-container" className="container-fluid">
                    <div id="turn-box"></div>
                    <div id="inside-container" className="container">
                        <div className="row row_clr main-row">

                            <div className="col-sm-6 logo-txt">
                                <img src={require('../images/webxpay-logo.svg')} alt="logo" className="logo"/>

                                <h1>Welcome!</h1>
                                <h3>Enter your details to create an account</h3>
                              {/*  <a href="#" className="help"><i className="far fa-life-ring"></i> Need a hand figuring
                                    it out?</a>*/}
                            </div>

                            <div className="col sign-box">
                                <div className="row">
                                    <div className="col-12 sign-top">
                                        <img src={require('../images/sign-in-icon.svg')} alt="sign-in-icon"
                                             className="sign-icon"/>
                                        <h2 className="sign-head">Sign Up</h2>
                                    </div>
                                    <div className="col-12 form-type-1">

                                        <form className="row row_clr">
                                            <div className="col-12 p-0">
                                                <div className="form-group"><input type="text" className="form-control"
                                                                                   name="name" onChange={this.onChange} id="u-name" placeholder="Your Name"/>
                                                     <span className="error" style={{color: "red"}}>{this.state.errors_signup['name']}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group"><input type="text"
                                                                                           className="form-control"
                                                                                           placeholder="Phone Number"
                                                                                           name="phone_number"
                                                                                           onChange={this.onChange}/>
                                                             <span className="error" style={{color: "red"}}>{this.state.errors_signup['phone_number']}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group"><input type="text"
                                                                                           className="form-control"
                                                                                           placeholder="Email Address"
                                                                                           name="email"
                                                                                           onChange={this.onChange}
                                                        />
                                                             <span className="error" style={{color: "red"}}>{this.state.errors_signup['email']}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 no-pad submit">
                                                <input id="submit-btn" type="button" value="Send Verification Code"  onClick={this.signup}/>
                                            </div>
                                        </form>

                                    </div>
                                    <div className="col-12 no-account">
                                        <p className="sms-p">Standard SMS Rates and Charges would Apply</p>
                                        <p className="no-account-p">Have an account already?
                                             &nbsp;<Link to="../login" className="caccount">Sign
                                                In
                                                Instead!</Link></p>
                                    </div>
                                    <div className="col-12 copy-txt">
                                        <p className="cop-p">By Signing In or Registering on the system, you confirm
                                            that
                                            you agree to our <a href="#" className="green-txt">Terms of
                                                Service</a> and <a
                                                href="#" className="green-txt">Privacy Policy</a>.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Snackbar
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={this.state.Transition}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    autoHideDuration={3500}
                    message={<span id="message-id">Invalid Details</span>}
                />
            </div>
        );
    }

}