import {jsPDF} from "jspdf";
import moment from "moment";

function imgToBase64(url, callback) {
    if (!window.FileReader) {
        callback(null);
        return;
    }
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result.replace('text/xml', 'image/jpeg'));
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.send();
}




export function exportPDFDownload(title,headers,data,document_name) {

    const image=require('../images/webxlogo_png.png');
    imgToBase64(image, function(base64) {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const logo_file = ""


        doc.addFont('ComicSansMS', 'Comic Sans', 'normal');
        doc.setFont('Comic Sans');
        doc.setFontSize(9);
        doc.addImage(base64, 'JPEG', 400, 10, 100, 50);
        doc.setFont('Comic Sans');
        doc.setFontSize(9);


        let content = {
            startY: 50,
            head: headers,
            body: data,
            headStyles: { fillColor:'#77b53c' },
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable( {
            headStyles: {fillColor: '#77b53c'},
            styles: { fillColor: [218, 247, 218], },
            startY: 50,
            margin: { top: 20 },
            fontSize:6,
            font: 'courier'|'times'|'courier',
            ontStyle: 'bold',
            head: headers,
            body: data,
        });
        console.log(data)
        doc.save(document_name)

    });

}