import Modal from "react-bootstrap/Modal";
import React, {useEffect, useRef, useState} from "react";
import '../../../../css/bootstrap.min.css';
import '../../../../css/supplier_modal/master-model.css';
import '../../../../css/supplier_modal/screen-model.css';
import '../../../../css/supplier_modal/switch.css';
import Nav from 'react-bootstrap/Nav';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Badge from "react-bootstrap/Badge";
import {SupplierCreationForm} from "../../SupplierIncludes/SupplierManagement/SupplierCreationForm";
import {PostWebxLaravel} from "../../../PostCompnent";

export function SupplierCreation(props) {
    const submitRef = useRef();

    const [bankData, setBankData] = useState([]);
    const [supplierTypes, setSupplierTypes] = useState([]);

    useEffect(() => {
        PostWebxLaravel("bpsp/get-supplier-types").then(response => {
            setSupplierTypes(response.data.supplier_types)
        });

        PostWebxLaravel("bpsp/get-banks").then(response => {

            if (response.data.bank_data) {
                setBankData(response.data.bank_data)
            }
        });

    }, []);



    return <>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="activation-modal"


        >
            <Modal.Body className="modal-type-2">
                <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={props.onHide}>X</div>

                <Tab.Container className="container-fluid" defaultActiveKey="first">
                    <div className="row">
                        <div className="col-md-4 lft">
                            <h4 className="modal-title" id="activation-modal-label">CREATE NEW SUPPLIER</h4>
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                 aria-orientation="vertical">
                           
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link className="nav-link" eventKey="first"><i className="fa fa-angle-right"
                                                                                           aria-hidden="true"></i> Supplier
                                            Information </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>

                            <button type="button" className="nxt-btn" onClick={() => submitRef.current.click()}>CREATE SUPPLIER</button>

                            <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need a
                                hand
                                figuring it out?</a>
                        </div>
                        <div className="col-md-8 rht">
                            <Tab.Content className="tab-content" id="v-pills-tabContent">
                                <SupplierCreationForm submitRef={submitRef}  onReload={props.onReload} hideElement={props.onHide}
                                                      bankData={bankData}
                                                      supplierTypes={supplierTypes}
                                />
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </Modal.Body>
        </Modal>
    </>
}