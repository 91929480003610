import React, { PureComponent } from 'react';
import {NavigationSandbox} from "./NavigationSandbox";
import {TopBarSandbox} from "./TopBarSandbox";


export class DashboardSandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
        };
    }

    render() {
        return (
            <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                <div id="turn-box" />
                <div id="side-navigation" className="col">
                    <row className="row row_clr sn-row">
                        <div className="col-12 logo-outer">
                            <img src={require('../../images/webxpay-dashboard-logo.svg')} alt="logo" className="logo" />
                        </div>
                        <div className="col-12 act-acc">
                            <h3>Activate Your Account <img src={require('../../images/arrow-black.svg')} alt="arrow" className="arrow-r" /></h3>
                            <p className="ns">Next Step : <span>Choose Subscription Plan</span></p>
                            <div className="row row_clr line-outer">
                                <div className="col-12 line-comp">
                                    <div className="gl" />
                                </div>
                                <span>15% Completed</span>
                                <span>18 Days Left</span>
                            </div>
                        </div>
                       <NavigationSandbox pathname="dashboard-sandbox"/>
                        <div className="col-12 nav-user">
                            <div className="row row_clr nu-in">
                                <a href="#">
                                    <img  src={require('../../images/user.png')} alt="user" className="user" />
                                    Viraj Adhihetty
                                    <img src={require('../../images/dots.svg')} alt="" className="dots" />
                                </a>
                            </div>
                        </div>
                    </row>
                </div>
                <div id="main-content" className="row row_clr">
                   <TopBarSandbox />
                    <div className="col-12 top-account-line">
                        <div className="row row_clr">
                            <div className="tal-in tal-in-first">
                                <div className="t-icon"><i className="fas fa-check" /></div>
                                <h4>Create Your Account</h4>
                                <p>Sign Up for WEBXPAY Account to Access Your Sandbox Dashboard</p>
                            </div>
                            <div className="tal-in tal-in-middle">
                                <div className="t-icon"><i className="fas fa-power-off" /></div>
                                <h4>Activate Your Account</h4>
                                <p>Pick Your Subscription Plan and Activate Your Merchant Dashboard</p>
                                <a href="#">Activate Account <i className="fas fa-caret-right" /></a>
                            </div>
                            <div className="tal-in tal-in-last">
                                <div className="t-icon"><i className="fas fa-lock" /></div>
                                <h4>Authorize Your Account</h4>
                                <p>Submit Your Registration Documents and Start Doing Live Transactions</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mid-gate-line">
                        <div className="row row_clr">
                            <div className="col-sm-8 gate-tool">
                                <h3>Sandbox Gateway Tools <span className="rs">Hide Quick Setup <i className="fas fa-caret-down" aria-hidden="true" /></span></h3>
                                <div className="row row_clr">
                                    <div className="col-sm-3 g-tool">
                                        <div className="icon-d"><i className="fas fa-download" /></div>
                                        <h4>Downloads</h4>
                                        <p>Download the WEBXPAY Payment Gateway Plugin to easily integrate with your Web Store</p>
                                        <a href="#">Go to Downloads <i className="fas fa-caret-right" /></a>
                                    </div>
                                    <div className="col-sm-3 g-tool">
                                        <div className="icon-d"><i className="fas fa-magic" /></div>
                                        <h4>Quick Setup</h4>
                                        <p>Follow the Quick Setup Wizard to set the Payment Gateway up on your web store in less than 5 minutes!</p>
                                        <a href="#">Open Quick Setup <i className="fas fa-caret-right" /></a>
                                    </div>
                                    <div className="col-sm-3 g-tool">
                                        <div className="icon-d"><i className="fas fa-book" /></div>
                                        <h4>Documentation</h4>
                                        <p>Need some information about our gateway? Find the Knowledgebase and Documentation here!</p>
                                        <a href="#">Open Knowledgebase <i className="fas fa-caret-right" /></a>
                                    </div>
                                    <div className="col-sm-3 g-tool">
                                        <div className="icon-d"><i className="fas fa-cogs" /></div>
                                        <h4>Settings &amp; API</h4>
                                        <p>Configure your gateway settings and find your API key for setting up the gateway on your Web Store.</p>
                                        <a href="#">Go to Settings <i className="fas fa-caret-right" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 personalize-db">
                                <h3>Personalize Your Dashboard <span className="rs">Hide Quick Setup <i className="fas fa-caret-down" aria-hidden="true" /></span></h3>
                                <div className="row row_clr">
                                    <div className="col-12 no-pad">
                                        {/* Nav tabs */}
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#tab1">Your Logo</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#tab2">Your Avatar</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#tab3">System Theme</a>
                                            </li>
                                        </ul>
                                        {/* Tab panes */}
                                        <div className="tab-content">
                                            <div className="tab-pane container active" id="tab1">
                                                <div className="row row_clr">
                                                    <div className="up-img">
                                                        <div className="up-box">
                                                            <img src={require('../../images/upload.svg')} alt="" />
                                                        </div>
                                                        <p>Maximum File Size 2 MB</p>
                                                    </div>
                                                    <div className="up-cont">
                                                        <h4>Upload Your Logo</h4>
                                                        <p className="p1">Choose a square or circular image of minimum dimensions 512x512 px.</p>
                                                        <p className="p2">Drag and drop or choose a file to upload Only .jpg, .jpeg, .png &amp; .pdf file types are supported.</p>
                                                        <form className="up-form">
                                                            <label htmlFor="myFile">Choose Image <i className="fas fa-caret-right" /></label>
                                                            <input type="file" id="my-file" name="filename" />
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane container fade" id="tab2">
                                                <h4>Choose Your Avatar</h4>
                                                <ul className="user-list">
                                                    <li><img src={require('../../images/u1.svg')} alt="" /></li>
                                                    <li><img src={require('../../images/u2.svg')} alt="" /></li>
                                                    <li><img src={require('../../images/u3.svg')} alt="" /></li>
                                                    <li><img src={require('../../images/u4.svg')} alt="" /></li>
                                                    <li><img src={require('../../images/u5.svg')} alt="" /></li>
                                                    <li><img src={require('../../images/u6.svg')} alt="" /></li>
                                                    <li><img src={require('../../images/u7.svg')} alt="" /></li>
                                                    <li><img src={require('../../images/u8.svg')} alt="" /></li>
                                                    <li><img src={require('../../images/u9.svg')} alt="" /></li>
                                                </ul>
                                            </div>
                                            <div className="tab-pane container fade" id="tab3">
                                                <h4>Pick Your System Color Scheme</h4>
                                                <ul className="color-list">
                                                    <li className="orange" id="orange-btn">
                                                        <div className="ball" />Fiery<span>Orange</span>
                                                    </li>
                                                    <li className="green" id="green-btn">
                                                        <div className="ball" />Lemon<span>Green</span>
                                                    </li>
                                                    <li className="black" id="black-btn">
                                                        <div className="ball" />Midnight<span>Black</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 balance-line">
                        <div className="row row_clr">
                            <div className="lft">
                                <div className="in">
                                    <h5>Active Account Balance</h5>
                                    <h3>Rs. 0.00</h3>
                                </div>
                                <div className="in">
                                    <h5>Next Settlement Date - <span>View History</span></h5>
                                    <h3>March 10, 2020</h3>
                                </div>
                            </div>
                            <div className="rht">
                                <div className="in">
                                    <button className="date-btn"><i className="far fa-calendar-alt" /> Past 30 Days <i className="fas fa-caret-down" aria-hidden="true" /></button>
                                </div>
                                <div className="in">
                                    <h5>From</h5>
                                    <h3>Feb. 2, 2020</h3>
                                </div>
                                <div className="in">
                                    <h5>Until</h5>
                                    <h3>Mar. 1, 2020</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 transactions">
                        <div className="row row_clr">
                            <div className="col-sm-3 lft">
                                <ul className="t-list">
                                    <li className="active">
                                        <h5>Total Transactions</h5>
                                        <h3>Rs. 0.00</h3>
                                        <i className="fas fa-caret-right" aria-hidden="true" />
                                    </li>
                                    <li>
                                        <h5>Total Transactions</h5>
                                        <h3>Rs. 0.00</h3>
                                    </li>
                                    <li>
                                        <h5>Total Transactions</h5>
                                        <h3>Rs. 0.00</h3>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-9 rht img-box">
                                <img src={require('../../images/uh.png')} alt="" className="mi" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mid-gate-line w-boxes">
                        <div className="row row_clr">
                            <div className="col-sm-8 gate-tool">
                                <h3>Recent Activity: <span>Transactions <i className="fas fa-caret-down" aria-hidden="true" /></span>  <span className="rs">Hide Quick Setup <i className="fas fa-caret-down" aria-hidden="true" /></span></h3>
                                <div className="col-sm-12 rht img-box">
                                    <img src={require('../../images/uh.png')} alt="" className="mi" />

                                </div>
                            </div>
                            <div className="col-sm-4 personalize-db">
                                <h3>Payment Methods  <span className="rs">Hide Quick Setup <i className="fas fa-caret-down" aria-hidden="true" /></span></h3>
                                <div className="col-sm-12 rht img-box">
                                    <img src={require('../../images/uh.png')} alt="" className="mi" />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 footer">
                        <p>© 2020 Copyright WEBXPAY (Pvt) Ltd. · <a href="#">Merchant Agreement</a> · <a href="#">Terms of Use</a> · <a href="#">Privacy Policy</a> · Solution by <a href="#">Meridian Creative Solutions</a></p>
                    </div>
                </div>
            </div>
        );
    }

}


