import React from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import moment from 'moment';
import ReactDOM from "react-dom";

export class Verification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            otp5: '',
            otp6: '',
            timerOn: false,
            timerStart: 0,
            time_now: 0,
            errors_verification: [],
            hours: "00",
            minutes: 1,
            seconds: 0,


        };
        this.onChange = this.onChange.bind(this);
        this.verify= this.verify.bind(this);
        this.startTimer= this.startTimer.bind(this);
        this.handleValidation= this.handleValidation.bind(this);
    }
    startTimer = () => {
        this.setState({
            timerOn: true,
            time_now: this.state.time_now,
            timerStart: Date.now() - this.state.time_now
        });
        this.timer = setInterval(() => {
            this.setState({
                time_now: Date.now() - this.state.timerStart
            });
        }, 10);
       /* let interval = moment().format('HH:mm:ss: A');
        this.setState({ time_now:interval });*/

    };
    handleValidation(){

        let errors_verification = {};
        let formIsValid = true;
        //Email
        if(this.state.otp1==""){
            formIsValid = false;

            this.nextComponent1.style.background = "#e69696";
           /* errors_verification["otp1"] = "Cannot be empty";*/
        }else {
            this.nextComponent1.style.background = "#e6e6e600";
        }

        if(this.state.otp2==""){
            formIsValid = false;
            this.nextComponent2.style.background = "#e69696";
        }else {
            this.nextComponent2.style.background = "#e6e6e600";
        }

        if(this.state.otp3==""){
            formIsValid = false;
            this.nextComponent3.style.background = "#e69696";
        }else {
            this.nextComponent3.style.background = "#e6e6e600";
        }
        if(this.state.otp4==""){
            formIsValid = false;
            this.nextComponent4.style.background = "#e69696";
        }else {
            this.nextComponent4.style.background = "#e6e6e600";
        }
        if(this.state.otp5==""){
            formIsValid = false;
            this.nextComponent5.style.background = "#e69696";
        }else {
            this.nextComponent5.style.background = "#e6e6e600";
        }
        if(this.state.otp6==""){
            formIsValid = false;
            this.nextComponent6.style.background = "#e69696";
        }else {
            this.nextComponent6.style.background = "#e6e6e600";
        }

        this.setState({errors_verification: errors_verification});
        return formIsValid;
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.name=="otp1"){
            this.nextComponent2.focus();
        }
        if (e.target.name=="otp2"){
            this.nextComponent3.focus();
        }
        if (e.target.name=="otp3"){
            this.nextComponent4.focus();
        }
        if (e.target.name=="otp4"){
            this.nextComponent5.focus();
        }
        if (e.target.name=="otp5"){
            this.nextComponent6.focus();
        }
    }
    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { hours,seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    sessionStorage.removeItem("one_time");
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        hours: 0,
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
        //setInterval(this.startTimer.bind(this), 1000)
    }

    verify(e) {
        if (this.handleValidation()) {
            this.setState({loading: true});
            e.preventDefault();
            let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6;
            if (this.state.otp1 && this.state.otp2 && this.state.otp3 && this.state.otp4 && this.state.otp5 && this.state.otp6) {
                let one_time = sessionStorage.getItem("one_time");
                //this.handleToggleProgress();
                let otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4 + this.state.otp5 + this.state.otp6;
                const Cryptr = require('cryptr');
                const cryptr = new Cryptr('myTotalySecretKey');
                const encryptedString = cryptr.encrypt(otp);
                const decrypt = cryptr.decrypt(encryptedString);
                const decrypt_one_time = cryptr.decrypt(one_time);

                if (decrypt_one_time == decrypt) {
                    this.props.history.push('/dashboard')
                } else if( one_time===null) {
                    alert("OTP Expired");
                }else{
                    alert("Invalid OTP");
                }
            }
        }
    }

    render() {
        const { time_now } = this.state;
        let centiseconds = ("0" + (Math.floor(time_now / 10) % 100)).slice(-2);
        const { hours,minutes, seconds } = this.state
        let person_name=sessionStorage.getItem('person_name');
        return (
            <div id="outer-container" className="container-fluid">
                <div id="turn-box"></div>
                <div id="inside-container" className="container">
                    <div className="row row_clr main-row">

                        <div className="col-sm-6 logo-txt">
                            <img src={require('../images/webxpay-logo.svg')} alt="logo" className="logo" />

                                <h1>Welcome <span> {person_name}</span></h1>
                                <h3>Please verify your account to procced</h3>
                               {/* <h4>Didn't Receive Verification Code? <a href="sign-up.html"><i
                                    className="fas fa-sync-alt"></i> Resend Code</a></h4>*/}
                              {/*  <a href="#" className="help"><i className="far fa-life-ring"></i> Need a hand figuring
                                    it out?</a>*/}
                        </div>

                        <div className="col sign-box">
                            <div className="row">
                                <div className="col-12 sign-top">
                                    <img src={require('../images/verify-icon.svg')} alt="sign-in-icon" className="sign-icon" />
                                        <h2 className="sign-head">Verify Your Account</h2>
                                    { minutes === 0 && seconds === 0
                                        ? <p className="time-left">Time up otp not valid</p>
                                        : <p className="time-left">Time Remaining: 0{hours}:0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
                                    }
                                      {/*  <p className="time-left"> {hours} : {minutes} : {seconds}  Since Code Sent </p>*/}
                                </div>
                                <div className="col-12 form-type-1">

                                    <form className="row row_clr pin-ver-form">
                                        <div className="col-2">
                                            <div className="form-group"><input type="text" className="form-control" name="otp1" onChange={this.onChange} ref={a => this.nextComponent1=a} />
                                            </div>

                                        </div>
                                        <div className="col-2">
                                            <div className="form-group"><input type="text" className="form-control" name="otp2" onChange={this.onChange} maxlength="1" ref={b => this.nextComponent2=b}/>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group"><input type="text" className="form-control" name="otp3" onChange={this.onChange} maxlength="1" ref={c => this.nextComponent3=c}/>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group"><input type="text" className="form-control" name="otp4" onChange={this.onChange} maxlength="1" ref={d => this.nextComponent4=d}/>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group"><input type="text" className="form-control" name="otp5" onChange={this.onChange} maxlength="1" ref={e => this.nextComponent5=e}/>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group"><input type="text" className="form-control" name="otp6" onChange={this.onChange}  maxlength="1" ref={c => this.nextComponent6=c}/>
                                            </div>
                                        </div>

                                        <div className="col-12 no-pad submit">
                                            <input id="submit-btn" type="button" value="Verify Account" onClick={this.verify} />
                                               {/* <a href="sign-up.html" className="change-tel">Change Mobile number</a>*/}
                                        </div>
                                    </form>

                                </div>
                                <div className="col-12 no-account">
                                    <p className="go-back"><Link to="../sign-up"><i
                                        className="far fa-arrow-alt-circle-left"></i> Go Back to Previous Step</Link></p>
                                </div>
                                <div className="col-12 copy-txt">
                                    <p className="cop-p">By Signing In or Registering on the system, you confirm that
                                        you agree to our <a href="#" className="green-txt">Terms of Service</a> and <a
                                            href="#" className="green-txt">Privacy Policy</a>.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}