import React, {PureComponent} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DataTable from "@bit/adeoy.utils.data-table";
import {NavigationSandbox} from "../SandBox/NavigationSandbox";
import ReactPaginate from 'react-paginate';
import {jsPDF} from "jspdf";
import 'jspdf-autotable';


import {
    PieChart, Pie, Sector, Cell, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import {myConfig} from "../../config";
import {TopBar} from "../TopBar";

import Modal from "react-bootstrap/Modal";
import {CSVLink, CSVDownload} from "react-csv";
import {addZeroes, changeNumber, GetWebxLaravel, PostCompnentWebx, PostWebxLaravel} from "../PostCompnent";
import Dropdown from "react-bootstrap/Dropdown";
import {Footer} from "../Footer";
import LoadingSpinner from "../LoadingSpinner";
import {SupportButton} from "../SupportButton";
import {TopBarSandbox} from "./TopBarSandbox";


export class TransectionsSandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order_ref_number: '',
            status: '',
            transaction_value: '',
            startDate: moment().subtract(30, 'days').format('ll'),
            endDate: moment().format('ll'),
            transaction_lists: [],
            transaction_lists_render: [],
            lkr_transaction_total: 0,
            lkr_refund_total: 0,
            usd_refund_total: 0,
            usd_transaction_total: 0,
            offset: 0,
            perPage: 10,
            currentPage: 0,
            transaction_lists_filters: [],
            modalShow: false,
            setModalShow: false,
            transaction_individual_data: [],
            dayCount: 31,
            csvData: [],
            base_image: '',
            transactionModelOpen: false,
            setTransactionModelOpen: false,
            transaction_reference: '',
            refund_amount: '',
            total_amount: '',
            refund_reason: '',
            store_reference: '',
            transaction_currency: '',
            refund_processing_currency: '',
            errors_refund: [],
            pending_search: true,
            modalShowCloseConfirmation: false,
            setModalShowCloseConfimation: false,
            refund_password: '',
            refund_message: '',
            point_of_transaction:'',
            refund_reason_status:'',
            loading:false,
            startDateCsv: moment().subtract(30, 'days').format('ll'),
            endDateCsv: moment().format('ll'),
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.fetchDataFirst = this.fetchDataFirst.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.loadTable = this.loadTable.bind(this);

        
        this.onChanges = this.onChanges.bind(this);
        this.ModalCheckTrans = this.ModalCheckTrans.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.printReceipt = this.printReceipt.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
        this.imgToBase64 = this.imgToBase64.bind(this);
        this.showModalConfirmation = this.showModalConfirmation.bind(this);
        this.closeModalConfirmation = this.closeModalConfirmation.bind(this);
        this.approveTransaction = this.approveTransaction.bind(this);
        this.declineTransaction = this.declineTransaction.bind(this);
        this.openModalRefundConfirm = this.openModalRefundConfirm.bind(this);
        this.closeModalRefundConfirm = this.closeModalRefundConfirm.bind(this);
        this.findDate = this.findDate.bind(this);
        this.onChangesRefund = this.onChangesRefund.bind(this);

    }


    findDate() {
        var array = ["01-03-2021", "02-03-2021", "03-03-2021"];

        let new_date = moment('23-02-2021', 'DD-MM-YYYY').add(1, 'weeks').startOf('isoWeek').format('DD-MM-YYYY');

        for (let i = 0; i < array.length; i++) {
            var d1 = new Date(new_date);
            var d2 = new Date(array[i]);
            var same = d1.getTime() === d2.getTime();
        }
    }

    showModalConfirmation(transaction_reference, total_amount,processing_currency) {
        this.setState({
            transaction_reference: transaction_reference,
            total_amount: total_amount,
            refund_processing_currency: processing_currency,
            transactionModelOpen: true,
            setTransactionModelOpen: true,
            modalShow: false,
            setModalShow: false
        }, () => {
            var modelD = document.getElementById("anis-modal");
            modelD.classList.add("ancd-mod");
        });
    }

    closeModalConfirmation() {
        this.setState({
            transactionModelOpen: false,
            setTransactionModelOpen: false,
        });
    }

    openModalRefundConfirm() {

        this.setState({
            modalShowCloseConfirmation: true,
            setModalShowCloseConfimation: true,
        });

    }

    closeModalRefundConfirm() {
        this.setState({
            modalShowCloseConfirmation: false,
            setModalShowCloseConfimation: false,
            //modalShow: true,
           // setModalShow: true
        });
    }

    approveTransaction() {
        if (this.handleValidation()) {
            this.handleToggleProgress();
            this.setState({loading:true});
            let formData = new FormData();    //formdata object
            formData.append('order_refference_number', this.state.transaction_reference);   //append the values with key, value pair
            formData.append('refund_amount', this.state.refund_amount);
            formData.append('refund_reason_id', this.state.refund_reason);
            formData.append('refund_reason_other', this.state.refund_reason_other);
            formData.append('user_password', this.state.refund_password);
            PostCompnentWebx("requestRefundByOrderReference", formData).then(response => {
                this.handleCloseProgress();
                this.closeModalConfirmation();

                this.setState({
                    refund_amount:'',
                    loading:false,
                    refund_reason:'',
                    refund_reason_other:'',
                    refund_password:'',
                    refund_message: response.data.explaination
                }, () => {
                    this.openModalRefundConfirm();

                })

            });
        }
    }

    declineTransaction() {

    }




    handleCallback(start, end, label) {
        this.setState({
            startDate: moment(start).format('ll'),
            endDate: moment(end).format('ll'),
        });

       
    }

    fetchDataFirst(end, start) {

        this.handleToggleProgress();

        var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");
        if (end && start) {
            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');
            var a = moment(end);
            var b = moment(start);
            let days_count = a.diff(b, 'days')   // =1
            days_count=parseInt(days_count)+parseInt(1);
            formData.append('expand_view', "");   //append the values with key, value pair
            formData.append('filter_date_start', startd);
            formData.append('filter_date_end', endd);

            PostCompnentWebx("getStoreTransactions", formData).then(response => {
                this.loadCsvDataTransaction(response);

                this.setState({
                    transaction_lists: response.data,
                    transaction_lists_filters: response.data,
                    dayCount: days_count
                }, () => {
                    this.loadTable()
                });

            });

            PostCompnentWebx("getStoreTransactionAndRefundTotal", formData).then(response => {
                this.handleCloseProgress();
                this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
                this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
                this.setState({usd_refund_total: response.data.totals.usd_refund_total});
                this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
            });

        }
    }

    loadCsvDataTransaction(response){
        const csvData = [[]];

        csvData.push([
            "","","Transaction Report -",this.state.startDateCsv+'-'+this.state.endDateCsv
        ]);
        csvData.push([]);

        csvData.push(["Transaction Date","Store Order ID","Customer First Name", "Customer Last Name", "Transaction ID","Processing Currency","Order Amount","Convenience Fee / Discount", "Transaction Amount", "Gateway Discount", "Transaction Net",/* "Settlement Currency", "Settlement Amount",*/"Payment Gateway","Card Type","Payment Status"]);
        csvData.push([]);

        response.data.map((value, index) => {
            let payment_status="";
            if(value.payment_status_id==2){
                payment_status="Approved";
            }else if (value.payment_status_id==3){
                payment_status="Declined";
            }
            csvData.push([moment(value.date_added).format('ll'), value.store_reference, value.first_name, value.last_name, value.order_refference_number,value.processing_currency_id == 5 ? "LKR" : "USD", value.order_amount,value.convenience_fee, value.total_amount, value.discount_amount,value.transaction_net, /* value.payment_currency_id == 5 ? "LKR" : "USD", value.payment_amount,*/value.payment_gateway,value.card_type,payment_status]);
        });


        this.setState({
            csvData: csvData
        });
    }

    loadTable() {
        const list_data = this.state.transaction_lists_filters;
        const slice = list_data.slice(this.state.offset, this.state.offset + this.state.perPage);
        const trans = list_data && list_data.length ? (slice.map(list => list.payment_status_id !== 1 ? (
                <tr className=''>
                    <td className=''
                        data-title='Transaction ID'>{list.order_id}
                    </td>
                    <td className=''
                        data-title='Transaction ID'>{list.order_refference_number}
                    </td>
                    <td className=''
                        data-title='Store Reference'>{list.store_reference}
                    </td>

                    {/* <td className=''
                 data-title='Transaction ID'>{list.order_refference_number}
             </td>*/}
                    <td className='' data-title='Transaction Value'>
                        <NumberFormat
                            value={(Math.round(list.total_amount * 100) / 100).toFixed(2)}
                            displayType={'text'} thousandSeparator={true}
                            prefix={list.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
                    </td>
                    <td>
                        {
                            (() => {
                                let default_size="35px";
                                let max_size="63px";
                                if (list.payment_gateway=="FriMi (LKR)" ||list.payment_gateway=="Genie Visa Master (LKR)"||list.payment_gateway=="UPay (LKR)"){
                                    default_size="35px";

                                }else {
                                    default_size=max_size;
                                }

                                if (list.card_type.toLowerCase()=="visa")
                                    return  <img style={{width:"35px"}} src={myConfig.laravelWeb+'gateway_logos/visa.jpg'} alt=""/>
                                if (list.card_type.toLowerCase()=="master"||list.card_type.toLowerCase()=="mastercard" )
                                    return  <img style={{width:"35px"}} src={myConfig.laravelWeb+'gateway_logos/master.jpg'} alt=""/>
                                else
                                return  <img style={{width:default_size}} src={myConfig.laravelWeb+'gateway_logos/'+list.payment_gateway_id+'.jpg'} alt=""/>

                            })()
                        }
                    </td>

                    <td className='' data-title='Date of Transaction'>{moment(list.date_added).format('ll')}
                    </td>
                    <td className='pop-td' data-toggle="popover"
                        data-trigger="hover" data-placement="auto"
                        data-content="Bank was Unable to Authenticate Transaction">
                        {list.payment_status_id == 2 ? (<span className="dot green"><i className="fas fa-circle"
                                                                                       aria-hidden="true"></i></span>) : ''} {list.payment_status_id == 3 ? (
                        <span className="dot red"><i className="fas fa-circle"
                                                     aria-hidden="true"></i></span>) : ''} {list.payment_status_id == 1 ? "" : ''}{list.payment_status_id == 2 ? "Approved" : ''}{list.payment_status_id == 3 ? "Declined" : ''}
                    </td>
                    <td className='' data-title='View'>
                        <button className="dot-drop btn"
                                onClick={() => this.ModalCheckTrans(list.order_refference_number)} >
                            <i className="fas fa-eye"></i></button>
                    </td>
                </tr>
            ) : (this.state.pending_search == true ? (
                    <tr className=''>
                        <td className='' data-title='#'>{list.order_id}</td>
                        <td className=''
                            data-title='Transaction ID'>{list.order_refference_number}
                        </td>
                        {/* <td className=''
                        data-title='Transaction ID'>{list.order_refference_number}
                    </td>*/}
                        <td className='' data-title='Transaction Value'>
                            <NumberFormat
                                value={(Math.round(list.total_amount * 100) / 100).toFixed(2)}
                                displayType={'text'} thousandSeparator={true}
                                prefix={list.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
                        </td>
                        <td className='' data-title='Date of Transaction'>{moment(list.date_added).format('ll')}
                        </td>
                        <td className='pop-td' data-toggle="popover"
                            data-trigger="hover" data-placement="auto"
                            data-content="Bank was Unable to Authenticate Transaction">

                                                                 {list.payment_status_id == 2 ? (<span className="dot green"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                                                                {list.payment_status_id == 3 ? (<span className="dot red"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                                                                {list.payment_status_id == 2 ? "Approved" : ''}
                                                                {list.payment_status_id == 3 ? "Declined" : ''}

                        </td>
                        <td className='' data-title='View'>
                            <button
                                onClick={() => this.ModalCheckTrans(list.order_refference_number)}>
                                <i className="fas fa-eye"></i></button>
                        </td>
                    </tr>
                ) : (
                    <tr className=''>
                        <td colSpan="6" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
                    </tr>
                )
            )
        )) : (
            <tr className=''>
                <td colSpan="8" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
            </tr>

        );
        this.setState({
            pageCount: Math.ceil(list_data.length / this.state.perPage),
            transaction_lists_render: trans
        })
    }


    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadTable()
        });

    };

    componentWillMount() {
        this.findDate();
        if (sessionStorage.getItem("userToken") || localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");

            this.setState({userToken: userToken});

            this.fetchDataFirst(this.state.endDate, this.state.startDate);


        } else {
            this.setState({redirect: true});
        }
    }

    modalClose() {
        this.setState({
            modalShow: false,
            setModalShow: false
        })
    }

    printReceipt() {
        var content = document.getElementById("divcontents");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    ModalCheckTrans(transaction_id) {


        let formData = new FormData();    //formdata objec

        formData.append('order_refference_number', transaction_id);   //append the values with key, value pair

        this.handleToggleProgress();

        GetWebxLaravel("common_list/get_point_of_transaction/"+transaction_id).then(response=>{
           let data_type=response.data.type;
           let point_of_transaction='';
            if (data_type===0){
                point_of_transaction="X GATEWAY";
            }else if(data_type===1){
                point_of_transaction="X DIRECT"
            }else{
                point_of_transaction="X SELL";
            }
            this.setState({
                    point_of_transaction:point_of_transaction
                });
        });

        PostCompnentWebx("getTransactionData", formData).then(response => {
            this.handleCloseProgress();
            this.setState({
                transaction_individual_data: response.data,
                modalShow: true,
                setModalShow: true
            });

        });

    }

    handleValidation() {
        let errors_refund = {};
        let formIsValid = true;
        //Email
        if (this.state.refund_amount == "") {
            formIsValid = false;
            errors_refund["refund_amount"] = "Cannot be empty";
        }
        if (this.state.refund_reason == "" ) {
            formIsValid = false;
            errors_refund["refund_reason"] = "Cannot be empty";
        }
        if (this.state.refund_reason == 5  ) {
            if (this.state.refund_reason_other == ""){
            formIsValid = false;
            errors_refund["refund_reason_other"] = "Cannot be empty";
        }
        }
        if (this.state.refund_password == "") {
            formIsValid = false;
            errors_refund["refund_password"] = "Cannot be empty";
        }

        this.setState({errors_refund: errors_refund});
        return formIsValid;
    }
    onChangesRefund(e){
        let value= e.target.value;
        this.setState({
            refund_reason_other:''
        })
     if(e.target.name =="refund_reason"){
            var index = e.nativeEvent.target.selectedIndex;
            if (value=="5"){
                var other_id = document.getElementById("other-id");
                other_id.classList.add("act");
                this.setState({
                    //refund_reason_other: '',
                    [e.target.name]: e.target.value
                });
            }else{
                var other_id = document.getElementById("other-id");
                other_id.classList.remove("act");
                this.setState({
                    refund_reason_other: e.nativeEvent.target[index].text,
                   [e.target.name]: e.target.value
                });
            }
        }
        if(e.target.name =="refund_reason_other") {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }
    onChanges(e) {

        if (e.target.name == "refund_amount") {

            let new_val = changeNumber(e.target.value);

            this.setState({[e.target.name]: new_val});

            if (parseFloat(this.state.total_amount) < parseFloat(e.target.value)) {
                this.setState({
                    [e.target.name]: this.state.total_amount
                });
            }
        }else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }


    }

    imgToBase64(url, callback) {
        if (!window.FileReader) {
            callback(null);
            return;
        }
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result.replace('text/xml', 'image/jpeg'));
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.send();
    }

    exportPDF = () => {
        let transections = this.state.transaction_lists_filters;
        const image = require('../../images/webxlogo_png.png');
        this.imgToBase64(image, function (base64) {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
            const logo_file = ""
            doc.setFontSize(15);


            doc.addImage(base64, 'JPEG', 450, 10, 100, 50);
            const title = "TRANSACTION REPORT";
            const headers = [["TRANSACTION ID", "TRANSACTION VALUE", "DATE OF TRANSACTION", "STATUS"]];

            const data = transections.map(value => [value.order_refference_number, value.total_amount, moment(value.date_added).format("ll"), value.order_status]);

            let content = {
                startY: 50,
                head: headers,
                body: data,
                headStyles: {fillColor: '#77b53c'},
            };

            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("Transactions.pdf")

        });

    }

    render() {
        const transaction_lists = this.state.transaction_lists_render;
        const transaction_individual_data = this.state.transaction_individual_data;

        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }
        return (
            <div>
                <SupportButton/>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Modal
                    show={this.state.transactionModelOpen}
                    onHide={this.state.setTransactionModelOpen}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anis-modal"
                    className="refund-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div>

                            <h1>Are you sure do you want to refund the amount?</h1>

                            <p><strong>Refund Reference No :</strong>  T8989898989898</p>
                            <p className="lst"><strong>Amount :</strong><NumberFormat
                                value="10.00"
                                displayType={'text'} thousandSeparator={true} prefix="LKR" /></p>
                            <p><strong>Enter Amount to Refund</strong></p>
                            <div className="form-group">
                                <input type="text" className="form-control" value="10.00"
                                       name="refund_amount" placeholder="Enter Refund Amount" id=""
                                       onChange={this.onChanges}/>
                                <span className="error" style={{color: "red"}}>{this.state.errors_refund["refund_amount"]}</span>
                            </div>
                            <p><strong>Refund Reason</strong></p>
                          {/*  <div className="form-group">
                                <input type="text" className="form-control" value={this.state.refund_reason}
                                       name="refund_reason" placeholder="Enter Refund Reason"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_refund["refund_reason"]}</span>
                            </div>*/}
                            <div className="form-group" >
                                <select id="refund_reason" className="form-control" value={this.state.refund_reason}
                                        name="refund_reason" placeholder="Enter Refund Reason" onChange={this.onChangesRefund}>
                                    <option value="">Select Reason</option>
                                    <option value="1">Customer Request</option>
                                    <option value="2">Customer Cancelled</option>
                                    <option value="3">Product Damage</option>
                                    <option value="4">Late Delivery</option>
                                    <option value="5">Other</option>
                                </select>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_refund["refund_reason"]}</span>

                            </div>
                            <div className="form-group" id="other-id">
                                <input type="text" className="form-control" value={this.state.refund_reason_other}
                                       name="refund_reason_other" placeholder="Enter Other Reason"
                                       onChange={this.onChangesRefund}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_refund["refund_reason_other"]}</span>
                            </div>

                            <p><strong>Enter Login Password</strong></p>
                            <div className="form-group">
                                <input type="password" className="form-control" value={this.state.refund_password}
                                       name="refund_password" placeholder="Enter Login Password"
                                       onChange={this.onChanges}/>
                                <span className="error"
                                      style={{color: "red"}}>{this.state.errors_refund["refund_password"]}</span>
                            </div>


                            <button className="btn btn-danger" onClick={this.closeModalConfirmation}>Cancel</button>
                            {this.state.loading ? <LoadingSpinner /> :<button className="btn btn-success" >Submit</button>}
                        </div>
                    </Modal.Body>

                </Modal>
                <Modal
                    show={this.state.modalShow}
                    onHide={this.state.setModalShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="activation-modal"
                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            <div className="colse-mdl" data-dismiss="modal" aria-label="Close"
                                 onClick={this.modalClose}>X
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 lft">
                                    <h3 className="tdh">Transaction Details</h3>

                                    <div className="col-12 tdm-in">

                                        <h6>Transaction Amount</h6>
                                        <h3 className="tdh"><NumberFormat
                                            value={(Math.round(transaction_individual_data.total_amount * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true}
                                            prefix={transaction_individual_data.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
                                        </h3>

                                        <h6>Date of Transaction</h6>
                                        <h5 className="sh">{moment(transaction_individual_data.date_time_transaction).format("ll")}</h5>

                                        <h6>Transaction ID</h6>
                                        <h5 className="sh">{transaction_individual_data.order_refference_number}</h5>
                                        <h6>Store Reference</h6>
                                        <h5 className="sh">{transaction_individual_data.gateway_order_id}</h5>
                                        <h6>Clearance Status</h6>
                                        <h5 className="sh">
                                            {transaction_individual_data.clearance_status == "Settlement Pending" ? (
                                                <span className="dot yellow"><i className="fas fa-circle"
                                                                                aria-hidden="true"></i></span>) : ('')}
                                            {transaction_individual_data.clearance_status == "Settled" ? (
                                                <span className="dot green"><i className="fas fa-circle"
                                                                               aria-hidden="true"></i></span>) : ('')} {transaction_individual_data.clearance_status=="No Settlement"?(<><span style={{color:"red"}}>Payment Declined</span> < p className = "sp" style={{fontSize:12,fontWeight: 600,color:"#dc5937",padding: "10px 0 0 0"}} > {transaction_individual_data.response_text}</p></>):transaction_individual_data.clearance_status}
                                        </h5>
                                        <h6>Payment Gateway</h6>
                                        <h5 className="sh">{transaction_individual_data.payment_gateway}</h5>
                                        <h6>Point of Transaction</h6>
                                        <h5 className="sh">{this.state.point_of_transaction}</h5>

                                        {transaction_individual_data.card_type=="Visa" || transaction_individual_data.card_type=="Master"||transaction_individual_data.card_type=="Mastercard"?(<><h6>Card Type</h6><h5 className="sh">{transaction_individual_data.card_type}</h5></>):('')}
                                        {/*   <h6>Notes</h6>
                                        <h5 className="sh">REF 12323/1223</h5>*/}

                                    </div>

                                    {/*<a href={myConfig.laravelWeb + "reports/print_transaction/" + transaction_individual_data.order_refference_number + '/' + sessionStorage.getItem("userToken") || localStorage.getItem("userToken")}
                                           type="button" className="nxt-btn anb" target="_blank">Print Receipt</a>*/}
                                    {transaction_individual_data.payment_status_id == 2 ? (
                                        transaction_individual_data.order_status_id==17 || transaction_individual_data.order_status_id==11?(''):(<button type="button" className="ext-btn ext2"
                                                                                                                                                         onClick={() => this.showModalConfirmation(transaction_individual_data.order_refference_number, transaction_individual_data.total_amount,transaction_individual_data.processing_currency_id)}>Refund
                                            </button>)
                                    ) : ('')}
                                        { transaction_individual_data.order_status_id==17?(<button type="button" className="ext-btn ext2" disabled style={{backgroundColor:"#cccccc",color:"#000000",borderColor:"#000000"}}>Refund Requested</button>):''}
                                        { transaction_individual_data.order_status_id==11?<button type="button" className="ext-btn ext2" disabled style={{backgroundColor:"#cccccc",color:"#000000",borderColor:"#000000"}}>Refunded</button>:''}
                                 {/*   <a href="#" className="help"><i className="far fa-life-ring"
                                                                    aria-hidden="true"></i> Need a
                                        hand figuring it out?</a>*/}
                                </div>
                                    <div className="col-md-8 rht">

                                        <h3 className="tdh">Transaction Timeline</h3>

                                        <div className="row">
                                            <div className="col-sm-3 col-12 tt-icns">
                                                <div className="icon-box">
                                                    <img src={require('../../images/transactions/money.svg')} alt="money"/>
                                                </div>
                                                <p>Payment Initiated</p>
                                                <p className="sp">{moment(transaction_individual_data.date_time_transaction).format('ll')}</p>
                                            </div>

                                            {transaction_individual_data.clearance_status_id !== "33" ? (
                                                <React.Fragment>

                                                    <div className="col-sm-3 col-12 tt-icns">
                                                        <div className="icon-box">
                                                            <img src={require('../../images/transactions/invoice.svg')}
                                                                 alt="money"/>
                                                        </div>
                                                        <p>Payment Successful</p>
                                                        <p className="sp">{moment(transaction_individual_data.date_time_settlement).format('ll')}</p>
                                                    </div>
                                                    {transaction_individual_data.clearance_status_id == "22"  ?
                                                        (
                                                            <div className="col-sm-3 col-12 tt-icns">
                                                                <div className="icon-box">
                                                                    <img
                                                                        src={require('../../images/transactions/bank.svg')}
                                                                        alt="money"/>
                                                                </div>
                                                                <p>Settlement Completed</p>
                                                                <p className="sp">{moment(transaction_individual_data.settlement_date).format('ll')}</p>
                                                            </div>

                                                        ) : (
                                                            <>
                                                                <div className="col-sm-3 col-12 tt-icns">
                                                                    <div className="icon-box half-filled">
                                                                        <img
                                                                            src={require('../../images/transactions/time-is-money.svg')}
                                                                            alt="money"/>
                                                                    </div>
                                                                    <p>{transaction_individual_data.clearance_status}</p>
                                                                    {transaction_individual_data.settlement_date?transaction_individual_data.settlement_date!==""?
                                                                        ( <p className="sp">Your Funds will be credited to your
                                                                            account on <span>{transaction_individual_data.settlement_date!==""?moment(transaction_individual_data.settlement_date).format('ll'):''}</span>
                                                                        </p>):(''):('')}

                                                                </div>
                                                            </>
                                                        )}
                                                </React.Fragment>
                                            ) : (
                                                <div className="col-sm-3 col-12 tt-icns">
                                                    <div className="icon-box">
                                                        <img
                                                            src={require('../../images/transactions/payment_declined.svg')}
                                                            alt="money"/>
                                                    </div>
                                                    <p>Payment Declined</p>
                                                    <p className="sp">Sorry the payment has been declined</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-12 details-box">
                                                <div className="col-12 dbx-in">
                                                    <h3>Payee Details</h3>

                                                    <h6>Payee Name</h6>
                                                    <h5 className="sh">{transaction_individual_data.first_name} {transaction_individual_data.last_name}</h5>


                                                    <h6>Payee Telephone</h6>
                                                    <h5 className="sh">{transaction_individual_data.contact_number}</h5>

                                                    <h6>Payee Address</h6>
                                                    <h5 className="sh">{transaction_individual_data.address_line_one}, {transaction_individual_data.address_line_two=="null"?"":transaction_individual_data.address_line_two+', '} {transaction_individual_data.city}</h5>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-12 details-box">
                                                <div className="col-12 dbx-in">
                                                    <h3>Payment Breakdown</h3>

                                                    <h6>Total Amount</h6>
                                                    <h5 className="sh">
                                                        <NumberFormat
                                                            value={(Math.round(transaction_individual_data.total_amount * 100) / 100).toFixed(2)}
                                                            displayType={'text'} thousandSeparator={true}
                                                            prefix={transaction_individual_data.processing_currency_id == 5 ? 'LKR ' : 'USD '}/>
                                                    </h5>


                                                    <h6>WEBXPAY Charges</h6>
                                                    <h5 className="sh">
                                                        <NumberFormat
                                                            value={(Math.round(transaction_individual_data.discount_amount * 100) / 100).toFixed(2)}
                                                            displayType={'text'} thousandSeparator={true}
                                                            prefix={transaction_individual_data.processing_currency_id == 5 ? '- LKR ' : '- USD '}/>({transaction_individual_data.payment_gateway_rate} %)
                                                    </h5>


                                                    <h6>Settlement Amount</h6>
                                                    <h5 className="sh">
                                                        <NumberFormat
                                                            value={(Math.round(transaction_individual_data.payment_amount * 100) / 100).toFixed(2)}
                                                            displayType={'text'} thousandSeparator={true}
                                                            prefix={transaction_individual_data.payment_currency_id == 5 ? 'LKR ' : 'USD '}/>
                                                    </h5>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>

                <Modal
                    show={this.state.modalShowCloseConfirmation}
                    onHide={this.state.setModalShowCloseConfimation}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="info-modal"
                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            {/* <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>*/}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 lft">
                                        <h5 className="in-h">{this.state.refund_message}</h5>
                                        <button className="btn btn-danger close-btn"
                                                onClick={this.closeModalRefundConfirm}>Close
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>

                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <NavigationSandbox pathname="transactions-sandbox"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBarSandbox pathname="transactions"/>

                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="../transactions-sandbox" className="nav-link active" id="tt-tab" data-toggle="tab"
                                          role="tab"
                                          aria-controls="tt" aria-selected="true"><span
                                        className="tt">Total Transactions</span>
                                        <span
                                            className="amt">LKR 100.00</span>
                                       <span
                                                className="sep"> |</span> <span
                                                className="amt">USD 10.00</span>


                                    </Link>

                                </li>
                                <li className="nav-item">
                                    <Link to="../refund-sandbox" className="nav-link" id="tt-tab" data-toggle="tab"
                                          role="tab"
                                          aria-controls="tr" aria-selected="false"><span
                                        className="tt">Total Refunds</span>
                                        <span
                                            className="amt">LKR 10.00</span>
                                       <span className="sep"> | </span>
                                                <span
                                                    className="amt">USD 10.00</span>
                                    </Link>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tt" role="tabpanel"
                                     aria-labelledby="tt-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <form className="col-md-8 col-12 tt-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <label htmlFor="">Transaction ID</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Transaction ID" name="order_ref_number"
                                                                   value={this.state.order_ref_number}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {

                                                                       }
                                                                   }}
                                                            />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="">Store Reference</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Store Reference" name="store_reference"
                                                                   value={this.state.store_reference}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {

                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Status</label>
                                                            <div className="slct">
                                                                <select name="status" id="c1" className="form-control"
                                                                        value={this.state.status}
                                                                        onChange={this.onChanges}>
                                                                    <option value="">Select Status</option>
                                                                    <option value="2">Approved</option>
                                                                    <option value="3">Declined</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Transaction Amount</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Transaction Amount" name="transaction_value"
                                                                   value={this.state.transaction_value}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {

                                                                       }
                                                                   }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Transaction Currency</label>
                                                            <div className="slct">
                                                            <select name="transaction_currency" id="c1" className="form-control"
                                                                    value={this.state.transaction_currency}
                                                                    onChange={this.onChanges}>
                                                                <option value="">Select Currency</option>
                                                                <option value="5">LKR</option>
                                                                <option value="2">USD</option>
                                                            </select>
                                                            </div>
                                                           {/* <input type="text" className="form-control"
                                                                   placeholder="Enter Value" name="transaction_value"
                                                                   value={this.state.transaction_value}
                                                                   onChange={this.onChanges}
                                                                   onKeyPress={event => {
                                                                       if (event.key === 'Enter') {
                                                                           this.filterList(event)
                                                                       }
                                                                   }}
                                                            />*/}
                                                        </div>
                                                        {/* <div className="form-group">
                                                            <label htmlFor="">Notes</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Any Special Notes" />
                                                        </div>*/}
                                                        <div className="form-group" >
                                                            <button type="button" className="srch-btn"
                                                                    style={{marginTop: "15px"}}>Search
                                                            </button>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn clear-btn"
                                                                    onClick={this.clearSearch} style={{marginTop: "15px"}}>Clear
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form className="col-md-4 col-12 tr-form">
                                                    <div className="row row_clr">

                                                        <div className="form-group">
                                                            <label htmlFor="">Filters & Reports</label>
                                                            <div className="in table-datepicker">
                                                                <DateRangePicker
                                                                    onEvent={this.handleEvent}
                                                                    onCallback={this.handleCallback}
                                                                    initialSettings={{
                                                                        startDate:moment(this.state.startDate).format('M/DD/YYYY'),
                                                                        endDate:moment(this.state.endDate).format('M/DD/YYYY'),
                                                                        maxDate: moment().format('M/DD/YYYY'),
                                                                        autoApply:true
                                                                    }}

                                                                >
                                                                    <button type="button" className="date-btn"><i
                                                                        className="far fa-calendar-alt"></i> {this.state.dayCount} Days <i
                                                                        className="fas fa-caret-down"
                                                                        aria-hidden="true"></i></button>
                                                                </DateRangePicker>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">

                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="none"
                                                                                 id="settlement-download-dropdown">
                                                                    <i className="fas fa-download"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {/*<CSVLink data={this.state.csvData} style={{
                                                                        textDecoration: "none !important",
                                                                        color: "#000"
                                                                    }} className="dropdown-item" filename={"TransactionReport.csv"}>
                                                                        <i className="fas fa-download"></i> CSV Download
                                                                    </CSVLink>*/}
                                                                   {/* <button type="button"
                                                                            onClick={() => this.exportPDF()}
                                                                            className="dropdown-item"><i
                                                                        className="fas fa-file-pdf"></i>Generate Report
                                                                    </button>*/}

                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>


                                                        {/*   <button className="dwn-btn"><i
                                                                className="fas fa-download"></i></button>*/}

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className='rg-container'>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Transaction ID</th>
                                                        <th className=''>Store Reference</th>
                                                        <th className=''>Transaction Amount</th>
                                                        <th className=''>Payment Type</th>
                                                        <th className=''>Date of Transaction</th>
                                                        <th className=''>Payment Status</th>
                                                        <th className=''></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="">
                                                        <td className="" data-title="Transaction ID">797484</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7974842021I051656
                                                        </td>
                                                        <td className="" data-title="Store Reference">66</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td><img src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                                 alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span> Declined
                                                        </td>
                                                        <td className="" data-title="View">
                                                            <button className="dot-drop btn"><i className="fas fa-eye"
                                                                                                aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="Transaction ID">797476</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7974762021I051655
                                                        </td>
                                                        <td className="" data-title="Store Reference">65</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 18.00</span></td>
                                                        <td><img src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                                 alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot green"><i className="fas fa-circle"
                                                                                           aria-hidden="true"></i></span> Approved
                                                        </td>
                                                        <td className="" data-title="View">
                                                            <button className="dot-drop btn"><i className="fas fa-eye"
                                                                                                aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="Transaction ID">797091</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7970912021I051654
                                                        </td>
                                                        <td className="" data-title="Store Reference">52521</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td><img src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                                 alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span> Declined
                                                        </td>
                                                        <td className="" data-title="View">
                                                            <button className="dot-drop btn"><i className="fas fa-eye"
                                                                                                aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="Transaction ID">797061</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7970612021I051652
                                                        </td>
                                                        <td className="" data-title="Store Reference">52521</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td><img src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                                 alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span> Declined
                                                        </td>
                                                        <td className="" data-title="View">
                                                            <button className="dot-drop btn"><i className="fas fa-eye"
                                                                                                aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="Transaction ID">797058</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7970582021I051651
                                                        </td>
                                                        <td className="" data-title="Store Reference">52521</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td><img src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                                 alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span> Declined
                                                        </td>
                                                        <td className="" data-title="View">
                                                            <button className="dot-drop btn"><i className="fas fa-eye"
                                                                                                aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="Transaction ID">790520</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7905202021I021650
                                                        </td>
                                                        <td className="" data-title="Store Reference">4913</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 100.00</span></td>
                                                        <td><img src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                                 alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 2, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span> Declined
                                                        </td>
                                                        <td className="" data-title="View">
                                                            <button className="dot-drop btn"><i className="fas fa-eye"
                                                                                                aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="Transaction ID">790507</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7905072021I021649
                                                        </td>
                                                        <td className="" data-title="Store Reference">4911</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 300.00</span></td>
                                                        <td><img src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                                 alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 2, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span> Declined
                                                        </td>
                                                        <td className="" data-title="View">
                                                            <button className="dot-drop btn"><i className="fas fa-eye"
                                                                                                aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="Transaction ID">784145</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7841452021I291647
                                                        </td>
                                                        <td className="" data-title="Store Reference">906</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 18.00</span></td>
                                                        <td><img src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                                 alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jun 29, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot green"><i className="fas fa-circle"
                                                                                           aria-hidden="true"></i></span> Approved
                                                        </td>
                                                        <td className="" data-title="View">
                                                            <button className="dot-drop btn"><i className="fas fa-eye"
                                                                                                aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="Transaction ID">784144</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7841442021I291646
                                                        </td>
                                                        <td className="" data-title="Store Reference">905</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td><img
                                                            src="https://api.webxapi.online/gateway_logos/master.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jun 29, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span> Declined
                                                        </td>
                                                        <td className="" data-title="View">
                                                            <button className="dot-drop btn"><i className="fas fa-eye"
                                                                                                aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="Transaction ID">784140</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7841402021I291645
                                                        </td>
                                                        <td className="" data-title="Store Reference">903</td>
                                                        <td className="" data-title="Transaction Value"><span>LKR 1,500.00</span>
                                                        </td>
                                                        <td><img src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                                 alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jun 29, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span> Declined
                                                        </td>
                                                        <td className="" data-title="View">
                                                            <button className="dot-drop btn"><i className="fas fa-eye"
                                                                                                aria-hidden="true"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={1}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                            </div>

                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>

                        <Footer/>

                    </div>
                </div>
            </div>

        );
    }

}


