import React, { PureComponent } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DataTable from "@bit/adeoy.utils.data-table";
import {Navigation} from "./Navigation";

import {
    PieChart, Pie, Sector, Cell, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,Legend
} from 'recharts';
import {myConfig} from "../config";
import {TopBar} from "./TopBar";
import ReactPaginate from "react-paginate";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import {GetWebxLaravel, PostCompnentWebx, PostWebxLaravel} from "./PostCompnent";
import {Footer} from "./Footer";
import {SupportButton} from "./SupportButton";

export class SettingsCurrency extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bank_name: '',
            settlement_status: '',
            exchange_rate: 0,
            currency_code: 0,
            current_date: '',
            current_time: '',
            markup_currency_id: '',
            markup_amount: 0,
            currency_type: '',
        };
        this.onChanges = this.onChanges.bind(this);
        this.handleCloseProgress=this.handleCloseProgress.bind(this);
        this.handleToggleProgress=this.handleToggleProgress.bind(this);
        this.getExchangeRate=this.getExchangeRate.bind(this);
        this.saveExchageRate=this.saveExchageRate.bind(this);
        this.getMarkupAmount=this.getMarkupAmount.bind(this);

    }
    saveExchageRate(){
        this.handleToggleProgress();
        let formData = new FormData();    //formdata object
        formData.append('merchant_id',sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"));
        formData.append('currency_type_id',2);
        formData.append('exchange_rate',this.state.exchange_rate);
        formData.append('markup_amount',this.state.markup_amount);
        this.handleToggleProgress();
        PostWebxLaravel("wbx_exchange/create_exchange",formData).then(response=>{
            this.handleCloseProgress();
        });
    }
    getExchangeRate(){
        this.handleToggleProgress();
        let formData = new FormData();    //formdata object

        PostCompnentWebx("getExchangeRate",formData).then(response=>{
            this.handleCloseProgress();
            this.setState({
                exchange_rate: response.data[0].exchange_rate,
                currency_code: response.data[0].currency_code,
                current_date:moment().format('MMMM Do, YYYY ').toString(),
                current_time:moment().format('h:mm:ss A').toString()
            });
        });
    }
    getMarkupAmount(){
        this.handleToggleProgress();
        GetWebxLaravel("wbx_exchange/get_exchange_rate/"+sessionStorage.getItem('merchant_id')).then(response=>{
            if (response){

                this.handleCloseProgress();
                this.setState({
                    markup_amount:response.data.markup_amount
                });
            }
        });
    }
    onChanges(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    }

    componentWillMount() {
        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {
            this.getExchangeRate();
            this.getMarkupAmount();
            setInterval( this.getExchangeRate.bind(this), 600000);
            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");

            this.setState({userToken: userToken});
        } else {
            this.setState({redirect: true});
        }
    }


    render() {

        const settlement_lists_render=this.state.settlement_lists_render;

        if (this.state.redirect) {
            return <Navigate to='/login'  />
        }
        return (
            <div>
                <SupportButton/>
                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <Navigation pathname="settings-general"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="reports-metrics"/>
                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <Link className="nav-link" id="tt-tab" to="../settings-general"><span
                                        className="tt">Business Configuration</span><span className="amt">General</span></Link>
                                </li>
                              {/*  <li className="nav-item">
                                    <Link className="nav-link active" id="tr-tab" to="../settings-currency"><span className="tt">Currency & Exchange Rates</span><span
                                        className="amt">Currency</span></Link>
                                </li>*/}
                                <li className="nav-item">
                                    <Link className="nav-link" id="tp-tab" to="../invoice-settings"><span className="tt">Invoices, Payment Links & Taxes</span><span
                                        className="amt">Invoices</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" id="tp-tab" to="../invoices"><span className="tt">Billing & Settlement</span><span
                                        className="amt">Financials</span></Link>
                                </li>
                            </ul>


                            <div className="row row_clr settings-outer">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="one">

                                    <div className="col-12 rht-sd">
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="to" role="tabpanel"
                                                 aria-labelledby="tr-tab">
                                                <div className="row row_clr">
                                                    <div className="col-12">
                                                        <h3 className="tdh">Currency Management</h3>
                                                        <h4 className="sth mb">Accept Payments in USD?</h4>
                                                        <p className="mp">Switch on to enable USD payments. Please note
                                                            that if you have not already subscribed to this service, you
                                                            will occur a one-time fee of LKR 5,000.00 to enable this
                                                            service. Please also note that enabling this service does
                                                            not mean that you would be able to hold currency in USD in
                                                            your account. While you will be able to accept USD payments,
                                                            they will be converted to LKR when realizing into your
                                                            account.</p>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="cbx model-11">
                                                            <div className="checkbox">
                                                                <input type="checkbox"/>
                                                                <label></label>
                                                            </div>
                                                            <span className="txt">Enable USD Transactions</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <h4 className="sth mb">Exchange Rates</h4>
                                                        <p className="mp">Currency Exchange rates are updated every <b>10
                                                            Minutes</b> as per the Central Bank of Sri Lanka SPOT
                                                            Currency Exchange Rate. Last Update <b>{this.state.current_date} at {this.state.current_time} (IST)</b></p>
                                                    </div>
                                                    {/*<div className="col-sm-12">
                                                        <h6 className="sh">USD Exchange Rate</h6>
                                                        <h5 className="mh">
                                                            <NumberFormat
                                                                value={(Math.round(this.state.exchange_rate * 100) / 100).toFixed(2)}
                                                                displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
                                                                <button type="button"
                                                                                              className="icn tp" onClick={this.getExchangeRate}><i
                                                            className="fas fa-sync-alt" aria-hidden="true"></i></button>
                                                        </h5>
                                                    </div>*/}

                                                    <div className="col-sm-12">
                                                        <h4 className="sth mb">Exchange Rate Markup</h4>
                                                        <div className="cbx model-11 mt-3">
                                                            <div className="checkbox">
                                                                <input type="checkbox"/>
                                                                <label></label>
                                                            </div>
                                                            <span className="txt">Add Markup to Exchange Rate</span>
                                                        </div>
                                                    </div>

                                                   {/* <div className="col-md-3 col-sm-12 set-fi">
                                                        <h6 className="sh">Value Calculator</h6>
                                                        <div className="form-group">
                                                            <select name="" id="" className="form-control">
                                                                <option value="">Percentage (%)</option>
                                                            </select>
                                                        </div>
                                                    </div>*/}


                                                    <div className="col-md-8 col-sm-12 set-fi">
                                                        <h6 className="sh">Markup Amount</h6>
                                                        <div className="form-group">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control"
                                                                       placeholder="Enter Markup Amount" name="markup_amount" id="markup_amount" value={this.state.markup_amount} onChange={this.onChanges}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-12 set-fi">
                                                        <h6 className="sh">Markup Currency</h6>
                                                        <div className="form-group">
                                                            <select name="currency_type" id="currency_type" className="form-control" onChange={this.onChanges}>
                                                                <option value="2">USD</option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className="col-12">
                                                        <button type="button" className="nxt-btn" onClick={this.saveExchageRate}>Save Exchange Rate
                                                            Markup &nbsp;&nbsp;<i className="fas fa-save"
                                                                                  aria-hidden="true"></i></button>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="tr" role="tabpanel"
                                                 aria-labelledby="tr-tab">
                                                <div className="row row_clr">
                                                    <div className="col-12">
                                                        <h3 className="tdh">Registration Information</h3>
                                                        <h4 className="sth">Registration Information</h4>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <h6 className="sh">Business Registration Number</h6>
                                                        <h5 className="mh">PV-99998</h5>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <h6 className="sh">Date of Registration</h6>
                                                        <h5 className="mh">September 18, 2009</h5>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <h6 className="sh">Business Registration Certificate</h6>
                                                        <h5 className="mh">Meridian_Creative_Services_BR_Scan</h5>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <h6 className="sh">Form 01 Document</h6>
                                                        <h5 className="mh">Meridian_Creative_Services_F1_Scan</h5>
                                                    </div>
                                                    <div className="col-12">
                                                        <h4 className="sth">Director/Shareholder Information</h4>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="rg-container">
                                                            <table className="transactions-table" summary="Hed">
                                                                <thead>
                                                                <tr>
                                                                    <th className="">#</th>
                                                                    <th className="">Shareholder Name</th>
                                                                    <th className="">Designation</th>
                                                                    <th className="">Share %</th>
                                                                    <th className="">NIC Number</th>
                                                                    <th className="">Political Affiliations</th>
                                                                    <th className=""></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                <tr className="">
                                                                    <td className="" data-title="#">001</td>
                                                                    <td className=""
                                                                        data-title="Shareholder Name">Sebastian Vettel
                                                                    </td>
                                                                    <td className="" data-title="Designation">Chief
                                                                        Executive Officer
                                                                    </td>
                                                                    <td className="" data-title="Share %">75.00</td>
                                                                    <td className=""
                                                                        data-title="NIC Number">923732883v
                                                                    </td>
                                                                    <td className=""
                                                                        data-title="Political Affiliations">None
                                                                    </td>
                                                                    <td className="" data-title="">
                                                                        <div className="dropdown">
                                                                            <button
                                                                                className="btn dropdown-toggle dot-drop"
                                                                                type="button" id="dropdownMenuButton"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false">
                                                                                ...
                                                                            </button>
                                                                            <div className="dropdown-menu table-drop"
                                                                                 aria-labelledby="dropdownMenuButton">
                                                                                <button className="dropdown-item"><i
                                                                                    className="fas fa-pencil-alt"
                                                                                    aria-hidden="true"></i></button>
                                                                                <button className="dropdown-item"><i
                                                                                    className="fas fa-trash-alt"
                                                                                    aria-hidden="true"></i></button>

                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>


                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="th" role="tabpanel"
                                                 aria-labelledby="tr-tab">
                                                <div className="row row_clr">
                                                    <div className="col-12">
                                                        <h3 className="tdh">User Management</h3>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <div className="rg-container">
                                                            <table className="transactions-table" summary="Hed">
                                                                <thead>
                                                                <tr>
                                                                    <th className="">#</th>
                                                                    <th className="">User Name</th>
                                                                    <th className="">User Role</th>
                                                                    <th className="">Email Address</th>
                                                                    <th className="">Status</th>
                                                                    <th className="" style={{textAlign: "center"}}><i
                                                                        className="fas fa-plus" aria-hidden="true"
                                                                        style={{cursor: "pointer"}}  data-toggle="modal"
                                                                        data-target="#add-new-customer-modal"></i></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>


                                                                <tr className="">
                                                                    <td className="" data-title="#">001</td>
                                                                    <td className="" data-title="User Name">Sebastian
                                                                        Vettel
                                                                    </td>
                                                                    <td className="" data-title="User Role">Owner</td>
                                                                    <td className=""
                                                                        data-title="Email Address">viraj@wearemeridian.com
                                                                    </td>
                                                                    <td className="pop-td" data-title="Status"><span
                                                                        className="dot yellow"><i
                                                                        className="fas fa-circle"
                                                                        aria-hidden="true"></i></span> Settlement
                                                                        Pending
                                                                    </td>
                                                                    <td className="" data-title="">
                                                                        <div className="dropdown">
                                                                            <button
                                                                                className="btn dropdown-toggle dot-drop"
                                                                                type="button" id="dropdownMenuButton"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false">
                                                                                ...
                                                                            </button>
                                                                            <div className="dropdown-menu table-drop"
                                                                                 aria-labelledby="dropdownMenuButton">
                                                                                <button className="dropdown-item"
                                                                                        data-toggle="modal"
                                                                                        data-target="#edit-customer-modal">
                                                                                    <i className="fas fa-pencil-alt"
                                                                                       aria-hidden="true"></i></button>
                                                                                <button className="dropdown-item"><i
                                                                                    className="fas fa-trash-alt"
                                                                                    aria-hidden="true"></i></button>

                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>


                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>

                        </div>

                        <Footer />
                    </div>
                </div>
            </div>

        );
    }

}


