import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import NumberFormat from "react-number-format";
import moment from "moment";

export function LastTransactions(props) {
  const zeroPad = (num, places) => String(num).padStart(places, '0')
  return (
    <>
      <div className="top-fx-section">
        <div className="lft">
          <h3 className="title">Last 5 Transactions</h3>
        </div>
        <div className="rht">
          <span className="date">
            <span className="ot">
              {/* <DropdownButton
                title="By Trx. Date"
                className="filter-dropdown-toggle"
              >
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </DropdownButton> */}
            </span>
          </span>
        </div>
      </div>

      <table className="transactions-table" summary="Hed">
        <thead>
          <tr>

            <th className="">Transaction Ref</th>
            <th className="">Amount </th>
            <th className="">Status</th>
            <th className="">Date of Transaction</th>

          </tr>
        </thead>
        <tbody>
        {props.supplierTransactions && props.supplierTransactions.length
            ? props.supplierTransactions.map((supplierTransaction, key) => {
              if(key<5)
              return (
                  <tr className="">

                    <td className="" data-title="#">

                      {supplierTransaction.order_refference_number}
                    </td>
                    <td className="" data-title="#">
                      <NumberFormat
                          value={(Math.round(supplierTransaction.transaction_amount * 100) / 100).toFixed(2)}
                          displayType={'text'} thousandSeparator={true}
                          prefix='LKR '/>
                    </td>
                    <td className="" data-title="#">
                      {supplierTransaction.supplier_settlement_status ==
                      2 ? (
                          <>
                            {supplierTransaction.payment_status_id ==
                            2 ?( <>
                              <span className="dot green">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                              Settled
                            </>):('')}
                          </>
                      ) : (
                          ""
                      )}
                      {supplierTransaction.supplier_settlement_status ==
                      3 ? (
                          <>
                              <span className="dot red">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            Settlement Declined
                          </>
                      ) : (
                          ""
                      )}
                      {supplierTransaction.supplier_settlement_status ==
                      1 ? (
                          <>

                              {supplierTransaction.payment_status_id ==
                              1 ?( <>
                              <span className="dot yellow">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                                Payment Pending
                              </>):('')}
                              {supplierTransaction.payment_status_id ==
                              2 ?( <>
                              <span className="dot green">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                                Payment Approved
                              </>):('')}
                              {supplierTransaction.payment_status_id ==
                              3 ?( <>
                              <span className="dot red">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                                Payment Declined
                              </>):('')}

                          </>
                      ) : (
                          ""
                      )}
                    </td>
                    <td className="" data-title="#">
                      {moment(supplierTransaction.date_added).format("ll")}
                    </td>

                  </tr>
              );
            })
            : ""}
        </tbody>
      </table>
    </>
  );
}
