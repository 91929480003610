import React, { PureComponent } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {Navigation} from "./Navigation";
import {myConfig} from "../config";
import {TopBar} from "./TopBar";
import Modal from "react-bootstrap/Modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ReactPaginate from "react-paginate";
import {GetWebxLaravel, PostWebxLaravel} from "./PostCompnent";
import {CSVLink} from "react-csv";
import {Footer} from "./Footer";
import Dropdown from "react-bootstrap/Dropdown";


export class CustomerGroups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShowCustomerGroup:false,
            setModalShowCustomerGroupEdit:false,
            customers_list:[],
            customer_group:[],
            customer_group_list:[],
            group_id:'',
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageCount: 0,
            customer_group_list_filters:[],
            customer_group_list_render:[],
            customer_group_name:'',
            description:'',
            actionModal:this.saveGroup,
            customer_group_save_text:'Processing...',
            csvData:[],
            errors_cus_group:[],
        };

        this.onChanges = this.onChanges.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.addRemoveActive = this.addRemoveActive.bind(this);
        this.showModalCustomerGroup = this.showModalCustomerGroup.bind(this);
        this.showModalCustomerGroupEdit = this.showModalCustomerGroupEdit.bind(this);
        this.closeModalCustomerGroup = this.closeModalCustomerGroup.bind(this);
        this.onChangesAuto = this.onChangesAuto.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.saveGroup = this.saveGroup.bind(this);
        this.editGroup = this.editGroup.bind(this);
        this.getCustomerGroup = this.getCustomerGroup.bind(this);
        this.getCustomerDetail = this.getCustomerDetail.bind(this);
        this.loadTable = this.loadTable.bind(this);
        this.filterList = this.filterList.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.validateCustomerGroup = this.validateCustomerGroup.bind(this);
    }

    validateCustomerGroup(){
        let errors_cus = {};
        let formIsValid = true;
        //Email

        if(this.state.customer_group_name==""){
            formIsValid = false;
            errors_cus["customer_group_name"] = "Cannot be empty";
        }
        if(this.state.description==""){
            formIsValid = false;
            errors_cus["description"] = "Cannot be empty";
        }

        if(this.state.customer_group==""){
            formIsValid = false;
            errors_cus["customer_group"] = "Cannot be empty";
        }

        this.setState({errors_cus_group: errors_cus});
        return formIsValid;
    }

    filterList(){
        let keyword='';
        if(this.state.keyword){
             keyword = this.state.keyword.toLowerCase();
        }

        let formData = new FormData();    //formdata object
        formData.append('keyword', keyword);   //append the values with key, value pair
        formData.append('merchant_id', sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"));   //append the values with key, value pair


        this.handleToggleProgress();
        PostWebxLaravel("customer_group/get_group_with_filters", formData).then(response => {
            this.setState({
                customer_group_list:response.data,
                customer_group_list_filters:response.data,
            },()=>{
                this.loadTable();
            });
        });
    }
    clearSearch(){
        GetWebxLaravel( "customer_group/get_groups/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            this.handleCloseProgress();
            this.setState({
                customer_group_list:response.data,
                customer_group_list_filters:response.data,
                keyword:'',
            },()=>{
                this.loadTable();
            });
        });
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadTable()
        });

    };

    loadTable(){

        const zeroPad = (num, places) => String(num).padStart(places, '0')
        const list_data=this.state.customer_group_list_filters;
        const slice = list_data.slice(this.state.offset, this.state.offset + this.state.perPage);

        const trans=  list_data && list_data.length?(slice.map((list, key) =>
                {
                    return (
                        <tr className='' data-toggle="modal" data-target="#modal-2">
                            <td className='' data-title='#'>{zeroPad(key+1, 3)}</td>
                            <td className='' data-title='Group Name'>{list.customer_group}</td>
                            <td className='cus-names' data-title='Customer Names'>
                                {list.get_items && list.get_items?(
                                    list.get_items.map((new_list, key) =>
                                    {
                                        return (


                                            <p>
                                                {new_list.has_many_customer && new_list.has_many_customer?(

                                                    <React.Fragment>{new_list.has_many_customer.customer_first_name+' '+new_list.has_many_customer.customer_last_name}, </React.Fragment>


                                                 /*   new_list.has_many_customer.map((lists, key) =>
                                                    {
                                                        return (
                                                            <React.Fragment>{lists.customer_first_name+' '+lists.customer_last_name}, </React.Fragment>
                                                        )
                                                    })*/
                                                ):(
                                                    ''

                                                )
                                                }
                                            </p>


                                        )
                                    })
                                ):(
                                    ''
                                )
                                }
                            </td>
                            <td className='pop-td'>
                                {list.is_active==0?(
                                    <p>
                                                                       <span className="dot yellow"><i
                                                                           className="fas fa-circle"
                                                                           aria-hidden="true"></i></span>In Active</p>
                                ):(
                                    <p><span className="dot green"><i
                                        className="fas fa-circle"
                                        aria-hidden="true"></i></span>Active</p>
                                )


                                }
                            </td>
                            <td className='' data-title=''>
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle dot-drop"
                                            type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false" onClick={()=>this.showModalCustomerGroupEdit(list.id)}>
                                        ...
                                    </button>
                                    <div className="dropdown-menu table-drop"
                                         aria-labelledby="dropdownMenuButton">
                                        <button className="dropdown-item"><i
                                            className="fas fa-power-off"></i></button>
                                        <button className="dropdown-item"><i
                                            className="fas fa-pencil-alt"></i></button>
                                        <button className="dropdown-item"><i
                                            className="fas fa-share"></i></button>
                                        <button className="dropdown-item"><i
                                            className="fas fa-trash-alt"></i></button>

                                    </div>
                                </div>
                            </td>
                        </tr>

                    )
                })
            ):(
                ''
            )



        this.setState({
            pageCount: Math.ceil(list_data.length / this.state.perPage),
            customer_group_list_render:trans
        })
    }


    saveGroup(){
        if (this.validateCustomerGroup()){
            let formData = new FormData();    //formdata object
            let customer_group_name=this.state.customer_group_name;
            let description=this.state.description;

            formData.append('customer_group', customer_group_name);   //append the values with key, value pair
            formData.append('description', description);
            formData.append('is_active', 1);
            formData.append('customers_array', JSON.stringify(this.state.customer_group));   //append the values with key, value pair
            formData.append('merchant_id', sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"));   //append the values with key, value pair
            this.handleToggleProgress();
            PostWebxLaravel( "customer_group/create_group",formData).then(response=>{
                this.handleCloseProgress();
                if (response.status==200){
                    this.closeModalCustomerGroup();
                    this.getCustomerGroup();

                }
            });
        }
    }

    editGroup(){
        if (this.validateCustomerGroup()) {
            let formData = new FormData();    //formdata object

            let customer_group_name = this.state.customer_group_name;
            let description = this.state.description;

            formData.append('customer_group', customer_group_name);   //append the values with key, value pair
            formData.append('description', description);
            formData.append('is_active', 1);
            formData.append('customers_array', JSON.stringify(this.state.customer_group));   //append the values with key, value pair
            formData.append('_method', 'put');
            this.handleToggleProgress();
            PostWebxLaravel("customer_group/update_group/" + this.state.group_id, formData).then(response => {
                this.handleCloseProgress();
                if (response.status == 200) {
                    this.closeModalCustomerGroup();
                    this.getCustomerGroup();

                }
            });

        }
    }

    removeItem(index) {
        let customer_data = JSON.parse(JSON.stringify(this.state.customer_group));
        //make changes to ingredients
        customer_data.splice(index, 1);
        this.setState({
            customer_group: customer_data
        })
    }
    onChangesAuto(customer_name,customer_id){
        if(this.state.customer_group.findIndex(x =>  x.customer_id==customer_id)===-1 ) {
            this.setState(prevState => ({
                customer_group: [...prevState.customer_group, {
                    customer_name: customer_name,
                    customer_id: customer_id,
                }],
            }));
        }
    }
    getCustomerDetail(){
        this.handleToggleProgress();
        GetWebxLaravel( "customers/get_customers/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            this.handleCloseProgress();
            this.setState({
                customers_list:response.data,
            });
        });
    }
    showModalCustomerGroup(){

        this.setState(prevState => ({
            customer_group: [],
        }));
        this.setState({
          //  manageCustomerGroups:addCustomerGroups,
            actionModal:this.saveGroup,
            customer_group_save_text:'Save Customer Group',
            modalShowCustomerGroup: true,
            setModalShowCustomerGroupEdit: true,
            customer_group_name:'',
            description:''
        },() => {
            var modelD = document.getElementById("anis-modal");
            modelD.classList.add("ancd-mod");
        });
    }

    showModalCustomerGroupEdit(id){
        this.setState(prevState => ({
            customer_group: [],
        }));
        this.handleToggleProgress();
        GetWebxLaravel("customer_group/get_single_group/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+id).then(response=>{
            this.handleCloseProgress();
            response.data.get_items.forEach((item) => {
                this.setState(prevState => ({
                    customer_group: [...prevState.customer_group, {
                        customer_name: item.has_many_customer.customer_first_name+' '+item.has_many_customer.customer_last_name,
                        customer_id: item.has_many_customer.id,
                    }],
                }));

            });
            this.setState({
                actionModal:this.editGroup,
                customer_group_save_text:'Update Group',
                customer_group_name:response.data.customer_group,
                description:response.data.description,
                group_id:response.data.id,
            });
        });

        this.setState({
            //  manageCustomerGroups:addCustomerGroups,
            modalShowCustomerGroup: true,
            setModalShowCustomerGroupEdit: true,
        },() => {
            var modelD = document.getElementById("anis-modal");
            modelD.classList.add("ancd-mod");
        });
    }

    getCustomerGroup(){
        this.handleToggleProgress();
        GetWebxLaravel( "customer_group/get_groups/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            this.handleCloseProgress();
            const csvData = [
                ["Group Name", "Customer Names", "Status"]
            ];
            response.data.map((list, index) => {

                let customer_list=[];
                if (list.get_items) {
                    list.get_items.map((new_list, key) => {
                        let customer_list_one=[];
                        if (new_list.has_many_customer) {
                            customer_list_one.push(new_list.has_many_customer.customer_first_name + ' ' + new_list.has_many_customer.customer_last_name)
                          /*  new_list.has_many_customer.map((lists, key) => {
                                customer_list_one.push(lists.customer_first_name + ' ' + lists.customer_last_name)
                            });*/
                        }
                        customer_list.push(customer_list_one)
                    });

                }


                csvData.push([list.customer_group,customer_list,list.is_active==1?"Active":"Inactive"]);

            });

            this.setState({
                csvData:csvData,
                customer_group_list:response.data,
                customer_group_list_filters:response.data,

            },()=>{
                this.loadTable();
            });
        });

    }

    closeModalCustomerGroup(){
        this.setState({
            modalShowCustomerGroup: false,
            setModalShowCustomerGroupEdit: false,
        });
    }


    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };


    componentWillMount() {

        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");

            this.setState({userToken: userToken});

            this.getCustomerGroup();
            this.getCustomerDetail();

        } else {
            this.setState({redirect: true});
        }
    }

    addRemoveActive(){
        var plusButton = document.getElementById("plus-button");
        var plusMenu = document.getElementById("plus-menu");
        if (plusMenu.classList.contains('active')) {
            plusMenu.classList.remove("active");
            plusButton.classList.remove("active");
        } else {
            plusMenu.classList.add("active");
            plusButton.classList.add("active");
        }
        var myElement = document.getElementsByClassName('l-item');
        for (var i = 0; i < myElement.length; i++) {
            myElement[i].onclick = function() {
                var plusButton = document.getElementById("plus-button");
                var plusMenu = document.getElementById("plus-menu");
                plusMenu.classList.remove("active");
                plusButton.classList.remove("active");
            }
        }
    }



    render() {
        const customer_group_count=this.state.customer_group_list.length;
        const customers_list=this.state.customers_list;
        const customer_group=this.state.customer_group;
        const customer_group_list=this.state.customer_group_list_render;
        const zeroPad = (num, places) => String(num).padStart(places, '0')

        return (
           <React.Fragment>

               <div id="plus-button" onClick={this.addRemoveActive}>
                   <i className="fas fa-plus"></i>
               </div>

               <Modal
                   show={this.state.modalShowCustomerGroup}
                   onHide={this.state.setModalShowCustomerGroupEdit}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   id="anis-modal"
               >
                   <Modal.Body className="modal-type-2">
                       <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.closeModalCustomerGroup}>X</div>
                       <div className="container-fluid">
                           <div className="row">
                               <div className="col-12 cd-frm">
                                   <h3 className="tdh">Add New Group</h3>

                                   <div className="col-12 tdm-in p-0">




                                       <h6>Group Name</h6>
                                       <div className="form-group">
                                           <input type="text" className="form-control" value={this.state.customer_group_name} name="customer_group_name" placeholder="Enter Group Name" onChange={this.onChanges}/>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus_group["customer_group_name"]}</span>
                                       </div>

                                       <h6>Description (Optional)</h6>
                                       <div className="form-group">
                                           <input type="text" className="form-control" value={this.state.description} name="description" placeholder="Enter Description" onChange={this.onChanges}/>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus_group["description"]}</span>
                                       </div>

                                       <Autocomplete

                                           options={customers_list}
                                           getOptionLabel={(customers_list) => customers_list.customer_first_name+' '+customers_list.customer_last_name}
                                           renderInput={(params) => <TextField {...params}
                                                                               label={"Search Members" }
                                                                               margin="normal"
                                           />}
                                           freeSolo
                                           className="select-item-invoice select-type2 customer-2"
                                           //onInputChange={this.onChangesAuto}
                                           inputValue=""
                                           onChange={
                                               (event, newValue) =>
                                           {
                                               if (newValue) {
                                                   this.onChangesAuto(newValue.customer_first_name+' '+newValue.customer_last_name,newValue.id)
                                               }
                                           }}
                                       />

                                       <div className="rg-container">
                                           <table className="transactions-table invoice-table iinvt" summary="Hed" >
                                           <thead>

                                                       <tr>
                                                           <th className="">#</th>
                                                           <th className="">Customer Name</th>
                                                           <th className=""></th>
                                                       </tr>


                                           </thead>
                                           <tbody>
                                           {customer_group && customer_group.length?(
                                               customer_group.map((list, key) =>
                                           {
                                               return (
                                               <tr className="">
                                               <td className="" data-title="#">{zeroPad(key+1, 3)}</td>
                                               <td className="" data-title="">{list.customer_name}</td>
                                               <td className="" data-title=""><i className="fas fa-trash-alt"
                                               aria-hidden="true" onClick={()=>this.removeItem(key)}></i></td>

                                               </tr>

                                               )
                                           })
                                               ):(
                                               ''

                                               )
                                           }
                                           </tbody>
                                       </table>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus_group["customer_group"]}</span>


                                   </div>

                                   <button type="button" className="nxt-btn" onClick={this.state.actionModal}>{this.state.customer_group_save_text} &nbsp;&nbsp;<i
                                       className="fas fa-save" ></i></button>
                                   {/*<a href="#" className="help"><i className="far fa-life-ring"
                                                                   aria-hidden="true"></i> Need Help with the
                                       System?</a>*/}
                               </div>
                           </div>
                       </div>
                       </div>
                   </Modal.Body>

               </Modal>

               <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                   <div id="plus-menu">
                       <h4><i className="fas fa-plus"></i> Create New</h4>
                       <div className="pm-in">
                           <ul>
                               <li data-toggle="modal" data-target="#add-new-group-modal" className="l-item" onClick={this.showModalCustomerGroup}>
                                   <div className="icn"><i className="fas fa-socks"></i></div>
                                   <p className="tp">Create New Group</p>
                                   <p className="bp">Create easy to access product bundles</p>
                               </li>
                           </ul>
                       </div>
                   </div>


                   <div id="turn-box"></div>
                   <Navigation pathname="customers"/>
                   <div id="main-content" className="row row_clr dashboard-live-mode">
                       <TopBar pathname="customers"/>
                       <div className="col-12 transaction-top">
                       <ul className="nav nav-tabs" role="tablist">
                           <li className="nav-item">
                               <Link className="nav-link " id="tt-tab" data-toggle="tab" to={"/customer"} role="tab"
                                  aria-controls="tt" aria-selected="true"><span className="tt">All Customers</span><span
                                   className="amt">{customers_list.length} Customers</span></Link>
                           </li>
                           <li className="nav-item">
                               <Link className="nav-link active" id="tr-tab" data-toggle="tab" to={"/customer-groups"} role="tab"
                                  aria-controls="tr" aria-selected="false"><span
                                   className="tt">Customer Groups</span><span className="amt">{customer_group_count}</span></Link>
                           </li>
                       </ul>
                            <div className="tab-content">
                           <div className="tab-pane fade  show active" id="tr" role="tabpanel" aria-labelledby="tr-tab">
                               <div className="row row_clr">
                                   <div className="col-12">
                                       <div className="row row_clr tt-ttp bcab" style={{alignItems: "flex-end"}}>
                                           <form className="col-md-8 col-12 tt-form">
                                               <div className="row row_clr">
                                                   <div className="form-group w50">
                                                       <label htmlFor="">Keywords</label>
                                                       <input type="text" className="form-control"
                                                              placeholder="Enter Keyword(s)" name="keyword" onChange={this.onChanges}/>
                                                   </div>
                                                   <div className="form-group">
                                                       <button className="srch-btn" onClick={this.filterList} type="button">Search</button>

                                                   </div>
                                                   <div className="form-group">

                                                       <button className="srch-btn clear-btn" type="button" onClick={this.clearSearch}>Clear</button>
                                                   </div>
                                               </div>
                                           </form>
                                           <form className="col-md-4 col-12 tr-form">
                                               <div className="row row_clr">
                                                   <div className="form-group">

                                                       <Dropdown>
                                                           <Dropdown.Toggle variant="none" id="cusg-download-dropdown">
                                                               <i className="fas fa-download"></i>
                                                           </Dropdown.Toggle>
                                                           <Dropdown.Menu>
                                                               <CSVLink  className="dropdown-item" data={this.state.csvData} style={{textDecoration: "none !important",color:"#000"}} filename={"CustomerGroups.csv"}>
                                                                   <i className="fas fa-download"></i> Download CSV
                                                               </CSVLink>
                                                              {/* <button type="button" className="dropdown-item"><i
                                                                   className="fas fa-file-pdf"></i>Generate Report</button>*/}

                                                           </Dropdown.Menu>
                                                       </Dropdown>
                                                   </div>
                                               </div>
                                           </form>
                                       </div>
                                   </div>

                                   <div className="col-12">
                                       <div className='rg-container'>
                                           <table className='transactions-table' summary='Hed'>
                                               <thead>
                                               <tr>
                                                   <th className=''>#</th>
                                                   <th className=''>Group Name</th>
                                                   <th className=''>Customer Names</th>
                                                   <th className=''>Status</th>
                                                   <th className=''></th>
                                               </tr>
                                               </thead>

                                               <tbody>
                                               {customer_group_list}
                                               </tbody>
                                           </table>
                                           <ReactPaginate
                                               previousLabel={"PREV"}
                                               nextLabel={"NEXT"}
                                               breakLabel={"..."}
                                               breakClassName={"break-me"}
                                               pageCount={this.state.pageCount}
                                               marginPagesDisplayed={2}
                                               pageRangeDisplayed={5}
                                               onPageChange={this.handlePageClick}
                                               containerClassName={"pagination"}
                                               subContainerClassName={"pages pagination"}
                                               activeClassName={"active"}/>
                                       </div>
                                       </div>

                                   </div>


                               </div>
                           </div>
                            </div>
                       </div>

                        <Footer />
                   </div>
           </React.Fragment>
        );
    }

}


