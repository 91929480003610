import React from 'react';
import { Link } from 'react-router-dom';

import TopBarCollapse  from  '../TopBarCollapse'
import {PostCompnentWebx} from "../PostCompnent";
import Collapse from "react-bootstrap/Collapse";
import {myConfig} from "../../config";
import {decode} from "html-entities";

export class TopBarSandbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
            greeting:'',
            doing_business_name:'',
            open:false,
            collapse_status:null
        };

        this.timerSetup = this.timerSetup.bind(this);
        this.getUser = this.getUser.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.setClose = this.setClose.bind(this);
        this.getCustomerData = this.getCustomerData.bind(this);
    }
    getCustomerData(userToken){
        let BaseUrl = myConfig.apiUrl;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization':'Bearer'+' '+userToken
            }
        };
        let formData=new FormData();
        PostCompnentWebx("getStoreData",formData).then(response=>{
            sessionStorage.setItem("doing_business_name",response.data[0].doing_business_name);
            sessionStorage.setItem("merchant_number",response.data[0].merchant_number);

            this.setState({
                doing_business_name:response.data[0].doing_business_name
            })
        });

    }
    setOpen(set_open_status){
        if (localStorage.getItem("collapsed")==null) {
            if (set_open_status == true) {
                this.setState({
                    open: false,
                    collapse_status:true
                });
                localStorage.setItem("collapsed", this.state.collapse_status);
            } else {
                this.setState({
                    open: true
                });
            }
        }
    }
    setClose(){

    }
    componentDidMount() {
        this.timerSetup();
        this.getUser();
        this.setOpen();

        if (sessionStorage.getItem("doing_business_name")==null){
            this.getCustomerData(sessionStorage.getItem("userToken"));
        }else if (typeof sessionStorage.getItem("doing_business_name")=="undefined"){
            this.getCustomerData(sessionStorage.getItem("userToken"));
        }
    }
    getUser(){
        this.setState({
            doing_business_name:sessionStorage.getItem("doing_business_name")
        })
    }

    timerSetup(){
        var data = [
               /* [0, 4, "Good Night"],*/
                [0, 11, "Good Morning"],          //Store messages in an array
                [12, 16, "Good Afternoon"],
                [16, 24, "Good Evening"]
            ],
            hr = new Date().getHours();

        for(var i = 0; i < data.length; i++){
            if(hr >= data[i][0] && hr <= data[i][1]){
                this.setState({greeting:data[i][2]})
            }
        }
    }
    render(){

        return (
            <React.Fragment>


                <div className="col-12 top-line">
                    <p> <a href="#"><i className="fas fa-sync-alt" aria-hidden="true" style={{color:"#ffffff"}}/> </a></p>
                    <div className="notification-bell">
                        <img src={require('../../images/notification.png')} alt="" />
                    </div>
                    <div className="sandbox sandbox-mode">
                        <div className="l-circle" /> Sandbox Mode
                    </div>
                </div>
        {/*<div className="col-12 notification-bar collapse show" id="collapseExample">*/}
        {/*    <div className="row row_clr nb-in">*/}
        {/*        <p>This Account is now Live. Please replace <span className="underlined">Sandbox API with Live API</span> to*/}
        {/*            start live payment transactions.</p>*/}
        {/*        <button className="colps-btn" type="button" data-toggle="collapse"*/}
        {/*                data-target="#collapseExample" aria-expanded="false"*/}
        {/*                aria-controls="collapseExample">Collapse Notification <i*/}
        {/*            className="fas fa-times"></i></button>*/}
        {/*    </div>*/}
        {/*</div>*/}
               {/* <Collapse className="col-12 notification-bar" in={this.state.open}>
                    <div id="example-collapse-text">
                        <div className="row row_clr nb-in">
                            <p>This Account is now Live. Please replace <span className="underlined">Sandbox API with Live API</span> to
                                start live payment transactions.</p>
                            <button className="colps-btn" onClick={() => this.setOpen(this.state.open)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={this.state.open}>Collapse Notification <i
                                className="fas fa-times"></i></button>
                        </div>
                    </div>
                </Collapse>*/}
                </React.Fragment>
        );
    }
}