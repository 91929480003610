import Tab from "react-bootstrap/Tab";
import React, {useRef, useState} from "react";
import { ViewSupplierTransactionReceipt } from "./ViewSupplierTransactionReceipt";
import { useEffect } from "react";
import {GetWebxLaravel, PostCompnentWebxBpsp, PostWebxLaravel} from "../../../PostCompnent";
import moment from "moment";
import NumberFormat from "react-number-format";
import ReactPaginate from "react-paginate";

export function CreateNewTransaction() {
  const [modalShow, setModalShow] = React.useState(false);


  return (
    <>
      <div onClick={() => setModalShow(true)}>
        <div style={{ color: "#e6562fe6" }}>
          <strong> + Add New Transaction </strong>
        </div>
      </div>

      <ViewSupplierTransactionReceipt
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}


export function AllSupplierTransactions(props) {
    const [modalShow, setModalShow] = React.useState(false);
  const [supplierTransactions, setSupplierTransactions] = useState([]);
    const [invoiceIDView, setinvoiceIDView] = React.useState("");
    const [BpspOrderId, setBpspOrderId] = React.useState("");
    const [invoiceDetail, setInvoiceDetail] = useState([]);
    const [invoiceLineItems, setInvoiceLineItems] = useState([]);
    const [invoiceReference, setInvoiceReference] = useState("");
    const clickIndividualInvoice = useRef();

    const [offsetTransactions, setOffsetTransactions] = React.useState(0);
    const [perPageTransactions, setPerPageTransactions] = React.useState(10);
    const [currentPageTransactions, setCurrentPageTransactions] = React.useState(0);
    const [pageCountTransactions, setPageCountTransactions] = React.useState(0);
    const [transaction_lists_render, setTransactionListRender] = React.useState([]);


    useEffect(() => {
      getSupplierTransactions();
  }, []);
    useEffect(() => {

        loadTableTransactions();
    }, [supplierTransactions,offsetTransactions,
        currentPageTransactions,]);


  function getSupplierTransactions(){

      let formData = new FormData();
      formData.append("store_id", sessionStorage.getItem("merchant_id"));
      formData.append("supplier_id", props.supplier_data.supplier_id);
      formData.append("payment_status_id", 2);

      PostCompnentWebxBpsp("getMerchantBpspOrders", formData).then((response) => {
          setSupplierTransactions(response.data);
      });

  }

    const loadTableTransactions=()=> {
        const list_data =supplierTransactions;

        const zeroPad = (num, places) => String(num).padStart(places, '0');

        const slice = list_data.slice(offsetTransactions, offsetTransactions + perPageTransactions
        );



        const trans = list_data && list_data.length ? (slice.map((list, key) =>
            <tr>
                <td>{zeroPad(offsetTransactions+key+1, 3)}</td>
                <td>{list.order_refference_number}</td>
                <td>
                    <NumberFormat
                        value={list.transaction_amount}
                        displayType={'text'} thousandSeparator={true}
                        prefix={ 'LKR '}/>
                </td>
                <td>{moment(list.date_added).format('ll')}</td>
                <td>   {list.supplier_settlement_status ==
                2 ? (
                    <>
                              <span className="dot green">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                        Approved
                    </>
                ) : (
                    ""
                )}
                    {list.supplier_settlement_status ==
                    3 ? (
                        <>
                              <span className="dot red">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            Declined
                        </>
                    ) : (
                        ""
                    )}
                    {list.supplier_settlement_status ==
                    1 ? (
                        <>
                              <span className="dot yellow">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            Pending
                        </>
                    ) : (
                        ""
                    )}
                    {list.supplier_settlement_status ==
                    4 ? (
                        <>
                              <span className="dot yellow">
                                <i
                                    className="fas fa-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                              </span>
                            In-Progress
                        </>
                    ) : (
                        ""
                    )}
                </td>
                <td className="" data-title="#">

                    <div onClick={() => viewModalTransaction(list.supplier_invoice_id,list.bpsp_order_id)}>
                        <button className="dot-drop btn"><i className="fas fa-eye" aria-hidden="true"></i></button></div>
                </td>
            </tr>
        )) : (
            <tr className=''>
                <td colSpan="6" className='' data-title='#'><strong>No results found</strong></td>
            </tr>
        );
        setPageCountTransactions(Math.ceil(list_data.length / perPageTransactions))
        setTransactionListRender(trans);
    }

    const viewModalTransaction=(invoiceId,bpspOrderId)=>{

        let formData = new FormData();
        formData.append("store_id", sessionStorage.getItem("merchant_id"));
        formData.append("bpsp_order_id",bpspOrderId);
        formData.append("supplier_id", props.supplier_data.supplier_id);

        PostCompnentWebxBpsp("getMerchantBpspOrders", formData).then((response) => {
            if(response.data.length>0) {
                setInvoiceDetail(response.data[0]);
                getInvoiceLineItems(response.data[0]);
            }
        });



    }

    const getInvoiceLineItems = (inv_data) => {

        if(typeof inv_data!== 'undefined'){
            GetWebxLaravel(
                "bpsp/get-invoice-items/" + inv_data.supplier_invoice_id
            ).then((response) => {
                setInvoiceLineItems(response.data.line_items);
                setInvoiceReference(response.data.invoice_reference);
            });
        }
        setModalShow(true);
    };
    const handlePageClickTransactions = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPageTransactions;
        setCurrentPageTransactions(selectedPage)
        setOffsetTransactions(offset)
        //   loadTableTransactions();

    };
  let lend = 0;
  return (
    <Tab.Pane eventKey="second">
        <ViewSupplierTransactionReceipt
            show={modalShow}
            onHide={() => setModalShow(false)}
            supplierId={props.supplier_data.supplier_id}
            invoiceDetail={invoiceDetail}
            invoiceLineItems={invoiceLineItems}
            invoiceReference={invoiceReference}
        />
      {lend == 0 ? (
        <div className="rg-container">
          {/*  <DataTable
                                            data={transaction_lists}
                                            columns={columns}
                                            striped={true}
                                            hover={true}
                                            responsive={true}
                                            onClickRow={click}
                                        />*/}
          <table className="transactions-table" summary="Hed">
            <thead>
              <tr>
                <th className="">#</th>
                <th className="">Transaction Ref</th>
                <th className="">Amount </th>
                  <th className="">Date Of Transaction</th>
                  <th className="">Settlement Status</th>
                  <th className="">
                  <i className="fa fa-cog" aria-hidden="true"></i>
                </th>
              </tr>
            </thead>
            <tbody>
            {transaction_lists_render}
            </tbody>
          </table>
            <ReactPaginate
                previousLabel={"PREV"}
                nextLabel={"NEXT"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCountTransactions}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClickTransactions}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}/>
        </div>

      ) : (
        <div className="rg-container">
          <div className="col-sm-12">
            <img
              src={require("../../../../images/uh_no.png")}
              alt=""
              className="mi"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
          <div className="row">
            <strong className="mt-1 " style={{ textAlign: "center" }}>
              Tip : Create a new transaction to display under the client
            </strong>
          </div>
          <CreateNewTransaction />

        </div>
      )}
        <button ref={props.clickTransaction}  onClick={getSupplierTransactions} style={{display:"none"}}></button>
    </Tab.Pane>
  );
}
