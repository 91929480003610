import Tab from "react-bootstrap/Tab";
import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {GetWebxLaravel, PostCompnentWebxBpsp, PostWebxLaravel} from "../../../PostCompnent";
import {supplierIdContext} from "./LoadModalFunctions";
import {Backdrop, CircularProgress} from "@material-ui/core";
import Modal from "react-bootstrap/Modal";


export function SupplierCreationForm({onBoard, submitRef, type, hideElement, setSupplierData, supplierData,supplierTypes,
                                         bankData,onReload}) {


    const [bankID, setBankId] = useState([]);
    const [bankBranches, setBankBranches] = useState([]);


    const [indexChanged, SetIndexChanged] = useState(false);
    const supplier_id = useContext(supplierIdContext);
    const [loderStatePro, setLoaderState] = useState(false);
    const [allSupplierData, setAllSupplierData] = useState(false);
    const [showConfirm,setShowConfirm]=useState(false);
    const [bankDetail,setBankDetail]=useState({});

    useEffect(() => {
        getBanks();
    }, []);
    const getBanks = () => {

        if (typeof type !== 'undefined') {
            //  setSupplier()
            getSupplierData()

        }
    }


    const getBranches = (e) => {
        let bank_id_set = null;

        if (typeof e.target !== 'undefined') {
            bank_id_set = e.target.value;
        } else {
            bank_id_set = e;
        }
  
        bankData.some((item) => {

            if (item.bank_id==bank_id_set){

                setBankBranches(item.bank_branches)
            }
        });

    }


    const {
        register,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm();
    const getSupplierData = () => {


        getBranches( supplierData.bank_id);

            reset({
                username: supplierData.username,
                phone_number: supplierData.phone_number,
                wesite_url: supplierData.wesite_url,
                business_name: supplierData.business_name,
                business_email: supplierData.business_email,
                type_of_service: supplierData.type_of_service,
                account_name: supplierData.account_name,
                bank_id: supplierData.bank_id,
                account_number: supplierData.account_number,
                bank_branch_id: supplierData.bank_branch_id
            });

    }

    const setSupplier = (e) => {
        let formData = new FormData();    //formdata object
        formData.append("store_id", sessionStorage.getItem("merchant_id"));
        formData.append("supplier_id", supplier_id)
        PostCompnentWebxBpsp("getMerchantBpspSuppliers", formData).then(response => {
            getBanks()
            setSupplierData(response.data[0]);
        });
    }

    const confirmData=()=>{


        let suppliersData=allSupplierData;
        SetIndexChanged(false)

        let formData = new FormData();    //formdata object
        Object.keys(suppliersData).map(function (key) {
            formData.append(key, suppliersData[key]);
        });


        if (typeof type !== 'undefined') {
            formData.append("supplier_id", supplierData.supplier_id);
            PostCompnentWebxBpsp("updateBpspSupplier", formData).then(response => {
                console.log(typeof onBoard);
                if (typeof onBoard === 'function') {
                    onBoard();
                    setSupplier();
                }
                hideElement();
                onReload()
                setShowConfirm(false)
            });
        } else {
            PostCompnentWebxBpsp("createBpspSupplier", formData).then(response => {
                if (typeof onBoard === 'function') {
                    onBoard();
                    setSupplier();
                }
                hideElement();
                onReload()
                setShowConfirm(false)
            });
        }
    }

    const onSubmit = (suppliersData) => {
        let formData = new FormData();    //formdata object
        formData.append("bank_id",suppliersData.bank_id)
        formData.append("branch_id",suppliersData.bank_branch_id)

      //  account_name
        //account_number

        PostWebxLaravel("bpsp/get-bank-branch", formData).then(response => {
          console.log(response);
            setBankDetail(
                {
                    "bank_name":response.data.bank_name,
                    "branch_name":response.data.branch_name,
                }
            )
        });
        setAllSupplierData(suppliersData);
        setShowConfirm(true);
    };


    return (

        <Tab.Pane className="tab-pane fade show " eventKey="first">
            <Modal
                show={showConfirm}
                onHide={()=>{
                    setShowConfirm(false)
                }}

                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="info-modal"
                className="info-modal-class"
            >
                <Modal.Body className="modal-type-2">
                    {/*  <button onClick={this.closeModal} className="cls-btn">X</button>*/}
                    <div className="row log-sec">
                        <div className="col-12 suc-outer">

                            <h4 className="in-h mb-4">Is the account details correct?</h4>
                            <p>Bank Name   : {bankDetail.bank_name}</p>
                            <p>Branch Name : {bankDetail.branch_name}</p>
                            <p>Account Name : {allSupplierData.account_name}</p>
                            <p>Account Number : {allSupplierData.account_number}</p>
                            <p>{}</p>
                        </div>
                        <div className="col-6 submit">
                            <div id="submit-btn" className="btn btn-primary" onClick={confirmData}><i className="fas fa-check"></i> Proceed</div>
                        </div>
                        <div className="col-6 submit">
                            <div id="submit-btn" className="btn btn-primary nbtn"
                                 onClick={()=>setShowConfirm(false)}><i className="fas fa-times"></i> No</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Backdrop className="backdrop-loading" open={loderStatePro}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            <form className="row administrator-information" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                    <h3>Supplier Information</h3>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Supplier Full Name</label>
                        <input type="text" className="form-control gfc"
                               placeholder="Enter Full Name"
                               name="username" {...register('username', {required: true})} />
                        {errors.username && errors.username.type === "required" && (
                            <p className="error" style={{color: "red"}}>Supplier full name is required.</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Business Phone Number</label>
                        <input type="text" className="form-control gfc"
                               placeholder="Enter Supplier Business Phone Number"
                               name="phone_number" {...register('phone_number', {
                            required: true, pattern: {
                                value: /^(0|[0-9]\d*)(\.\d+)?$/
                            }
                        })} />
                        {errors.phone_number && errors.phone_number.type === "required" && (
                            <p className="error" style={{color: "red"}}> Business phone number is required.</p>
                        )}
                        {errors.phone_number && errors.phone_number.type === "pattern" && (
                            <p className="error" style={{color: "red"}}> Please enter only numbers</p>
                        )}
                    </div>
                    <div className="form-group ">
                        <label htmlFor="">Website URL</label>
                        <input type="text" className="form-control gfc"
                               placeholder="Enter Supplier Website Url" name="wesite_url" {...register('wesite_url',{
                            pattern: {
                                value: /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                            }})} />
                        {errors.wesite_url && errors.wesite_url.type === "pattern" && (
                            <p className="error" style={{color: "red"}}> Please enter valid url</p>
                        )}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Business Name</label>
                        <input type="text" className="form-control gfc"
                               placeholder="Enter Supplier Business Name"
                               name="business_name" {...register('business_name', {required: true})} />
                        {errors.business_name && errors.business_name.type === "required" && (
                            <p className="error" style={{color: "red"}}> Supplier business name is required.</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Business E-Mail (Notifications)</label>
                        <div className="row db-row">
                            <input type="text" className="form-control gfc"
                                   placeholder="Enter Supplier Business E-Mail"
                                   name="business_email" {...register('business_email', {
                                required: true,
                                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                            })} />
                            {errors.business_email && errors.business_email.type === "required" && (
                                <p className="error" style={{color: "red"}}>Email is required.</p>
                            )}
                            {errors.business_email && errors.business_email.type === "pattern" && (
                                <p className="error" style={{color: "red"}}>Email is not valid.</p>
                            )}
                        </div>

                    </div>

                    <div className="form-group slct">
                        <label htmlFor="">Select Type Of Supplier</label>
                        <select id="" className="form-control gfc"
                                name="type_of_service" {...register('type_of_service', {required: true})}>
                            <option value="">-- Select Supplier Type --</option>
                            {supplierTypes.map((value, index) => {
                                return (
                                    <option value={value.bpsp_supplier_type_id}>{value.supplier_type}</option>
                                )
                            })
                            }
                        </select>
                        {errors.type_of_service && errors.type_of_service.type === "required" && (
                            <p className="error" style={{color: "red"}}> Type of service is required.</p>
                        )}
                    </div>
                </div>

                <div className="col-12">
                    <h3 className="ht2">Bank Account Details</h3>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Account Name</label>
                        <input type="text" className="form-control gfc"
                               placeholder="Enter Account Name"
                               name="account_name" {...register("account_name", {required: true})} />
                        {errors.account_name && errors.account_name.type === "required" && (
                            <p className="error" style={{color: "red"}}> Account name is required.</p>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Name of Bank (Bank Code)</label>
                        <select name="bank_id" id=""
                                className="form-control gfc"  {...register("bank_id", {required: true})}
                                onChange={(e) => getBranches(e)}>
                                <option value="">Select Name Of Bank</option>
                                {bankData.map((value, index) => {
                                return (
                                    <option value={value.bank_id}>{value.bank_name}</option>
                                )
                            })
                            }
                        </select>
                        {errors.bank_id && errors.bank_id.type === "required" && (
                            <p className="error" style={{color: "red"}}> Name of bank is required.</p>
                        )}
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">Account Number</label>
                        <input type="text" className="form-control gfc"
                               placeholder="Enter Account Number" name="account_number" {...register('account_number', {
                            required: true, pattern: {
                                value: /^(0|[0-9]\d*)(\.\d+)?$/
                            }
                        })} />
                        {errors.account_number && errors.account_number.type === "required" && (
                            <p className="error" style={{color: "red"}}> Account number is required.</p>
                        )}
                        {errors.account_number && errors.account_number.type === "pattern" && (
                            <p className="error" style={{color: "red"}}> Please enter only numbers</p>
                        )}
                    </div>
                    <div className="form-group slct">
                        <label htmlFor="">Bank Branch (Branch Code)</label>
                        <select name="bank_branch_id" id=""
                                className="form-control gfc" {...register('bank_branch_id', {required: true})}>
                            <option value="">Select Bank Branch</option>

                           {bankBranches.map((value, index) => {
                                return (
                                    <option value={value.branch_id}>{value.branch_name}</option>
                                )
                            })
                            }
                        </select>
                        {errors.bank_branch_id && errors.bank_branch_id.type === "required" && (
                            <p className="error" style={{color: "red"}}> Bank branch is required.</p>
                        )}
                    </div>
                </div>
                <input type="hidden" className="form-control gfc"
                       placeholder="Enter Account Number" name="status" value="1" {...register('status')} />

                <input type="hidden" className="form-control gfc"
                       placeholder="Enter Account Number" name="store_id"
                       value={sessionStorage.getItem("merchant_id")} {...register('store_id')} />
                <button ref={submitRef} type="submit" style={{display: 'none'}}/>
            </form>
        </Tab.Pane>
    )
}