import React, {PureComponent} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {Navigation} from "../Navigation";
import {myConfig} from "../../config";
import {TopBar} from "../TopBar";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    FacebookShareCount,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    WhatsappIcon, LinkedinIcon, MailruIcon, PinterestIcon, TelegramIcon, TwitterIcon, ViberIcon
} from "react-share";
import {GetWebxLaravel, PostCompnentWebx, PostWebxLaravel} from "../PostCompnent";
import {Footer} from "../Footer";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {SupportButton} from "../SupportButton";
import {TopBarSandbox} from "./TopBarSandbox";
import {NavigationSandbox} from "./NavigationSandbox";

export class PaymentLinksSandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_amount: '',
            payment_amount_print: '',
            payment_link: '',
            payment_link_description: '',
            toasts: '',
            payment_link_tray: [],
            errors_link: [],
            lkr_available:'',
            usd_available:''
        };
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.generateLink = this.generateLink.bind(this);
        this.onChanges = this.onChanges.bind(this);
        this.setMoney = this.setMoney.bind(this);
        this.getLinks = this.getLinks.bind(this);
        this.deleteLink = this.deleteLink.bind(this);
        this.validateLink = this.validateLink.bind(this);
        this.setMoneyPrint = this.setMoneyPrint.bind(this);
        this.notify = this.notify.bind(this);

    }

    notify(){
        toast("Copied to Clipboard");
    }


    validateLink() {
        let errors_link = {};
        let formIsValid = true;
        //Email


        if (this.state.currency_id == "" || typeof this.state.currency_id == "undefined") {
            formIsValid = false;
            errors_link["currency_id"] = "Currency is required";
        }
        if (this.state.payment_amount == "") {
            formIsValid = false;
            errors_link["payment_amount"] = "Payment amount is required";
        }

        if (this.state.payment_link_description == "") {
            formIsValid = false;
            errors_link["payment_link_description"] = "Description field is required";
        }

        this.setState({errors_link: errors_link});
        return formIsValid;
    }

    deleteLink(id) {

        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': sessionStorage.getItem("laravel_token"),

            },
        };
        let formData = new FormData();    //formdata object
        formData.append('id', id);
        formData.append('_method', "DELETE");
        this.handleToggleProgress();
        PostWebxLaravel("links/delete_links/" + sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+id, formData).then(response => {
            this.handleCloseProgress();
            this.getLinks();
        });
    }

    getLinks() {
        this.handleToggleProgress();
        GetWebxLaravel("links/get_links/" + sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            this.handleCloseProgress();
            if (response) {
                this.setState({
                    payment_link_tray: response.data,
                }, () => {
                    this.handleCloseProgress();
                });
            }
        });
        let formData = new FormData();    //formdata object
        PostCompnentWebx("getStoreCurrency",formData).then(response=>{
            let LKR=false;
            let USD=false;
            if (response.data.indexOf("LKR") > -1){
                LKR=true;
            }
            if (response.data.indexOf("USD") > -1){
                USD=true;
            }
            this.setState({
                lkr_available:LKR,
                usd_available:USD
            });
        });
    }

    setMoney(payment_amount) {
        this.setState({payment_amount: payment_amount});
    }
    setMoneyPrint()
    {
        this.setState({payment_amount_print: this.state.payment_amount});
    }
    generateLink() {

        if (this.validateLink()) {
            let formData = new FormData();    //formdata object
            let payment_amount = this.state.payment_amount;
            let currency_id = this.state.currency_id;
            let payment_link_description = this.state.payment_link_description;

            formData.append('link_amount', payment_amount);
            formData.append('merchant_id', sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"));
            formData.append('currency_id', currency_id);
            formData.append('payment_link_description', payment_link_description);
            this.handleToggleProgress();
            PostWebxLaravel("links/create_link", formData).then(response => {
                this.handleCloseProgress();
                this.getLinks();
                this.setMoneyPrint();
                let payment_link = myConfig.laravelWeb + 'payment_link/generate_payment/' + response.data;
                this.setState({
                    payment_link: payment_link
                });
            });

        }

    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    componentWillMount() {

        if (sessionStorage.getItem("userToken") || localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");

            this.setState({userToken: userToken});
            this.getLinks();


        } else {
            this.setState({redirect: true});
        }
    }


    render() {

        var React = require('react');
        var QRCode = require('qrcode.react');
        var payment_link_tray = this.state.payment_link_tray;
        let payment_link = this.state.payment_link;
        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }
        return (
            <div>
                <SupportButton/>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <NavigationSandbox pathname="payment-links-sandbox"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBarSandbox pathname="payment-links"/>

                        <div id="activation-modal"
                             className="modal-dialog modal-dialog-centered payment-link-modal plmd" role="document">
                            <div className="modal-content">

                                <div className="modal-body modal-type-2">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-4 lft">
                                                {/*<h3 className="tdh">Invoice Details</h3>*/}

                                                <div id="create-new-payment-link-model"
                                                     className="modal-dialog modal-dialog-centered"
                                                     role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-body modal-type-2">

                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col-12 cd-frm">
                                                                        <h3 className="tdh">Create Payment Link</h3>

                                                                        <div className="col-12 tdm-in p-0">

                                                                            <div className="form-group"><h6
                                                                                className="sh">Currency</h6>
                                                                                <select name="currency_id"
                                                                                        id="currency_id"
                                                                                        onChange={this.onChanges}
                                                                                        className="form-control">
                                                                                    <option value="">-- Select Currency
                                                                                        --
                                                                                    </option>
                                                                                    {this.state.lkr_available?(<option value="5">LKR</option>):('')}
                                                                                    {this.state.usd_available?(<option value="2">USD</option>):('')}
                                                                                </select></div>
                                                                            <span className="error"
                                                                                  style={{color: "red"}}>{this.state.errors_link["currency_id"]}</span>

                                                                            <h6>Payment Link Amount</h6>

                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                className="row row_clr pla-in">
                                                                                                <button type="button"
                                                                                                        className="nt-btn"
                                                                                                        onClick={() => this.setMoney(100)}>Add <NumberFormat
                                                                                                    value={100}
                                                                                                    displayType={'text'}
                                                                                                    thousandSeparator={true}
                                                                                                    prefix={''}/>
                                                                                                    <i className="fas fa-money"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                        className="nt-btn del-btn"
                                                                                                        onClick={() => this.deleteLink(1)}>Delete <i
                                                                                                    className="fas fa-trash"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row row_clr pla-in">
                                                                                                <button type="button"
                                                                                                        className="nt-btn"
                                                                                                        onClick={() => this.setMoney(200)}>Add <NumberFormat
                                                                                                    value={200}
                                                                                                    displayType={'text'}
                                                                                                    thousandSeparator={true}
                                                                                                    prefix={''}/>
                                                                                                    <i className="fas fa-money"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                        className="nt-btn del-btn"
                                                                                                        onClick={() => this.deleteLink(1)}>Delete <i
                                                                                                    className="fas fa-trash"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row row_clr pla-in">
                                                                                                <button type="button"
                                                                                                        className="nt-btn"
                                                                                                        onClick={() => this.setMoney(300)}>Add <NumberFormat
                                                                                                    value={300}
                                                                                                    displayType={'text'}
                                                                                                    thousandSeparator={true}
                                                                                                    prefix={''}/>
                                                                                                    <i className="fas fa-money"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                        className="nt-btn del-btn"
                                                                                                        onClick={() => this.deleteLink(1)}>Delete <i
                                                                                                    className="fas fa-trash"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row row_clr pla-in">
                                                                                                <button type="button"
                                                                                                        className="nt-btn"
                                                                                                        onClick={() => this.setMoney(400)}>Add <NumberFormat
                                                                                                    value={400}
                                                                                                    displayType={'text'}
                                                                                                    thousandSeparator={true}
                                                                                                    prefix={''}/>
                                                                                                    <i className="fas fa-money"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                        className="nt-btn del-btn"
                                                                                                        onClick={() => this.deleteLink(1)}>Delete <i
                                                                                                    className="fas fa-trash"></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        </React.Fragment>

                                                                            <div className="form-group">
                                                                              {/*  <input type="text"
                                                                                       className="form-control"
                                                                                       name="payment_amount"
                                                                                       placeholder="Payment Amount"
                                                                                       value={this.state.payment_amount}
                                                                                       onChange={this.onChanges}/>*/}
                                                                                <NumberFormat thousandSeparator={true} prefix={''}  className="form-control"
                                                                                              name="payment_amount"
                                                                                              placeholder="Payment Amount"
                                                                                              value={this.state.payment_amount}
                                                                                              onValueChange={(values) => {
                                                                                                  const {formattedValue, value} = values;
                                                                                                  this.setState({payment_amount: value})
                                                                                              }}/>

                                                                                <span className="error"
                                                                                      style={{color: "red"}}>{this.state.errors_link["payment_amount"]}</span>
                                                                            </div>

                                                                            <div className="form-group">
                                                                                {/*  <input type="text"
                                                                                       className="form-control"
                                                                                       name="payment_amount"
                                                                                       placeholder="Payment Amount"
                                                                                       value={this.state.payment_amount}
                                                                                       onChange={this.onChanges}/>*/}

                                                                <textarea name="payment_link_description"
                                                                          className="form-control tfc"
                                                                          cols="30" rows="3" onChange={this.onChanges}
                                                                          value={this.state.payment_link_description} placeholder="Payment Description"></textarea>

                                                                                <span className="error"
                                                                                      style={{color: "red"}}>{this.state.errors_link["payment_link_description"]}</span>
                                                                            </div>
                                                                        </div>

                                                                        <button type="button" className="nxt-btn"
                                                                                onClick={this.generateLink}>Generate
                                                                            Link &nbsp;&nbsp;
                                                                            <i className="fas fa-save"></i></button>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 rht">
                                                <div className="row">
                                                    <div className="col-12 details-box d-inv-box">
                                                        <div className="col-12 dbx-in">
                                                            <h3>Payment Link Preview</h3>
                                                            {payment_link !== "" ? (
                                                                <div className="row row_clr dit-in">
                                                                    <div className="col-12 lht">
                                                                        <div className="row">
                                                                            {/*<div className="col-12 lgo">*/}
                                                                            {/*    <img src="images/invoice/l1.png" alt="" />*/}
                                                                            {/*</div>*/}
                                                                            <div className="col-md-6 col-12">


                                                                                <QRCode value={payment_link}
                                                                                        size={220}/>
                                                                                <p className="qrp"><NumberFormat
                                                                                    value={(Math.round(this.state.payment_amount_print * 100) / 100).toFixed(2)}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    prefix={this.state.currency_id == 5 ? 'LKR ' : 'USD '}/>
                                                                                </p>
                                                                            </div>
                                                                            <ToastContainer />
                                                                            <div className="col-md-6 col-12">
                                                                                <div className="sd">
                                                                                    <h5>Share</h5>
                                                                                    <FacebookShareButton
                                                                                        url="test_link">
                                                                                        <FacebookIcon size={40}
                                                                                                      logoFillColor="white"/>
                                                                                    </FacebookShareButton>
                                                                                    <TwitterShareButton
                                                                                        url="test_link">
                                                                                        <TwitterIcon size={40}
                                                                                                     logoFillColor="white"/>
                                                                                    </TwitterShareButton>

                                                                                    <WhatsappShareButton
                                                                                        url="test_link">
                                                                                        <WhatsappIcon size={40}
                                                                                                      logoFillColor="white"/>
                                                                                    </WhatsappShareButton>

                                                                                    <ViberShareButton
                                                                                        url="test_link">
                                                                                        <ViberIcon size={40}
                                                                                                   logoFillColor="white"/>
                                                                                    </ViberShareButton>

                                                                                    <TelegramShareButton
                                                                                        url="test_link">
                                                                                        <TelegramIcon size={40}
                                                                                                      logoFillColor="white"/>
                                                                                    </TelegramShareButton>

                                                                                    <CopyToClipboard size={40} text="test_link" >
                                                                                        <button   onClick={this.notify} className="cpy-btn"><i className="far fa-copy"></i></button>
                                                                                    </CopyToClipboard>
                                                                                </div>

                                                                                <a
                                                                                   className="p-btn plpb"
                                                                                   target="_blank">Pay Now &nbsp;&nbsp;
                                                                                    <i
                                                                                        className="fas fa-credit-card"></i></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}

                                                        </div>

                                                        <p className="det-p">
                                                            {this.state.payment_link_description}
                                                        </p>

                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer/>
                    </div>

                </div>

            </div>
        );
    }

}


