import Tab from "react-bootstrap/Tab";
import React, {useEffect, useState, useRef} from "react";
import Nav from "react-bootstrap/Nav";
import {PostWebxLaravel} from "../../PostCompnent";
import NumberFormat from "react-number-format";
import {myConfig} from "../../../config";
import {useForm} from "react-hook-form";
import moment from "moment/moment";


export function ChangeSubscription(props) {

    const initialFee = 0.00;
    const [selectedOption, setSelectedOption] = useState('');
    const [recurringFee, setRecurringFee] = useState(initialFee);
    const [recurringPeriod, setRecurringPeriod] = useState(initialFee);
    const [activePlan, setActivePlan] = useState('');

   const[selectedCurrencies,setSelectedCurrencies]=useState([]);
   const[currencies,setCurrencies]=useState([]);
   const[isCheckedCurrencies,setIsCheckedCurrencies]=useState([]);
   const[currencyCost,setCurrencyCost]=useState(0);
   const[currencyCostEmi,setCurrencyCostEmi]=useState(0);
   const[totalFee,setTotalFee]=useState(0);
   const[mainPackageTotal,setMainPackageTotal]=useState(0);

   const[selectedEmi,setSelectedEmi]=useState([]);
   const[emis,setEmis]=useState([]);
   const [isChecked, setIsChecked] = useState(false);

    const selectRef = useRef(null);

    useEffect(() => {
        if (props.subscriptionAddonEMI !== undefined) {
            // Perform operations with props here
            setEmiArray(props.subscriptionAddonEMI)
        }
        if (props.subscriptionAddonUSD !== undefined){
            setCurrencyArray(props.subscriptionAddonUSD);
        }

    }, [props]);

    const setCurrencyArray=(subscriptionAddonUSD)=>{
        const newDataArray= subscriptionAddonUSD.map((value,index)=>{
           return {
               ...value,
               id:value.id,
               name:value.package,
               checked:false
           }
        });
        setCurrencies(newDataArray);
    }

    const setEmiArray=(subscriptionAddonEMI)=>{

        const newDataArray =  subscriptionAddonEMI.map((value, index) => {
            return {
                ...value,
                id:value.id,
                name:value.package,
                checked: false
            };
        });
        setEmis(newDataArray)
    }



    const handleCheckboxChangeEmi = (event,value,cost)=>{
        setIsChecked(
            emis.map((checkbox) =>
                checkbox.id === value ? { ...checkbox, checked: !checkbox.checked } : checkbox
            )
        );

        if (selectedEmi.includes(value)) {
            setCurrencyCostEmi(parseFloat(currencyCostEmi)-parseFloat(cost))
            setSelectedEmi(selectedEmi.filter((num) => num !== value));
            calculateTotal(null,null,parseFloat(currencyCostEmi)-parseFloat(cost));
        } else {
            setCurrencyCostEmi(parseFloat(currencyCostEmi)+parseFloat(cost))
            // Otherwise, add it to the array
            setSelectedEmi([...selectedEmi, value]);
            calculateTotal(null,null,parseFloat(currencyCostEmi)+parseFloat(cost));
        }
    }

    const handleCheckboxChange = (event,checked_value,cost) => {

        setIsCheckedCurrencies(
            currencies.map((checkbox=>checkbox.id===checked_value?{...checkbox,checked:!checkbox.checked}:checkbox))
        );

        if (selectedCurrencies.includes(checked_value)){
            setCurrencyCost(parseFloat(currencyCost)-parseFloat(cost));
            setSelectedCurrencies(selectedCurrencies.filter((num)=>num!==checked_value))
            calculateTotal(null,parseFloat(currencyCost)-parseFloat(cost),null);
        }else{
            setCurrencyCost(parseFloat(currencyCost)+parseFloat(cost));
            setSelectedCurrencies([...selectedCurrencies,checked_value]);
            calculateTotal(null,parseFloat(currencyCost)+parseFloat(cost),null);
        }

    };

    const calculateTotal= (mainPackageCost,currencyTotalCost,emiTotalCost) =>{

        let mainCost=0;
        let currCost=0;
        let emCost=0;
        if (mainPackageCost!==null){
            mainCost=mainPackageCost;
        }else{
            mainCost=mainPackageTotal;
        }

        if (currencyTotalCost!==null){
            currCost=currencyTotalCost;
        }else{
            currCost=currencyCost;
        }

        if (emiTotalCost!==null){
            emCost=emiTotalCost;
        }else{
            emCost=currencyCostEmi;
        }
        console.log(mainCost+currCost+emCost);
        setTotalFee(parseFloat(mainCost)+parseFloat(currCost)+parseFloat(emCost))
    }


    function popupWindow(url, windowName, win, w, h) {
        const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
        const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
        return win.open(
            url,
            windowName,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
        );
    }

    const handleChange = (event, active_plan, list_id) => {

        if (active_plan == list_id) {
            setSelectedOption(event.target.value);
            setRecurringFee(event.target.options[event.target.selectedIndex].dataset.recurringFee)
            setRecurringPeriod(event.target.options[event.target.selectedIndex].dataset.recurringPeroid)
            setMainPackageTotal(parseFloat(event.target.options[event.target.selectedIndex].dataset.recurringFee)*parseFloat(event.target.options[event.target.selectedIndex].dataset.recurringPeroid))
            calculateTotal(parseFloat(event.target.options[event.target.selectedIndex].dataset.recurringFee)*parseFloat(event.target.options[event.target.selectedIndex].dataset.recurringPeroid),null,null);
        }

    };



    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const onClickPlan = (id, data) => {
        console.log(id)
        if (activePlan !== id) {
            setSelectedOption("");
            setRecurringFee(initialFee)
            setRecurringPeriod(initialFee)
            setActivePlan(id);

            if (id==15){
                setMainPackageTotal(0)
            }


            calculateTotal(0,null,null,null);
        }

    }

    const onSubmit = (paymentData) => {
        let formData = new FormData(); //formdata object
        formData.append('invoice_id',3);

        PostWebxLaravel("process-subscription-payment", formData).then((response) => {
            console.log("response.data.success")
            console.log(response.data)
            if (response.data.success == 200) {
                let BaseUrl = myConfig.laravelWeb;
              /*  popupWindow(
                    BaseUrl + "load-secure-otp/"+,
                    "test",
                    window,
                    1000,
                    800
                );*/
                const timer = setInterval(() => {
                    let formData = new FormData(); //formdata object
                    formData.append("invoice_id", props.invoiceId);

                    /*  PostWebxLaravel("bpsp/check-payment-status-process", formData).then(
                          (response) => {

                              if(response.data.paid_status==2){
                                  setTransactionStatus("Payment was successful")
                                  handleShow()

                                  clearInterval(timer);
                                  setTimeout(
                                      handleClose(),
                                      5000
                                  );
                              }else if(response.data.paid_status==3){
                                  setTransactionStatus("Payment Failed Try Again")
                                  handleShow()

                                  clearInterval(timer);
                                  setTimeout(
                                      handleCloseOnly(),
                                      5000
                                  );
                              }
                          }
                      );
                  }, 5000);
              }*/
                });
            }
        })
    }


    return (

        <>
            <div className="tab-pane fade show active" id="tt" role="tabpanel"
                 aria-labelledby="tt-tab">
                <div id="">
                    <div className="activation-modal-dialog">
                        <div className="modal-body">
                            <div className="lft">
                                <div className="sf-box mt-2">
                                    <h5>Current Subscription Details</h5>
                                    <p>
                                        <span>Current Package</span>: {props.currentSubscriptionMainPackage.get_package_billing_periods ? props.currentSubscriptionMainPackage.get_package_billing_periods.get_packages.package : ''}
                                    </p>
                                    <p><span>Package EMI Addons</span>:
                                        {props.currentSubscriptionAddonEMI && props.currentSubscriptionAddonEMI.length ? props.currentSubscriptionAddonEMI.map((list, key) =>
                                            list.get_package_billing_periods ? list.get_package_billing_periods.get_packages.package + ', ' : ''
                                        ) : (
                                            'No package addons'
                                        )}
                                    </p>

                                    <p><span>USD Payment</span>:
                                        {props.currentSubscriptionAddonUSD && props.currentSubscriptionAddonUSD.length ? props.currentSubscriptionAddonUSD.map((list, key) =>
                                            list.get_package_billing_periods ? list.get_package_billing_periods.get_packages.package + ', ' : ''
                                        ) : (
                                            'No package addons'
                                        )}
                                    </p>

                                </div>
                            </div>
                            <div className="rht">
                                <div className="row">


                                    {props.subscriptionMainPackages && props.subscriptionMainPackages.length ? props.subscriptionMainPackages.map((list, key) =>

                                        <div className="col-md-6 pack-detail">

                                            <div className="row row_clr t-in">

                                                <div
                                                    className={activePlan == list.id ? "col-12 sp-box selected" : "col-12 sp-box"}>
                                                    <h3 className="s-name">{list.package}</h3>


                                                    <div className="line"></div>
                                                    <p>Subscription Plan</p>
                                                    <div className="slct">
                                                        <select
                                                            disabled={activePlan !== list.id ? "disabled" : ""}
                                                            ref={selectRef} name="" id={key + "c2"}
                                                            value={activePlan == list.id ? selectedOption : ""}
                                                            onChange={(event) => handleChange(event, activePlan, list.id)}>
                                                            {list.id == "15" ? '' :
                                                                <option value="">Select</option>}

                                                            {
                                                                list.get_package_billing_periods && list.get_package_billing_periods.length ? list.get_package_billing_periods.map((lis, key) =>
                                                                    <option cost={lis.get_billing_periods.id}
                                                                            value={lis.get_billing_periods.id}
                                                                            data-recurring-fee={lis.recurring_fee}
                                                                            data-recurring-peroid={lis.get_billing_periods.billing_period_months}>{lis.get_billing_periods.billing_period_months == "6000" ? "Unlimited" : lis.get_billing_periods.billing_period_months + " Month/s"} </option>
                                                                ) : ('')
                                                            }

                                                        </select>
                                                    </div>

                                                    <button type="button" className="check-plan"
                                                            onClick={() => onClickPlan(list.id, list)}>
                                                        Select Plan
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    ) : ('')}


                                </div>
                            </div>
                            <div className="lft">
                                <div className="sf-box">
                                    <p className="mb-2">ENABLE USD GATEWAY</p>
                                    {currencies && currencies.length ? currencies.map((currency_list, key) =>
                                        <div className="form-group fg2">
                                            <input type="checkbox" id={"currency_" + currency_list.id}
                                                   className="enable_usd" name="enable_usd[]"
                                                   onChange={(e) => handleCheckboxChange(e, currency_list.id, currency_list.get_package_billing_periods[0].recurring_fee)}
                                                   checked={isCheckedCurrencies.checked}
                                            />
                                            <label
                                                htmlFor={"currency_" + currency_list.id}><span>{currency_list.package}</span>
                                            </label>
                                        </div>
                                    ) : ('')}
                                    <p className="mb-2">ENABLE EMI GATEWAYS</p>
                                    {emis ? emis.map((emi_list, key) =>
                                        <div className="form-group fg2">
                                            <input type="checkbox" id={"emi" + emi_list.id}
                                                   className="enable_emi" name="enable_emi[]"
                                                   onChange={(e) => handleCheckboxChangeEmi(e, emi_list.id, emi_list.get_package_billing_periods[0].recurring_fee)}
                                                   checked={isChecked.checked}
                                            />
                                            <label
                                                htmlFor={"emi_" + emi_list.id}> <span> {emi_list.package}</span>
                                            </label>
                                        </div>
                                    ) : ('')}
                                </div>
                                <div className="sf-box mt-3">
                                    <form onSubmit={handleSubmit(onSubmit)} id="black">

                                        <h5>Your Subscription Fee</h5>
                                        <p className="mb-2"><span>Subscription Plan</span>: <NumberFormat
                                            value={(Math.round(parseFloat(recurringFee) * parseFloat(recurringPeriod) * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true}
                                            prefix={'LKR '}/>(LKR {recurringFee} x {recurringPeriod})</p>

                                        <p><span>Addon Fees</span>
                                        </p>
                                        <p><span>Currency Charges</span>:
                                            <NumberFormat
                                                value={(Math.round(parseFloat(currencyCost) * 100) / 100).toFixed(2)}
                                                displayType={'text'} thousandSeparator={true}
                                                prefix={'LKR '}/></p>
                                        <p><span>EMI Charges</span>:
                                            <NumberFormat
                                                value={(Math.round(parseFloat(currencyCostEmi) * 100) / 100).toFixed(2)}
                                                displayType={'text'} thousandSeparator={true}
                                                prefix={'LKR '}/></p>

                                        <p className="ptp">Total Payable</p>
                                        <p className="am"><NumberFormat
                                            value={(Math.round(totalFee * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></p>

                                        {parseFloat(recurringFee) * parseFloat(recurringPeriod) !== 0 ?
                                            (<button type="submit" className="check-plan-btn">
                                                Pay Now
                                            </button>) : ('')
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

