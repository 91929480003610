import React from 'react';
import {
    BrowserRouter,
    Route,
    Link,
    Routes
  } from 'react-router-dom'
import './App.css';


// All Screens
/*import {Header} from "./components/Header";*/
import {Root} from "./components/Root";
//Sandbox
import {TransectionsSandbox} from "./components/SandBox/TransectionsSandbox";
import {DashboardSandbox} from "./components/SandBox/DashboardSandbox";
import {TransectionsRefundSandbox} from "./components/SandBox/TransectionsRefundSandbox";
import {SettlementsSandbox} from "./components/SandBox/SettlementsSandbox";
import {SettlementsDueSandbox} from "./components/SandBox/SettlementsDueSandbox";
import {AllInvoicesSandbox} from "./components/SandBox/AllInvoicesSandbox";
import { createBrowserHistory } from "history";

//Live
import {SignUp} from "./components/SignUp";
import {SignIn} from "./components/SignIn";
import {Verification} from "./components/Verification";
import {PasswordConfirmation} from "./components/PasswordConfirmation";
import {ActiveDashboard} from "./components/ActiveDashboard";
import {ForgotPassword} from "./components/ForgotPassword";
import {VerifyCode} from "./components/VerifyCode";
import {Transections} from "./components/Transections";
import {TransectionsRefund} from "./components/TransectionsRefund";
import {Settlements} from "./components/Settlements";
import {SettlementsDue} from "./components/SettlementsDue";
import {CheckModal} from "./components/CheckModal";
import {AllInvoices} from "./components/AllInvoices";
import {InvoiceProductServices} from "./components/InvoiceProductServices";
import {InvoiceTemplates} from "./components/InvoiceTemplates";
import {PaymentLinks} from "./components/PaymentLinks";
import {PaymentLinkSchool} from "./components/SchoolPaymentLink/PaymentLinkSchool";
import {CustomerGroups} from "./components/CustomerGroups";
import {Customers} from "./components/Customers";
import {SettingsGeneral} from "./components/SettingsGeneral";
import {UploadFiles} from "./components/UploadFile";
import {ReportsAndMetrics} from "./components/ReportsAndMetrics";
import {SettingsCurrency} from "./components/SettingsCurrency";
import {SettingsInvoice} from "./components/SettingsInvoice";
import {UserProfile} from "./components/UserProfile";
import {Errorpage} from "./components/Errorpage";
import {ResponsivePage} from "./components/ResponsivePage";
import {SupportButton} from "./components/SupportButton";
import {Timer} from "./components/Timer";
import {PaymentLinksSandbox} from "./components/SandBox/PaymentLinksSandbox";
import {ReportsAndMetricsSandbox} from "./components/SandBox/ReportsAndMetricsSandbox";
import {CustomersSandbox} from "./components/SandBox/CustomersSandbox";
import {CustomerGroupsSandbox} from "./components/SandBox/CustomerGroupsSandbox";
import {SettingsUser} from "./components/SettingsUser";
import {GetWebxLaravel} from "./components/PostCompnent";
import {SettlementsUsd} from "./components/SettlementsUsd";
import {SettlementsDueUsd} from "./components/SettlementsDueUsd";
import {ChangePassword} from "./components/ChangePassword";
import {BpspDashboard} from "./components/BPSP/BpspDashboard";
import {Suppliers} from "./components/BPSP/Suppliers";
import {TopBar} from "./components/TopBar";

import history from "./components/History";
import {TransactionListTable} from "./components/BPSP/SupplierIncludes/TransactionManagement/TransactionListTable";
import {SubscriptionSettings} from "./components/Subscriptions/SubscriptionSettings";
import {SettlementsDueGBP} from "./components/SettlementsDueGBP";
import {PaymentLinkListTransactions} from "./components/PaymentLink/PaymentLinkListTransactions";



export class MainScript extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            navigation:[]
        };


        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
    }
    componentWillMount() {
        this.getRolePermissions();
    }
    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };
    getRolePermissions(){
        let admin_status=sessionStorage.getItem("admin_status");
        let permissions=sessionStorage.getItem("permissions");
        let all_permissions=JSON.parse(permissions);
        let comp=new Array();
        const trans1=   <React.Fragment>
            <Route exact path={'/dashboard-sandbox'} element={<DashboardSandbox/>}/>
            <Route exact path={'/transactions-sandbox'} element={<TransectionsSandbox/>}/>
            <Route exact path={'/refund-sandbox'} element={<TransectionsRefundSandbox/>}/>
            <Route exact path={'/settlement-sandbox'} element={<SettlementsSandbox/>}/>
            <Route exact path={'/settlement-due-sandbox'} element={<SettlementsDueSandbox/>}/>
            <Route exact path={'/all-invoices-sandbox'} element={<AllInvoicesSandbox/>}/>
            <Route exact path={'/payment-links-sandbox'} element={<PaymentLinksSandbox/>}/>
            <Route exact path={'/reports-and-matrix-sandbox'} element={<ReportsAndMetricsSandbox/>}/>
            <Route exact path={'/customer-sandbox'} element={<CustomersSandbox/>}/>
            <Route exact path={'/customer-group-sandbox'} element={<CustomerGroupsSandbox/>}/>
            <Route exact path={'/transactions'} element={<Transections/>}/>
            <Route exact path={'/transaction-refund'} element={<TransectionsRefund/>}/>
            <Route exact path={'/settlements'} element={<Settlements/>}/>
            <Route exact path={'/settlements-refund'} element={<SettlementsDue/>}/>
            <Route exact path={'/settlements-usd'} element={<SettlementsUsd/>}/>
            <Route exact path={'/settlements-refund-usd'} element={<SettlementsDueUsd/>}/>
            <Route exact path={'/settlements-refund-gbp'} element={<SettlementsDueGBP/>}/>
            <Route exact path={'/checkmodal'} element={<CheckModal/>}/>
            <Route exact path={'/all-invoices'} element={<AllInvoices/>}/>
            <Route exact path={'/invoice-product-service'} element={<InvoiceProductServices/>}/>
            <Route exact path={'/invoice-templetes'} element={<InvoiceTemplates/>}/>
            <Route exact path={'/payment-links'} element={<PaymentLinks/>}/>
            <Route exact path={'/payment-links-school'} element={<PaymentLinkSchool/>}/>
            <Route exact path={'/customer-groups'} element={<CustomerGroups/>}/>
            <Route exact path={'/customer'} element={<Customers/>}/>
            <Route exact path={'/settings-general'} element={<SettingsGeneral/>}/>
            <Route exact path={'/settings-currency'} element={<SettingsCurrency/>}/>
            <Route exact path={'/upload-file'} element={<UploadFiles/>}/>
            <Route exact path={'/reports-metrics'} element={<ReportsAndMetrics/>}/>
            <Route exact path={'/invoice-settings'} element={<SettingsInvoice/>}/>
            <Route exact path={'/user-profile'} element={<UserProfile/>}/>
            <Route exact path={'/responsive'} element={<SupportButton/>}/>
            <Route exact path={'/timer'} element={<Timer/>}/>
            <Route exact path={'/settings-user'} element={<SettingsUser/>}/>
            <Route exact path={'/change-password'} element={<ChangePassword/>}/>
{/*BPSP*/}
            <Route exact path={'/bpsp-dashboard'} element={<BpspDashboard/>}/>
            <Route exact path={'/suppliers'} element={<Suppliers/>}/>
            <Route exact path={'/subscription-settings'} element={<SubscriptionSettings/>}/>
            <Route exact path={'/payment-link-transactions'} element={<PaymentLinkListTransactions/>}/>
        </React.Fragment>


        if(all_permissions && all_permissions.length)
        {

            all_permissions.map((list, key) => {
                (() => {

                    if (list.component_name == "Transactions") {

                        comp.push( <> <Route exact path={'transactions'} element={<Transections/>}/>
                            <Route exact path={'/transaction-refund'} element={<TransectionsRefund/>}/></>)

                    }
                    if (list.component_name == "Settlements") {

                        comp.push(    <>
                            <Route exact path={'/settlements'} element={<Settlements/>}/>
                            <Route exact path={'/settlements-refund'} element={<SettlementsDue/>}/>
                            <Route exact path={'/settlements-usd'} element={<SettlementsUsd/>}/>
                            <Route exact path={'/settlements-refund-usd'} element={<SettlementsDueUsd/>}/>
                        </>)

                    }
                    if (list.component_name == "Invoices") {

                        comp.push(  <>
                            <Route exact path={'/all-invoices'} element={<AllInvoices/>}/>
                            <Route exact path={'/invoice-product-service'} element={<InvoiceProductServices/>}/>
                            <Route exact path={'/invoice-templetes'} element={<InvoiceTemplates/>}/>
                        </>)

                    }
                    if (list.component_name == "Payment Links") {
                        comp.push(<>
                            <Route exact path={'/payment-links'} element={<PaymentLinks/>}/>
                        </>)

                    }
                    if (list.component_name == "Reports and Metrics") {

                        comp.push(  <>
                            <Route exact path={'/reports-metrics'} element={<ReportsAndMetrics/>}/>
                        </>)

                    }
                    if (list.component_name == "Customers") {

                        comp.push(  <>
                            <Route exact path={'/customer-groups'} element={<CustomerGroups/>}/>
                            <Route exact path={'/customer'} element={<Customers/>}/>
                        </>)

                    }
                    if (list.component_name == "Settings General") {

                        comp.push(  <>
                            <Route exact path={'/settings-general'} element={<SettingsGeneral/>}/>
                            <Route exact path={'/settings-currency'} element={<SettingsCurrency/>}/>
                            <Route exact path={'/settings-user'} element={<SettingsUser/>}/>
                            <Route exact path={'/invoice-settings'} element={<SettingsInvoice/>}/>
                            <Route exact path={'/user-profile'} element={<UserProfile/>}/>
                        </>)

                    } else {
                        return (
                            <div>catch all</div>
                        )
                    }
                })
                ()}

            )
        }

        let new_permissions=[];
        if(admin_status=="false"){
            new_permissions=comp;
        }else{
            new_permissions=trans1;
        }

        this.setState({
            navigation: new_permissions,
        });
    }
    render() {
            let admin_status=sessionStorage.getItem("admin_status");
           // let admin_status="false";
            let permissions_array=sessionStorage.getItem("permissions");
            let permissions=JSON.parse(permissions_array);

        return (

                     <Routes>

                    <Route exact path={'/active-dashboard'} element={<ActiveDashboard/>}/>
                    <Route exact path={'/login'} element={<SignIn/>}/>
                    <Route exact path={'/verification'} element={<Verification/>}/>
                    <Route exact path={'/confirmation'} element={<PasswordConfirmation/>}/>
                    <Route exact path={'/forgot-password'} element={<ForgotPassword/>}/>
                    <Route exact path={'/verify-code'} element={<VerifyCode/>}/>
                    <Route exact path={'/error'} element={<Errorpage/>}/>
                   
                    <Route exact path={'/bpsp-transaction-list'} element={<TransactionListTable/>}/>

                {this.state.navigation}
                         <Route exact path={'/'} element={<SignIn/>}/>
                </Routes>


        );
    }

}
