import React, {PureComponent} from 'react';


export class SupportButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.showInfo=this.showInfo.bind(this);
    }
    showInfo(){
        const plusButton = document.getElementById("plus-button");
        const callButton = document.getElementById("call-button");

            const callMenu = document.getElementById("call-menu");
            const plusMenu = document.getElementById("plus-menu");
            console.log(callMenu.classList);
            if (callMenu.classList.contains('active')) {
                callMenu.classList.remove("active");
                callButton.classList.remove("active");
            } else {
                if (plusMenu) {
                    plusMenu.classList.remove("active");
                }
                if (plusButton) {
                    plusButton.classList.remove("active");
                }
                callMenu.classList.add("active");
                callButton.classList.add("active");
            }


    }


    render() {
        return (
            <>

                <button id="call-button" onClick={this.showInfo}><i className="fas fa-headset" aria-hidden="true"></i><i className="fas fa-plus"></i>
                </button>

                <div id="call-menu">
                    <h4><i className="fas fa-headset" aria-hidden="true"></i> How Can We Help You?</h4>
                    <div className="pm-in">
                        <ul>
                            <li className="l-item">
                                <a href={"mailto:support@webxpay.com?subject=Merchant Dashboard Support Ticket - Name : "+sessionStorage.getItem("doing_business_name")+' (MERCHANT NUMBER : '+sessionStorage.getItem("merchant_number")+")"} >
                                <div className="icn"><i className="fas fa-ticket-alt"></i></div>
                                <p className="tp">Raise a Support Ticket</p>
                                <p className="bp">For System, Billing or Account Issues</p>
                                </a>
                            </li>
                          {/*  <li className="l-item">
                                <a href="https://webxpay.co/index.php/integrations/#devfaq" target="_blank">
                                <div className="icn"><i className="fas fa-book"></i></div>
                                <p className="tp">Search Knowledge Base</p>
                                <p className="bp">For Documentation on the System</p>
                                </a>
                            </li>*/}
                            {/*     <li className="l-item">
                                <a href="tel:+94117441900" >
                                <div className="icn"><i className="fas fa-headset"></i></div>
                                <p className="tp">Talk to an Agent</p>
                                <p className="bp">Speak, Chat or Email our Support Team</p>
                                </a>
                            </li>
                          <li className="l-item">
                                <a href="mailto:support@webxpay.com" target="_blank">
                                <div className="icn"><i className="far fa-comment-dots"></i></div>
                                <p className="tp">Share Feedback</p>
                                <p className="bp">Share Your Thoughts on our New System</p>
                                </a>
                            </li>*/}

                        </ul>
                    </div>
                </div>
            </>
        )
    }

}


