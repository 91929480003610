import React, { PureComponent } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Navigate  } from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {Navigation} from "../Navigation";
import {myConfig} from "../../config";
import {TopBar} from "../TopBar";
import ReactPaginate from "react-paginate";
import {GetWebxLaravel, PostCompnentWebx, PostWebxLaravel} from "../PostCompnent";
import {CSVLink} from "react-csv";
import {Footer} from "../Footer";
import {SupportButton} from "../SupportButton";
import {NavigationSandbox} from "./NavigationSandbox";
import {TopBarSandbox} from "./TopBarSandbox";

export class ReportsAndMetricsSandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_amount:'',
            payment_link:'',
            //customers list
            customer_list:[],
            customer_list_filter:[],
            customer_list_render:[],
            offsetCustomers: 0,
            perPageCustomers: 10,
            currentPageCustomers: 0,
            pageCountCustomers: 0,
            customer_keyword_value:'',
            //transaction list
            transaction_lists:[],
            transaction_lists_filters:[],
            transaction_lists_render:[],
            offsetTransactions: 0,
            perPageTransactions: 10,
            currentPageTransactions: 0,
            pageCountTransactions: 0,
            transaction_id:'',
            transaction_currency:'',
            transaction_status:'',
            transaction_value:'',
            transaction_store_reference:'',
            transaction_start_date:moment().subtract(30, 'days').format('ll'),
            transaction_end_date:moment(),
            //refund list
            refund_lists: [],
            refund_lists_render: [],
            refund_lists_filters:[],
            offsetRefunds: 0,
            perPageRefunds: 10,
            currentPageRefunds: 0,
            pageCountRefunds: 0,
            refund_start_date:moment().subtract(30, 'days').format('ll'),
            refund_end_date:moment(),
            //due list
            due_list:[],
            transaction_id_search:'',
            store_reference_id:'',
            transaction_amount:'',
            //settlements list
            settlement_lists:[],
            settlement_lists_filters:[],
            settlement_lists_render:[],
            offsetSettlements: 0,
            perPageSettlements: 10,
            currentPageSettlements: 0,
            pageCountSettlements: 0,
            settlement_start_date:moment().subtract(30, 'days').format('ll'),
            settlement_end_date:moment(),
            payout_reference:'',
            total_settlement:'',
            bank_name:'',
            //invoice list
            invoice_lists:[],
            invoice_lists_filters:[],
            invoice_lists_render:[],
            offsetInvoices: 0,
            perPageInvoices: 10,
            currentPageInvoices: 0,
            pageCountInvoices: 0,
            //due list
            due_lists:[],
            due_lists_filters:[],
            due_lists_render:[],
            offsetDues: 0,
            perPageDues: 10,
            currentPageDues: 0,
            pageCountDues: 0,
            //Other order list
            invoice_order_list:[],
            invoice_order_list_filters:[],
            invoice_order_list_render:[],
            offsetInvoiceOrderList: 0,
            perPageInvoiceOrderList: 10,
            currentPageInvoiceOrderList: 0,
            pageCountInvoiceOrderList: 0,
            invoice_order_start_date:moment().subtract(30, 'days').format('ll'),
            invoice_order_end_date:moment(),
            //Settlement Old Report
            payout_lists:[],
            payout_lists_filter:[],
            payout_lists_render:[],
            offsetPayoutLists: 0,
            perPagePayoutLists: 10,
            currentPagePayoutLists: 0,
            pageCountPayoutLists: 0,
            processing_currency:'',
            filter_date_start:moment().subtract(30, 'days').format('ll'),
            filter_date_end:moment(),
            dayCountSettlement:31,

            //transaction list
            transaction_lists_promotion:[],
            transaction_lists_filters_promotion:[],
            transaction_lists_render_promotion:[],
            offsetTransactions_promotion: 0,
            perPageTransactions_promotion: 10,
            currentPageTransactions_promotion: 0,
            pageCountTransactions_promotion: 0,
            transaction_id_promotion:'',
            transaction_currency_promotion:'',
            transaction_status_promotion:'',
            transaction_value_promotion:'',
            transaction_store_reference_promotion:'',
            transaction_start_date_promotion:moment().subtract(30, 'days').format('ll'),
            transaction_end_date_promotion:moment(),

            //other
            clearStatus:this.alertBlah,
            searchStatus:this.alertBlah,
            visibleclassTransaction:"inactive-class-table",
            visibleclassCustomers:"inactive-class-table",
            visibleclassRefund:"inactive-class-table",
            visibleClassSettlement:"inactive-class-table",
            visibleClassInvoice:"inactive-class-table",
            visibleClassDue:"inactive-class-table",
            visibleClassOrderList:"inactive-class-table",
            visibleClassPayoutList:"inactive-class-table",
            visibleClassTransactionPromotion:"inactive-class-table",
            refund_id:'',
            refund_value:'',
            refund_status:'',
            settlement_status:'',
            search_customer_name:'',
            search_invoice_id:'',
            search_invoice_value:'',
            payment_status_search:'',
            due_payment_status:'',
            due_type:'',
            order_tb_invoice_number:'',
            order_tb_reference_id:'',
            order_tb_customer_name:'',
            csvDataCustomer:[],
            csvDataTransaction:[],
            csvDataTransactionPromotion:[],
            csvDataRefund:[],
            csvDataDues:[],
            csvDataSettlements:[],
            csvDataInvoice:[],
            csvDataOrderList:[],
            csvDataPayoutList:[],
            day_count_invoice: 31,
            startDateCsvTransaction:moment().subtract(30, 'days').format('ll'),
            endDateCsvTransaction:moment().format('ll'),
            startDateCsvRefund:moment().subtract(30, 'days').format('ll'),
            endDateCsvRefund:moment().format('ll'),
            csvDataInvoiceStartDate:moment().subtract(30, 'days').format('ll'),
            csvDataInvoiceEndDate:moment().format('ll'),
            csvDataDueStartDate:moment().subtract(30, 'days').format('ll'),
            csvDataDueEndDate:moment().format('ll'),
            csvSettlementStartDate:moment().subtract(30, 'days').format('ll'),
            csvSettlementEndDate:moment().format('ll'),
            startDateCsvTransactionPromotion:moment().subtract(30, 'days').format('ll'),
            endDateCsvTransactionPromotion:moment().format('ll'),
            promotion_available:false
        };


        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.handlePageClickRefunds = this.handlePageClickRefunds.bind(this);
        this.handlePageClickInvoices = this.handlePageClickInvoices.bind(this);
        this.handlePageClickDues = this.handlePageClickDues.bind(this);
        this.handlePageClickSettlements=this.handlePageClickSettlements.bind(this);
        this.handlePageClickPayouts=this.handlePageClickPayouts.bind(this);
        this.handlePageClickInvoiceOrder=this.handlePageClickInvoiceOrder.bind(this);
        this.handlePageClickTransactionsPromotion=this.handlePageClickTransactionsPromotion.bind(this);



        this.onChanges = this.onChanges.bind(this);
        this.onChangesSelect = this.onChangesSelect.bind(this);

        this.GenerateCustomerList = this.GenerateCustomerList.bind(this);
        this.GenerateTransactionList = this.GenerateTransactionList.bind(this);
        this.GenerateRefundList = this.GenerateRefundList.bind(this);
        this.GenerateSettlementsList = this.GenerateSettlementsList.bind(this);
        this.GenerateInvoiceList = this.GenerateInvoiceList.bind(this);
        this.GenerateDueList = this.GenerateDueList.bind(this);
        this.GenerateTransactionListPromotion = this.GenerateTransactionListPromotion.bind(this);

        this.GeneratePayoutList = this.GeneratePayoutList.bind(this);
        this.generateCsvReportInvoice = this.generateCsvReportInvoice.bind(this);
        this.csvGenerateTransactionPromotion = this.csvGenerateTransactionPromotion.bind(this);

        //transaction filter
        this.filterListTransaction=this.filterListTransaction.bind(this);
        this.clearListTransaction=this.clearListTransaction.bind(this);
        //customer filter
        this.filterListCustomer=this.filterListCustomer.bind(this);
        this.clearListCustomer=this.clearListCustomer.bind(this);
        //refund filter
        this.filterListRefund=this.filterListRefund.bind(this);
        this.clearListRefund=this.clearListRefund.bind(this);
        //settlements filter
        this.filterListSettlements=this.filterListSettlements.bind(this);
        this.clearListSettlements=this.clearListSettlements.bind(this);
        //invoice filter
        this.filterListInvoice=this.filterListInvoice.bind(this);
        this.clearListInvoice=this.clearListInvoice.bind(this);
        //due filter
        this.filterListDues=this.filterListDues.bind(this);
        this.clearListDues=this.clearListDues.bind(this);
        //order list filter
        this.filterListInvoiceOrder=this.filterListInvoiceOrder.bind(this);
        this.clearListInvoiceOrder=this.clearListInvoiceOrder.bind(this);
        //payout filter
        this.filterListPayout=this.filterListPayout.bind(this);
        this.clearListPayout=this.clearListPayout.bind(this);

        //promotional transaction filter
        this.filterListTransactionPromotion=this.filterListTransactionPromotion.bind(this);
        this.clearListTransactionPromotion=this.clearListTransactionPromotion.bind(this);


        this.onChanges = this.onChanges.bind(this);
        this.alertBlah = this.alertBlah.bind(this);
        this.handleCallbackTransaction=this.handleCallbackTransaction.bind(this);
        this.handleCallbackRefund=this.handleCallbackRefund.bind(this);
        this.handleCallbackTransactionPromotion=this.handleCallbackTransactionPromotion.bind(this);
        this.loadTableTransactionsPromotion=this.loadTableTransactionsPromotion.bind(this);



        this.handleCallbackInvoiceOrder=this.handleCallbackInvoiceOrder.bind(this);
        this.handleCallbackPayouts=this.handleCallbackPayouts.bind(this);
        this.handleCallbackSettlement=this.handleCallbackSettlement.bind(this);
        this.handleCallbackInvoice=this.handleCallbackInvoice.bind(this);
        this.checkPromotionsAvailable=this.checkPromotionsAvailable.bind(this);
    }

    checkPromotionsAvailable(){
        PostCompnentWebx( "checkPromotionsAvailable").then(response=>{
            this.handleCloseProgress();
            let promotion_available=false;
            if (response.data.status==1){
                promotion_available=true;
            }else{
                promotion_available=false;
            }
            this.setState({
                promotion_available:promotion_available
            });
        });
    }
    alertBlah(){

    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    //Customers List
    GenerateCustomerList(){

        this.handleToggleProgress();
        GetWebxLaravel("customers/get_customers/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            this.handleCloseProgress();
            const csvData = [["Customer First Name","Customer Last Name","Customer E-Mail","Customer Phone Number",'Customer Website Url',"Customer Address Line 1","Customer Address Line 2","Customer City","Customer Country","Active Since"]];
            response.data.map((value, index) => {
                csvData.push([
                    value.customer_first_name,
                    value.customer_last_name,
                    value.customer_email,
                    value.customer_phone_number,
                    value.customer_website_url,
                    value.customer_address_line_1,
                    value.customer_address_line_2,
                    value.customer_city,
                    value.get_countries.nicename,
                    moment(value.created_at).format('ll')
                ]);
            });
            this.handleCloseProgress();
            this.setState({
                csvDataCustomer:csvData,
                customer_list:response.data,
                customer_list_filter:response.data,
            },()=>{
                this.loadTableCustomers();
            });
        });

    }
    loadTableCustomers(){
        const list_data=this.state.customer_list_filter;
        const zeroPad = (num, places) => String(num).padStart(places, '0');

        const slice = list_data.slice(this.state.offsetCustomers, this.state.offsetCustomers + this.state.perPageCustomers);
        const trans=  list_data && list_data.length?(slice.map((list,key) =>
            <tr className='' data-toggle="modal" data-target="#modal-1">
                <td className='' data-title='#'>{zeroPad(key+1,3)}</td>
                <td className='' data-title='Customer Name'>{list.customer_first_name} {list.customer_last_name}
                </td>
                <td className='' data-title='Email Address'>{list.customer_email}
                </td>
                <td className='' data-title='Phone Number'>{list.customer_phone_number}</td>
                <td className='' data-title='Active Since'>{moment(list.created_at).format('ll')}</td>

            </tr>
        ) ):(
            <tr className=''>
                <td colSpan="8" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
            </tr>
        );
        this.setState({
            pageCountCustomers: Math.ceil(list_data.length / this.state.perPageCustomers),
            customer_list_render:trans
        })
    }
    handlePageClickCustomers = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageCustomers;

        this.setState({
            currentPageCustomers: selectedPage,
            offsetCustomers: offset
        }, () => {
            this.loadTableCustomers();
        });

    };
    filterListCustomer(){
        let keyword = this.state.customer_keyword_value.toLowerCase();
        let formData = new FormData();    //formdata object
        formData.append('keyword', keyword);   //append the values with key, value pair
        formData.append('merchant_id', sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"));   //append the values with key, value pair

        this.handleToggleProgress();
        PostWebxLaravel("customers/get_customer_with_filters", formData).then(response => {
            this.handleCloseProgress();
            this.setState({
                customer_list:response.data,
                customer_list_filter:response.data,
            },()=>{
                this.loadTableCustomers();
            });
        });
    }
    clearListCustomer(){
        this.handleToggleProgress();
        GetWebxLaravel( "customers/get_customers/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    keyword:'',
                    customer_list:response.data,
                    customer_list_filter:response.data,
                },()=>{
                    this.loadTableCustomers()
                });
            }
        });

    }

    //Transaction List
    GenerateTransactionList(start,end){

        if (end && start) {
            this.handleToggleProgress();
            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd =moment(end).format('YYYY-MM-DD');
            var a = moment(end);
            var b = moment(start);
            this.setState({
                startDateCsvTransaction:start,
                endDateCsvTransaction:end
            });
            let days_count=a.diff(b, 'days')   // =1
            days_count=parseInt(days_count)+parseInt(1);

            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);
            formData.append('expand_view', "");   //append the values with key, value pair
            this.handleToggleProgress();
            PostCompnentWebx( "getStoreTransactions", formData).then(response=>{
                this.handleCloseProgress();
                this.csvGenerateTransaction(response);
                this.handleCloseProgress();
                this.setState({
                    transaction_lists:response.data,
                    transaction_lists_filters:response.data,
                    dayCount: days_count
                }, () => {
                    this.loadTableTransactions()
                });
            });
        }
    }
    loadTableTransactions(){
        const list_data=this.state.transaction_lists_filters;

        const slice = list_data.slice(this.state.offsetTransactions, this.state.offsetTransactions + this.state.perPageTransactions);
        const trans=  list_data && list_data.length?(slice.map(list =>  <tr className='' /*onClick={() => this.ModalCheckTrans(list.order_refference_number)}*/>
            <td className='' data-title='#'>{list.order_id}</td>
            <td className=''
                data-title='Transaction ID'>{list.order_refference_number}
            </td>
            <td className=''
                data-title='Store Reference'>{list.store_reference}
            </td>
            <td className='' data-title='Transaction Value'>
                <NumberFormat
                    value={(Math.round(list.total_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={list.processing_currency_id==5?'LKR ':'USD '}/>
            </td>
            <td className='' data-title='Transaction Value'>
                {
                    (() => {
                        let default_size="35px";
                        let max_size="63px";
                        if (list.payment_gateway_id==35 ||list.payment_gateway_id==42||list.payment_gateway_id==45||list.payment_gateway_id==2||list.payment_gateway_id==3||list.payment_gateway_id==16){
                            default_size="35px";
                        }else {
                            default_size=max_size;
                        }

                        if (list.card_type.toLowerCase()=="visa")
                            return  <img style={{width:"35px"}} src={myConfig.laravelWeb+'gateway_logos/visa.jpg'} alt=""/>
                        if (list.card_type.toLowerCase()=="master"||list.card_type.toLowerCase()=="mastercard" )
                            return  <img style={{width:"35px"}} src={myConfig.laravelWeb+'gateway_logos/master.jpg'} alt=""/>
                        else
                            return  <img style={{width:default_size}} src={myConfig.laravelWeb+'gateway_logos/'+list.payment_gateway_id+'.jpg'} alt=""/>

                    })()
                }
            </td>
            <td className='' data-title='Date of Transaction'>{moment(list.date_added).format('ll')}
            </td>
            <td className='pop-td' data-toggle="popover"
                data-trigger="hover" data-placement="auto"
                data-content="Bank was Unable to Authenticate Transaction">
                {list.payment_status_id == 2 ? (<span className="dot green"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                {list.payment_status_id == 3 ? (<span className="dot red"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                {list.payment_status_id == 2 ? "Approved" : ''}
                {list.payment_status_id == 3 ? "Declined" : ''}
            </td>
        </tr>) ):(
            <tr className=''>
                <td colSpan="6" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
            </tr>

        );
        this.setState({
            pageCountTransactions: Math.ceil(list_data.length / this.state.perPageTransactions),
            transaction_lists_render:trans
        })
    }
    handlePageClickTransactions = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageTransactions;

        this.setState({
            currentPageTransactions: selectedPage,
            offsetTransactions: offset
        }, () => {
            this.loadTableTransactions();
        });

    };
    filterListTransaction() {
        this.handleToggleProgress();
        let order_ref_no = this.state.transaction_id.toLowerCase();
        let status = this.state.transaction_status;
        let transaction_value = this.state.transaction_value.toLowerCase();
        let transaction_store_reference = this.state.transaction_store_reference.toLowerCase();
        let transaction_currency = this.state.transaction_currency;


        let startDate= moment(this.state.transaction_start_date).format('YYYY-MM-DD');
        let endDate= moment(this.state.transaction_end_date).format('YYYY-MM-DD');
        if (this.state.startDate=="") {
            startDate=moment().subtract(30, 'days').format('YYYY-MM-DD')
        }
        if (this.state.endDate==""){
            endDate= moment().format('YYYY-MM-DD');
        }
        this.setState({
            startDateCsvTransaction:moment(startDate).format('ll'),
            endDateCsvTransaction:moment(endDate).format('ll')
        });
        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        let formData = new FormData();    //formdata object
        formData.append('filter_date_start', startDate);
        formData.append('filter_date_end', endDate);

        formData.append('total_amount', transaction_value);   //append the values with key, value pair
        formData.append('order_refference_number', order_ref_no);   //append the values with key, value pair
        formData.append('payment_status_id', status);   //append the values with key, value pair
        formData.append('expand_view', "");   //append the values with key, value pair
        formData.append('processing_currency_id',transaction_currency );   //append the values with key, value pair
        formData.append('store_reference',transaction_store_reference );   //append the values with key, value pair

        PostCompnentWebx("getStoreTransactions", formData).then(response => {
            this.handleCloseProgress();
            this.csvGenerateTransaction(response);

            this.handleCloseProgress();
            this.setState({
                transaction_lists:response.data,
                transaction_lists_filters:response.data,
                dayCount: days_count
            }, () => {
                this.loadTableTransactions()
            });

        });
        this.handleToggleProgress();
        PostCompnentWebx("getStoreTransactionAndRefundTotal",formData).then(response=>{
            this.handleCloseProgress();
            this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
            this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
            this.setState({usd_refund_total: response.data.totals.usd_refund_total});
            this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
        });

    }
    clearListTransaction(){
        this.handleToggleProgress();
        let formData = new FormData();    //formdata object
        let startDate=  moment().subtract(30, 'days').format('YYYY-MM-DD');
        let endDate= moment().format('YYYY-MM-DD');
        formData.append('filter_date_start', startDate);
        formData.append('filter_date_end', endDate);
        formData.append('expand_view', "");   //append the values with key, value pair
        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);
        PostCompnentWebx("getStoreTransactions", formData).then(response => {
            this.handleCloseProgress();
            this.csvGenerateTransaction(response);
            this.setState({
                transaction_lists:response.data,
                transaction_lists_filters:response.data,
                dayCount: days_count,
                transaction_id: '',
                transaction_status: '',
                transaction_currency: '',
                transaction_value: '',
                transaction_store_reference: '',
            }, () => {
                this.loadTableTransactions()
            });
        });
    }
    handleCallbackTransaction(start, end, label) {
        this.setState({
           transaction_start_date:start,
           transaction_end_date:end,
        });
        this.filterListTransaction(moment(start).format('ll'),moment(end).format('ll'));
    }
    csvGenerateTransaction(response){


        const csvData = [[]];

        csvData.push([
            "","","Transaction Report -",this.state.startDateCsvTransaction+'-'+this.state.endDateCsvTransaction
        ]);
        csvData.push([]);

        csvData.push(["Transaction Date","Store Order ID","Customer First Name", "Customer Last Name", "Transaction ID","Processing Currency","Order Amount","Convenience Fee / Discount", "Transaction Amount", "Gateway Discount", "Transaction Net", "Payment Gateway","Card Type","Payment Status"]);
        csvData.push([]);
        response.data.map((value, index) => {
            let payment_status="";
            if(value.payment_status_id==2){
                payment_status="Approved";
            }else if (value.payment_status_id==3){
                payment_status="Declined";
            }
            csvData.push([moment(value.date_added).format('ll'), value.store_reference, value.first_name, value.last_name, value.order_refference_number,value.processing_currency_id == 5 ? "LKR" : "USD", value.order_amount,value.convenience_fee, value.total_amount, value.discount_amount,value.transaction_net, value.payment_gateway,value.card_type,payment_status]);
        });

        this.setState({
           csvDataTransaction:csvData
        });
    }

    //Refund List
    GenerateRefundList(start,end){
        var a = moment(end);
        var b = moment(start);
        let days_count=a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);
        if (end && start) {

            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');


            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);
            this.handleToggleProgress();
            PostCompnentWebx( "getStoreRefunds", formData).then(response=>{
                this.generateCsvDataRefund(response);
                this.handleCloseProgress();
                this.setState({
                    refund_lists: response.data,
                    refund_lists_filters:response.data,
                    dayCount: days_count
                }, () => {
                    this.loadTableRefunds()
                });
            });

        }
    }
    loadTableRefunds(){
        const list_data = this.state.refund_lists_filters;
        const slice = list_data.slice(this.state.offsetRefunds, this.state.offsetRefunds + this.state.perPageRefunds);
        const trans = list_data && list_data.length ? (slice.map(list => <tr className=''>
            <td className='' data-title='#'>{list.order_id}</td>
            <td className='' data-title='Transaction ID'>{list.order_refference_number}
            </td>
            {/*   <td className='' data-title='Refund ID'>REF 12323/1223</td>*/}
            <td className=''
                data-title='Transaction Value'>{list.processing_currency_id == 5 ? 'LKR ' : 'USD '}{list.total_amount}
            </td>
            <td className=''
                data-title='Refund Value'>{list.processing_currency_id == 5 ? 'LKR ' : 'USD '}{list.refund_amount}
            </td>
            <td className='' data-title='Date of Transaction'>
                {moment(list.order_date).format('ll')}
            </td>
            {/*    <td className='' data-title='Contact'>Sebastian Vettel</td>
                                                        <td className='' data-title='Email Address'>seb@formula1.com
                                                        </td>*/}
            <td className='pop-td' data-toggle="popover"
                data-trigger="hover" data-placement="auto"
                data-content="Bank was Unable to Authenticate Transaction">
                {list.status == 2 ? (<span className="dot green"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                {list.status == 1 ? (<span className="dot yellow"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                {list.status == 3 ? (<span className="dot red"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                {list.status == 1 ? "Pending" : ''}
                {list.status == 2 ? "Refunded" : ''}
                {list.status == 3 ? "Rejected" : ''}
            </td>
        </tr>)) : (
            <tr className=''>
                <td colSpan="6" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
            </tr>
        );
        this.setState({
            pageCountRefunds: Math.ceil(list_data.length / this.state.perPageRefunds),
            refund_lists_render: trans
        });
    }
    handlePageClickRefunds(e){
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageRefunds;
        this.setState({
            currentPageRefunds: selectedPage,
            offsetRefunds: offset
        }, () => {
            this.loadTableRefunds();
        });
    }
    filterListRefund() {

        let order_ref_no = this.state.refund_id.toLowerCase();
        let status = this.state.refund_status.toLowerCase();
        let refund_amount = this.state.refund_value.toLowerCase();
        let transaction_currency = this.state.transaction_currency;
        let refund_lists = this.state.refund_lists, refund_lists_filters=[];

        let startDate= moment(this.state.refund_start_date).format('YYYY-MM-DD');
        let endDate= moment(this.state.refund_end_date).format('YYYY-MM-DD');
        if (this.state.refund_start_date=="") {
            startDate=moment().subtract(30, 'days').format('YYYY-MM-DD');
        }
        if (this.state.refund_end_date==""){
            endDate= moment().format('YYYY-MM-DD');
        }
        this.setState({
            startDateCsvRefund:moment(startDate).format('ll'),
            endDateCsvRefund:moment(endDate).format('ll')
        })

        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        let formData=new FormData();
        formData.append('filter_date_start', startDate);   //append the values with key, value pair
        formData.append('filter_date_end', endDate);
        formData.append('refund_amount', refund_amount);
        formData.append('order_refference_number', order_ref_no);
        formData.append('processing_currency_id', transaction_currency);
        formData.append('status', status);

        this.handleToggleProgress();

        PostCompnentWebx("getStoreRefunds",formData).then(response=>{

           this.generateCsvDataRefund(response);
            this.handleCloseProgress();
            this.setState({
                refund_lists: response.data,
                refund_lists_filters:response.data,
                dayCount: days_count
            }, () => {
                this.loadTableRefunds()
            });
        });
        this.handleToggleProgress();
        PostCompnentWebx("getStoreTransactionAndRefundTotal",formData).then(response=>{
            this.handleCloseProgress();
            this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
            this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
            this.setState({usd_refund_total: response.data.totals.usd_refund_total});
            this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
        });
    }
    clearListRefund(){


        let startDate=  moment().subtract(30, 'days').format('YYYY-MM-DD');
        let endDate= moment().format('YYYY-MM-DD');

        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);
        let formData=new FormData();
        formData.append('filter_date_start', startDate);   //append the values with key, value pair
        formData.append('filter_date_end', endDate);
        this.setState({
            refund_start_date:moment(startDate).format('ll'),
            refund_end_date:moment(endDate).format('ll')
        })

        this.handleToggleProgress();

        PostCompnentWebx("getStoreRefunds",formData).then(response=>{
            this.generateCsvDataRefund(response);
            this.handleCloseProgress();
            this.setState({
                refund_lists: response.data,
                refund_lists_filters:response.data,
                dayCount: days_count,
                refund_id:'',
                refund_status:'',
                refund_value:'',
                transaction_currency:''
            }, () => {
                this.loadTableRefunds()
            });
        });
        this.handleToggleProgress();
        PostCompnentWebx("getStoreTransactionAndRefundTotal",formData).then(response=>{
            this.handleCloseProgress();
            this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
            this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
            this.setState({usd_refund_total: response.data.totals.usd_refund_total});
            this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
        });
    }
    generateCsvDataRefund(response){
        const csvData = [[
            "","","Refund Report","From "+this.state.startDateCsvRefund +" To "+this.state.endDateCsvRefund
             ]];
        csvData.push([]);

        csvData.push(["Transaction Date","Store Order ID","Transaction ID","Transaction Currency", "Transaction Amount","Refund Requested Date","Refund Amount","Status"]);

        response.data.map((value, index) => {
                csvData.push([moment(value.order_date).format("MMMM Do YYYY"),value.gateway_order_id,value.order_refference_number,value.processing_currency_id==5?"LKR":"USD",value.total_amount,moment(value.refund_request_date).format("ll"),value.refund_amount,value.order_status=="Abendend"?"Pending Transaction":value.order_status]);
        });
        this.setState({csvDataRefund:csvData})
    }
    handleCallbackRefund(start,end){
        this.setState({
            refund_start_date:moment(start).format('ll'),
            refund_end_date:moment(end).format('ll')
        })
        this.filterListRefund(moment(start).format('ll'),moment(end).format('ll'));
    }

    //Due Report
    GenerateDueList(start,end){
        var a = moment(end);
        var b = moment(start);
        let days_count=a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);
        if (end && start) {
            this.handleToggleProgress();

            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');


            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);


            this.handleToggleProgress();
            PostCompnentWebx( "getMerchantDueTransactionsAndRefunds", formData).then(response=>{

                this.generateDueCsv(response);
                this.handleCloseProgress();
                this.setState({
                    due_lists: response.data,
                    due_lists_filters:response.data,
                    dayCount: days_count
                }, () => {
                    this.loadTableDue()
                });
            });
        }
    }
    loadTableDue(){

        const list_data = this.state.due_lists_filters;

        const slice = list_data.slice(this.state.offsetDues, this.state.offsetDues + this.state.perPageDues);
        const trans = list_data && list_data.length ? (slice.map(list => <tr className=''>
            <td className='' data-title='Transaction Date'>{moment(list.transaction_date).format('ll')}
            </td>
            <td className='' data-title='Order Reference No'>{list.store_order_reference}
            </td>
            <td className='' data-title='Order Reference No'>{list.order_reference}
            </td>
            <td className='' data-title='Name'>{list.first_name} {list.last_name}
            </td>


            <td className='' data-title='Payment Currency'>{list.processing_currency}
            </td>
            <td className='' data-title='Payment Currency'>
                <NumberFormat
                    value={(Math.round(list.transaction_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={' '}/>
            </td>


            <td className='' data-title='Refund Charge'>
                <NumberFormat
                    value={(Math.round(list.refund_charge * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
            </td>
            <td className='' data-title='Payment Amount'>
                <NumberFormat
                    value={(Math.round(list.payment_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={list.payment_currency + ' '}/>


            </td>

        </tr>)) : (
            <tr className=''>
                <td colSpan="14" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
            </tr>
        );
        this.setState({
            pageCountDues: Math.ceil(list_data.length / this.state.perPageDues),
            due_lists_render: trans
        });
    }
    handlePageClickDues(e){
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageDues;
        this.setState({
            currentPageDues: selectedPage,
            offsetDues: offset
        }, () => {
            this.loadTableDue();
        });
    }
    filterListDues() {

        this.handleToggleProgress();

        let transaction_id_search=this.state.transaction_id_search;
        let store_reference_id=this.state.store_reference_id;
        let transaction_amount=this.state.transaction_amount;
        let formData = new FormData();    //formdata object


        formData.append('order_refference_number',transaction_id_search );   //append the values with key, value pair
        formData.append('gateway_order_id',store_reference_id );   //append the values with key, value pair
        formData.append('total_amount ', transaction_amount);   //append the values with key, value pair


        PostCompnentWebx("getMerchantDueTransactionsAndRefunds", formData).then(response => {
            this.handleCloseProgress();
            this.generateDueCsv(response)
/*            const csvData = [
                ["Transaction Date","Store Reference","Transaction ID", "Customer Name","Processing Currency","Transaction Amount","Gateway Charge","Net Transaction","Refund Charges","Settlement Currency","Settlement Amount","Payment Gateway"]
            ];
            response.data.map((list, index) => {
                csvData.push([moment(list.transaction_date).format('ll'),list.store_order_reference,list.order_reference,list.first_name+' '+list.last_name,list.processing_currency,list.transaction_amount,list.gateway_charge,list.transaction_net,list.refund_charge,list.payment_currency,list.payment_amount]);
            });*/

            this.setState({
                //csvData:csvData,
                due_lists_filters:response.data,
                due_lists:response.data,

            }, () => {
                this.loadTableDue()
            });

        });
    }
    clearListDues(){
        this.handleToggleProgress();
        let formData = new FormData();    //formdata object
        PostCompnentWebx("getMerchantDueTransactionsAndRefunds", formData).then(response => {
            this.handleCloseProgress();
            const csvData = [
                ["Transaction Date","Store Reference","Transaction ID", "Customer Name","Processing Currency","Transaction Amount","Gateway Charge","Net Transaction","Refund Charges","Settlement Currency","Settlement Amount","Payment Gateway"]
            ];
            response.data.map((list, index) => {
                csvData.push([moment(list.transaction_date).format('ll'),list.store_order_reference,list.order_reference,list.first_name+' '+list.last_name,list.processing_currency,list.transaction_amount,list.gateway_charge,list.transaction_net,list.refund_charge,list.payment_currency,list.payment_amount]);
            });

            this.setState({
                csvData:csvData,
                due_lists_filters:response.data,
                due_lists:response.data,
                transaction_id_search:'',
                store_reference_id:'',
                transaction_amount:''
            }, () => {
                this.loadTableDue()
            });

        });
    }
    handleCallbackDues(start,end){
        this.GenerateDueList(moment(start).format('ll'),moment(end).format('ll'));
    }
    generateDueCsv(response){
        const csvData = [[
            "","","Due Report","From "+this.state.csvDataDueStartDate +" To "+this.state.csvDataDueEndDate
        ]];
        csvData.push([]);
        csvData.push(
            ["Transaction Date","Store Reference","Transaction ID", "Customer Name","Processing Currency","Transaction Amount","Gateway Discount","Net Transaction","Refund Charge","Settlement Currency","Settlement Amount","Payment Gateway"]
        );
        csvData.push([]);
        response.data.map((list, index) => {
            csvData.push([moment(list.transaction_date).format('ll'),list.store_order_reference,list.order_reference,list.first_name+' '+list.last_name,list.processing_currency,list.transaction_amount,list.gateway_charge,list.transaction_net,list.refund_charge,list.payment_currency,list.payment_amount,list.payment_gateway]);

        })
        this.setState({
            csvDataDues:csvData,
        });
    }

    //Settlements List
    GenerateSettlementsList(start,end){

        if (end && start) {
            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd =moment(end).format('YYYY-MM-DD');


            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);
            var a = moment(start);
            var b = moment(end);
            let days_count = b.diff(a, 'days')   // =1
            days_count=parseInt(days_count)+parseInt(1);
            this.handleToggleProgress();
            PostCompnentWebx( "getStoreSettlements",formData).then(response=>{
                this.generateSettlementCSV(response);

                this.handleCloseProgress();
                this.setState({
                    dayCountSettlement:days_count,
                    settlement_lists:response.data,
                    settlement_lists_filters:response.data,
                },()=>{
                    this.loadTableSettlements();
                })
            });
        }
    }
    handlePageClickSettlements(e){

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageSettlements;

        this.setState({
            currentPageSettlements: selectedPage,
            offsetSettlements: offset
        }, () => {
            this.loadTableSettlements();
        });
    }
    loadTableSettlements(){
        const list_data=this.state.settlement_lists_filters;

        const slice = list_data.slice(this.state.offsetSettlements, this.state.offsetSettlements + this.state.perPageSettlements);
        const trans=  list_data && list_data.length?(slice.map((list,key) =>   <tr className=''>
                <td className='' data-title='#'>{key+1}</td>
                <td className='' data-title='Settlement ID'>{list.payout_reference}
                </td>
            <td className='' data-title='Total Settlement'><NumberFormat
                value={(Math.round(list.lkr_transaction_amount * 100) / 100).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
            </td>
            <td className='' data-title='Total Settlement'><NumberFormat
                value={(Math.round(list.usd_transaction_amount * 100) / 100).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={'USD '}/>
            </td>

            <td className='' data-title='Deductions'><NumberFormat
                value={(Math.round(list.lkr_discount_amount * 100) / 100).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></td>
            <td className='' data-title='Amount Settled'><NumberFormat
                value={(Math.round(list.usd_discount_amount * 100) / 100).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={'USD '}/>
            </td>
            <td className='' data-title='Amount Settled'><NumberFormat
                value={(Math.round(list.lkr_refund_payment_amount * 100) / 100).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
            </td>
            <td className='' data-title='Amount Settled'><NumberFormat
                value={(Math.round(list.total_lkr_settlement * 100) / 100).toFixed(2)}
                displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
            </td>
            <td className='' data-title='Settlement Date'>{moment(list.settlement_date).format('ll')}
            </td>
            <td className='' data-title='Account Deposited'>{list.bank_name}
            </td>

                {/*<td className='pop-td' data-toggle="popover"
                    data-trigger="hover" data-placement="auto"
                    data-content="Your Settlement will be Credited within 72 Hours">
                                                            <span className="dot yellow"><i className="fas fa-circle"
                                                                                            aria-hidden="true"></i></span> Settlement
                    Pending
                </td>*/}
            </tr>
        ) ):(
            <tr className=''>
                <td colSpan="11" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
            </tr>
        )
        this.setState({
            pageCountSettlements: Math.ceil(list_data.length / this.state.perPageSettlements),
            settlement_lists_render:trans
        })
    }
    filterListSettlements() {
        let payout_reference = this.state.payout_reference.toLowerCase();
        let total_settlement = this.state.total_settlement.toLowerCase();
        let bank_name = this.state.bank_name.toLowerCase();
        let settlement_lists = this.state.settlement_lists, settlement_lists_filters = [];



        let startDate= moment(this.state.settlement_start_date).format('YYYY-MM-DD');
        let endDate= moment(this.state.settlement_end_date).format('YYYY-MM-DD');
        if (this.state.settlement_start_date=="") {
            startDate=moment().subtract(30, 'days').format('YYYY-MM-DD')
        }
        if (this.state.settlement_end_date==""){
            endDate= moment().format('YYYY-MM-DD');
        }

        this.setState({
            csvSettlementStartDate:moment(startDate).format('ll'),
            csvSettlementEndDate:moment(endDate).format('ll')
        });

        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        let formData = new FormData();    //formdata object
        formData.append('filter_date_start', startDate);
        formData.append('filter_date_end', endDate);
        formData.append('payout_reference', payout_reference);
        formData.append('bank_name', bank_name);
        this.handleToggleProgress();
        PostCompnentWebx("getStoreSettlements", formData).then(response => {
            this.handleCloseProgress();
            this.generateSettlementCSV(response)
            this.setState({
                settlement_lists: response.data,
                settlement_lists_filters: response.data,
                dayCount: days_count
            }, () => {
                this.loadTableSettlements();
            })
            // this.setState({transaction_lists:response.data});

        });
        this.handleToggleProgress();
        PostCompnentWebx("getMerchantDueTransactionsAndRefunds", formData).then(response => {
            this.handleCloseProgress();
            this.setState({
                due_lists: response.data,
            });
        });
        this.handleToggleProgress();
        PostCompnentWebx("getStoreTransactionAndRefundTotal", formData).then(response => {
            this.handleCloseProgress();
            this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
            this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
            this.setState({usd_refund_total: response.data.totals.usd_refund_total});
            this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
        });

    }
    clearListSettlements(){
        let startDate=moment().subtract(30, 'days').format('YYYY-MM-DD');
        let endDate= moment(this.state.endDate).format('YYYY-MM-DD');

        if (this.state.endDate==""){
            endDate= moment().format('YYYY-MM-DD');
        }

        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        let formData = new FormData();    //formdata object
        formData.append('filter_date_start', startDate);
        formData.append('filter_date_end', endDate);
        this.handleToggleProgress();
        PostCompnentWebx("getStoreSettlements", formData).then(response => {
            this.handleCloseProgress();
            this.generateSettlementCSV(response);
            this.setState({

                settlement_lists: response.data,
                settlement_lists_filters: response.data,
                dayCount: days_count
            }, () => {
                this.loadTableSettlements();
            })
            // this.setState({transaction_lists:response.data});

        });
        PostCompnentWebx("getMerchantDueTransactionsAndRefunds", formData).then(response => {
            this.setState({
                due_lists: response.data,
                dayCount: days_count
            });
        });

        PostCompnentWebx("getStoreTransactionAndRefundTotal", formData).then(response => {
            this.handleCloseProgress();
            this.setState({lkr_transaction_total: response.data.totals.lkr_transaction_total});
            this.setState({lkr_refund_total: response.data.totals.lkr_refund_total});
            this.setState({usd_refund_total: response.data.totals.usd_refund_total});
            this.setState({usd_transaction_total: response.data.totals.usd_transaction_total});
        });
        this.setState({
            payout_reference:'',
            total_settlement:'',
            bank_name:''
        }, () => {
            this.loadTableSettlements()
        });

    }
    generateSettlementCSV(response){

        const csvData = [[
            "","","Settlement Report","From "+this.state.csvSettlementStartDate +" To "+this.state.csvSettlementEndDate
        ]];
        csvData.push([]);
        csvData.push(
            [ "Settlement ID", "Total Order Value- LKR", "Total Order Value-USD", "Gateway Deduction LKR", "Gateway Deduction USD","Refund","Settlement Date","Settlement Amount","Deposited Account"]
        );
        csvData.push([])
        response.data.map((value, index) => {
            csvData.push([value.payout_reference, value.lkr_transaction_amount, value.usd_transaction_amount, value.lkr_discount_amount,value.usd_discount_amount,value.lkr_refund_payment_amount, moment(value.settlement_date).format('ll'),value.total_lkr_settlement, value.bank_name]);
        });

        this.setState({
            csvDataSettlements:csvData
        });
    }
    handleCallbackSettlement(start,end){
        this.GenerateSettlementsList(moment(start).format('ll'),moment(end).format('ll'));
    }
    //Invoice List
    GenerateInvoiceList(){
        this.handleToggleProgress();
        GetWebxLaravel("invoice_detail/get_invoice/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            this.handleCloseProgress();
          this.generateCsvReportInvoice(response);
            this.setState({
                invoice_lists:response.data,
                invoice_lists_filters:response.data,
            },()=>{
                this.loadTableInvoices();
            });
        });
    }
    loadTableInvoices(e){
        const list_data=this.state.invoice_lists_filters;
        const slice = list_data.slice(this.state.offsetInvoices, this.state.offsetInvoices + this.state.perPageInvoices);

        const trans=  list_data && list_data.length?(slice.map((list, key) =>

            <tr className='' >
                <td className='' data-title='#'>{key+1}</td>
                <td className='' data-title='Invoice ID'>{list.invoice_id}</td>
                <td className='' data-title='Created Date'>{moment(list.created_at).format('ll')}</td>
                <td className='' data-title='Deductions'><NumberFormat
                    value={(parseFloat(list.sub_total)+ list.get_additional.reduce((total, currentValue) => total = total + parseFloat(currentValue.amount),0))}
                    displayType={'text'} thousandSeparator={true} prefix={'LKR '}/></td>
                <td className='' data-title='Customer'>{list.get_customer?list.get_customer.customer_first_name:''} {list.get_customer?list.get_customer.customer_last_name:''}</td>
                {/*  <td className='' data-title='Payment Date'>Payment Pending</td>*/}
                <td className=''
                    data-title='Payment Link'>{myConfig.laravelWeb+'invoice_detail/'+list.id}
                </td>
                <td className='' data-title='Payment Date'>
                    {list.get_order.length?(
                        list.get_order[list.get_order.length-1].order_status_id==1 ? (<span className="dot yellow"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''):(<span className="dot yellow"><i className="fas fa-circle" aria-hidden="true"></i></span>)}
                    {list.get_order.length?list.get_order[list.get_order.length-1].order_status_id==1?'':(<span className="dot green"><i className="fas fa-circle" aria-hidden="true"></i></span>):''}
                    {list.get_order.length?list.get_order[list.get_order.length-1].order_status_id==1?' Payment Pending':'Paid':' Payment Pending'}
                </td>
                {/*<td className='pop-td'><span className="dot yellow"><i
                    className="fas fa-circle"
                    aria-hidden="true"></i></span> Settlement Pending
                </td>
                <td className='' data-title=''>
                    <div className="dropdown">
                        <button className="btn dropdown-toggle dot-drop"
                                type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                            ...
                        </button>
                        <div className="dropdown-menu table-drop"
                             aria-labelledby="dropdownMenuButton">
                            <button className="dropdown-item"><i
                                className="fas fa-bell"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-copy"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-share"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-download"></i></button>
                        </div>
                    </div>
                </td>*/}
            </tr>) ):(
            <tr className=''>
                <td colSpan="9" className='' data-title='#'></td>
            </tr>

        )
        this.setState({
            pageCountInvoices: Math.ceil(list_data.length / this.state.perPageInvoices),
            invoice_lists_render:trans,
        });
    }
    handlePageClickInvoices(e){
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageInvoices;
        this.setState({
            currentPageInvoices: selectedPage,
            offsetInvoices: offset
        }, () => {
            this.loadTableInvoices()
        });
    }
    filterListInvoice(){
        //this.clearListInvoice();
        let search_invoice_id = this.state.search_invoice_id;
        let search_invoice_value = this.state.search_invoice_value;
        let search_start_date = this.state.startDate;
        let search_end_date = this.state.endDate;
        let payment_status_search = this.state.payment_status_search;
        let search_customer_name = this.state.search_customer_name;

        let start_dates = moment(search_start_date).format('YYYY-MM-DD');
        let end_dates = moment(search_end_date).format('YYYY-MM-DD');
        var a = moment(start_dates);
        var b = moment(end_dates);

        this.setState({
            csvDataInvoiceStartDate:moment(start_dates).format('ll'),
            csvDataInvoiceEndDate :moment(end_dates).format('ll')
        });

        let days_count = b.diff(a, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);
        let formData = new FormData();    //formdata object

        formData.append('start_dates',start_dates );   //append the values with key, value pair
        formData.append('end_dates', end_dates);   //append the values with key, value pair
        formData.append('search_invoice_id', search_invoice_id);   //append the values with key, value pair
        formData.append('search_invoice_value',search_invoice_value );   //append the values with key, value pair
        formData.append('payment_status_search',payment_status_search );   //append the values with key, value pair
        formData.append('search_customer_name',search_customer_name );   //append the values with key, value pair
        formData.append('merchant_id',+ sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id") );   //append the values with key, value pair

        this.handleToggleProgress();

        PostWebxLaravel("invoice_detail/get_invoice_with_filters", formData).then(response => {
            this.handleCloseProgress();
            this.generateCsvReportInvoice(response);
            this.setState({
            //    dayCount: days_count,
                invoice_lists:response.data,
                invoice_lists_filters: response.data,
                day_count_invoice: days_count,
            }, () => {
                this.loadTableInvoices();
            });
        });
    }
    clearListInvoice(){
        this.handleToggleProgress();
        GetWebxLaravel("invoice_detail/get_invoice/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            this.handleCloseProgress();

            this.setState({
                search_invoice_id: '',
                search_invoice_value: '',
                payment_status_search: '',
                search_customer_name: '',
                invoice_lists:response.data,
                invoice_lists_filters: response.data,
            }, () => {
                this.loadTableInvoices();
            });
        });
    }
    handleCallbackInvoice(start,end){
        this.setState({
            startDate:moment(start).format('ll'),
            endDate:moment(end).format('ll'),
        });
        this.filterListInvoice();
    }
    generateCsvReportInvoice(response){
        const csvData = [[
            "","","Invoice Report","From "+this.state.csvDataInvoiceStartDate +" To "+this.state.csvDataInvoiceEndDate
        ]];
        csvData.push([]);
        csvData.push(["Invoice Number","Transaction ID","Customer Full Name","E-Mail Address","Telephone Number", "Created Date", "Currency Type","Invoice Amount","Payment Status"]
        );
        csvData.push([]);
        response.data.map((value, index) => {
            csvData.push([value.invoice_id,value.order_reference?value.order_reference:'', value.get_customer?value.get_customer.customer_first_name+' '+value.get_customer.customer_last_name:'', value.get_customer?value.get_customer.customer_email:'', value.get_customer?value.get_customer.customer_phone_number:'', moment(value.created_at).format('ll'),value.currency_id==5?'LKR':'USD',value.total, value.get_order.length?value.get_order[value.get_order.length-1].order_status_id!==2?'Payment Pending':'Paid':'Payment Pending']);
        });
        this.setState({
            csvDataInvoice:csvData,
        });
    }

    //Invoice Order Report
    GenerateInvoiceOrder(start,end){
        var a = moment(end);
        var b = moment(start);
        let days_count=a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);



        if (end && start) {

            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');

            formData.append('inv_created_from', startd);   //append the values with key, value pair
            formData.append('inv_created_to', endd);
            this.handleToggleProgress();
            PostCompnentWebx( "getInvoiceOrders",formData).then(response=>{
                this.generateCsvReportCustomerInvoice(response);
                this.handleCloseProgress();
                this.setState({
                    dayCountOrderList: days_count,
                    invoice_order_list:response.data,
                    invoice_order_list_filters:response.data
                },()=>{
                    this.loadTableInvoiceOrder();
                    this.handleCloseProgress();
                });
            });
        }

    }
    loadTableInvoiceOrder(e){
        const list_data=this.state.invoice_order_list_filters;

        const slice = list_data.slice(this.state.offsetInvoiceOrderList, this.state.offsetInvoiceOrderList + this.state.perPageInvoiceOrderList);

        const trans=  list_data && list_data.length?(slice.map((list, key) =>

            <tr className=''>
                <td className='' data-title='#'>{list.invoice_id}</td>
                <td className='' data-title='#'>{list.invoice_number}</td>
                <td className='' data-title='#'>{list.order_refference_number}</td>
                <td className='' data-title='#'>  <NumberFormat
                    value={list.amount}
                    displayType={'text'} thousandSeparator={true} prefix={list.currency_code}/></td>
                <td className='' data-title='#'>{moment(list.created_date).format('ll')}</td>
                <td className='' data-title='#'>{list.payment_status} </td>
                <td className='' data-title='#'>{list.full_name}</td>
                <td className='' data-title='#'>{list.email}</td>
                <td className='' data-title='#'>{list.telephone}</td>


            </tr>) ):(
            <tr className=''>
                <td colSpan="9" className='' data-title='#'></td>
            </tr>

        );

        this.setState({
            pageCountInvoiceOrderList: Math.ceil(list_data.length / this.state.perPageInvoiceOrderList),
            invoice_order_list_render:trans,
        });
    }
    handlePageClickInvoiceOrder(e){
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageInvoiceOrderList;
        this.setState({
            currentPageInvoiceOrderList: selectedPage,
            offsetInvoiceOrderList: offset
        }, () => {
            this.loadTableInvoiceOrder()
        });
    }
    filterListInvoiceOrder(){
        let order_tb_invoice_number = this.state.order_tb_invoice_number.toLowerCase();
        let order_tb_reference_id = this.state.order_tb_reference_id.toLowerCase();
        let order_tb_customer_name = this.state.order_tb_customer_name.toLowerCase();
        let invoice_order_list = this.state.invoice_order_list, invoice_order_list_filters = [];

        let start= moment(this.state.invoice_order_start_date).format('YYYY-MM-DD');
        let end= moment(this.state.invoice_order_end_date).format('YYYY-MM-DD');
        if (this.state.invoice_order_start_date=="") {
            start=moment().subtract(30, 'days').format('YYYY-MM-DD');
        }
        if (this.state.invoice_order_end_date==""){
            end= moment().format('YYYY-MM-DD');
        }
        var a = moment(end);
        var b = moment(start);
        let days_count=a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);


        if (end && start) {

            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');

            formData.append('inv_created_from', startd);   //append the values with key, value pair
            formData.append('inv_created_to', endd);
            formData.append('filter_invoice_number', order_tb_invoice_number);
            formData.append('filter_order_reference', order_tb_reference_id);
            formData.append('filter_customer_name', order_tb_customer_name);

            this.handleToggleProgress();
            PostCompnentWebx( "getInvoiceOrders",formData).then(response=>{
                this.generateCsvReportCustomerInvoice(response);
                this.handleCloseProgress();
                this.setState({
                    dayCountOrderList: days_count,
                    invoice_order_list:response.data,
                    invoice_order_list_filters:response.data
                },()=>{
                    this.loadTableInvoiceOrder();
                    this.handleCloseProgress();
                });
            });
        }
    }
    clearListInvoiceOrder(){

        let start= moment().subtract(30, 'days').format('YYYY-MM-DD');
        let end=moment().format('YYYY-MM-DD');

        var a = moment(end);
        var b = moment(start);
        let days_count=a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        if (end && start) {

            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');

            formData.append('inv_created_from', startd);   //append the values with key, value pair
            formData.append('inv_created_to', endd);

            this.handleToggleProgress();
            PostCompnentWebx( "getInvoiceOrders",formData).then(response=>{
                this.generateCsvReportCustomerInvoice(response);
                this.handleCloseProgress();
                this.setState({
                    dayCountOrderList: days_count,
                    invoice_order_list:response.data,
                    invoice_order_list_filters:response.data,
                    order_tb_invoice_number:'',
                    order_tb_reference_id:'',
                    order_tb_customer_name:''
                },()=>{
                    this.loadTableInvoiceOrder();
                    this.handleCloseProgress();
                });
            });
        }
    }
    handleCallbackInvoiceOrder(start,end){
        this.GenerateInvoiceOrder(moment(start).format('ll'),moment(end).format('ll'));
    }
    generateCsvReportCustomerInvoice(response){
        const csvData = [
            [ "Invoice Number","Full Name","E-Mail","Phone Number", "Order Date","Transaction ID","Currency Code","Amount","Payment Status",]
        ];
        response.data.map((list, index) => {
            csvData.push([list.invoice_number,list.full_name,list.email,list.telephone,moment(list.created_date).format('ll'),list.order_refference_number,list.currency_code , list.amount,list.payment_status]);

        });
        this.setState({
            csvDataOrderList:csvData,
        });
    }

  //  Payout List
    GeneratePayoutList(start,end){
        var a = moment(end);
        var b = moment(start);
        let days_count=a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        let startd = moment(start).format('YYYY-MM-DD');

        let endd = moment(end).format('YYYY-MM-DD');

        if(startd && endd) {
            let formData = new FormData();    //formdata object
            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);


            this.handleToggleProgress();
            PostCompnentWebx("getPayoutData", formData).then(response => {
                this.generateCsvReportPayouts(response);
                this.handleCloseProgress();
                let payoutarray=[];

                this.setState({
                    dayCountPayoutList: days_count,
                    payout_lists: response.data,
                    payout_lists_filter: response.data
                }, () => {
                    this.loadTablePayouts();
                    this.handleCloseProgress();
                });
            });
        }
        }
    loadTablePayouts(){
        const list_data=this.state.payout_lists_filter;

        const slice = list_data.slice(this.state.offsetPayoutLists, this.state.offsetPayoutLists + this.state.perPagePayoutLists);

        let trans=  list_data && list_data.length?(slice.map((value, key) =>
            <tr className=''>
                <td className='' data-title='#'>{ moment(value.date_added).format('ll')}</td>
                <td className='' data-title='#'>{value.gateway_order_id}</td>
                <td className='' data-title='#'>  {value.order_refference_number}</td>
                <td className='' data-title='#'>{value.first_name+' '+value.last_name}</td>
                <td className='' data-title='#'>{value.processing_currency_id == 5 ? "LKR" : "USD"} </td>
                <td className='' data-title='#'>{value.transaction_amount}</td>
                <td className='' data-title='#'>{value.discount_amount}</td>
                <td className='' data-title='#'>{ value.transaction_net}</td>
                <td className='' data-title='#'>{ value.refund_amount}</td>
                <td className='' data-title='#'>{ value.payment_currency_id == 5 ? "LKR" : "USD"}</td>
                <td className='' data-title='#'>{ value.payment_amount}</td>


            </tr>) ):(
            <tr className=''>
                <td colSpan="11" className='' data-title='#'></td>
            </tr>

        );
        this.setState({
            pageCountPayoutLists: Math.ceil(list_data.length / this.state.perPagePayoutLists),
            payout_lists_render:trans,
        });
    }
    handlePageClickPayouts(e){
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPagePayoutLists;
        this.setState({
            currentPagePayoutLists: selectedPage,
            offsetPayoutLists: offset
        }, () => {
            this.loadTablePayouts()
        });
    }
    filterListPayout(){
        let processing_currency  = this.state.processing_currency.toLowerCase();
        let filter_date_start  = this.state.filter_date_start;
        let filter_date_end = this.state.filter_date_end;
        let payout_lists = this.state.payout_lists, payout_lists_filter = [];

        let start= moment(this.state.filter_date_start).format('YYYY-MM-DD');
        let end= moment(this.state.filter_date_end).format('YYYY-MM-DD');

        if (this.state.filter_date_start=="") {
            start=moment().subtract(30, 'days').format('YYYY-MM-DD');
        }
        if (this.state.filter_date_end==""){
            end= moment().format('YYYY-MM-DD');
        }
        var a = moment(end);
        var b = moment(start);
        let days_count=a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);


        if (end && start) {

            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');


            formData.append('processing_currency', processing_currency);
            formData.append('filter_date_start', filter_date_start);
            formData.append('filter_date_end', filter_date_end);

            this.handleToggleProgress();
            PostCompnentWebx( "getPayoutData",formData).then(response=>{
                this.generateCsvReportCustomerInvoice(response);
                this.handleCloseProgress();
                this.setState({
                    dayCountPayoutList: days_count,
                    payout_lists:response.data,
                    payout_lists_filter:response.data
                },()=>{
                    this.loadTablePayouts();
                    this.handleCloseProgress();
                });
            });
        }
    }
    clearListPayout(){

        let start= moment().subtract(30, 'days').format('YYYY-MM-DD');
        let end=moment().format('YYYY-MM-DD');

        var a = moment(end);
        var b = moment(start);
        let days_count=a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        if (end && start) {

            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');

            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);

            this.handleToggleProgress();
            PostCompnentWebx( "getPayoutData",formData).then(response=>{
                this.generateCsvReportCustomerInvoice(response);
                this.handleCloseProgress();
                this.setState({
                    dayCountPayoutList: days_count,
                    payout_lists:response.data,
                    payout_lists_filter:response.data,
                    processing_currency:'',
                },()=>{
                    this.loadTablePayouts();
                    this.handleCloseProgress();
                });
            });
        }
    }
    handleCallbackPayouts(start,end){
        this.GeneratePayoutList(moment(start).format('ll'),moment(end).format('ll'));
    }
    generateCsvReportPayouts(response){
        const csvData = [
            [ "Transaction / Refund Date","Store Order ID","Transaction ID","Name", "Processing Currency","Transaction Amount","Gateway Charge","Transaction Net","Refund Charge","Payment Currency","Settlement Amount",]
        ];
        response.data.map((value, index) => {
            csvData.push([moment(value.date_added).format('ll'),value.gateway_order_id,value.order_refference_number,value.first_name+' '+value.last_name,value.processing_currency_id == 5 ? 'LKR' : 'USD',value.transaction_amount,value.discount_amount , value.transaction_net,value.refund_amount,value.payment_currency_id == 5 ? "LKR" : "USD",value.payment_amount]);

        });
        this.setState({
            csvDataPayoutList:csvData,
        });
    }

    //filterListPromotionTransactions
    GenerateTransactionListPromotion(start,end){

        if (end && start) {
            this.handleToggleProgress();
            let BaseUrl = myConfig.apiUrl;
            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd =moment(end).format('YYYY-MM-DD');
            var a = moment(end);
            var b = moment(start);
            this.setState({
                startDateCsvTransactionPromotion:start,
                endDateCsvTransactionPromotion:end
            });
            let days_count=a.diff(b, 'days')   // =1
            days_count=parseInt(days_count)+parseInt(1);

            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);
            formData.append('expand_view', "");   //append the values with key, value pair
            this.handleToggleProgress();
            PostCompnentWebx( "getStorePromotionTransactions", formData).then(response=>{
                this.handleCloseProgress();
                this.csvGenerateTransactionPromotion(response);
                this.handleCloseProgress();
                this.setState({
                    transaction_lists_promotion:response.data,
                    transaction_lists_filters_promotion:response.data,
                    dayCount: days_count
                }, () => {
                    this.loadTableTransactionsPromotion()
                });
            });
        }
    }
    loadTableTransactionsPromotion(){
        const list_data=this.state.transaction_lists_filters_promotion;

        const slice = list_data.slice(this.state.offsetTransactions_promotion, this.state.offsetTransactions_promotion + this.state.perPageTransactions_promotion);
        const trans=  list_data && list_data.length?(slice.map(list =>  <tr className='' /*onClick={() => this.ModalCheckTrans(list.order_refference_number)}*/>
            <td className='' data-title='#'>{list.order_id}</td>
            <td className=''
                data-title='Transaction ID'>{list.order_refference_number}
            </td>
            <td className=''
                data-title='Store Reference'>{list.store_reference}
            </td>
            <td className='' data-title='Transaction Value'>
                <NumberFormat
                    value={(Math.round(list.total_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={list.processing_currency_id==5?'LKR ':'USD '}/>
            </td>
            <td className='' data-title='Transaction Value'>
                {
                    (() => {
                        let default_size="35px";
                        let max_size="63px";
                        if (list.payment_gateway_id==35 ||list.payment_gateway_id==42||list.payment_gateway_id==45||list.payment_gateway_id==2||list.payment_gateway_id==3||list.payment_gateway_id==16){
                            default_size="35px";
                        }else {
                            default_size=max_size;
                        }

                        if (list.card_type.toLowerCase()=="visa")
                            return  <img style={{width:"35px"}} src={myConfig.laravelWeb+'gateway_logos/visa.jpg'} alt=""/>
                        if (list.card_type.toLowerCase()=="master"||list.card_type.toLowerCase()=="mastercard" )
                            return  <img style={{width:"35px"}} src={myConfig.laravelWeb+'gateway_logos/master.jpg'} alt=""/>
                        else
                            return  <img style={{width:default_size}} src={myConfig.laravelWeb+'gateway_logos/'+list.payment_gateway_id+'.jpg'} alt=""/>

                    })()
                }
            </td>
            <td className='' data-title='Date of Transaction'>
                {
                    (() => {
                      let card_number_new="";
                        if (list.card_first_six!==null){
                            return list.card_first_six+'******'+list.card_last_four;
                        }

                    })()
                }

            </td>
            <td className='' data-title='Date of Transaction'>{moment(list.date_added).format('ll')}
            </td>
            <td className='pop-td' data-toggle="popover"
                data-trigger="hover" data-placement="auto"
                data-content="Bank was Unable to Authenticate Transaction">
                {list.payment_status_id == 2 ? (<span className="dot green"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                {list.payment_status_id == 3 ? (<span className="dot red"><i className="fas fa-circle" aria-hidden="true"></i></span>) : ''}
                {list.payment_status_id == 2 ? "Approved" : ''}
                {list.payment_status_id == 3 ? "Declined" : ''}
            </td>
        </tr>) ):(
            <tr className=''>
                <td colSpan="6" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
            </tr>

        );
        this.setState({
            pageCountTransactions_promotion: Math.ceil(list_data.length / this.state.perPageTransactions_promotion),
            transaction_lists_render_promotion:trans
        })
    }
    handlePageClickTransactionsPromotion = (e) => {

        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageTransactions;

        this.setState({
            currentPageTransactions_promotion: selectedPage,
            offsetTransactions_promotion: offset
        }, () => {
            this.loadTableTransactionsPromotion();
        });

    };
    filterListTransactionPromotion() {
        this.handleToggleProgress();
        let order_ref_no = this.state.transaction_id_promotion.toLowerCase();
        let status = this.state.transaction_status_promotion;
        let transaction_value = this.state.transaction_value_promotion.toLowerCase();
        let transaction_store_reference = this.state.transaction_store_reference_promotion.toLowerCase();
        let transaction_currency = this.state.transaction_currency_promotion;


        let startDate= moment(this.state.transaction_start_date_promotion).format('YYYY-MM-DD');
        let endDate= moment(this.state.transaction_end_date_promotion).format('YYYY-MM-DD');
        if (this.state.startDate=="") {
            startDate=moment().subtract(30, 'days').format('YYYY-MM-DD')
        }
        if (this.state.endDate==""){
            endDate= moment().format('YYYY-MM-DD');
        }
        this.setState({
            startDateCsvTransactionPromotion:moment(startDate).format('ll'),
            endDateCsvTransactionPromotion:moment(endDate).format('ll')
        });
        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        let formData = new FormData();    //formdata object
        formData.append('filter_date_start', startDate);
        formData.append('filter_date_end', endDate);

        formData.append('total_amount', transaction_value);   //append the values with key, value pair
        formData.append('order_refference_number', order_ref_no);   //append the values with key, value pair
        formData.append('payment_status_id', status);   //append the values with key, value pair
        formData.append('expand_view', "");   //append the values with key, value pair
        formData.append('processing_currency_id',transaction_currency );   //append the values with key, value pair
        formData.append('store_reference',transaction_store_reference );   //append the values with key, value pair

        PostCompnentWebx("getStorePromotionTransactions", formData).then(response => {
            this.handleCloseProgress();

            this.csvGenerateTransactionPromotion(response);

            this.handleCloseProgress();
            this.setState({
                transaction_lists_promotion:response.data,
                transaction_lists_filters_promotion:response.data,
                dayCount: days_count
            }, () => {
                this.loadTableTransactionsPromotion()
            });

        });
        this.handleToggleProgress();


    }
    clearListTransactionPromotion(){
        this.handleToggleProgress();
        let formData = new FormData();    //formdata object
        let startDate=  moment().subtract(30, 'days').format('YYYY-MM-DD');
        let endDate= moment().format('YYYY-MM-DD');
        formData.append('filter_date_start', startDate);
        formData.append('filter_date_end', endDate);
        formData.append('expand_view', "");   //append the values with key, value pair
        var a = moment(endDate);
        var b = moment(startDate);
        let days_count = a.diff(b, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);
        PostCompnentWebx("getStorePromotionTransactions", formData).then(response => {
            this.handleCloseProgress();
            this.csvGenerateTransaction(response);
            this.setState({
                transaction_lists_promotion:response.data,
                transaction_lists_filters_promotion:response.data,
                dayCount: days_count,
                transaction_id_promotion: '',
                transaction_status_promotion: '',
                transaction_currency_promotion: '',
                transaction_value_promotion: '',
                transaction_store_reference_promotion: '',
            }, () => {
                this.loadTableTransactionsPromotion();
            });
        });
    }
    handleCallbackTransactionPromotion(start, end, label) {
        this.setState({
            transaction_start_date_promotion:start,
            transaction_end_date_promotion:end,
        });
        this.filterListTransactionPromotion(moment(start).format('ll'),moment(end).format('ll'));
    }
    csvGenerateTransactionPromotion(response){

        const csvData = [[]];

        csvData.push([
            "","","Transaction Report -",this.state.startDateCsvTransactionPromotion+'-'+this.state.endDateCsvTransactionPromotion
        ]);
        csvData.push([]);

        csvData.push(["Transaction Date","Store Order ID","Customer First Name", "Customer Last Name", "Transaction ID","Processing Currency","Order Amount","Convenience Fee / Discount", "Transaction Amount", "Gateway Discount", "Transaction Net", "Payment Gateway","Card Type","Card Number","Payment Status"]);
        csvData.push([]);
        response.data.map((value, index) => {
            let payment_status="";
            if(value.payment_status_id==2){
                payment_status="Approved";
            }else if (value.payment_status_id==3){
                payment_status="Declined";
            }
            let card_number="";
            if (value.card_first_six!==null) {
                card_number = value.card_first_six + '******' + value.card_last_four
            }

            csvData.push([moment(value.date_added).format('ll'), value.store_reference, value.first_name, value.last_name, value.order_refference_number,value.processing_currency_id == 5 ? "LKR" : "USD", value.order_amount,value.convenience_fee, value.total_amount, value.discount_amount,value.transaction_net, value.payment_gateway,value.card_type,card_number,payment_status]);
        });

        this.setState({
            csvDataTransactionPromotion:csvData
        });
    }

    //Other functions
    onChangesSelect(e) {
        let selected_report=e.target.value;
        if (selected_report==1){
            this.setState({
                visibleclassCustomers:"active-class-table",
                visibleclassTransaction:"inactive-class-table",
                visibleClassTransactionPromotion:"inactive-class-table",
                visibleclassRefund:"inactive-class-table",
                visibleClassSettlement:"inactive-class-table",
                visibleClassInvoice:"inactive-class-table",
                visibleClassDue:"inactive-class-table",
                visibleClassOrderList:"inactive-class-table",
                visibleClassPayoutList:"inactive-class-table",
                searchStatus:this.filterListCustomer,
                clearStatus:this.clearListCustomer
            });
            this.GenerateCustomerList();
        }else if (selected_report==2){
            this.setState({
                visibleclassTransaction:"active-class-table",
                visibleclassCustomers:"inactive-class-table",
                visibleclassRefund:"inactive-class-table",
                visibleClassSettlement:"inactive-class-table",
                visibleClassInvoice:"inactive-class-table",
                visibleClassDue:"inactive-class-table",
                visibleClassOrderList:"inactive-class-table",
                visibleClassPayoutList:"inactive-class-table",
                visibleClassTransactionPromotion:"inactive-class-table",
                searchStatus:this.filterListTransaction,
                clearStatus:this.clearListTransaction
            })
            this.GenerateTransactionList(moment().subtract(30, 'days').format('ll'),moment().format('ll'));
        }else if(selected_report==3){
            this.setState({
                visibleclassTransaction:"inactive-class-table",
                visibleclassCustomers:"inactive-class-table",
                visibleclassRefund:"active-class-table",
                visibleClassSettlement:"inactive-class-table",
                visibleClassInvoice:"inactive-class-table",
                visibleClassDue:"inactive-class-table",
                visibleClassOrderList:"inactive-class-table",
                visibleClassPayoutList:"inactive-class-table",
                visibleClassTransactionPromotion:"inactive-class-table",
                searchStatus:this.filterListRefund,
                clearStatus:this.clearListRefund
            });
            this.GenerateRefundList(moment().subtract(30, 'days').format('ll'),moment().format('ll'));
        }else if(selected_report==4){
            this.setState({
                visibleclassTransaction:"inactive-class-table",
                visibleclassCustomers:"inactive-class-table",
                visibleclassRefund:"inactive-class-table",
                visibleClassSettlement:"inactive-class-table",
                visibleClassInvoice:"inactive-class-table",
                visibleClassDue:"active-class-table",
                visibleClassOrderList:"inactive-class-table",
                visibleClassPayoutList:"inactive-class-table",
                visibleClassTransactionPromotion:"inactive-class-table",
                searchStatus:this.filterListDues,
                clearStatus:this.clearListDues
            });
            this.GenerateDueList(moment().subtract(30, 'days').format('ll'),moment().format('ll'));
        }else if(selected_report==5){
            this.setState({
                visibleclassTransaction:"inactive-class-table",
                visibleclassCustomers:"inactive-class-table",
                visibleclassRefund:"inactive-class-table",
                visibleClassSettlement:"active-class-table",
                visibleClassInvoice:"inactive-class-table",
                visibleClassDue:"inactive-class-table",
                visibleClassOrderList:"inactive-class-table",
                visibleClassPayoutList:"inactive-class-table",
                visibleClassTransactionPromotion:"inactive-class-table",
                searchStatus:this.filterListSettlements,
                clearStatus:this.clearListSettlements
            });
            this.GenerateSettlementsList(moment().subtract(30, 'days').format('ll'),moment().format('ll'));
        }else if(selected_report==6){
            this.setState({
                visibleclassTransaction:"inactive-class-table",
                visibleclassCustomers:"inactive-class-table",
                visibleclassRefund:"inactive-class-table",
                visibleClassSettlement:"inactive-class-table",
                visibleClassInvoice:"active-class-table",
                visibleClassDue:"inactive-class-table",
                visibleClassOrderList:"inactive-class-table",
                visibleClassPayoutList:"inactive-class-table",
                visibleClassTransactionPromotion:"inactive-class-table",
                searchStatus:this.filterListInvoice,
                clearStatus:this.clearListInvoice
            });
            this.GenerateInvoiceList(moment().subtract(30, 'days').format('ll'),moment().format('ll'));
        }else if(selected_report==7){
            this.setState({
                visibleclassTransaction:"inactive-class-table",
                visibleclassCustomers:"inactive-class-table",
                visibleclassRefund:"inactive-class-table",
                visibleClassSettlement:"inactive-class-table",
                visibleClassInvoice:"inactive-class-table",
                visibleClassDue:"inactive-class-table",
                visibleClassPayoutList:"inactive-class-table",
                visibleClassOrderList:"active-class-table",
                visibleClassTransactionPromotion:"inactive-class-table",
                searchStatus:this.filterListInvoiceOrder,
                clearStatus:this.clearListInvoiceOrder
            });
            this.GenerateInvoiceOrder(moment().subtract(30, 'days').format('ll'),moment().format('ll'));
        }else if(selected_report==8){
            this.setState({
                visibleclassTransaction:"inactive-class-table",
                visibleclassCustomers:"inactive-class-table",
                visibleclassRefund:"inactive-class-table",
                visibleClassSettlement:"inactive-class-table",
                visibleClassInvoice:"inactive-class-table",
                visibleClassDue:"inactive-class-table",
                visibleClassOrderList:"inactive-class-table",
                visibleClassPayoutList:"active-class-table",
                visibleClassTransactionPromotion:"inactive-class-table",
                searchStatus:this.filterListPayout,
                clearStatus:this.clearListPayout
            });
            this.GeneratePayoutList(moment().subtract(30, 'days').format('ll'),moment().format('ll'));
        }else if(selected_report==9){
            this.setState({
                visibleclassTransaction:"inactive-class-table",
                visibleclassCustomers:"inactive-class-table",
                visibleclassRefund:"inactive-class-table",
                visibleClassSettlement:"inactive-class-table",
                visibleClassInvoice:"inactive-class-table",
                visibleClassDue:"inactive-class-table",
                visibleClassOrderList:"inactive-class-table",
                visibleClassPayoutList:"inactive-class-table",
                visibleClassTransactionPromotion:"active-class-table",
                searchStatus:this.filterListTransactionPromotion,
                clearStatus:this.clearListTransactionPromotion
            });
            this.GenerateTransactionListPromotion(moment().subtract(30, 'days').format('ll'),moment().format('ll'));
        }
    }
    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    componentWillMount() {

        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {
this.checkPromotionsAvailable();
            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");

            this.setState({userToken: userToken});

        } else {
            this.setState({redirect: true});
        }
    }



    render() {

    const customers_list=this.state.customer_list_render;
    const transaction_lists=this.state.transaction_lists_render;
    const refund_lists=this.state.refund_lists_render;
    const settlement_lists_render=this.state.settlement_lists_render;
    const invoice_lists_render=this.state.invoice_lists_render;
    const due_lists_render=this.state.due_lists_render;
    const invoice_order_list=this.state.invoice_order_list_render;
    const payout_lists=this.state.payout_lists_render;
    const transaction_lists_promotions=this.state.transaction_lists_render_promotion;


        if (this.state.redirect) {
            return <Navigate to='/login'  />
        }

        return (
            <div>
                <SupportButton/>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <NavigationSandbox pathname="reports-and-matrix-sandbox"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBarSandbox pathname="reports-metrics"/>

                        <div className="col-12 transaction-top reports-top reportstt-top">
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="tt" role="tabpanel"
                                     aria-labelledby="tt-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <div className="col-12 reports-h">
                                                    <h4>Reports & Metrics</h4>
                                                    <h3>Generate Reports</h3>
                                                </div>
                                                <form className="col-12">
                                                    <div className="row row_clr">
                                                        <div className="col-12 lft">
                                                            <div className="row">
                                                                <div className="col-md-6 col-12">
                                                            <h6>Select Report Type</h6>
                                                            <div className="form-group">
                                                                <select name="report_type" id="report_type" className="form-control" onChange={this.onChangesSelect}>
                                                                    <option value="">--Select Report--</option>
                                                                    <option value="1">Customer report</option>
                                                                    <option value="2">Transaction Report</option>
                                                                    <option value="3">Refund Report</option>
                                                                    <option value="4">Due Report</option>
                                                                    <option value="5">Settlement Report</option>
                                                                    <option value="6">Invoice Report</option>
                                                                   {/* <option value="7">Invoice Report (Previous Records)</option>
                                                                    <option value="8">Settlement Report (Previous Records)</option>*/}
                                                                    {this.state.promotion_available? (<option value="9">Promotional Transaction Report</option>):('')}
                                                                </select>
                                                            </div>
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                            <h6>What is a Report?</h6>
                                                            <p>This report provides all transactions (Transactions,
                                                                Settlements, Refunds) that were made in the
                                                                selected time range.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 rht gr-rht">

                                                            <div className="row ">
                                                                {/*//Customers*/}
                                                                <div className={this.state.visibleclassCustomers+" col-md-3 col-12"}>
                                                                    <h6>Search Keyword</h6>
                                                                    <div className="form-group">
                                                                        <input type="text" name="customer_keyword_value" value={this.state.customer_keyword_value} className="form-control" onChange={this.onChanges} />
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleclassCustomers+" col-md-3 col-12"}>
                                                                    <h6>Download CSV</h6>
                                                                    <CSVLink data={this.state.csvDataCustomer} style={{textDecoration: "none !important",color:"#000"}} filename={"CustomersReport.csv"}>
                                                                        <i className="fas fa-download"></i>
                                                                    </CSVLink>
                                                                </div>
                                                              {/*  //Transactions*/}
                                                                <div className={this.state.visibleclassTransaction+" col-md-3 col-12"}>
                                                                    <h6>Transaction Id</h6>
                                                                    <div className="form-group">
                                                                       <input type="text" name="transaction_id" value={this.state.transaction_id} className="form-control" onChange={this.onChanges} />
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleclassTransaction+" col-md-3 col-12"}>
                                                                    <h6>Transaction Status</h6>
                                                                    <div className="form-group">
                                                                        <select name="transaction_status" id="" className="form-control" onChange={this.onChanges} value={this.state.transaction_status}>
                                                                            <option value="">Select Status</option>
                                                                            <option value="2">Approved</option>
                                                                            <option value="3">Declined</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className={this.state.visibleclassTransaction+" col-md-3 col-12"}>
                                                                    <h6>Transaction Value</h6>
                                                                    <div className="form-group">
                                                                        <input type="text" name="transaction_value" className="form-control" onChange={this.onChanges} value={this.state.transaction_value}/>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleclassTransaction+" col-md-3 col-12"}>
                                                                    <h6>Store Reference</h6>
                                                                    <div className="form-group">
                                                                        <input type="text" name="transaction_store_reference" className="form-control" onChange={this.onChanges} value={this.state.transaction_store_reference}/>
                                                                    </div>
                                                                </div>

                                                                <div className={this.state.visibleclassTransaction+" col-md-3 col-12"}>
                                                                    <h6>Transaction Date Range Filter</h6>
                                                                    <div className="in table-datepicker">
                                                                        <DateRangePicker
                                                                            initialSettings={{
                                                                                autoApply:true
                                                                            }}
                                                                            onEvent={this.handleEvent} onCallback={this.handleCallbackTransaction}
                                                                        >
                                                                            <button type="button" className="date-btn"> <i className="far fa-calendar-alt"></i>  {this.state.dayCount} Days <i
                                                                                className="fas fa-caret-down" aria-hidden="true"></i> </button>
                                                                        </DateRangePicker>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleclassTransaction+" col-md-3 col-12"}>
                                                                    <h6>Transaction Currency</h6>
                                                                    <div className="form-group">
                                                                        <select name="transaction_currency" id="c1" className="form-control"  onChange={this.onChanges}  value={this.state.transaction_currency}>
                                                                            <option value="">Select Currency</option>
                                                                            <option value="5">LKR</option>
                                                                            <option value="2">USD</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleclassTransaction+" col-md-3 col-12"}>
                                                                    <h6>Download CSV</h6>
                                                                    <CSVLink data={this.state.csvDataTransaction} style={{textDecoration: "none !important",color:"#000"}} filename={"TransactionReport.csv"}>
                                                                        <i className="fas fa-download"></i>
                                                                    </CSVLink>
                                                                </div>
                                                                {/*//Refund Reports*/}
                                                                <div className={this.state.visibleclassRefund+" col-md-3 col-12"}>
                                                                    <h6>Transaction Id</h6>
                                                                    <div className="form-group">
                                                                        <input type="text" name="refund_id" value={this.state.refund_id} className="form-control" onChange={this.onChanges} />
                                                                    </div>
                                                                </div>

                                                                <div  className={this.state.visibleclassRefund+" col-md-3 col-12"}>
                                                                    <h6>Transaction Currency</h6>
                                                                    <div className="form-group">
                                                                    <div className="slct">
                                                                        <select name="transaction_currency" id="c1" className="form-control"
                                                                                value={this.state.transaction_currency}
                                                                                onChange={this.onChanges}>
                                                                            <option value="">Select Currency</option>
                                                                            <option value="5">LKR</option>
                                                                            <option value="2">USD</option>
                                                                        </select>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleclassRefund+" col-md-3 col-12"}>
                                                                    <h6>Invoice Value</h6>
                                                                    <div className="form-group">
                                                                        <div className="slct">
                                                                            <select name="refund_status" id="c1" className="form-control" value={this.state.refund_status} onChange={this.onChanges}>
                                                                                <option value="">Select Status</option>
                                                                                <option value="1">Pending</option>
                                                                                <option value="2">Refunded</option>
                                                                                <option value="3">Rejected</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleclassRefund+" col-md-3 col-12"}>
                                                                <div className="form-group">
                                                                    <h6>Filters & Reports</h6>
                                                                    <div className="in table-datepicker">
                                                                        <DateRangePicker
                                                                            onEvent={this.handleEvent} onCallback={this.handleCallbackRefund}
                                                                            initialSettings={{
                                                                                autoApply:true
                                                                            }}
                                                                        >
                                                                            <button type="button" className="date-btn"><i className="far fa-calendar-alt"></i>  {this.state.dayCount} Days <i
                                                                                className="fas fa-caret-down" aria-hidden="true"></i></button>
                                                                        </DateRangePicker>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <div className={this.state.visibleclassRefund+" col-md-3 col-12"}>
                                                                    <h6>Download CSV</h6>
                                                                    <CSVLink data={this.state.csvDataRefund} style={{textDecoration: "none !important",color:"#000"}} filename={"RefundReport.csv"}>
                                                                        <i className="fas fa-download"></i>
                                                                    </CSVLink>
                                                                </div>

                                                                {/*Due Report*/}
                                                               {/* <div className={this.state.visibleClassDue+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Payment Status</h6>
                                                                        <select name="due_payment_status" id="c1" className="form-control" onChange={this.onChanges}>
                                                                            <option value="">Select Status</option>
                                                                            <option value="Paid">Paid</option>
                                                                            <option value="Declined">Declined</option>
                                                                            <option value="Pending">Pending</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className={this.state.visibleClassDue+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Type</h6>
                                                                        <select name="due_type" id="c1" className="form-control" onChange={this.onChanges}>
                                                                            <option value="">Select Status</option>
                                                                            <option value="Order">Order</option>
                                                                            <option value="Refund">Refund</option>
                                                                        </select>
                                                                    </div>
                                                                </div>*/}

                                                                <div className={this.state.visibleClassDue+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6 htmlFor="">Transaction ID</h6>
                                                                        <input type="text" className="form-control"
                                                                               placeholder="Enter Transaction ID" name="transaction_id_search" value={this.state.transaction_id_search} onChange={this.onChanges}/>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassDue+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6 htmlFor="">Store Reference ID</h6>
                                                                        <input type="text" className="form-control"
                                                                               placeholder="Enter Reference ID" name="store_reference_id" value={this.state.store_reference_id} onChange={this.onChanges}/>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassDue+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6 htmlFor=""> Transaction Amount</h6>
                                                                        <input type="text" className="form-control"
                                                                               placeholder="Enter Value" name="transaction_amount" value={this.state.transaction_amount} onChange={this.onChanges} />
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassDue+" col-md-3 col-12"}>
                                                                    <h6>Download CSV</h6>
                                                                    <CSVLink data={this.state.csvDataDues} style={{textDecoration: "none !important",color:"#000"}} filename={"DueReport.csv"}>
                                                                        <i className="fas fa-download"></i>
                                                                    </CSVLink>
                                                                </div>
                                                               {/* Settlement Report*/}
                                                             {/*   <div className={this.state.visibleClassSettlement+" col-md-3 col-12"}>
                                                                <div className="form-group">
                                                                    <h6>Settlement ID</h6>
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Enter ID" name="payout_reference" onChange={this.onChanges}/>
                                                                </div>
                                                                </div>*/}
                                                                <div className={this.state.visibleClassSettlement+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Settlement ID</h6>
                                                                        <input type="text" className="form-control"
                                                                               placeholder="Enter settlement id" name="payout_reference" value={this.state.payout_reference} onChange={this.onChanges}/>
                                                                    </div>
                                                                </div>
                                                               {/* <div className={this.state.visibleClassSettlement+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Settlement Value</h6>
                                                                        <input type="text" className="form-control"
                                                                               placeholder="Enter Value" name="total_settlement" onChange={this.onChanges}/>
                                                                    </div>
                                                                </div>*/}
                                                                <div className={this.state.visibleClassSettlement+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Bank Account</h6>
                                                                        <input type="text" className="form-control"
                                                                               placeholder="Account Name" name="bank_name" value={this.state.bank_name} onChange={this.onChanges}/>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassSettlement+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Filters & Reports</h6>
                                                                        <div className="in table-datepicker">
                                                                            <DateRangePicker
                                                                                onEvent={this.handleEvent} onCallback={this.handleCallbackSettlement}
                                                                                initialSettings={{
                                                                                    autoApply:true
                                                                                }}
                                                                            >
                                                                                <button type="button" className="date-btn"><i className="far fa-calendar-alt"></i>  {this.state.dayCountSettlement} Days <i
                                                                                    className="fas fa-caret-down" aria-hidden="true"></i></button>
                                                                            </DateRangePicker>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassSettlement+" col-md-3 col-12"}>
                                                                    <h6>Download CSV</h6>
                                                                    <CSVLink data={this.state.csvDataSettlements} style={{textDecoration: "none !important",color:"#000"}} filename={"Settlement.csv"}>
                                                                        <i className="fas fa-download"></i>
                                                                    </CSVLink>
                                                                </div>
                                                                {/*Invoice*/}

                                                                <div className={this.state.visibleClassInvoice+" col-md-3 col-12"}>
                                                                <div className="form-group">
                                                                    <h6>Invoice ID</h6>
                                                                    <input type="text" className="form-control" name="search_invoice_id"
                                                                           placeholder="Enter ID" onChange={this.onChanges} value={this.state.search_invoice_id}/>
                                                                </div>
                                                                </div>
                                                                <div className={this.state.visibleClassInvoice+" col-md-3 col-12"}>
                                                                <div className="form-group">
                                                                    <h6>Status</h6>
                                                                    <div className="slct">
                                                                        <select name="payment_status_search" id="c1" className="form-control" onChange={this.onChanges}  value={this.state.payment_status_search}>
                                                                            <option value="1">Payment Pending</option>
                                                                            <option value="2">Paid</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <div className={this.state.visibleClassInvoice+" col-md-3 col-12"}>
                                                                <div className="form-group">
                                                                    <h6>Invoice Value</h6>
                                                                    <input type="text" className="form-control" name="search_invoice_value" value={this.state.search_invoice_value}
                                                                           placeholder="Enter Value" onChange={this.onChanges}/>
                                                                </div>
                                                                </div>
                                                                <div className={this.state.visibleClassInvoice+" col-md-3 col-12"}>
                                                                <div className="form-group">
                                                                    <h6>Customer</h6>
                                                                    <input type="text" className="form-control" value={this.state.search_customer_name}
                                                                           placeholder="Customer Name" name="search_customer_name" onChange={this.onChanges}/>
                                                                </div>
                                                                </div>
                                                                <div className={this.state.visibleClassInvoice+" col-md-3 col-12"}>
                                                                    <h6>Download CSV</h6>
                                                                    <CSVLink data={this.state.csvDataInvoice} style={{textDecoration: "none !important",color:"#000" }} filename={"InvoiceReport.csv"}>
                                                                        <i className="fas fa-download"></i>
                                                                    </CSVLink>
                                                                </div>
                                                                <div className={this.state.visibleClassInvoice+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Filters & Reports</h6>
                                                                        <div className="in table-datepicker">
                                                                            <DateRangePicker
                                                                                onEvent={this.handleEvent} onCallback={this.handleCallbackInvoice}
                                                                                initialSettings={{
                                                                                    startDate:moment(this.state.startDate).format('M/DD/YYYY'),
                                                                                    endDate:moment(this.state.endDate).format('M/DD/YYYY'),
                                                                                    maxDate: moment().format('M/DD/YYYY'),
                                                                                    autoApply:true
                                                                                }}
                                                                            >
                                                                                <button type="button" className="date-btn"> <i className="far fa-calendar-alt"></i>  {this.state.day_count_invoice} Days <i
                                                                                    className="fas fa-caret-down" aria-hidden="true"></i> </button>
                                                                            </DateRangePicker>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*Order List*/}
                                                                <div className={this.state.visibleClassOrderList+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Invoice Number</h6>
                                                                        <input type="text" className="form-control" name="order_tb_invoice_number"
                                                                               placeholder="Enter Invoice Number" value={this.state.order_tb_invoice_number} onChange={this.onChanges}/>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassOrderList+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Order Reference Number</h6>
                                                                        <input type="text" className="form-control" name="order_tb_reference_id"
                                                                               placeholder="Enter Reference Number" value={this.state.order_tb_reference_id} onChange={this.onChanges}/>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassOrderList+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Customer Name</h6>
                                                                        <input type="text" className="form-control" name="order_tb_customer_name"
                                                                               placeholder="Enter Customer Name"  value={this.state.order_tb_customer_name} onChange={this.onChanges}/>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassOrderList+" col-md-3 col-12"}>
                                                                    <h6>Invoice Order List Filter</h6>
                                                                    <div className="in table-datepicker">
                                                                        <DateRangePicker
                                                                            onEvent={this.handleEvent} onCallback={this.handleCallbackInvoiceOrder}
                                                                            initialSettings={{
                                                                                autoApply:true
                                                                            }}
                                                                        >
                                                                            <button type="button" className="date-btn"> <i className="far fa-calendar-alt"></i>  {this.state.dayCountOrderList} Days <i
                                                                                className="fas fa-caret-down" aria-hidden="true"></i> </button>
                                                                        </DateRangePicker>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassOrderList+" col-md-3 col-12"}>
                                                                    <h6>Download CSV</h6>
                                                                    <CSVLink data={this.state.csvDataOrderList} style={{textDecoration: "none !important",color:"#000"}} filename={"InvoiceOrderReport.csv"}>
                                                                        <i className="fas fa-download"></i>
                                                                    </CSVLink>
                                                                </div>
                                                                {/*Payout List*/}

                                                                <div className={this.state.visibleClassPayoutList+" col-md-3 col-12"}>
                                                                    <div className="form-group">
                                                                        <h6>Currency Type</h6>
                                                                        <div className="slct">
                                                                        <select name="processing_currency" id="processing_currency" className="form-control" onChange={this.onChanges}>
                                                                            <option value="5">LKR</option>
                                                                            <option value="2">USD</option>
                                                                        </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassPayoutList+" col-md-3 col-12"}>
                                                                    <h6>Payout List Filter</h6>
                                                                    <div className="in table-datepicker">
                                                                        <DateRangePicker
                                                                            onEvent={this.handleEvent} onCallback={this.handleCallbackPayouts}
                                                                            initialSettings={{
                                                                                autoApply:true
                                                                            }}
                                                                        >
                                                                            <button type="button" className="date-btn"> <i className="far fa-calendar-alt"></i>  {this.state.dayCountPayoutList} Days <i
                                                                                className="fas fa-caret-down" aria-hidden="true"></i> </button>
                                                                        </DateRangePicker>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassPayoutList+" col-md-3 col-12"}>
                                                                    <h6>Download CSV</h6>
                                                                    <CSVLink data={this.state.csvDataPayoutList} style={{textDecoration: "none !important",color:"#000"}} filename={"PayoutList.csv"}>
                                                                        <i className="fas fa-download"></i>
                                                                    </CSVLink>
                                                                </div>


                                                                {/*  //Transaction Promotions*/}
                                                                <div className={this.state.visibleClassTransactionPromotion+" col-md-3 col-12"}>
                                                                    <h6>Transaction Id</h6>
                                                                    <div className="form-group">
                                                                        <input type="text" name="transaction_id_promotion" value={this.state.transaction_id_promotion} className="form-control" onChange={this.onChanges} />
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassTransactionPromotion+" col-md-3 col-12"}>
                                                                    <h6>Transaction Status</h6>
                                                                    <div className="form-group">
                                                                        <select name="transaction_status_promotion" id="" className="form-control" onChange={this.onChanges} value={this.state.transaction_status_promotion}>
                                                                            <option value="">Select Status</option>
                                                                            <option value="2">Approved</option>
                                                                            <option value="3">Declined</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className={this.state.visibleClassTransactionPromotion+" col-md-3 col-12"}>
                                                                    <h6>Transaction Value</h6>
                                                                    <div className="form-group">
                                                                        <input type="text" name="transaction_value_promotion" className="form-control" onChange={this.onChanges} value={this.state.transaction_value_promotion}/>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassTransactionPromotion+" col-md-3 col-12"}>
                                                                    <h6>Store Reference</h6>
                                                                    <div className="form-group">
                                                                        <input type="text" name="transaction_store_reference_promotion" className="form-control" onChange={this.onChanges} value={this.state.transaction_store_reference_promotion}/>
                                                                    </div>
                                                                </div>

                                                                <div className={this.state.visibleClassTransactionPromotion+" col-md-3 col-12"}>
                                                                    <h6>Transaction Date Range Filter</h6>
                                                                    <div className="in table-datepicker">
                                                                        <DateRangePicker
                                                                            initialSettings={{
                                                                                autoApply:true
                                                                            }}
                                                                            onEvent={this.handleEvent} onCallback={this.handleCallbackTransactionPromotion}
                                                                        >
                                                                            <button type="button" className="date-btn"> <i className="far fa-calendar-alt"></i>  {this.state.dayCount} Days <i
                                                                                className="fas fa-caret-down" aria-hidden="true"></i> </button>
                                                                        </DateRangePicker>
                                                                    </div>
                                                                </div>
                                                                <div className={this.state.visibleClassTransactionPromotion+" col-md-3 col-12"}>
                                                                    <h6>Transaction Currency</h6>
                                                                    <div className="form-group">
                                                                        <select name="transaction_currency_promotion" id="c1" className="form-control"  onChange={this.onChanges}  value={this.state.transaction_currency_promotion}>
                                                                            <option value="">Select Currency</option>
                                                                            <option value="5">LKR</option>
                                                                            <option value="2">USD</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={this.state.visibleClassTransactionPromotion+" col-md-3 col-12"}>
                                                                <h6>Download CSV</h6>
                                                                <CSVLink data={this.state.csvDataTransactionPromotion} style={{textDecoration: "none !important",color:"#000"}} filename={"TransactionReportPromotion.csv"}>
                                                                    <i className="fas fa-download"></i>
                                                                </CSVLink>
                                                            </div>
                                                        {/*    {this.state.visibleClassDue=="active-class-table"?(''):(*/}
                                                            <div className="row">

                                                                {/*<div className="col-md-3 col-12">
                                                                    <label className="cont">
                                                                        <input type="checkbox" />
                                                                        <span className="checkmark"></span>
                                                                        Email to Primary Email
                                                                    </label>
                                                                    <i className="fas fa-exchange-alt"
                                                                       data-toggle="modal"
                                                                       data-target="#cnpl-modal"></i>
                                                                </div>*/}
                                                                <div className="col-md-3 col-12">


                                                                    <button type="button" className="nxt-btn" onClick={this.state.searchStatus}>Search
                                                                    </button>
                                                                </div>
                                                                <div className="col-md-3 col-12">
                                                                    <button type="button" className="nxt-btn" onClick={this.state.clearStatus}>Clear Filters
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {/*   )}*/}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12">

                                                <div className={this.state.visibleclassCustomers+' rg-container'}>
                                                    <table className='transactions-table' summary='Hed'>
                                                        <thead>
                                                        <tr>
                                                            <th className=''>#</th>
                                                            <th className=''>Customer Name</th>
                                                            <th className=''>Email Address</th>
                                                            <th className=''>Phone Number</th>
                                                            <th className=''>Active Since</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr className="" data-toggle="modal" data-target="#modal-1">
                                                            <td className="" data-title="#">001</td>
                                                            <td className="" data-title="Customer Name">hmmm hmmm</td>
                                                            <td className="" data-title="Email Address">hmmm@fff.lk</td>
                                                            <td className="" data-title="Phone Number">33344</td>
                                                            <td className="" data-title="Active Since">May 20, 2021</td>
                                                        </tr>
                                                        <tr className="" data-toggle="modal" data-target="#modal-1">
                                                            <td className="" data-title="#">002</td>
                                                            <td className="" data-title="Customer Name">sdfd sfd</td>
                                                            <td className=""
                                                                data-title="Email Address">yugan91@gmail.comsss
                                                            </td>
                                                            <td className="" data-title="Phone Number">0777 478-913</td>
                                                            <td className="" data-title="Active Since">May 5, 2021</td>
                                                        </tr>
                                                        <tr className="" data-toggle="modal" data-target="#modal-1">
                                                            <td className="" data-title="#">003</td>
                                                            <td className="" data-title="Customer Name">Shuaib Mohamed
                                                            </td>
                                                            <td className=""
                                                                data-title="Email Address">shuaib.mohamed@webxpay.comm
                                                            </td>
                                                            <td className="" data-title="Phone Number">0777755643</td>
                                                            <td className="" data-title="Active Since">Apr 21, 2021</td>
                                                        </tr>
                                                        <tr className="" data-toggle="modal" data-target="#modal-1">
                                                            <td className="" data-title="#">004</td>
                                                            <td className="" data-title="Customer Name">Yuga Navaa</td>
                                                            <td className=""
                                                                data-title="Email Address">yuganasdasdasd91@gmail.com
                                                            </td>
                                                            <td className="" data-title="Phone Number">0777478913</td>
                                                            <td className="" data-title="Active Since">Apr 19, 2021</td>
                                                        </tr>
                                                        <tr className="" data-toggle="modal" data-target="#modal-1">
                                                            <td className="" data-title="#">005</td>
                                                            <td className="" data-title="Customer Name">asdas dil</td>
                                                            <td className="" data-title="Email Address">dsad@afsad.lk
                                                            </td>
                                                            <td className="" data-title="Phone Number">342423423</td>
                                                            <td className="" data-title="Active Since">Apr 8, 2021</td>
                                                        </tr>
                                                        <tr className="" data-toggle="modal" data-target="#modal-1">
                                                            <td className="" data-title="#">006</td>
                                                            <td className="" data-title="Customer Name">Yuga Navaa</td>
                                                            <td className=""
                                                                data-title="Email Address">yugan91@gmail.com
                                                            </td>
                                                            <td className="" data-title="Phone Number">0777478913</td>
                                                            <td className="" data-title="Active Since">Apr 7, 2021</td>
                                                        </tr>
                                                        <tr className="" data-toggle="modal" data-target="#modal-1">
                                                            <td className="" data-title="#">007</td>
                                                            <td className="" data-title="Customer Name">Krishan
                                                                Samarasinghe
                                                            </td>
                                                            <td className=""
                                                                data-title="Email Address">krishan.s@webxpay.com
                                                            </td>
                                                            <td className="" data-title="Phone Number">0115738333</td>
                                                            <td className="" data-title="Active Since">Apr 7, 2021</td>
                                                        </tr>
                                                        <tr className="" data-toggle="modal" data-target="#modal-1">
                                                            <td className="" data-title="#">008</td>
                                                            <td className="" data-title="Customer Name">Test
                                                                testloccgo
                                                            </td>
                                                            <td className=""
                                                                data-title="Email Address">testloccdddddo@mailinator.com
                                                            </td>
                                                            <td className="" data-title="Phone Number">0777777777</td>
                                                            <td className="" data-title="Active Since">Mar 23, 2021</td>
                                                        </tr>
                                                        <tr className="" data-toggle="modal" data-target="#modal-1">
                                                            <td className="" data-title="#">009</td>
                                                            <td className="" data-title="Customer Name">Mubeen gani</td>
                                                            <td className=""
                                                                data-title="Email Address">shuaib.mohamed@webxpay.com
                                                            </td>
                                                            <td className="" data-title="Phone Number">0777755643</td>
                                                            <td className="" data-title="Active Since">Mar 23, 2021</td>
                                                        </tr>
                                                        <tr className="" data-toggle="modal" data-target="#modal-1">
                                                            <td className="" data-title="#">010</td>
                                                            <td className="" data-title="Customer Name">Rex Perera</td>
                                                            <td className=""
                                                                data-title="Email Address">sujith.spam@gmail.com
                                                            </td>
                                                            <td className="" data-title="Phone Number">+94776192178</td>
                                                            <td className="" data-title="Active Since">Mar 22, 2021</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={1}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClickCustomers}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                                </div>


                                            
                                            <div className={this.state.visibleclassTransaction+' rg-container'}>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Transaction ID</th>
                                                        <th className=''>Store Reference</th>
                                                        <th className=''>Transaction Value</th>
                                                        <th className=''>Payment Type</th>
                                                        <th className=''>Date of Transaction</th>
                                                        <th className=''>Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="">
                                                        <td className="" data-title="#">797484</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7974842021I051656
                                                        </td>
                                                        <td className="" data-title="Store Reference">66</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td className="" data-title="Transaction Value"><img
                                                            src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Declined
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">797476</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7974762021I051655
                                                        </td>
                                                        <td className="" data-title="Store Reference">65</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 18.00</span></td>
                                                        <td className="" data-title="Transaction Value"><img
                                                            src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot green"><i className="fas fa-circle"
                                                                                           aria-hidden="true"></i></span>Approved
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">797091</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7970912021I051654
                                                        </td>
                                                        <td className="" data-title="Store Reference">52521</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td className="" data-title="Transaction Value"><img
                                                            src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Declined
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">797061</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7970612021I051652
                                                        </td>
                                                        <td className="" data-title="Store Reference">52521</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td className="" data-title="Transaction Value"><img
                                                            src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Declined
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">797058</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7970582021I051651
                                                        </td>
                                                        <td className="" data-title="Store Reference">52521</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td className="" data-title="Transaction Value"><img
                                                            src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Declined
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">790520</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7905202021I021650
                                                        </td>
                                                        <td className="" data-title="Store Reference">4913</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 100.00</span></td>
                                                        <td className="" data-title="Transaction Value"><img
                                                            src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 2, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Declined
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">790507</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7905072021I021649
                                                        </td>
                                                        <td className="" data-title="Store Reference">4911</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 300.00</span></td>
                                                        <td className="" data-title="Transaction Value"><img
                                                            src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jul 2, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Declined
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">784145</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7841452021I291647
                                                        </td>
                                                        <td className="" data-title="Store Reference">906</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 18.00</span></td>
                                                        <td className="" data-title="Transaction Value"><img
                                                            src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jun 29, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot green"><i className="fas fa-circle"
                                                                                           aria-hidden="true"></i></span>Approved
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">784144</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7841442021I291646
                                                        </td>
                                                        <td className="" data-title="Store Reference">905</td>
                                                        <td className="" data-title="Transaction Value">
                                                            <span>LKR 10.00</span></td>
                                                        <td className="" data-title="Transaction Value"><img
                                                            src="https://api.webxapi.online/gateway_logos/master.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jun 29, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Declined
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">784140</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7841402021I291645
                                                        </td>
                                                        <td className="" data-title="Store Reference">903</td>
                                                        <td className="" data-title="Transaction Value"><span>LKR 1,500.00</span>
                                                        </td>
                                                        <td className="" data-title="Transaction Value"><img
                                                            src="https://api.webxapi.online/gateway_logos/visa.jpg"
                                                            alt="" style={{width: "35px"}} /></td>
                                                        <td className="" data-title="Date of Transaction">Jun 29, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot red"><i className="fas fa-circle"
                                                                                         aria-hidden="true"></i></span>Declined
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel={"PREV"}
                                                    nextLabel={"NEXT"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={1}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClickTransactions}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}/>
                                            </div>
                                         

                                            <div className={this.state.visibleclassRefund+' rg-container'}>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Transaction ID</th>
                                                        {/*      <th className=''>Refund ID</th>*/}
                                                        <th className=''>Transaction Value</th>
                                                        <th className=''>Refund Value</th>
                                                        <th className=''>Date of Transaction</th>
                                                        {/*   <th className=''>Contact</th>
                                                        <th className=''>Email Address</th>*/}
                                                        <th className=''>Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="">
                                                        <td className="" data-title="#">738886</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7388862021I111488
                                                        </td>
                                                        <td className="" data-title="Transaction Value">LKR 1.00</td>
                                                        <td className="" data-title="Refund Value">LKR 1.00</td>
                                                        <td className="" data-title="Date of Transaction">Jun 11, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot green"><i className="fas fa-circle"
                                                                                           aria-hidden="true"></i></span>Refunded
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">797476</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7974762021I051655
                                                        </td>
                                                        <td className="" data-title="Transaction Value">LKR 18.00</td>
                                                        <td className="" data-title="Refund Value">LKR 10.00</td>
                                                        <td className="" data-title="Date of Transaction">Jul 5, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot yellow"><i className="fas fa-circle"
                                                                                            aria-hidden="true"></i></span>Pending
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">784145</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7841452021I291647
                                                        </td>
                                                        <td className="" data-title="Transaction Value">LKR 18.00</td>
                                                        <td className="" data-title="Refund Value">LKR 10.00</td>
                                                        <td className="" data-title="Date of Transaction">Jun 29, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot yellow"><i className="fas fa-circle"
                                                                                            aria-hidden="true"></i></span>Pending
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">778412</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7784122021I251614
                                                        </td>
                                                        <td className="" data-title="Transaction Value">LKR 12.00</td>
                                                        <td className="" data-title="Refund Value">LKR 12.00</td>
                                                        <td className="" data-title="Date of Transaction">Jun 25, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction"></td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">778434</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7784342021I251618
                                                        </td>
                                                        <td className="" data-title="Transaction Value">LKR 1.00</td>
                                                        <td className="" data-title="Refund Value">LKR 1.00</td>
                                                        <td className="" data-title="Date of Transaction">Jun 25, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot green"><i className="fas fa-circle"
                                                                                           aria-hidden="true"></i></span>Refunded
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">778439</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7784392021I251619
                                                        </td>
                                                        <td className="" data-title="Transaction Value">LKR 2.00</td>
                                                        <td className="" data-title="Refund Value">LKR 2.00</td>
                                                        <td className="" data-title="Date of Transaction">Jun 25, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction">
                                                            <span className="dot green"><i className="fas fa-circle"
                                                                                           aria-hidden="true"></i></span>Refunded
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">778403</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7784032021I251613
                                                        </td>
                                                        <td className="" data-title="Transaction Value">LKR 9.00</td>
                                                        <td className="" data-title="Refund Value">LKR 9.00</td>
                                                        <td className="" data-title="Date of Transaction">Jun 25, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction"></td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">774163</td>
                                                        <td className=""
                                                            data-title="Transaction ID">T7741632021I231596
                                                        </td>
                                                        <td className="" data-title="Transaction Value">LKR 12.00</td>
                                                        <td className="" data-title="Refund Value">LKR 10.00</td>
                                                        <td className="" data-title="Date of Transaction">Jun 23, 2021
                                                        </td>
                                                        <td className="pop-td" data-toggle="popover"
                                                            data-trigger="hover" data-placement="auto"
                                                            data-content="Bank was Unable to Authenticate Transaction"></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel={"PREV"}
                                                    nextLabel={"NEXT"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={1}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClickRefunds}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}/>
                                            </div>


                                                <div className={this.state.visibleClassDue+' rg-container'}>
                                                    <table className='transactions-table' summary='Hed'>
                                                        <thead>
                                                        <tr>
                                                            <th>Transaction Date</th>
                                                            <th>Store Reference</th>
                                                            <th>Transaction ID</th>
                                                            <th>Customer Name</th>
                                                            <th>Processing Currency</th>
                                                            <th>Transaction Amount</th>
                                                            <th>Refund Charges</th>
                                                            <th>Payment Amount</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td className="" data-title="Settlement Date">Jul 6, 2021</td>
                                                            <td className=""
                                                                data-title="Settlement ID">3754-202107061421-1
                                                            </td>
                                                            <td className=""
                                                                data-title="Settlement ID">T12121212545454
                                                            </td>
                                                            <td className="" data-title="Account Deposited">Customer 1</td>
                                                            <td className="" data-title="Account Deposited">LKR</td>
                                                            <td className="" data-title="Account Deposited">10.00</td>
                                                            <td className="" data-title="Account Deposited">5.00</td>
                                                            <td className="" data-title="Account Deposited">5.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="" data-title="Settlement Date">Jul 6, 2021</td>
                                                            <td className=""
                                                                data-title="Settlement ID">3754-202107061421-1
                                                            </td>
                                                            <td className=""
                                                                data-title="Settlement ID">T12121212545454
                                                            </td>
                                                            <td className="" data-title="Account Deposited">Customer 1</td>
                                                            <td className="" data-title="Account Deposited">LKR</td>
                                                            <td className="" data-title="Account Deposited">10.00</td>
                                                            <td className="" data-title="Account Deposited">5.00</td>
                                                            <td className="" data-title="Account Deposited">5.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="" data-title="Settlement Date">Jul 6, 2021</td>
                                                            <td className=""
                                                                data-title="Settlement ID">3754-202107061421-1
                                                            </td>
                                                            <td className=""
                                                                data-title="Settlement ID">T12121212545454
                                                            </td>
                                                            <td className="" data-title="Account Deposited">Customer 1</td>
                                                            <td className="" data-title="Account Deposited">LKR</td>
                                                            <td className="" data-title="Account Deposited">10.00</td>
                                                            <td className="" data-title="Account Deposited">5.00</td>
                                                            <td className="" data-title="Account Deposited">5.00</td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={this.state.pageCountDues}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClickDues}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                                </div>

                                            <div className={this.state.visibleClassSettlement+' rg-container'}>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Settlement ID</th>
                                                        <th className=''>Total LKR Sales</th>
                                                        <th className=''>Total USD Sales</th>
                                                        <th className=''>Deduction LKR</th>
                                                        <th className=''>Deduction USD</th>
                                                        <th className=''>Refund</th>
                                                        <th className=''>Amount Settled</th>
                                                        <th className=''>Settlement Date </th>
                                                        <th className=''>Account Deposited</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="">
                                                        <td className="" data-title="#">1</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107090929-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 3,425.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 85.63</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 3,339.37</span></td>
                                                        <td className="" data-title="Settlement Date">Jul 9, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">2</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107081557-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 28,220.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 730.78</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 27,489.22</span></td>
                                                        <td className="" data-title="Settlement Date">Jul 8, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">3</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107071539-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 6,380.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 195.09</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>-LKR 5,462.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 722.91</span></td>
                                                        <td className="" data-title="Settlement Date">Jul 7, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">4</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107061421-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 24,990.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 691.73</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 24,298.27</span></td>
                                                        <td className="" data-title="Settlement Date">Jul 6, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">5</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107051553-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 81,262.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 243.70</span></td>
                                                        <td className="" data-title="Deductions">
                                                            <span>LKR 2,248.65</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 8.29</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled"><span>LKR 125,461.86</span>
                                                        </td>
                                                        <td className="" data-title="Settlement Date">Jul 5, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">6</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107021245-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 16,195.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 169.63</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 453.46</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 5.77</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 48,072.59</span></td>
                                                        <td className="" data-title="Settlement Date">Jul 2, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">7</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202107012112-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 9,290.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 260.12</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 9,029.88</span></td>
                                                        <td className="" data-title="Settlement Date">Jul 1, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">8</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202106301554-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 33,785.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 945.98</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 32,839.02</span></td>
                                                        <td className="" data-title="Settlement Date">Jun 30, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">9</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202106291347-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 22,675.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Deductions"><span>LKR 592.72</span>
                                                        </td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 22,082.28</span></td>
                                                        <td className="" data-title="Settlement Date">Jun 29, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">10</td>
                                                        <td className=""
                                                            data-title="Settlement ID">3754-202106281502-1
                                                        </td>
                                                        <td className="" data-title="Total Settlement"><span>LKR 63,250.00</span>
                                                        </td>
                                                        <td className="" data-title="Total Settlement">
                                                            <span>USD 14.48</span></td>
                                                        <td className="" data-title="Deductions">
                                                            <span>LKR 1,845.58</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>USD 0.49</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 0.00</span></td>
                                                        <td className="" data-title="Amount Settled">
                                                            <span>LKR 64,164.77</span></td>
                                                        <td className="" data-title="Settlement Date">Jun 28, 2021</td>
                                                        <td className="" data-title="Account Deposited">NTB</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel={"PREV"}
                                                    nextLabel={"NEXT"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={1}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClickSettlements}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}/>
                                            </div>


                                            <div className={this.state.visibleClassInvoice+' rg-container'}>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Invoice ID</th>
                                                        <th className=''>Created Date</th>
                                                        <th className=''>Amount</th>
                                                        <th className=''>Customer</th>
                                                        {/*<th className=''>Payment Date</th>*/}
                                                        <th className=''>Payment Link</th>
                                                        <th className=''>Status</th>
                                                      {/*  <th className=''></th>*/}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="">
                                                        <td className="" data-title="#">1</td>
                                                        <td className="" data-title="Invoice ID">1234</td>
                                                        <td className="" data-title="Created Date">Jul 2, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 300</span>
                                                        </td>
                                                        <td className="" data-title="Customer">Krishan Samarasinghe</td>
                                                        <td className=""
                                                            data-title="Payment Link">https://api.webxapi.online/invoice_detail/1706
                                                        </td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot green"><i className="fas fa-circle"
                                                                                     aria-hidden="true"></i></span>Paid
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">2</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16250-43892
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 30, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 11,500</span>
                                                        </td>
                                                        <td className="" data-title="Customer">Yuga Navaa</td>
                                                        <td className=""
                                                            data-title="Payment Link">https://api.webxapi.online/invoice_detail/1649
                                                        </td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">3</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16250-43705
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 30, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 11</span>
                                                        </td>
                                                        <td className="" data-title="Customer">Shuaib Mohamed</td>
                                                        <td className=""
                                                            data-title="Payment Link">https://api.webxapi.online/invoice_detail/1646
                                                        </td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">4</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16250-27389
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 30, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 1,220</span>
                                                        </td>
                                                        <td className="" data-title="Customer">Yuga Navaa</td>
                                                        <td className=""
                                                            data-title="Payment Link">https://api.webxapi.online/invoice_detail/1622
                                                        </td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">5</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16250-26394
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 30, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 50</span>
                                                        </td>
                                                        <td className="" data-title="Customer">hmmm hmmm</td>
                                                        <td className=""
                                                            data-title="Payment Link">https://api.webxapi.online/invoice_detail/1619
                                                        </td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">6</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16246-44454
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 25, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 2</span></td>
                                                        <td className="" data-title="Customer">shuhib mohamed</td>
                                                        <td className=""
                                                            data-title="Payment Link">https://api.webxapi.online/invoice_detail/1476
                                                        </td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot green"><i className="fas fa-circle"
                                                                                     aria-hidden="true"></i></span>Paid
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">7</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16246-44141
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 25, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 1</span></td>
                                                        <td className="" data-title="Customer">shuhib mohamed</td>
                                                        <td className=""
                                                            data-title="Payment Link">https://api.webxapi.online/invoice_detail/1475
                                                        </td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot green"><i className="fas fa-circle"
                                                                                     aria-hidden="true"></i></span>Paid
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">8</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16245-19465
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 24, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 30,000</span>
                                                        </td>
                                                        <td className="" data-title="Customer">Yuga Navaa</td>
                                                        <td className=""
                                                            data-title="Payment Link">https://api.webxapi.online/invoice_detail/1442
                                                        </td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel={"PREV"}
                                                    nextLabel={"NEXT"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={1}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClickInvoices}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}/>
                                            </div>

                                    {/*        {settlement_lists_render.length>0?(
                                                <div className={this.state.visibleClassSettlement+' rg-container'}>
                                                    <table className='transactions-table' summary='Hed'>
                                                        <thead>
                                                        <tr>
                                                            <th className=''>#</th>
                                                            <th className=''>Settlement ID</th>
                                                            <th className=''>Total Settlement</th>
                                                            <th className=''>Deductions</th>
                                                            <th className=''>Amount Settled</th>
                                                            <th className=''>Settlement Date</th>
                                                            <th className=''>Account Deposited</th>
                                                            <th className=''>Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {settlement_lists_render}
                                                        </tbody>
                                                    </table>
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={this.state.pageCountSettlements}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClickSettlements}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                                </div>
                                            ):(
                                                ''
                                            )}*/}

                                                <div className={this.state.visibleClassOrderList+' rg-container'}>
                                                    <table className='transactions-table' summary='Hed'>
                                                        <thead>
                                                        <tr>
                                                            <th className=''>Invoice ID</th>
                                                            <th className=''>Invoice Number</th>
                                                            <th className=''>Order Reference Number</th>
                                                            <th className=''>Amount</th>
                                                            <th className=''>Order Date</th>
                                                            <th className=''>Payment Status</th>
                                                            <th className=''>Full Name</th>
                                                            <th className=''>E-Mail</th>
                                                            <th className=''>Phone Number</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr className="">
                                                            <td className="" data-title="#">58</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">D6267822021I031136</td>
                                                            <td className="" data-title="#"><span>USD10.00</span></td>
                                                            <td className="" data-title="#">Apr 3, 2021</td>
                                                            <td className="" data-title="#">Abandoned</td>
                                                            <td className="" data-title="#">shuhib mohamed</td>
                                                            <td className="" data-title="#">harshakadilan@gmail.com</td>
                                                            <td className="" data-title="#">0773756036</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">57</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#"><span>USD10.00</span></td>
                                                            <td className="" data-title="#">Apr 3, 2021</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">Lanka Property</td>
                                                            <td className=""
                                                                data-title="#">harshaka.ratnapala@webxpay.com
                                                            </td>
                                                            <td className="" data-title="#">0773111555</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">56</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#"><span>USD22.00</span></td>
                                                            <td className="" data-title="#">Mar 17, 2021</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">Test Webx tese</td>
                                                            <td className="" data-title="#">test@test.lk</td>
                                                            <td className="" data-title="#">+94114 724 724</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">55</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">D5838762021I221007</td>
                                                            <td className="" data-title="#"><span>USD200.00</span></td>
                                                            <td className="" data-title="#">Feb 22, 2021</td>
                                                            <td className="" data-title="#">Abandoned</td>
                                                            <td className="" data-title="#">Test Webx tese</td>
                                                            <td className="" data-title="#">test@test.lk</td>
                                                            <td className="" data-title="#">+94114 724 724</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">54</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#"><span>USD20.00</span></td>
                                                            <td className="" data-title="#">Feb 8, 2021</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">Test Webx tese</td>
                                                            <td className="" data-title="#">test@test.lk</td>
                                                            <td className="" data-title="#">+94114 724 724</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">53</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#"><span>USD2.00</span></td>
                                                            <td className="" data-title="#">Feb 8, 2021</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">Test Webx tese</td>
                                                            <td className="" data-title="#">test@test.lk</td>
                                                            <td className="" data-title="#">+94114 724 724</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">52</td>
                                                            <td className="" data-title="#">1000</td>
                                                            <td className="" data-title="#">D5619922021I05964</td>
                                                            <td className="" data-title="#"><span>USD8.00</span></td>
                                                            <td className="" data-title="#">Feb 1, 2021</td>
                                                            <td className="" data-title="#">Decline</td>
                                                            <td className="" data-title="#">Harshaka Dilan</td>
                                                            <td className="" data-title="#">est@email.com</td>
                                                            <td className="" data-title="#">0773606370</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">51</td>
                                                            <td className="" data-title="#">1000</td>
                                                            <td className="" data-title="#">D5549362021I01948</td>
                                                            <td className="" data-title="#"><span>LKR800.00</span></td>
                                                            <td className="" data-title="#">Feb 1, 2021</td>
                                                            <td className="" data-title="#">Decline</td>
                                                            <td className="" data-title="#">Harshaka Dilan</td>
                                                            <td className="" data-title="#">est@email.com</td>
                                                            <td className="" data-title="#">0773606370</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">50</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">D5509042021I29943</td>
                                                            <td className="" data-title="#"><span>LKR800.00</span></td>
                                                            <td className="" data-title="#">Jan 26, 2021</td>
                                                            <td className="" data-title="#">Decline</td>
                                                            <td className="" data-title="#">Harshaka Dilan</td>
                                                            <td className="" data-title="#">est@email.com</td>
                                                            <td className="" data-title="#">0773606370</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">49</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#"><span>LKR800.00</span></td>
                                                            <td className="" data-title="#">Jan 11, 2021</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">Harshaka Dilan</td>
                                                            <td className="" data-title="#">@test@email.com</td>
                                                            <td className="" data-title="#">0773606370</td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={1}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClickInvoiceOrder}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                                </div>


                                                <div className={this.state.visibleClassPayoutList+' rg-container'}>
                                                    <table className='transactions-table' summary='Hed'>
                                                        <thead>
                                                        <tr>
                                                            <th className=''>Transaction / Refund Date</th>
                                                            <th className=''>Store Order ID</th>
                                                            <th className=''>Transaction ID</th>
                                                            <th className=''>Name</th>
                                                            <th className=''>Processing Currency</th>
                                                            <th className=''>Transaction Amount</th>
                                                            <th className=''>Gateway Charge</th>
                                                            <th className=''>Transaction Net</th>
                                                            <th className=''>Refund Charge</th>
                                                            <th className=''>Payment Currency</th>
                                                            <th className=''>Settlement Amount</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr className="">
                                                            <td className="" data-title="#">Jun 6, 2021</td>
                                                            <td className="" data-title="#">2771</td>
                                                            <td className="" data-title="#"> T7259932021I063256</td>
                                                            <td className="" data-title="#">Gastrolabs (Pvt) Ltd
                                                                834425
                                                            </td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">13200.00</td>
                                                            <td className="" data-title="#">369.60</td>
                                                            <td className="" data-title="#">12830.4</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">12830.40</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">Jun 6, 2021</td>
                                                            <td className="" data-title="#">2752</td>
                                                            <td className="" data-title="#"> T7246752021I063251</td>
                                                            <td className="" data-title="#">Shavindi Henepola 834374
                                                            </td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">1500.00</td>
                                                            <td className="" data-title="#">42.00</td>
                                                            <td className="" data-title="#">1458</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">1458.00</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">Jun 6, 2021</td>
                                                            <td className="" data-title="#">2759</td>
                                                            <td className="" data-title="#"> T7256352021I063254</td>
                                                            <td className="" data-title="#">Rohanthi Wijewickrama
                                                                832505
                                                            </td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">350.00</td>
                                                            <td className="" data-title="#">9.80</td>
                                                            <td className="" data-title="#">340.2</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">340.20</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">Jun 6, 2021</td>
                                                            <td className="" data-title="#">2762</td>
                                                            <td className="" data-title="#"> T7256852021I063255</td>
                                                            <td className="" data-title="#">Ashweni miranda Ashweni
                                                                miranda
                                                            </td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">100.00</td>
                                                            <td className="" data-title="#">2.80</td>
                                                            <td className="" data-title="#">97.2</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">97.20</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">Jun 6, 2021</td>
                                                            <td className="" data-title="#">2779</td>
                                                            <td className="" data-title="#"> T7263382021I063258</td>
                                                            <td className="" data-title="#">Sajitha Anthony 834325</td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">3297.50</td>
                                                            <td className="" data-title="#">92.33</td>
                                                            <td className="" data-title="#">3205.17</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">3205.17</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">Jun 6, 2021</td>
                                                            <td className="" data-title="#">2757</td>
                                                            <td className="" data-title="#"> T7256112021I063253</td>
                                                            <td className="" data-title="#">Pan Global Holdings 834391
                                                            </td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">960.00</td>
                                                            <td className="" data-title="#">23.04</td>
                                                            <td className="" data-title="#">936.96</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">936.96</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">Jun 7, 2021</td>
                                                            <td className="" data-title="#">2792</td>
                                                            <td className="" data-title="#"> T7272322021I073259</td>
                                                            <td className="" data-title="#">Dawn Edwin 834291</td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">960.00</td>
                                                            <td className="" data-title="#">26.88</td>
                                                            <td className="" data-title="#">933.12</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">933.12</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">Jun 7, 2021</td>
                                                            <td className="" data-title="#">2804</td>
                                                            <td className="" data-title="#"> T7275212021I073261</td>
                                                            <td className="" data-title="#">Shanaz Fouze 834449</td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">350.00</td>
                                                            <td className="" data-title="#">9.80</td>
                                                            <td className="" data-title="#">340.2</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">340.20</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">Jun 7, 2021</td>
                                                            <td className="" data-title="#">2823</td>
                                                            <td className="" data-title="#"> T7279042021I073263</td>
                                                            <td className="" data-title="#">Ashani Gopallawa 834346</td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">480.00</td>
                                                            <td className="" data-title="#">13.44</td>
                                                            <td className="" data-title="#">466.56</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">466.56</td>
                                                        </tr>
                                                        <tr className="">
                                                            <td className="" data-title="#">Jun 7, 2021</td>
                                                            <td className="" data-title="#">2831</td>
                                                            <td className="" data-title="#"> T7280882021I073265</td>
                                                            <td className="" data-title="#">AALAM Holdings 834470</td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">1440.00</td>
                                                            <td className="" data-title="#">40.32</td>
                                                            <td className="" data-title="#">1399.68</td>
                                                            <td className="" data-title="#"></td>
                                                            <td className="" data-title="#">LKR</td>
                                                            <td className="" data-title="#">1399.68</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={1}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClickPayouts}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                                </div>

                                            {transaction_lists_promotions.length>0?(
                                                <div className={this.state.visibleClassTransactionPromotion+' rg-container'}>
                                                    <table className='transactions-table' summary='Hed'>
                                                        <thead>
                                                        <tr>
                                                            <th className=''>#</th>
                                                            <th className=''>Transaction ID</th>
                                                            <th className=''>Store Reference</th>
                                                            <th className=''>Transaction Value</th>
                                                            <th className=''>Payment Type</th>
                                                            <th className=''>Card Number</th>
                                                            <th className=''>Date of Transaction</th>
                                                            <th className=''>Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {transaction_lists_promotions}
                                                        </tbody>
                                                    </table>
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={this.state.pageCountTransactions_promotion}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClickTransactionsPromotion}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>
                                                </div>
                                            ):(
                                                ''
                                            )}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                       <Footer />
                    </div>

                </div>

            </div>
        );
    }

}


