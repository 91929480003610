import React from 'react';


export class Root extends React.Component {


    render() {
        return (
            <div className="page">
                <div className="">
                    {this.props.children}
                </div>
            </div>
        );
    }
}