import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import moment from 'moment';


import {Navigation} from "../Navigation";
import {TopBar} from "../TopBar";
import {PostCompnentWebxBpsp, PostWebxLaravel} from "../PostCompnent";
import {Footer} from "../Footer";
import {SupportButton} from "../SupportButton";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Dropdown from "react-bootstrap/Dropdown";
import {CSVLink} from "react-csv";
import {PlusButton} from './SupplierIncludes/PlusButton';
import ReactPaginate from "react-paginate";
import {exportPDFDownload} from "../ExportPdf";
import {SupplierDetailModal} from "./SupplierIncludes/SupplierManagement/SupplierModal";
import NumberFormat from "react-number-format";


export class Suppliers extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            supplier_data: [],
            openprogress: false,
            setOpenProgress: false,
            startDate: moment().subtract(30, 'days').format('ll'),
            endDate: moment().format('ll'),
            dayCount: 30,
            //===========================
            supplier_lists: [],
            supplier_lists_render: [],
            supplier_lists_filters: [],
            offsetSuppliers: 0,
            perPageSuppliers: 10,
            currentPageSuppliers: 0,
            pageCountSuppliers: 0,
            supplier_start_date: moment().subtract(30, 'days').format('ll'),
            supplier_end_date: moment(),
            supplier_keyword_value: '',
            bankData: [],
            supplierTypes: [],

            modalShowSupplier: false,
            setModalShowSupplier: false,
            supplierData: [],
            searchResults: [],
            searchTerm: "",
            business_name: '',
            csvDataSupplier: []

        };
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);

        this.GenerateSuppliersList = this.GenerateSuppliersList.bind(this);
        this.loadTableSuppliers = this.loadTableSuppliers.bind(this);
        this.handlePageClickSuppliers = this.handlePageClickSuppliers.bind(this);
        this.filterListSuppliers = this.filterListSuppliers.bind(this);
        this.clearListSuppliers = this.clearListSuppliers.bind(this);
        this.csvGenerateSuppliers = this.csvGenerateSuppliers.bind(this);
        this.clearPagination = this.clearPagination.bind(this);

        this.callSupplierInfo = this.callSupplierInfo.bind(this);
        this.loadSupplierDeps = this.loadSupplierDeps.bind(this);
        this.onChange = this.onChange.bind(this);
        this.filterList = this.filterList.bind(this);
        this.clearList = this.clearList.bind(this);
    }

    filterList() {

        if (this.state.business_name) {

            const results = this.state.supplier_lists_filters.filter(supplier =>
                supplier.business_name.toLowerCase().includes(this.state.business_name.toLowerCase())
            );
            console.log(results)
            this.setState({
                supplier_lists_filters: results,
                offsetSuppliers: 0,
                perPageSuppliers: 10,
                currentPageSuppliers: 0,
            }, () => {
                this.loadTableSuppliers()
            });


        } else {
            this.setState({supplier_lists_filters: []}, () => {
                this.loadTableSuppliers()
            });

        }

    }

    clearList() {
        this.setState({
            supplier_lists_filters: this.state.supplier_lists,business_name:'',
            offsetSuppliers: 0,
            perPageSuppliers: 10,
            currentPageSuppliers: 0,
        }, () => {
            this.loadTableSuppliers()
                this.clearListSuppliers()
        });

    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value})

    }

    loadSupplierDeps() {

        PostWebxLaravel("bpsp/get-banks").then(response => {
            this.setState({bankData: response.data.bank_data})
        });

        PostWebxLaravel("bpsp/get-supplier-types").then(response => {
            this.setState({supplierTypes: response.data.supplier_types})
        });
    }

    //List Generation Supplier

    //Customers List
    GenerateSuppliersList() {
        this.handleToggleProgress();
        let formData = new FormData();    //formdata object
        formData.append("store_id", sessionStorage.getItem("merchant_id"));
        PostCompnentWebxBpsp("getMerchantBpspSuppliersWithTransactionTotal", formData).then(response => {
            this.handleCloseProgress();
            this.setState({
                supplier_lists: response.data,
                supplier_lists_filters: response.data
            }, () => {
                this.csvGenerateSuppliers(response.data)
                this.loadTableSuppliers();

            });
        });
    }


    callSupplierInfo(id, supplier_data) {


        this.setState({
            modalShowSupplier: true,
            setModalShowSupplier: false,
            supplier_id: id,
            supplier_data: supplier_data
        })


    }

    loadTableSuppliers() {

        const list_data = this.state.supplier_lists_filters.slice().reverse();

        const zeroPad = (num, places) => String(num).padStart(places, '0');

        const slice = list_data.slice(this.state.offsetSuppliers, this.state.offsetSuppliers + this.state.perPageSuppliers);
        const trans = list_data && list_data.length ? (slice.map((list, key) =>

            <tr key={key}>
                <td>{zeroPad(this.state.offsetSuppliers + key+1, 3)}</td>
                <td>{list.username}</td>
                <td>{list.business_email}</td>
                <td>{list.phone_number}</td>
                <td>{list.business_name}</td>
                <td>
                    <NumberFormat
                        value={(Math.round(list.total_settlement_pending * 100) / 100).toFixed(2)}
                        displayType={'text'} thousandSeparator={true}
                        prefix='LKR '/>

                </td>
                <td>{list.status == 1 ? (
                    <><span className="dot green"><i className="fas fa-circle"
                                                     aria-hidden="true"></i></span> Active</>) : (
                    <><span className="dot red"><i className="fas fa-circle"
                                                   aria-hidden="true"></i></span> Inactive</>)}
                </td>
                {/*<td><LoadModalFunctions supplier_id={list.supplier_id}  supplierTypes={this.state.supplierTypes} bankData={this.state.bankData}  onBoard={this.GenerateSuppliersList} supplierData={list} /></td>*/}
                <td>
                    <button className="dot-drop btn" onClick={() => this.callSupplierInfo(list.supplier_id, list)}><i className="fas fa-eye" aria-hidden="true"></i></button>

                </td>
            </tr>
        )) : (
            <tr className=''>
                <td colSpan="9" className='' data-title='#'><strong>No results found, for the selected date range /
                    filter</strong></td>
            </tr>
        );
        console.log(trans)
        this.setState({
            pageCountSuppliers: Math.ceil(list_data.length / this.state.perPageSuppliers),
            supplier_lists_render: trans
        })
    }

    handlePageClickSuppliers = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageSuppliers;

        this.setState({
            currentPageSuppliers: selectedPage,
            offsetSuppliers: offset
        }, () => {
            this.loadTableSuppliers();
        });

    };

    filterListSuppliers() {
        let keyword = this.state.supplier_keyword_value.toLowerCase();
        let formData = new FormData();    //formdata object
        formData.append('keyword', keyword);   //append the values with key, value pair
        formData.append('merchant_id', sessionStorage.getItem("merchant_id") + '|' + sessionStorage.getItem("encrypted_merchant_id"));   //append the values with key, value pair

        this.handleToggleProgress();
        PostCompnentWebxBpsp("getMerchantBpspSuppliersWithTransactionTotal", formData).then(response => {
            this.csvGenerateSuppliers(response.data);
            this.handleCloseProgress();
            this.setState({
                supplier_lists: response.data,
                supplier_lists_filters: response.data,
                offsetSuppliers: 0,
                currentPageSuppliers:1
            }, () => {
                this.loadTableSuppliers();
            });
        });
    }

    clearListSuppliers() {
        this.handleToggleProgress();
        let formData = new FormData();    //formdata object
        formData.append("store_id", sessionStorage.getItem("merchant_id"));
        PostCompnentWebxBpsp("getMerchantBpspSuppliersWithTransactionTotal", formData).then(response => {
            this.handleCloseProgress();
            this.setState({
                keyword: '',
                supplier_lists: response.data,
                supplier_lists_filters: response.data
            }, () => {
                this.loadTableSuppliers();
                this.clearPagination();
            });
        });

    }

    csvGenerateSuppliers(response) {

        const csvData = [];

        csvData.push([
            "", "", "SUPPLIER REPORT"
        ]);
        csvData.push([]);

        csvData.push([
            "SUPPLIER NAME",
            "E-MAIL",
            "PHONE NUMBER",
            "BUSINESS NAME",
            "PENDING SETTLEMENT",
            "STATUS",


        ]);
        response.map((value, index) => {
            let status = value.status == 1 ? "Active" : "Inactive";
            csvData.push([
                value.account_name,
                value.business_email,
                value.phone_number,
                value.business_name,
                value.total_settlement_pending,
                status])
        });

        this.setState({
            csvDataSupplier: csvData
        });
    }

    clearPagination() {
        var second = document.querySelector(".pagination > li:nth-child(2) > a");
        if (second !== null) {
            second.click();
        }
    }

    exportPDF(type, csvData) {
        let title = "";
        let pdf_header = "";
        let document_name = "";
        let pdf_filtered_data = [];
        let pdf_data = csvData;


        switch (type) {
            case 'supplier_data':
                title = "Supplier Data";
                document_name = "Supplier Data.pdf";
                pdf_header = [pdf_data[2]];
                let pdf_data_suppliers = pdf_data;
                let pdf_data_supplier = pdf_data_suppliers.filter((pdf_data_supplier, index) => index > 2)
                pdf_filtered_data = pdf_data_supplier;
                break;


            default:
        }

        exportPDFDownload(title, pdf_header, pdf_filtered_data, document_name);
    }

    //End Of List Generation Supplier

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    componentDidMount() {
        if (sessionStorage.getItem("userToken") || localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");

            this.setState({userToken: userToken});

            this.loadSupplierDeps()
            this.GenerateSuppliersList()


        } else {
            this.setState({redirect: true});
        }
    }


    render() {
        const supplier_list = this.state.supplier_lists_render;

        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }
        return (
            <div>
                <SupportButton/>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <PlusButton
                    onHide={() => {
                        this.setState({modalShowSupplier: false})
                    }}
                    onReload={this.GenerateSuppliersList}
                    bankData={this.state.bankData}
                    supplierTypes={this.state.supplierTypes}

                />
                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <Navigation pathname="suppliers"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBar pathname="transactions"/>

                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="../suppliers" className="nav-link active" id="tt-tab" data-toggle="tab"
                                          role="tab"
                                          aria-controls="tt" aria-selected="true"><span
                                        className="tt">All Suppliers</span>
                                        <span
                                            className="amt">{this.state.supplier_lists.length} Suppliers</span>

                                    </Link>

                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tt" role="tabpanel"
                                     aria-labelledby="tt-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <form className="col-md-8 col-12 tt-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <label htmlFor="">Search </label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Business Name"
                                                                   name="business_name"
                                                                   value={this.state.business_name}
                                                                   onChange={this.onChange}
                                                            />
                                                        </div>

                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn"
                                                                    onClick={this.filterList}
                                                                    style={{marginTop: "15px"}}>Search
                                                            </button>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn clear-btn"
                                                                    onClick={this.clearList}
                                                                    style={{marginTop: "15px"}}>Clear
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form className="col-md-4 col-12 tr-form">
                                                    <div className="row row_clr">


                                                        <div className="form-group" style={{visibility:"hidden"}}>
                                                            <label htmlFor="">Filters & Reports</label>
                                                            <div className="in table-datepicker">
                                                                <DateRangePicker
                                                                    onEvent={this.handleEvent}
                                                                    onCallback={this.handleCallback}
                                                                    initialSettings={{
                                                                        startDate: moment(this.state.startDate).format('M/DD/YYYY'),
                                                                        endDate: moment(this.state.endDate).format('M/DD/YYYY'),
                                                                        maxDate: moment().format('M/DD/YYYY'),
                                                                        autoApply: true
                                                                    }}

                                                                >
                                                                    <button type="button" className="date-btn"><i
                                                                        className="far fa-calendar-alt"></i> {this.state.dayCount} Days <i
                                                                        className="fas fa-caret-down"
                                                                        aria-hidden="true"></i></button>
                                                                </DateRangePicker>
                                                            </div>
                                                        </div>
                                                        <div className="form-group" >

                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="none"
                                                                                 id="settlement-download-dropdown">
                                                                    <i className="fas fa-download"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <CSVLink data={this.state.csvDataSupplier} style={{
                                                                        textDecoration: "none !important",
                                                                        color: "#000"
                                                                    }} className="dropdown-item"
                                                                             filename={"Suppliers.csv"}>
                                                                        <i className="fas fa-download"></i> CSV Download
                                                                    </CSVLink>
                                                                    <button type="button"
                                                                            onClick={() => this.exportPDF("supplier_data", this.state.csvDataSupplier)}
                                                                            className="dropdown-item"><i
                                                                        className="fas fa-file-pdf"></i>Generate Report
                                                                    </button>

                                                                </Dropdown.Menu>

                                                            </Dropdown>


                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            {supplier_list.length > 0 ? (
                                                <div className='rg-container'>
                                                    <table className='transactions-table' summary='Hed'>
                                                        <thead>
                                                        <tr>
                                                            <th className=''>#</th>
                                                            <th className=''>Supplier Name</th>
                                                            <th className=''>Email Address</th>
                                                            <th className=''>Phone Number</th>
                                                            <th className=''>Business Name</th>
                                                            <th className=''>Pending Settlements</th>
                                                            <th className=''>Status</th>
                                                            <th className=''></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {supplier_list}
                                                        </tbody>
                                                    </table>
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={this.state.pageCountSuppliers}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClickSuppliers}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>

                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>

                        <Footer/>

                    </div>
                </div>
                <SupplierDetailModal
                    show={this.state.modalShowSupplier}
                    onHide={() => {
                        this.setState({modalShowSupplier: false})
                    }}
                    supplierid={this.state.supplier_id}
                    supplier_data={this.state.supplier_data}
                    bankData={this.state.bankData}
                    supplierTypes={this.state.supplierTypes}
                    onReload={this.GenerateSuppliersList}
                />
            </div>

        );
    }

}


