import React, { PureComponent } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DataTable from "@bit/adeoy.utils.data-table";
import ReactPaginate from 'react-paginate';
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
    PieChart, Pie, Sector, Cell, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,Legend
} from 'recharts';
import {Navigation} from "../Navigation";
import {myConfig} from "../../config";
import {TopBar} from "../TopBar";
import {Dropdown} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Autosuggest from 'react-autosuggest';
import { CSVLink, CSVDownload } from "react-csv";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/autosuggest.css';

import {
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    FacebookShareCount,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    WhatsappIcon, TwitterIcon, PinterestIcon, ViberIcon, TelegramIcon
} from "react-share";
import {WhatsApp} from "@material-ui/icons";
import {deDE} from "@material-ui/core/locale";
import {jsPDF} from "jspdf";
import {GetWebxLaravel,PostWebxLaravel,PostCompnentWebx} from "../PostCompnent";
import {Footer} from "../Footer";
import LoadingSpinner from "../LoadingSpinner";
import {SupportButton} from "../SupportButton";
import {decode} from "html-entities";
import {NavigationSandbox} from "./NavigationSandbox";
import {TopBarSandbox} from "./TopBarSandbox";





export class AllInvoicesSandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userToken: [],
            last_paid_amount_lkr: 0,
            last_paid_amount_usd: 0,
            last_paid_date: '',
            lkr_transaction_total:0,
            lkr_refund_total:0,
            usd_refund_total:0,
            usd_transaction_total:0,
            total_due: 0,
            total_due_usd: 0,
            transaction_lists: [],
            transaction_lists_graph_present: [],
            data_line: [],
            openprogress: false,
            setOpenProgress: false,
            startDate:moment().subtract(30, 'days').format('ll'),
            endDate:moment().format('ll'),
            dayCount:31,
            modalShow:false,
            setModalShow:false,
            modalShowAddInvoice:false,
            setModalShowAddInvoice:false,
            modalShowCustomer:false,
            setModalShowCustomer:false,
            fetchUserData:false,
            customer_details:[],
            customer_details_edit:[],
            invoice_array:[],
            invoice_data:[],
            invoice_data_render:[],
            invoice_data_filters:[],
            single_invoice_details:[],
            customer_first_name:'',
            customer_last_name:'',
            email_address:'',
            phone_number:'',
            website_url:'',
            street_address_1:'',
            street_address_2:'',
            city:'',
            country:'',
            customer_first_name_edit:'',
            customer_last_name_edit:'',
            email_address_edit:'',
            phone_number_edit:'',
            website_url_edit:'',
            street_address_edit_1:'',
            street_address_edit_2:'',
            city_edit:'',
            country_edit:'',
            customer_id:'',
            invoice_number:'',
            product_name:'',
            product_rate:'',
            product_quantity:'',
            single_hashed_id:'',
            total_payable:0,
            tax_payment:0,
            delivery_charge:0,
            modalShowCustomerEdit:false,
            setModalShowCustomerEdit:false,
            date_of_issuance:'',
            date_of_expiry:'',
            customer_notes:'',
            terms_condition_id:'',
            taxable_status:'',
            modalShowInvoiceDetail:false,
            setModalShowInvoiceDetail:false,
            offset: 0,
            perPage: 10,
            currentPage: 0,
            search_invoice_id:'',
            search_invoice_value:'',
            search_customer_name:'',
            payment_status_search:'',
            csvData:[],
            items_data:[],
            template_data:[],
            invoice_template:'',
            mangeProducts:this.addProductNew,
            currency_type:'LKR',
            currency_type_bulk_invoice:'LKR',
            currency_type_id:5,
            modalShowAdditionalCharges:false,
            setModalShowAdditionalCharges:false,
            additional_charge:'',
            additional_charge_name:'',
            additional_charge_array:[],
            additional_charge_id:0,
            additional_charge_ids:[],
            manageAdditionalCharges:this.addAdditionalCharge,
            additional_charge_amount:'',
            tax_type:'',
            selected_name:'',
            product_data:[],
            product_price:'',
            option_index:'',
            countries_list:[],
            terms_list:[],
            errors_cus:{},
            customer_group_list:[],
            //Bulk Invoice
            modalShowBulkInvoiceDetail:false,
            setModalShowBulkInvoiceDetail:false,
            invoice_message:'The invoice mail successfully sent to the customer',
            doing_business_name:'',
            merchant_address:'',
            merchant_contact_number:'',
            merchant_email_address:'',
            merchant_website_url:'',
            logo_file:'',
            lkr_available:false,
            usd_available:false,
            suggestions:[],
            value: '',
            csvDataInvoiceStartDate:moment().subtract(30, 'days').format('ll'),
            csvDataInvoiceEndDate:moment().format('ll'),

        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleCloseProgress = this.handleCloseProgress.bind(this);
        this.handleToggleProgress = this.handleToggleProgress.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.selectChart = this.selectChart.bind(this);
        this.addRemoveActive = this.addRemoveActive.bind(this);
        this.viewModal = this.viewModal.bind(this);
        this.viewModalInvoice = this.viewModalInvoice.bind(this);
        this.viewModalCustomer = this.viewModalCustomer.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.modalCloseInvoice = this.modalCloseInvoice.bind(this);
        this.closeModalCustomer = this.closeModalCustomer.bind(this);
        this.fetchUserData = this.fetchUserData.bind(this);
        this.onChanges = this.onChanges.bind(this);
        this.storeCustomer = this.storeCustomer.bind(this);
        this.storeCustomerEdit = this.storeCustomerEdit.bind(this);
        this.closeModalEditCustomer = this.closeModalEditCustomer.bind(this);
        this.generateInvoice = this.generateInvoice.bind(this);
        this.addProductNew = this.addProductNew.bind(this);
        this.saveInvoiceDetail = this.saveInvoiceDetail.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.handleEventEnd = this.handleEventEnd.bind(this);
        this.fetchInvoiceData = this.fetchInvoiceData.bind(this);
        this.showModalInvoiceDetail = this.showModalInvoiceDetail.bind(this);
        this.closeModalInvoiceDetail = this.closeModalInvoiceDetail.bind(this);
        this.filterList = this.filterList.bind(this);
        this.loadTable = this.loadTable.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.getTemplateSingle = this.getTemplateSingle.bind(this);
        this.editProductNew = this.editProductNew.bind(this);
        this.loadEditInvoice = this.loadEditInvoice.bind(this);
        this.onChangesCurrency = this.onChangesCurrency.bind(this);
        this.showAdditionalChargeModal = this.showAdditionalChargeModal.bind(this);
        this.closeAdditionalChargeModal = this.closeAdditionalChargeModal.bind(this);
        this.getSingleCharge = this.getSingleCharge.bind(this);
        this.addAdditionalCharge = this.addAdditionalCharge.bind(this);
        this.deleteAdditionalCharge = this.deleteAdditionalCharge.bind(this);
        this.onChangesAuto = this.onChangesAuto.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.getCountries = this.getCountries.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
        this.imgToBase64 = this.imgToBase64.bind(this);
        this.currencyFormat = this.currencyFormat.bind(this);
        this.handleValidationCustomer = this.handleValidationCustomer.bind(this);
        this.handleValidationCustomerEdit = this.handleValidationCustomerEdit.bind(this);
        this.handleValidationProduct = this.handleValidationProduct.bind(this);
        this.handleValidationAdditional = this.handleValidationAdditional.bind(this);
        this.handleSaveInvoice=this.handleSaveInvoice.bind(this);
        this.onChangeNumber=this.onChangeNumber.bind(this);
        this.saveInvoiceDetailGenerate=this.saveInvoiceDetailGenerate.bind(this);
        //Bulk Invoice
        this.viewModalBulkInvoice=this.viewModalBulkInvoice.bind(this);
        this.closeModalBulkInvoice=this.closeModalBulkInvoice.bind(this);
        this.getInvoiceCustomerGroups=this.getInvoiceCustomerGroups.bind(this);
        this.saveBulkInvoice=this.saveBulkInvoice.bind(this);
        this.handleSaveBulkInvoice=this.handleSaveBulkInvoice.bind(this);
        this.openModalSendInvoiceConfirm=this.openModalSendInvoiceConfirm.bind(this);
        this.closeModalSendInvoiceConfirm=this.closeModalSendInvoiceConfirm.bind(this);
        this.sendInvoiceIndividual=this.sendInvoiceIndividual.bind(this);
        this.reloadAdditionalCharge=this.reloadAdditionalCharge.bind(this);
        this.getUser=this.getUser.bind(this);
        this.getImage=this.getImage.bind(this);
        this.notify = this.notify.bind(this);

        this.onSuggestionsFetchRequested  = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested  = this.onSuggestionsClearRequested.bind(this);
        this.onChangesss  = this.onChangesss.bind(this);

        this.getSuggestions  = this.getSuggestions.bind(this);
        this.getSuggestionValue  = this.getSuggestionValue.bind(this);
        this.renderSuggestion  = this.renderSuggestion.bind(this);
        this.onSuggestionSelected  = this.onSuggestionSelected.bind(this);
        this.formatOptionLabel  = this.formatOptionLabel.bind(this);
        this.generateCsvDataInvoice  = this.generateCsvDataInvoice.bind(this);

    }
    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : this.state.product_data.filter(products =>
            products.item_name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
    getSuggestionValue = suggestions => suggestions.item_name;

// Use your imagination to render suggestions.
    renderSuggestion = suggestions => (
        <div>
            {suggestions.item_name}
        </div>
    );

    onChangesss = (event, { newValue }) => {
        GetWebxLaravel("products/get_product_price/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+"|"+newValue).then(response=>{

        });
        this.setState({
            value: newValue,
            product_name: newValue
        });
    };

    onSuggestionSelected=(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.setState({
            product_rate:suggestion.product_price
        })
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: this.state.product_data
        });
    };

    notify(){
        toast("Copied to Clipboard");
    }
    //Bulk Invoice
    viewModalBulkInvoice(){
        this.getInvoiceCustomerGroups();
        this.setState({
            modalShowBulkInvoiceDetail:true,
            setModalShowBulkInvoiceDetail:true,
            invoice_array:[],
            additional_charge_array:[],
            additional_charge_ids:[]
        });
    }
    closeModalBulkInvoice(){
        this.setState({
            modalShowBulkInvoiceDetail:false,
            setModalShowBulkInvoiceDetail:false
        });
    }
    handleSaveBulkInvoice(){

        let errors_cus = {};
        let formIsValid = true;
        //Email
        if(this.state.currency_type==""){
            formIsValid = false;
            errors_cus["currency_type"] = "Cannot be empty";
        }
        if(this.state.invoice_array.length==0){
            formIsValid = false;
            errors_cus["invoice_array"] = "Cannot be empty";
        }

        if(this.state.customer_group_id==""){
            formIsValid = false;
            errors_cus["customer_group_id"] = "Cannot be empty";
        }

        if(this.state.invoice_number==""){
            formIsValid = false;
            errors_cus["invoice_number"] = "Cannot be empty";
        }
        if(this.state.date_of_issuance==""){
            formIsValid = false;
            errors_cus["date_of_issuance"] = "Cannot be empty";
        }

        if(this.state.date_of_expiry==""){
            formIsValid = false;
            errors_cus["date_of_expiry"] = "Cannot be empty";
        }
        if(this.state.terms_condition_id==""){
            formIsValid = false;
            errors_cus["terms_condition_id"] = "Cannot be empty";
        }

        this.setState({errors_cus: errors_cus});
        return formIsValid;
    }
    saveBulkInvoice(){

        if (this.handleSaveBulkInvoice()){
            this.handleToggleProgress();
            let customer_group_id=this.state.customer_group_id;
            let invoice_number=this.state.invoice_number;
            let invoice_array=this.state.invoice_array;
            let date_of_issuance=moment(this.state.date_of_issuance,'DD-MM-YYYY').format('YYYY-MM-DD');
            let date_of_expiry=moment(this.state.date_of_expiry,'DD-MM-YYYY').format('YYYY-MM-DD');
            let customer_notes=this.state.customer_notes;
            let terms_condition_id=this.state.terms_condition_id;
            let taxable_status=this.state.taxable_status;
            let additional_charge_array=this.state.additional_charge_array;
            let total_amount=0;
            let sum = invoice_array.reduce(function(prev, current) {
                return prev + +current.sub_total
            }, 0);
            let sum_additional=additional_charge_array.reduce(function(prev, current) {
                return prev + +current.additional_charge
            }, 0)

            total_amount=parseFloat(sum);
            let addAdditional=[];
            additional_charge_array.forEach((item) => {
                addAdditional.push({
                    additional_charge_name:item.additional_charge_name,
                    additional_charge_amount:item.additional_charge,
                    tax_type:item.tax_type,
                });
            })


            let itemsArr = [];
            invoice_array.forEach((item) => {
                itemsArr.push({
                    description: item.product_name,
                    product_quantity: item.product_quantity,
                    price: item.product_rate,
                });
            })

            let formData = new FormData();    //formdata object

            formData.append('merchant_id', sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id"));   //append the values with key, value pair
            formData.append('group_id', customer_group_id);   //append the values with key, value pair
            formData.append('invoice_id_common', invoice_number);   //append the values with key, value pair
            formData.append('date_of_issuance', date_of_issuance);   //append the values with key, value pair
            formData.append('date_of_expiry', date_of_expiry);   //append the values with key, value pair
            formData.append('payment_link', "link");   //append the values with key, value pair
            formData.append('sub_total', sum);   //append the values with key, value pair
            formData.append('total', parseFloat(sum)+parseFloat(sum_additional));   //append the values with key, value pair
            formData.append('tax', this.state.tax_payment);   //append the values with key, value pair
            formData.append('customer_notes', customer_notes);   //append the values with key, value pair
            formData.append('taxable', 1);   //append the values with key, value pair
            formData.append('delivery_charges', this.state.delivery_charge);   //append the values with key, value pair
            formData.append('terms_and_condition', terms_condition_id);   //append the values with key, value pair
            formData.append('total_payable', total_amount);   //append the values with key, value pair
            formData.append('invoice_items', JSON.stringify(itemsArr));   //append the values with key, value pair
            formData.append('additional_charge_array', JSON.stringify(addAdditional));   //append the values with key, value pair
            formData.append('is_active', 1);   //append the values with key, value pair
            formData.append('currency_id', this.state.currency_type_id);   //append the values with key, value pair
            this.handleToggleProgress();
            PostWebxLaravel( "bulk_invoice/create_invoice",formData).then(response=>{
                this.handleCloseProgress();
                if (response.status==200){
                    this.setState({
                        modalShow: false,
                        setModalShow: false,
                        customer_id:'',
                        invoice_number:'',
                        store_id:'',
                        date_of_issuance:'',
                        date_of_expiry:'',
                        payment_link:'',
                        sub_total:'',
                        terms_condition_id:'',
                        tax:'',
                        customer_notes:'',
                        taxable:'',
                        delivery_charges:'',
                        terms_and_condition_id:'',
                        total_payable:'',
                        invoice_items:[],
                        additional_charge_array:[],
                        additional_charge_ids:[],
                        currency_type:'LKR',
                        currency_type_bulk_invoice:'LKR',
                        currency_type_id:5,
                        invoice_array:[],
                        is_active:'',
                        single_order_status:'',
                        single_order_transaction_id:'',
                        currency_id:'',
                        loading: false, // will be true when ajax request is running
                    });
                    this.fetchInvoiceData();
                    this.closeModalBulkInvoice();
                }

            })

        }
    }
    getInvoiceCustomerGroups(){
        let BaseUrl = myConfig.laravelUrl;
        let lar_config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': sessionStorage.getItem("laravel_token"),

            },
        };
        this.handleToggleProgress();
        GetWebxLaravel("customer_group/get_groups/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (typeof response !== 'undefined') {
                this.handleCloseProgress();
                this.setState({
                    customer_group_list:response.data,
                });
            }
        });
    }
    getImage(){
        GetWebxLaravel("merchants/get_image/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            this.handleCloseProgress();
            this.setState({
                logo_file:response.data!==null?response.data:""
            });
        });
    }
    getUser(){
        let formData=new FormData();
        GetWebxLaravel("merchants/get_merchant_data/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            let data_new=response.data;

            this.handleCloseProgress();

            this.setState({
                doing_business_name:data_new.doing_business_name,
                merchant_address:data_new.address_line_one+', '+data_new.address_line_two,
                merchant_contact_number:data_new.contact_number,
                merchant_email_address:data_new.email_address,
            });
        });

        PostCompnentWebx("getStoreBusinessInfo",formData).then(response=>{
            this.setState({
                merchant_website_url:response.data[0].website_url,
            })
        });
    }

    handleSaveInvoice(){

        let errors_cus = {};
        let formIsValid = true;
        //Email
        if(this.state.currency_type==""){
            formIsValid = false;
            errors_cus["currency_type"] = "Cannot be empty";
        }
        if(this.state.invoice_array.length==0){
            formIsValid = false;
            errors_cus["invoice_array"] = "Cannot be empty";
        }

        if(this.state.customer_id==""){
            formIsValid = false;
            errors_cus["customer_id"] = "Cannot be empty";
        }

        if(this.state.invoice_number==""){
            formIsValid = false;
            errors_cus["invoice_number"] = "Cannot be empty";
        }
        if(this.state.date_of_issuance==""){
            formIsValid = false;
            errors_cus["date_of_issuance"] = "Cannot be empty";
        }

        if(this.state.date_of_expiry==""){
            formIsValid = false;
            errors_cus["date_of_expiry"] = "Cannot be empty";
        }
        if(this.state.terms_condition_id==""){
            formIsValid = false;
            errors_cus["terms_condition_id"] = "Cannot be empty";
        }

        var parse_end_d = Date.parse(this.state.date_of_expiry);
        var parse_start_d = Date.parse(this.state.date_of_issuance);

        /*        if(parse_end_d-parse_start_d<1){
                    formIsValid = false;
                    errors_cus["date_of_expiry"] = "Expiry date backdated";
                }
                if(this.state.terms_condition_id==""){
                    formIsValid = false;
                    errors_cus["terms_condition_id"] = "Cannot be empty";
                }*/
        /*     if(this.state.taxable_status==""){
                 formIsValid = false;
                 errors_cus["taxable_status"] = "Cannot be empty";
             }*/

        this.setState({errors_cus: errors_cus});
        return formIsValid;
    }
    sendInvoiceIndividual(invoice_id){

        this.setState({
            invoice_message:'Mail sent successfully '
        });
        this.openModalSendInvoiceConfirm();
    }
    /* Send invoice confirmation*/
    openModalSendInvoiceConfirm() {
        this.setState({
            modalShowCloseConfirmation: true,
            setModalShowCloseConfimation: true,
        });

    }

    closeModalSendInvoiceConfirm() {
        this.setState({
            modalShowCloseConfirmation: false,
            setModalShowCloseConfimation: false,
            modalShowInvoiceDetail:false,
            setModalShowInvoiceDetail:false
        },()=>{
            this.fetchInvoiceData();
        });
    }
    handleValidationAdditional(){
        let errors_cus = {};
        let formIsValid = true;
        //Email
        if(this.state.additional_charge_name==""){
            formIsValid = false;
            errors_cus["additional_charge_name"] = "Cannot be empty";
        }
        if(this.state.additional_charge==""){
            formIsValid = false;
            errors_cus["additional_charge"] = "Cannot be empty";
        }

        this.setState({errors_cus: errors_cus});
        return formIsValid;
    }
    handleValidationProduct(){
        let errors_cus = {};
        let formIsValid = true;
        //Email
        if(this.state.product_name==""){
            formIsValid = false;
            errors_cus["product_name"] = "Cannot be empty";
        }
        if(this.state.product_rate==""){
            formIsValid = false;
            errors_cus["product_rate"] = "Cannot be empty";
        }
        if(this.state.product_quantity==""){
            formIsValid = false;
            errors_cus["product_quantity"] = "Cannot be empty";
        }


        this.setState({errors_cus: errors_cus});
        return formIsValid;
    }
    handleValidationCustomerEdit(){
        let errors_cus = {};
        let formIsValid = true;
        //Email
        if(this.state.customer_first_name_edit==""){
            formIsValid = false;
            errors_cus["customer_first_name_edit"] = "Cannot be empty";
        }
        if(this.state.customer_last_name_edit==""){
            formIsValid = false;
            errors_cus["customer_last_name_edit"] = "Cannot be empty";
        }
        if(this.state.email_address_edit==""){
            formIsValid = false;
            errors_cus["email_address_edit"] = "Cannot be empty";
        }
        if(this.state.phone_number_edit==""){
            formIsValid = false;
            errors_cus["phone_number_edit"] = "Cannot be empty";
        }
        if(this.state.street_address_1_edit==""){
            formIsValid = false;
            errors_cus["street_address_1_edit"] = "Cannot be empty";
        }
        if(this.state.city_edit==""){
            formIsValid = false;
            errors_cus["city_edit"] = "Cannot be empty";
        }
        if(this.state.country_edit==""){
            formIsValid = false;
            errors_cus["country_edit"] = "Cannot be empty";
        }

        this.setState({errors_cus: errors_cus});
        return formIsValid;
    }
    handleValidationCustomer(){
        let errors_cus = {};
        let formIsValid = true;
        //Email


        if(this.state.customer_first_name==""){
            formIsValid = false;
            errors_cus["customer_first_name"] = "Cannot be empty";
        }
        if(this.state.customer_last_name==""){
            formIsValid = false;
            errors_cus["customer_last_name"] = "Cannot be empty";
        }
        if(this.state.email_address==""){
            formIsValid = false;
            errors_cus["email_address"] = "Cannot be empty";
        }
        if(this.state.phone_number==""){
            formIsValid = false;
            errors_cus["phone_number"] = "Cannot be empty";
        }
        if(this.state.street_address_1==""){
            formIsValid = false;
            errors_cus["street_address_1"] = "Cannot be empty";
        }
        if(this.state.city==""){
            formIsValid = false;
            errors_cus["city"] = "Cannot be empty";
        }
        if(this.state.country==""){
            formIsValid = false;
            errors_cus["country"] = "Cannot be empty";
        }

        this.setState({errors_cus: errors_cus});
        return formIsValid;
    }
    imgToBase64(url, callback) {
        if (!window.FileReader) {
            callback(null);
            return;
        }
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result.replace('text/xml', 'image/jpeg'));
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.send();
    }
    currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    exportPDF = () => {
        let invoices=this.state.invoice_data_filters;

        const image=require('../../images/webxlogo_png.png');
        this.imgToBase64(image, function(base64) {
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
            const logo_file=""
            doc.setFontSize(15);

            doc.addImage(base64, 'JPEG',450, 10, 100, 50);
            const title = "INVOICE REPORT";
            const headers = [["INVOICE ID", "CREATED DATE","AMOUNT","CUSTOMER","PAYMENT LINK","STATUS"]];
            const priceSplitter = (number) => (number && number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','));
            const data = invoices.map(value=> [value.invoice_id,moment(value.date_of_issuance).format('ll'),priceSplitter(parseFloat(value.sub_total)+ value.get_additional.reduce((total, currentValue) => total = total + parseFloat(currentValue.amount),0)),value.get_customer?value.get_customer.customer_name:'',myConfig.laravelWeb+'invoice_detail/'+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+value.hashed_id,value.is_active==1?'Payment Pending':'Paid']);

            let content = {
                startY: 50,
                head: headers,
                body: data,
                headStyles: { fillColor:'#77b53c' },
            };

            doc.text(title, marginLeft, 40);
            doc.autoTable(content);
            doc.save("Invoices.pdf")

        });

    }

    getCountries(){
        this.handleToggleProgress();
        GetWebxLaravel("common_list/get_countries").then(response=>{
            if(response) {
                this.handleCloseProgress();
                this.setState({
                    countries_list: response.data.countries,
                });
            }
        });
        this.handleToggleProgress();
        GetWebxLaravel("terms/get_terms/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response) {
                this.handleCloseProgress();
                this.setState({
                    terms_list: response.data,
                });
            }
        });
    }
    deleteAdditionalCharge(arr_id){
        this.setState({additional_charge_ids: this.state.additional_charge_ids.filter(function(additional_charge) {
                return additional_charge.additional_charge_id !== arr_id
            })});
        this.setState({additional_charge_array: this.state.additional_charge_array.filter(function(additional_charge_arr) {
                return additional_charge_arr.additional_charge_id !== arr_id
            })});

    }
    addAdditionalCharge(){
        if (this.handleValidationAdditional()){
            let additional_charge=this.state.additional_charge;
            let additional_charge_name=this.state.additional_charge_name;
            let additional_charge_amount=this.state.additional_charge_amount;
            let tax_type=this.state.tax_type;
            let additional_charge_id=this.state.additional_charge_id;


            this.setState(prevState => ({
                additional_charge_ids:[...prevState.additional_charge_ids, {
                    additional_charge_id:additional_charge_id,
                }],
                additional_charge_array: [...prevState.additional_charge_array, {
                    additional_charge_id:additional_charge_id,
                    additional_charge:additional_charge,
                    additional_charge_name:additional_charge_name,
                    additional_charge_amount:additional_charge_amount,
                    tax_type:tax_type,
                }],
                modalShowAdditionalCharges:false,
                setModalShowAdditionalCharges:false,
            }));
        }
    }
    getSingleCharge(e){

        var index = e.nativeEvent.target.selectedIndex;

        this.setState({
            [e.target.name]: e.nativeEvent.target[index].text
        });
        //this.handleToggleProgress();
        this.setState({
            additional_charge_id:1,
            additional_charge: 100,
            additional_charge_amount: 100,
            tax_type: "Tax 1",
        });

    }
    showAdditionalChargeModal(){
       // this.handleToggleProgress();
       /* GetWebxLaravel("additional_charges/get_charges/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    additional_charges:response.data,
                });
            }
        });*/

        this.setState({
            additional_charge_name:'',
            additional_charge:'',
            modalShowAdditionalCharges:true,
            setModalShowAdditionalCharges:true,
            manageAdditionalCharges:this.addAdditionalCharge
        }  , () => {
            var modelD = document.getElementById("ani-modal-additional");
            modelD.classList.add("ancd-mod");
        })
    }

    closeAdditionalChargeModal(){
        this.setState({
            modalShowAdditionalCharges:false,
            setModalShowAdditionalCharges:false,
        });
    }

    handleCallback(start, end, label) {
        this.setState({
            startDate:moment(start).format('ll'),
            endDate:moment(end).format('ll'),
        });
        this.filterList();
    }
    filterList() {
        let search_invoice_id = this.state.search_invoice_id.toLowerCase();
        let search_invoice_value = this.state.search_invoice_value;
        let search_start_date = this.state.startDate;
        let search_end_date = this.state.endDate;

        let payment_status_search = this.state.payment_status_search;
        let search_customer_name = this.state.search_customer_name.toLowerCase();

        /*  let invoice_lists = this.state.invoice_data, invoice_data_filters = [];*/

        let start_dates = moment(search_start_date).format('YYYY-MM-DD');
        let end_dates = moment(search_end_date).format('YYYY-MM-DD');
        var a = moment(start_dates);
        var b = moment(end_dates);

        this.setState({
            csvDataInvoiceStartDate:start_dates,
            csvDataInvoiceEndDate:end_dates
        })

        let days_count = b.diff(a, 'days')   // =1
        days_count=parseInt(days_count)+parseInt(1);

        let formData = new FormData();    //formdata object
        formData.append('search_invoice_id', search_invoice_id);   //append the values with key, value pair
        formData.append('search_invoice_value',search_invoice_value );   //append the values with key, value pair
        formData.append('start_dates',start_dates );   //append the values with key, value pair
        formData.append('end_dates', end_dates);   //append the values with key, value pair
        formData.append('payment_status_search',payment_status_search );   //append the values with key, value pair
        formData.append('search_customer_name',search_customer_name );   //append the values with key, value pair
        formData.append('merchant_id',sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")  );   //append the values with key, value pair

        this.handleToggleProgress();

        PostWebxLaravel("invoice_detail/get_invoice_with_filters", formData).then(response => {
            this.handleCloseProgress();
            this.generateCsvDataInvoice(response);
            this.setState({
                dayCount: days_count,
                invoice_data:response.data,
                invoice_data_filters: response.data,
            }, () => {
                this.loadTable();
            });
        });
    }
    clearSearch(){
        this.handleToggleProgress();
        GetWebxLaravel("invoice_detail/get_invoice/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response => {
            this.handleCloseProgress();
            this.setState({
                payment_status_search: '',
                product_price: '',
                item_name: '',
                description: '',
                search_invoice_id: '',
                search_invoice_value: '',
                search_customer_name: '',
                invoice_data:response.data,
                invoice_data_filters: response.data,
            }, () => {
                this.filterList();
            });
        });
    }


    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;
        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.loadTable()
        });

    };
    showModalInvoiceDetail(id) {
        this.setState({
            modalShowInvoiceDetail: true,
            setModalShowInvoiceDetail: true,
        });
    }


    closeModalInvoiceDetail(){
        this.setState({
            modalShowInvoiceDetail: false,
            setModalShowInvoiceDetail: false,
        });
    }
    saveInvoiceDetailGenerate(){
        if (this.handleSaveInvoice()){
            this.handleToggleProgress();
            let customer_id=this.state.customer_id;
            let invoice_number=this.state.invoice_number;
            let invoice_array=this.state.invoice_array;
            let date_of_issuance=moment(this.state.date_of_issuance,'DD-MM-YYYY').format('YYYY-MM-DD');
            let date_of_expiry=moment(this.state.date_of_expiry,'DD-MM-YYYY').format('YYYY-MM-DD');
            let customer_notes=this.state.customer_notes;
            let terms_condition_id=this.state.terms_condition_id;
            let taxable_status=this.state.taxable_status;
            let additional_charge_array=this.state.additional_charge_array;
            let total_amount=0;
            let sum = invoice_array.reduce(function(prev, current) {
                return prev + +current.sub_total
            }, 0);
            let sum_additional=additional_charge_array.reduce(function(prev, current) {
                return prev + +current.additional_charge
            }, 0)

            total_amount=parseFloat(sum);
            let addAdditional=[];
            additional_charge_array.forEach((item) => {
                addAdditional.push({
                    additional_charge_name:item.additional_charge_name,
                    additional_charge_amount:item.additional_charge,
                    tax_type:item.tax_type,
                });
            })


            let itemsArr = [];
            invoice_array.forEach((item) => {
                itemsArr.push({
                    description: item.product_name,
                    product_quantity: item.product_quantity,
                    price: item.product_rate,
                });
            })



                    this.setState({
                        modalShow: false,
                        setModalShow: false,
                        customer_id:'',
                        invoice_number:'',
                        merchant_id:'',
                        date_of_issuance:'',
                        date_of_expiry:'',
                        payment_link:'',
                        sub_total:'',
                        tax:'',
                        customer_notes:'',
                        taxable:'',
                        delivery_charges:'',
                        terms_and_condition_id:'',
                        total_payable:'',
                        invoice_items:[],
                        additional_charge_array:[],
                        additional_charge_ids:[],
                        invoice_array:[],
                        is_active:'',
                        currency_id:'',
                    });
                    this.fetchInvoiceData();

                        this.showModalInvoiceDetail(1);



        }
    }
    saveInvoiceDetail(){

        if (this.handleSaveInvoice()){

                    this.setState({
                        modalShow: false,
                        setModalShow: false,
                        customer_id:'',
                        invoice_number:'',
                        merchant_id:'',
                        date_of_issuance:'',
                        date_of_expiry:'',
                        payment_link:'',
                        sub_total:'',
                        tax:'',
                        customer_notes:'',
                        taxable:'',
                        delivery_charges:'',
                        terms_and_condition_id:'',
                        total_payable:'',
                        invoice_items:[],
                        additional_charge_array:[],
                        additional_charge_ids:[],
                        invoice_array:[],
                        is_active:'',
                        currency_id:'',
                    });

                    this.closeModalInvoiceDetail();
                }

    }
    handleEvent(event, picker) {

        if (this.state.date_of_expiry) {
            let end_date=moment(this.state.date_of_expiry,'DD-MM-YYYY');
            let start_date=moment(picker.startDate,'DD-MM-YYYY');
            let parsed_end_date=Date.parse(end_date);
            let parsed_start_date=Date.parse(start_date);

            if (parsed_end_date > parsed_start_date) {
                this.setState({
                    date_of_issuance: picker.startDate.format("DD-MM-YYYY"),
                });
            } else {
                this.setState({
                    date_of_issuance: '',
                });
            }
        }else{
            this.setState({
                date_of_issuance: picker.startDate.format("DD-MM-YYYY"),
            });
        }
    }
    handleEventEnd(event, picker) {

        if (this.state.date_of_issuance) {
            let end_date=moment(picker.endDate,'DD-MM-YYYY');
            let start_date=moment(this.state.date_of_issuance,'DD-MM-YYYY');
            let parsed_end_date=Date.parse(end_date);
            let parsed_start_date=Date.parse(start_date);

            if (parsed_end_date > parsed_start_date) {
                this.setState({
                    date_of_expiry: picker.endDate.format("DD-MM-YYYY")
                });
            } else {
                this.setState({
                    date_of_expiry:''
                });
            }
        }else{
            this.setState({
                date_of_expiry: picker.endDate.format("DD-MM-YYYY")
            });
        }
    }
    editProductNew(index){

        if (this.handleValidationProduct()) {
            let product_name = this.state.product_name;
            let product_rate = this.state.product_rate;
            let product_quantity = this.state.product_quantity;

            const template_data_id = this.state.invoice_array.slice(); //copy the array


            template_data_id[index]['product_name'] = product_name; //execute the manipulations
            template_data_id[index]['product_quantity'] = product_quantity; //execute the manipulations
            template_data_id[index]['product_rate'] = product_rate; //execute the manipulations
            template_data_id[index]['sub_total'] = parseFloat(product_quantity) * parseFloat(product_rate);//execute the manipulations

            this.setState({
                invoice_array: template_data_id,
                modalShowAddInvoice: false,
                setModalShowAddInvoice: false
            }, () => {
                let additional_charge_array = this.state.additional_charge_array;
                if (additional_charge_array && additional_charge_array.length) {
                    additional_charge_array.map((list, key) => {
                        let additional_chg = 0;
                        if (list.tax_type == "F") {
                            additional_chg = list.additional_charge_amount
                        } else {
                            let total_payable = this.state.invoice_array.reduce(function (prev, current) {
                                return prev + +current.sub_total
                            }, 0);
                            additional_chg = total_payable * list.additional_charge_amount / 100
                        }
                        const additional_charge_array_new = this.state.additional_charge_array.slice() //copy the array
                        additional_charge_array_new[key]['additional_charge'] = additional_chg; //execute the manipulations
                        this.setState({additional_charge_array: additional_charge_array_new}) //set the new state

                    });
                }
            }); //set the new state

            var modelD = document.getElementById("activation-modal");
            modelD.style.visibility = 'visible';
        }
    }

    addProductNew(){
        if (this.handleValidationProduct()) {
            let product_name = this.state.product_name;
            let product_rate = this.state.product_rate;
            let product_quantity = this.state.product_quantity;
            let product_details = [];
            product_details.push({
                product_name: product_name,
                product_rate: product_rate,
                product_quantity: product_quantity,
            });
            this.setState(prevState => ({
                invoice_array: [...prevState.invoice_array, {
                    template_id: null,
                    product_name: product_name,
                    product_rate: product_rate,
                    product_quantity: product_quantity,
                    sub_total: parseFloat(product_quantity) * parseFloat(product_rate),
                }],
                modalShowAddInvoice: false,
                setModalShowAddInvoice: false
            }), () => {
                let additional_charge_array = this.state.additional_charge_array;
                if (additional_charge_array && additional_charge_array.length) {
                    additional_charge_array.map((list, key) => {
                        let additional_chg = 0;
                        if (list.tax_type == "F") {
                            additional_chg = list.additional_charge_amount
                        } else {
                            let total_payable = this.state.invoice_array.reduce(function (prev, current) {
                                return prev + +current.sub_total
                            }, 0);
                            additional_chg = total_payable * list.additional_charge_amount / 100
                        }
                        const additional_charge_array_new = this.state.additional_charge_array.slice() //copy the array
                        additional_charge_array_new[key]['additional_charge'] = additional_chg; //execute the manipulations
                        this.setState({additional_charge_array: additional_charge_array_new}) //set the new state

                    });
                }

                var modelD = document.getElementById("activation-modal");
                modelD.style.visibility = 'visible';
            });
        }
    }
    generateInvoice(){
        this.setState({
            invoice_number:"INV-3114-16257-25652"
        });
    }
    storeCustomerEdit(){
        if(this.handleValidationCustomerEdit()) {
            this.handleToggleProgress();
            let BaseUrl = myConfig.laravelUrl;
            let lar_config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': sessionStorage.getItem("laravel_token"),

                },
            };
            let formData = new FormData();    //formdata object
            let customer_first_name_edit = this.state.customer_first_name_edit;
            let customer_last_name_edit = this.state.customer_last_name_edit;
            let email_address = this.state.email_address_edit;
            let phone_number = this.state.phone_number_edit;
            let website_url = this.state.website_url_edit;
            let street_address_1 = this.state.street_address_edit_1;
            let street_address_2 = this.state.street_address_edit_2;
            let city = this.state.city_edit;
            let country = this.state.country_edit;
            formData.append('customer_first_name', customer_first_name_edit);   //append the values with key, value pair
            formData.append('customer_last_name', customer_last_name_edit);   //append the values with key, value pair
            /*     formData.append('customer_email', email_address);*/
            formData.append('customer_phone_number', phone_number);
            formData.append('customer_website_url', website_url);
            formData.append('customer_address_line_1', street_address_1);
            formData.append('customer_address_line_2', street_address_2);
            formData.append('customer_city', city);
            formData.append('customer_country_id', country);
            formData.append('_method', 'put');
            this.handleToggleProgress();
            PostWebxLaravel("customers/update_customer/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+ this.state.customer_id,formData).then(response=>{
                this.handleCloseProgress();
                this.fetchUserData();
                this.setState({
                    modalShowCustomerEdit: false,
                    setModalShowCustomerEdit: false,
                });
                var modelD = document.getElementById("activation-modal");
                modelD.style.visibility = 'visible';
            });

        }
    }

    storeCustomer(){
        //this.setState({errors_cus:[] });
        if(this.handleValidationCustomer()) {
            //this.handleToggleProgress();
            let formData = new FormData();    //formdata object
            let customer_first_name=this.state.customer_first_name;
            let customer_last_name=this.state.customer_last_name;
            let email_address=this.state.email_address;
            let phone_number=this.state.phone_number;
            let website_url=this.state.website_url;
            let street_address_1=this.state.street_address_1;
            let street_address_2=this.state.street_address_2;
            let city=this.state.city;
            let country=this.state.country;
            this.setState({
                modalShowCustomer: false,
                setModalShowCustomer: false,
                customer_name: '',
                email_address: '',
                phone_number: '',
                website_url: '',
                street_address_1: '',
                street_address_2: '',
                customer_id: 1,
                /*   modalShow: true,
                   setModalShow: true*/
            });
            var modelD = document.getElementById("activation-modal");
            modelD.style.visibility = 'visible';


        }

    }
    getTemplateSingle(e){
        //this.handleToggleProgress();
        this.setState(prevState => ({
            invoice_array: [...prevState.invoice_array, {
                template_id: 1,
                product_name: "Test Product",
                product_rate: 100,
                product_quantity: 5,
                sub_total: 500,
            }],
        }));
    }
    reloadAdditionalCharge(){

        let additional_charge_array = this.state.additional_charge_array;
        if (additional_charge_array && additional_charge_array.length) {
            additional_charge_array.map((list, key) => {
                let additional_chg = 0;
                if (list.tax_type == "F") {
                    additional_chg = list.additional_charge_amount
                } else {
                    let total_payable = this.state.invoice_array.reduce(function (prev, current) {
                        return prev + +current.sub_total
                    }, 0);
                    additional_chg = total_payable * list.additional_charge_amount / 100
                }
                const additional_charge_array_new = this.state.additional_charge_array.slice() //copy the array
                additional_charge_array_new[key]['additional_charge'] = additional_chg; //execute the manipulations
                this.setState({additional_charge_array: additional_charge_array_new}) //set the new state

            });
        }

        var modelD = document.getElementById("activation-modal");
        modelD.style.visibility = 'visible';

    }
    onChangeNumber(e){

        if (e.target.name == "product_rate") {
            const re = /^[0-9.\b]+$/;
            if (e.target.value == '' || re.test(e.target.value)) {
                this.setState({  [e.target.name]: e.target.value})
            }
            if (parseFloat(this.state.total_amount) < parseFloat(e.target.value)) {
                this.setState({[e.target.name]: e.target.value});
            }
        }else{
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value)) {

                this.setState({[e.target.name]: e.target.value})
            }

        }


        // if value is not blank, then test the regex


    }
    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onChangesAuto(e,value){
        this.setState({product_name:value});
    }
    onChangesCurrency(e) {

        var index = e.nativeEvent.target.selectedIndex;

        this.setState({
            [e.target.name]: e.nativeEvent.target[index].text,
            currency_type_id:e.target.value
        })
    }
    fetchUserData(userToken){
        this.handleToggleProgress();
        GetWebxLaravel("customers/get_customers/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    customer_details:response.data
                });
            }

        });
        let formData = new FormData();    //formdata object

        PostCompnentWebx("getStoreCurrency",formData).then(response=>{
            let LKR=false;
            let USD=false;
            if (response.data.indexOf("LKR") > -1){
                LKR=true;
            }
            if (response.data.indexOf("USD") > -1){
                USD=true;
            }
            this.setState({
                lkr_available:LKR,
                usd_available:USD
            });
            if(LKR==false){
                this.setState({
                    currency_type:'USD',
                    currency_type_bulk_invoice:'USD',
                    currency_type_id:2,
                });
            }
        });


    }
    generateCsvDataInvoice(response){

        const csvData = [[
            "","","Invoice Report","From "+this.state.csvDataInvoiceStartDate +" To "+this.state.csvDataInvoiceEndDate
        ]];
        csvData.push([]);
        csvData.push(["Invoice Number","Transaction ID","Customer Full Name","E-Mail Address","Telephone Number", "Created Date", "Currency Type","Invoice Amount","Payment Status"]
        );
        csvData.push([]);
        response.data.map((value, index) => {
            csvData.push([value.invoice_id,value.order_reference?value.order_reference:'', value.get_customer?value.get_customer.customer_first_name+' '+value.get_customer.customer_last_name:'', value.get_customer?value.get_customer.customer_email:'', value.get_customer?value.get_customer.customer_phone_number:'', moment(value.created_at).format('ll'),value.currency_id==5?'LKR':'USD',value.total, value.get_order.length?value.get_order[value.get_order.length-1].order_status_id!==2?'Payment Pending':'Paid':'Payment Pending']);
        });
        this.setState({
            csvData:csvData,
        });
    }
    fetchInvoiceData(){
        this.handleToggleProgress();
        GetWebxLaravel("invoice_detail/get_invoice/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if(response) {

                this.handleCloseProgress();

                if (response) {

                    this.generateCsvDataInvoice(response);
                    this.setState({
                        invoice_data_filters: response.data,
                        invoice_data: response.data,
                    }, () => {
                        this.loadTable();
                    });
                }
            }
        });
        this.handleToggleProgress();
        GetWebxLaravel("products/get_products/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    items_data:response.data
                    //   single_invoice_details:response.data,
                });
            }

        });
        this.handleToggleProgress();
        GetWebxLaravel("template_detail/get_template/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response) {
                this.handleCloseProgress();
                this.setState({
                    template_data: response.data,
                }, () => {
                    // this.loadTable();
                });
            }
        });
    }
    loadTable(){

        const list_data=this.state.invoice_data_filters;
        const slice = list_data.slice(this.state.offset, this.state.offset + this.state.perPage);

        const trans=  list_data && list_data.length?(slice.map((list, key) =>

            <tr className='' >
                <td className='' data-title='#'>{key+1}</td>
                <td className='' data-title='Invoice ID'>{list.invoice_id}</td>
                <td className='' data-title='Created Date'>{moment(list.created_at).format('ll')}</td>
                <td className='' data-title='Deductions'><NumberFormat
                    value={list.total}
                    displayType={'text'} thousandSeparator={true} prefix={list.currency_id==5?'LKR ':'USD '}/></td>
                <td className='' data-title='Customer'>{list.get_customer?list.get_customer.customer_first_name+' '+list.get_customer.customer_last_name:''}</td>
                {/*  <td className='' data-title='Payment Date'>Payment Pending</td>*/}
                {/* <td className=''
                    data-title='Payment Link'>{myConfig.laravelWeb+'invoice_detail/'+list.hashed_id}
                </td>*/}
                <td className='' data-title='Payment Date'>
                    {list.order_reference?list.order_reference:''}
                </td>
                <td className='' data-title='Payment Date'>
                    {list.order_status!==2?(<span className="dot yellow"><i className="fas fa-circle" aria-hidden="true"></i></span>):(<span className="dot green"><i className="fas fa-circle" aria-hidden="true"></i></span>)}
                    {list.order_status!==2?' Payment Pending':'Paid'}
                </td>
                {/*<td className='pop-td'><span className="dot yellow"><i
                    className="fas fa-circle"
                    aria-hidden="true"></i></span> Settlement Pending
                </td>*/}
                <td className='' data-title=''>
                    <div className="dropdown">
                        <button className="btn dropdown-toggle dot-drop"
                                type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" onClick={()=>this.showModalInvoiceDetail(list.id)}>
                            <i className="fas fa-eye"></i>
                        </button>
                        <div className="dropdown-menu table-drop"
                             aria-labelledby="dropdownMenuButton">
                            <button className="dropdown-item"><i
                                className="fas fa-bell"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-copy"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-share"></i></button>
                            <button className="dropdown-item"><i
                                className="fas fa-download"></i></button>
                        </div>
                    </div>
                </td>
            </tr>) ):(
            <tr className=''>
                <td colSpan="8" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
            </tr>

        )
        this.setState({
            pageCount: Math.ceil(list_data.length / this.state.perPage),
            invoice_data_render:trans,
        });
    }
    modalClose(){
        this.setState({
            modalShow:false,
            setModalShow:false,
            customer_id:'',
            invoice_number:'',
            merchant_id:'',
            date_of_issuance:'',
            date_of_expiry:'',
            payment_link:'',
            sub_total:'',
            tax:'',
            customer_notes:'',
            taxable:'',
            delivery_charges:'',
            terms_and_condition_id:'',
            total_payable:'',
            invoice_items:[],
            additional_charge_array:[],
            additional_charge_ids:[],
            invoice_array:[],
            is_active:'',
            currency_id:'',
        })
    }
    viewModalCustomer(){
        this.setState({
            modalShowCustomer: true,
            setModalShowCustomer: true,
        }, () => {
            var modelD = document.getElementById("anc-modal");
            modelD.classList.add("ancd-mod");
            var modelAct = document.getElementById("activation-modal");
            modelAct.style.visibility = 'hidden';
        });
    }
    closeModalCustomer(){
        this.setState({
            modalShowCustomer: false,
            setModalShowCustomer: false,
            /*  modalShow:true,
              setModalShow:true*/
        });
        var modelD = document.getElementById("activation-modal");
        modelD.style.visibility = 'visible';
    }
    editCustomer(){
        if (this.state.customer_id!=="") {

            this.handleToggleProgress();

            let customer_id = this.state.customer_id;
            this.handleToggleProgress();
            GetWebxLaravel("customers/get_single_customer/" +customer_id).then(response=>{
                if(response) {
                    this.handleCloseProgress();
                    let customer_details_edit = response.data;
                    this.setState({
                        customer_first_name_edit: customer_details_edit.customer_first_name,
                        customer_last_name_edit: customer_details_edit.customer_last_name,
                        email_address_edit: customer_details_edit.customer_email,
                        phone_number_edit: customer_details_edit.customer_phone_number,
                        website_url_edit: customer_details_edit.customer_website_url,
                        street_address_edit_1: customer_details_edit.customer_address_line_1,
                        street_address_edit_2: customer_details_edit.customer_address_line_2,
                        city_edit: customer_details_edit.customer_city,
                        country_edit: customer_details_edit.customer_country_id
                    });
                }
            });

            this.setState({
                modalShowCustomerEdit: true,
                setModalShowCustomerEdit: true,
            }, () => {
                var modelD = document.getElementById("anc-modal-edit");
                modelD.classList.add("ancd-mod");
            });

            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'hidden';
        }
    }

    closeModalEditCustomer(){
        this.setState({
            modalShowCustomerEdit: false,
            setModalShowCustomerEdit: false,
        });
        var modelD = document.getElementById("activation-modal");
        modelD.style.visibility = 'visible';
    }
    modalCloseInvoice(){
        this.setState({
            modalShowAddInvoice:false,
            setModalShowAddInvoice:false
        })
        if ( document.getElementById("activation-modal")) {
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'visible';
        }
    }
    viewModal(){
        this.handleToggleProgress();
        GetWebxLaravel("template_detail/get_template/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    template_data:response.data,
                    invoice_array:[],
                    additional_charge_array:[],
                    additional_charge_ids:[],
                });
            }

        });
        this.setState({
            modalShow:true,
            setModalShow:true
        })

    };
    loadEditInvoice(index){
        const template_data_id = this.state.invoice_array.slice(); //copy the array

        let product_quantity=this.state.product_quantity;

        this.setState({
            product_name:template_data_id[index].product_name,
            value:template_data_id[index].product_name,
            product_rate:template_data_id[index].product_rate,
            product_quantity:template_data_id[index].product_quantity,
            mangeProducts:()=>this.editProductNew(index),
            modalShowAddInvoice:true,
            setModalShowAddInvoice:true
        }, () => {
            var modelD = document.getElementById("ani-modal");
            modelD.classList.add("ancd-mod");
        });
        if ( document.getElementById("activation-modal")){
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'hidden';
        }
    }
    viewModalInvoice(){
       // this.handleToggleProgress();
       /* GetWebxLaravel("products/get_products/"+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")).then(response=>{
            if (response){
                this.handleCloseProgress();
                this.setState({
                    product_data:response.data,
                    suggestions:response.data,
                });
            }
        });*/

        this.setState({
            product_name:'',
            value:'',
            product_rate:'',
            product_quantity:'',
            mangeProducts:this.addProductNew,
            modalShowAddInvoice:true,
            setModalShowAddInvoice:true
        }, () => {
            var modelD = document.getElementById("ani-modal");
            modelD.classList.add("ancd-mod");
        });
        if ( document.getElementById("activation-modal")){
            var modelInvoice = document.getElementById("activation-modal");
            modelInvoice.style.visibility = 'hidden';
        }

    };

    removeItem(index) {
        let invoice_data = JSON.parse(JSON.stringify(this.state.invoice_array));
        //make changes to ingredients
        invoice_data.splice(index, 1);
        let additional_charge_array = this.state.additional_charge_array;



        this.setState({
            invoice_array: invoice_data
        },()=>{
            if (additional_charge_array && additional_charge_array.length) {
                additional_charge_array.map((list, key) => {
                    let additional_chg = 0;
                    if (list.tax_type == "F") {
                        additional_chg = list.additional_charge_amount
                    } else {
                        let total_payable = this.state.invoice_array.reduce(function (prev, current) {
                            return prev + +current.sub_total
                        }, 0);
                        additional_chg = total_payable * list.additional_charge_amount / 100
                    }
                    const additional_charge_array_new = this.state.additional_charge_array.slice() //copy the array
                    additional_charge_array_new[key]['additional_charge'] = additional_chg; //execute the manipulations
                    this.setState({additional_charge_array: additional_charge_array_new}) //set the new state

                });
            }
        })
    }

    addRemoveActive(){
        var plusButton = document.getElementById("plus-button");
        var plusMenu = document.getElementById("plus-menu");
        if (plusMenu.classList.contains('active')) {
            plusMenu.classList.remove("active");
            plusButton.classList.remove("active");
        } else {
            plusMenu.classList.add("active");
            plusButton.classList.add("active");
        }
        var myElement = document.getElementsByClassName('l-item');
        for (var i = 0; i < myElement.length; i++) {
            myElement[i].onclick = function() {
                var plusButton = document.getElementById("plus-button");
                var plusMenu = document.getElementById("plus-menu");
                plusMenu.classList.remove("active");
                plusButton.classList.remove("active");
            }
        }
    }

    selectChart(){
        let type_chart= document.getElementById("chart-selector").value;
        if(type_chart==="usd"){
            document.getElementById("chart_usd").style.display = "block";
            document.getElementById("chart_lkr").style.display = "none";
        }else{
            document.getElementById("chart_usd").style.display = "none";
            document.getElementById("chart_lkr").style.display = "block";
        }
    }


    loadTotalDue() {
        this.handleToggleProgress();

        let BaseUrl = myConfig.apiUrl;
        let formData = new FormData();    //formdata objec
        var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer' + ' ' + userToken

            }
        };
        PostCompnentWebx( BaseUrl+"getMerchantDue",formData).then(response=>{
            this.handleCloseProgress();
            if (response.data.lkr_total !== undefined) {
                this.setState({total_due: response.data.lkr_total});
            }
            if (response.data.usd_total !== undefined) {
                this.setState({total_due_usd: response.data.usd_total});
            }
        });
    }

    handleCloseProgress = () => {
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({openprogress: true});
    };

    componentWillMount() {

        if (sessionStorage.getItem("userToken")||localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken")||localStorage.getItem("userToken");

            this.setState({userToken: userToken});
            this.getUser();
            this.getImage();
            //this.fetchDataFirst(this.state.endDate,this.state.startDate);
            this.fetchInvoiceData();
            /*    this.loadPageData(userToken);
               */
            // this.loadLastPaid(userToken);
            //this.loadTotalDue(userToken);
            this.getCountries();
            this.fetchUserData();
            //this.handleToggleProgress();
        } else {
            this.setState({redirect: true});
        }
    }

    loadLastPaid(userToken) {
        this.handleToggleProgress();
        let formData = new FormData();    //formdata objec


        PostCompnentWebx("getRecentDepositSummery", formData).then(response=>{
            this.handleCloseProgress();
            this.setState({last_paid_amount_lkr: response.data.lkr_total});
            this.setState({last_paid_amount_usd: response.data.usd_total});
            this.setState({last_paid_date: response.data.recent_deposit_date});

        });
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    formatOptionLabel(data) {
        const charCount = data.length;
    }

    render() {

        const terms_list=this.state.terms_list;
        const customer_details=this.state.customer_details;
        const customer_group_list=this.state.customer_group_list;
        const invoice_array=this.state.invoice_array;
        const invoice_data=this.state.invoice_data;
        const single_invoice_details=this.state.single_invoice_details;
        const countries_list=this.state.countries_list;



        let terms_list_data=[];
        if(terms_list && terms_list.length){
            terms_list.map((value, index) => {
                let dot='';
                if (value.terms_condition_text.replace(/<[^>]+>/g, '').length>100 ){

                    dot='...';
                }
                terms_list_data.push({'value':value.terms_condition_text,'html': value.terms_condition_text.replace(/<[^>]+>/g, '').substr(0, 100)+dot});
            });
        }


        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Select Product',
            value,
            onChange: this.onChangesss
        };


        const total_invoice_sub=invoice_data.reduce((total, currentValue) => total = total + parseFloat(currentValue.total),0);
        let total_invoice_usd=0;
        let total_invoice_lkr=0;
        for (let i=0;i<invoice_data.length;i++){
            if (invoice_data[i].currency_id==2){
                total_invoice_usd+=parseFloat(invoice_data[i].total);
            }else{
                total_invoice_lkr+=parseFloat(invoice_data[i].total);
            }
        }
        let additional_crg=0;
        for (let i=0;i<invoice_data.length;i++){
            let additional_data=invoice_data[i].get_additional;
            for (let x=0;x<additional_data.length;x++){
                additional_crg+=additional_data[x].amount;
            }
        }


        const total_items=this.state.items_data.length;
        const total_templates=this.state.template_data.length;
        let total_amount=0;
        const template_data=this.state.template_data;
       // let additional_charges=this.state.additional_charges;
        let additional_charge_array=this.state.additional_charge_array;
        let sum = invoice_array.reduce(function(prev, current) {
            return prev + +current.sub_total
        }, 0);

        let sum_additional=0;
        let sum_additional_single=0;
        let total_sum=0;

        if (single_invoice_details.get_additional) {
            sum_additional_single = single_invoice_details.get_additional.reduce(function (prev, current) {
                return prev + +current.amount
            }, 0);
            total_sum = parseFloat(sum_additional_single) + parseFloat(single_invoice_details.sub_total);
        }
        sum_additional=additional_charge_array.reduce(function(prev, current) {
            return prev + +current.additional_charge
        }, 0);
        if (sum_additional){
            total_amount=parseFloat(sum)+parseFloat(sum_additional);
        }else{
            total_amount=parseFloat(sum);
        }
        let paid_status=false;
        if(single_invoice_details.get_order) {
            if (single_invoice_details.get_order.length) {
                if (single_invoice_details.get_order[single_invoice_details.get_order.length - 1].order_status_id == 2) {
                    paid_status = true;
                }
            }
        }
        if (this.state.redirect) {
            return <Navigate to='/login'  />
        }
        return (

            <>
                <SupportButton/>
                <ToastContainer />
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {/* Mail send confirmation*/}
                <Modal
                    show={this.state.modalShowCloseConfirmation}
                    onHide={this.state.setModalShowCloseConfimation}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="info-modal"
                >
                    <div id="divcontents">
                        <Modal.Body className="modal-type-2">
                            {/* <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>*/}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 lft">
                                        <p className="in-fh">{this.state.invoice_message}</p>
                                        <button className="btn btn-danger close-btn"
                                                onClick={this.closeModalSendInvoiceConfirm}>Close
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
                <Modal
                    show={this.state.modalShowCustomerEdit}
                    onHide={this.state.setModalShowCustomerEdit}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anc-modal-edit"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.closeModalEditCustomer}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh">Customer Details</h3>

                                    <div className="col-12 tdm-in p-0">

                                        <h6>Customer Name</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Customer Last Name" name="customer_first_name_edit" value={this.state.customer_first_name_edit} onChange={this.onChanges} />
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["customer_first_name_edit"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Customer Last Name" name="customer_last_name_edit" value={this.state.customer_last_name_edit} onChange={this.onChanges} />
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["customer_last_name_edit"]}</span>
                                        </div>

                                        <h6>Email Address</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Email Address" name="email_address_edit" value={this.state.email_address_edit} onChange={this.onChanges} readOnly/>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["email_address_edit"]}</span>
                                        </div>

                                        <h6>Contact Number</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Phone Number" name="phone_number_edit" value={this.state.phone_number_edit} onChange={this.onChanges} />
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["phone_number_edit"]}</span>
                                        </div>

                                        <h6>Website (Optional)</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Website URL" name="website_url_edit" value={this.state.website_url_edit} onChange={this.onChanges} />
                                        </div>

                                        <h6>Address Line 1</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Street Address Line 1" name="street_address_edit_1" value={this.state.street_address_edit_1} onChange={this.onChanges} />
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["street_address_edit_1"]}</span>
                                        </div>
                                        <h6>Address Line 2</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Street Address Line 2" name="street_address_edit_2" value={this.state.street_address_edit_2} onChange={this.onChanges} />
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <h6>City</h6>
                                                <div className="form-group">
                                                    {/*   <select name="" id="" className="form-control">
                                                     <option value="">Select City</option>
                                                 </select>*/}
                                                    <input type="text" className="form-control" placeholder="Enter City" name="city_edit" value={this.state.city_edit} onChange={this.onChanges}/>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["city_edit"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <h6>Country</h6>
                                                <div className="form-group">
                                                    <select  id="" className="form-control" name="country_edit" value={this.state.country_edit} onChange={this.onChanges}>
                                                        <option value="" >Select</option>
                                                        {countries_list && countries_list.length?(
                                                            countries_list.map((list, key) =>
                                                            {

                                                                return (
                                                                    <option value={list.id} >{list.nicename}</option>
                                                                )
                                                            })
                                                        ):('')
                                                        }
                                                    </select>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["country_edit"]}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <button type="button" className="nxt-btn" onClick={this.storeCustomerEdit}>Update Customer<span> </span> <i
                                        className="fas fa-save"></i></button>
                                    {/*   <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                     Help with the System?</a>*/}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                {/*Customer Create Modal*/}
                <Modal
                    show={this.state.modalShowCustomer}
                    onHide={this.state.setModalShowCustomer}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="anc-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.closeModalCustomer}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh">Customer Details</h3>

                                    <div className="col-12 tdm-in p-0">

                                        <h6>Customer First Name</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Customer First Name" name="customer_first_name" onChange={this.onChanges} />
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["customer_first_name"]}</span>
                                        </div>

                                        <h6>Customer Last Name</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Customer Last Name" name="customer_last_name" onChange={this.onChanges} />
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["customer_last_name"]}</span>
                                        </div>

                                        <h6>Email Address</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Email Address" name="email_address" onChange={this.onChanges} />
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["email_address"]}</span>
                                        </div>

                                        <h6>Contact Number</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Phone Number" name="phone_number" onChange={this.onChanges} />
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["phone_number"]}</span>
                                        </div>

                                        <h6>Website (Optional)</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Website URL" name="website_url" onChange={this.onChanges} />
                                        </div>

                                        <h6>Address Line 1</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Address Line1" name="street_address_1" onChange={this.onChanges} />
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["street_address_1"]}</span>
                                        </div>
                                        <h6>Address Line 2</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Address Line2" name="street_address_2" onChange={this.onChanges} />
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <h6>City</h6>
                                                <div className="form-group">
                                                    {/*   <select name="" id="" className="form-control">
                                                     <option value="">Select City</option>
                                                 </select>*/}
                                                    <input type="text" className="form-control" placeholder="Enter City" name="city" onChange={this.onChanges}/>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["city"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <h6>Country</h6>
                                                <div className="form-group">
                                                    <select  id="" className="form-control" name="country" onChange={this.onChanges}>
                                                        <option value="" >Select</option>
                                                        {countries_list && countries_list.length?(
                                                            countries_list.map((list, key) =>
                                                            {

                                                                return (
                                                                    <option value={list.id} >{list.nicename}</option>
                                                                )
                                                            })
                                                        ):('')
                                                        }
                                                    </select>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["country"]}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <button type="button" className="nxt-btn" onClick={this.storeCustomer}>Save New Customer &nbsp;&nbsp;<i
                                        className="fas fa-save"></i></button>
                                    {/*    <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                     Help with the System?</a>*/}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                {/* Invoice Modal*/}
                <Modal
                    show={this.state.modalShow}
                    onHide={this.state.setModalShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="activation-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalClose}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 lft cd-frm">
                                    <h3 className="tdh">Customer Details</h3>

                                    <div className="col-12 tdm-in">
                                        <h6>Currency Type</h6>
                                        <div className="form-group">
                                            <select name="currency_type" id="" className="form-control ctt" onChange={this.onChangesCurrency} >
                                                {this.state.lkr_available?(<option value="5">LKR</option>):('')}
                                                {this.state.usd_available?(<option value="2">USD</option>):('')}
                                            </select>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["currency_type"]}</span>
                                        </div>
                                        <h6>Client Details</h6>
                                        <div className="form-group">
                                            <select name="customer_id" value={this.state.customer_id} id="" className="form-control" onChange={this.onChanges}>
                                                <option value="">Select Customer</option>
                                                <option value="1">Test User</option>

                                            </select>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["customer_id"]}</span>
                                            <button type="button" className="icn" data-toggle="modal"
                                                    data-target="#edit-custome-modal" data-dismiss="modal"><i
                                                className="fas fa-pencil-alt" onClick={()=>this.editCustomer(this.state.customer_id)}></i></button>
                                        </div>
                                        <h5 className="sh cup" data-toggle="modal" data-target="#add-new-custome-modal"
                                            data-dismiss="modal" onClick={this.viewModalCustomer}><i className="fas fa-plus"></i> Add New
                                            Customer Details</h5>

                                        <h6>Invoice Number</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                   placeholder="Enter or Generate Invoice #" name="invoice_number" value={this.state.invoice_number} onChange={this.onChanges}/>
                                            <button type="button" className="icn" onClick={this.generateInvoice}><i className="fas fa-sync-alt"></i>
                                            </button>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["invoice_number"]}</span>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <h6>Date of Issuance</h6>
                                                <div className="form-group">
                                                    {/*<input type="text" className="form-control" placeholder="Enter Date" />*/}

                                                    <DateRangePicker  initialSettings={{  singleDatePicker: true,
                                                        showDropdowns: true,
                                                        locale: {
                                                            'format': 'DD-MM-YYYY'
                                                        },
                                                        parentEl: "#activation-modal .modal-body",
                                                        autoApply:true,
                                                        autoUpdateInput:false

                                                    }}
                                                                      onApply={this.handleEvent}
                                                                      startDate={this.state.date_of_issuance}

                                                    >


                                                        <input type="text" className="form-control" placeholder="Select Date" name="date_of_issuance" value={this.state.date_of_issuance} />

                                                    </DateRangePicker>
                                                    <button type="button" className="icn"><i
                                                        className="far fa-calendar-alt"></i></button>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["date_of_issuance"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <h6>Date of Expiry</h6>
                                                <div className="form-group">
                                                    <DateRangePicker  initialSettings={{  singleDatePicker: true,
                                                        showDropdowns: true,
                                                        autoApply:true,
                                                        autoUpdateInput:false,
                                                        locale: {
                                                            'format': 'DD-MM-YYYY'
                                                        },
                                                        parentEl: "#activation-modal .modal-body"
                                                    }}
                                                                      onApply={this.handleEventEnd}
                                                                      endDate={this.state.date_of_expiry}
                                                    >

                                                        <input type="text" className="form-control" placeholder="Select Date" name="date_of_expiry" value={this.state.date_of_expiry}/>

                                                    </DateRangePicker>
                                                    <button type="button" className="icn"><i
                                                        className="far fa-calendar-alt"></i></button>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["date_of_expiry"]}</span>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<h6>Payment Link</h6>
                                     <div className="form-group">
                                         <input name="" type="text" className="form-control" placeholder="Generate Payment Link"  />
                                         <button type="button" className="icn"><i className="fas fa-sync-alt"></i>
                                         </button>
                                     </div>*/}

                                        <h6 className="mt-4">Total Invoice Value</h6>
                                        <h3 className="tdh mb-0"><NumberFormat
                                            value={(Math.round(total_amount * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true} prefix={this.state.currency_type+' '}/></h3>


                                    </div>

                                    <button type="button" className="nxt-btn" onClick={this.saveInvoiceDetailGenerate}>Generate Invoice &nbsp;&nbsp;<i
                                        className="fas fa-check"></i></button>
                                    <button type="button" className="ext-btn" onClick={this.saveInvoiceDetail}>Save Invoice &nbsp;&nbsp;<i
                                        className="fas fa-save"></i></button>
                                    {/*    <button type="button" className="ext-btn ext-t2">Delete Invoice &nbsp;&nbsp;<i
                                     className="fas fa-trash-alt"></i></button>*/}
                                    {/*   <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                     Help with the System?</a>*/}
                                </div>
                                <div className="col-md-8 rht cd-il">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h3 className="tdh cup" data-toggle="modal" data-target="#create-template-modal"
                                                data-dismiss="modal" onClick={this.viewModalInvoice}>Add Invoice Line Items &nbsp;&nbsp;<i
                                                className="fas fa-folder-plus"></i></h3>
                                        </div>
                                        <div className="col-md-5 stmp">
                                            <div className="form-group">

                                                <select name="invoice_template" id="" className="form-control" onChange={this.getTemplateSingle}>
                                                    <option value="" >Select Template</option>
                                                    <option value="100" >Test Templete</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 details-box d-inv-box">
                                            <div className="col-12 dbx-in">

                                                <div className="row row_clr dit-in">
                                                    <div className="col-12 rht img-box p-0">

                                                        <div className='rg-container'>
                                                            <table className='transactions-table invoice-table' summary='Hed'>
                                                                <thead>
                                                                <tr>
                                                                    <th className=''>#</th>
                                                                    <th className=''>Title & Description</th>
                                                                    <th className=''>Rate/ITEM</th>
                                                                    <th className=''>Qty</th>
                                                                    <th className=''>Total</th>
                                                                    <th className=''></th>

                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {invoice_array && invoice_array.length?(
                                                                    invoice_array.map((list, key) =>
                                                                    {
                                                                        return (
                                                                            <tr className=''>
                                                                                <td className='' data-title='#'>{key+1}</td>
                                                                                <td className='' data-title='Title & Description'>{list.product_name}
                                                                                </td>
                                                                                <td className='' data-title='Rate/ITEM'>
                                                                                    <NumberFormat
                                                                                        value={(Math.round(list.product_rate * 100) / 100).toFixed(2)}
                                                                                        displayType={'text'} thousandSeparator={true} prefix={this.state.currency_type+' '}/>
                                                                                </td>
                                                                                <td className='' data-title='qty'>{list.product_quantity}</td>
                                                                                <td className='' data-title='qty'> <NumberFormat
                                                                                    value={(Math.round(list.sub_total * 100) / 100).toFixed(2)}
                                                                                    displayType={'text'} thousandSeparator={true} prefix={this.state.currency_type+' '}/></td>
                                                                                <td className='' data-title=''><i
                                                                                    className="fas fa-pencil-alt" onClick={()=>this.loadEditInvoice(key)}></i>
                                                                                    &nbsp;&nbsp;&nbsp;
                                                                                    <i className="fas fa-trash" onClick={()=>this.removeItem(key)}></i>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                ):(
                                                                    <tr className=''>
                                                                        <td colSpan="5" className='' data-title='#'></td>
                                                                    </tr>

                                                                )
                                                                }

                                                                {additional_charge_array && additional_charge_array.length?(
                                                                    additional_charge_array.map((list, key) =>
                                                                    {
                                                                        return (
                                                                            <tr className=''>
                                                                                <td className='' data-title=''></td>
                                                                                <td className='' data-title=''></td>
                                                                                <td className='' data-title=''></td>
                                                                                <td className=''
                                                                                    data-title=''>{list.additional_charge_name}</td>
                                                                                <td className=''
                                                                                    data-title=''>{this.state.currency_type} {list.additional_charge}</td>
                                                                                <td className='' data-title=''><i
                                                                                    className="fas fa-trash" onClick={()=>this.deleteAdditionalCharge(list.additional_charge_id)}></i>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                ):(
                                                                    <tr className=''>
                                                                        <td colSpan="5" className='' data-title='#'></td>
                                                                    </tr>

                                                                )
                                                                }

                                                                <tr className=''>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title='' onClick={this.showAdditionalChargeModal}>Add Additional Charges</td>
                                                                    <td className='' data-title='' onClick={this.showAdditionalChargeModal}><i
                                                                        className="fas fa-plus-square"></i></td>
                                                                </tr>

                                                                <tr className=''>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''>Total Payable</td>
                                                                    <td className='' data-title=''> <NumberFormat
                                                                        value={(Math.round(total_amount * 100) / 100).toFixed(2)}
                                                                        displayType={'text'} thousandSeparator={true} prefix={this.state.currency_type+' '}/></td>
                                                                    <td className='' data-title=''></td>
                                                                </tr>



                                                                </tbody>
                                                            </table>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["invoice_array"]}</span>
                                            <div className="col-12 p-0 stmp-bot">
                                                <p>Customer Notes</p>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="customer_notes"
                                                           placeholder="Add Customer Notes" onChange={this.onChanges} />
                                                </div>
                                                <p>Terms & Conditions</p>
                                                <div className="form-group">
                                                    <select id="" className="form-control" name="terms_condition_id" onChange={this.onChanges}>
                                                        <option value="">Add or Select Terms & Conditions</option>
                                                        <option value="Test Terms and Conditions">Test Terms and Conditions</option>

                                                    </select>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["terms_condition_id"]}</span>
                                                </div>
                                                {/*<p>Taxable</p>*/}
                                                <div className="form-group" style={{visibility:"hidden"}}>
                                                    <select id="" className="form-control" name="taxable_status" onChange={this.onChanges}>
                                                        <option value="">Select</option>
                                                        <option value="1" selected>Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["taxable_status"]}</span>
                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                {/*Invoice Detail show Modal*/}
                <Modal
                    show={this.state.modalShowInvoiceDetail}
                    onHide={this.state.setModalShowInvoiceDetail}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="activation-modal"
                >

                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.closeModalInvoiceDetail}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 lft">
                                    <h3 className="tdh">Invoice Details</h3>

                                    <div className="col-12 tdm-in">

                                        <h6>Transaction Value</h6>
                                        <h3 className="tdh">LKR. 100.00</h3>

                                        <h6>Invoice ID</h6>
                                        <h5 className="sh">INV-45454545454</h5>

                                        <h6>Client Details</h6>
                                        <ul className="i-list">
                                            <li><i className="fas fa-home"></i>

                                                Test User ,  Address Line 1, Address Line 2, Test City, Sri lanka'

                                            </li>
                                            <li><i className="fas fa-phone"></i>0114545445</li>
                                            <li><i className="fas fa-at"></i>test@gmail.com</li>
                                        </ul>

                                        <h6>Date of Creation</h6>
                                        <h5 className="sh">11 th of May 2021</h5>

                                            <><h6>Transaction Reference</h6><h5 className="sh">TR-2234234324234</h5></>

                                        {/*  <h6>Payment Link</h6>
                                     <h5 className="sh">{myConfig.laravelWeb+'invoice_detail/'+this.state.single_hashed_id} </h5>*/}

                                        {/*  <h6>Clearance Status</h6>
                                     <h5 className="sh">{single_invoice_details.is_active==1?(<React.Fragment><span className="dot yellow"><i className="fas fa-circle" aria-hidden="true"></i> </span>Payment Pending</React.Fragment> ):(
                                         <React.Fragment className="dot green"><span><i className="fas fa-circle" aria-hidden="true"></i> </span> Paid </React.Fragment>
                                     )} </h5>*/}


                                    </div>
                                    {this.state.single_order_status!==2?(
                                        <div className="sco-share">
                                            <FacebookShareButton
                                                url={myConfig.laravelWeb+'invoice_detail/'+this.state.single_hashed_id}>
                                                <FacebookIcon size={40}
                                                              logoFillColor="white"/>
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                url={myConfig.laravelWeb+'invoice_detail/'+this.state.single_hashed_id}>
                                                <TwitterIcon size={40}
                                                             logoFillColor="white"/>
                                            </TwitterShareButton>
                                            <WhatsappShareButton
                                                url={myConfig.laravelWeb+'invoice_detail/'+this.state.single_hashed_id}>
                                                <WhatsappIcon size={40}
                                                              logoFillColor="white"/>
                                            </WhatsappShareButton>

                                            <ViberShareButton
                                                url={myConfig.laravelWeb+'invoice_detail/'+this.state.single_hashed_id}>
                                                <ViberIcon size={40}
                                                           logoFillColor="white"/>
                                            </ViberShareButton>

                                            <TelegramShareButton
                                                url={myConfig.laravelWeb+'invoice_detail/'+this.state.single_hashed_id}>
                                                <TelegramIcon size={40}
                                                              logoFillColor="white"/>
                                            </TelegramShareButton>
                                            <CopyToClipboard size={40} text={myConfig.laravelWeb+'invoice_detail/'+this.state.single_hashed_id} >
                                                <button   onClick={this.notify} className="cpy-btn"><i className="far fa-copy"></i></button>
                                            </CopyToClipboard>




                                        </div>):('')}

                                    {/* <button type="button" className="nxt-btn">Send Reminder &nbsp;&nbsp;<i
                                     className="fas fa-bell"></i></button>
                                 <button type="button" className="ext-btn">Share Invoice &nbsp;&nbsp;<i
                                     className="fas fa-share"></i></button>
*/}
                                    {/*  <button type="button" className="ext-btn ext-t2">Delete Invoice &nbsp;&nbsp;<i
                                     className="fas fa-trash-alt"></i></button>*/}

                                    {this.state.single_order_status!==2?(this.state.loading ? <LoadingSpinner /> :<button type="button" className="nxt-btn" onClick={()=>this.sendInvoiceIndividual(single_invoice_details.id)}>Send Invoice &nbsp;&nbsp;<i className="fas fa-envelope" aria-hidden="true"></i></button>):('')}




                                    {/*  <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                     Help with the System?</a>*/}
                                </div>
                                <div className="col-md-8 rht">

                                    <h3 className="tdh">Invoice Timeline</h3>

                                    <div className="row">
                                        <div className="col-sm-3 col-12 tt-icns">
                                            <div className="icon-box">
                                                <img src={require('../../images/transactions/invoice.svg')} alt="money" />

                                            </div>
                                            <p>Invoice Generated</p>
                                            <p className="sp">
                                                {moment(single_invoice_details.date_of_issuance).format('ll')}
                                            </p>
                                        </div>
                                        <div className="col-sm-3 col-12 tt-icns fu">
                                            <div className={single_invoice_details.invoice_status==2?'icon-box full-filled':'icon-box half-filled'}>
                                                <img src={require('../../images/invoice/plane.svg')} alt="" />

                                            </div>
                                            {single_invoice_details.invoice_status==2?<p>Sent to Customer</p>:<p>Not Sent to Customer</p>}
                                            <p className="sp">{single_invoice_details.invoice_status==2?moment(single_invoice_details.updated_at).format('ll'):''}
                                            </p>

                                        </div>
                                        <div className="col-sm-3 col-12 tt-icns">
                                            <div className={this.state.single_order_status!==2?'icon-box half-filled':'icon-box full-filled'}>
                                                <img src={require('../../images/transactions/time-is-money.svg')} alt="money" />
                                            </div>


                                            {this.state.single_order_status!==2?( <p>Payment Pending</p>):( <><p>Payment Paid </p><p className="sp">{moment(single_invoice_details.get_order[single_invoice_details.get_order.length-1].created_at).format('ll')}</p></>)}

                                        </div>

                                        <div className="col-12 details-box d-inv-box">
                                            <div className="col-12 dbx-in">
                                                <h3>Detailed Invoice </h3>

                                                <div className="row row_clr dit-in">
                                                    <div className="col-md-8 lht">
                                                        <div className="row">
                                                            <div className="col-12 lgo">
                                                                <img src={this.state.logo_file} alt=""/>
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <h5>  {decode(this.state.doing_business_name)}</h5>
                                                                <ul className="i-list">
                                                                    <li><i className="fas fa-home"></i>{this.state.merchant_address}</li>
                                                                    <li><i className="fas fa-phone"></i> {this.state.merchant_contact_number}</li>
                                                                    <li><i className="fas fa-at"></i>{this.state.merchant_email_address}</li>
                                                                    <li><i className="fas fa-globe"></i>{this.state.merchant_website_url}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <h5>{single_invoice_details.get_customer?single_invoice_details.get_customer.customer_first_name+' '+single_invoice_details.get_customer.customer_last_name:''}</h5>
                                                                <ul className="i-list">
                                                                    <li>  {
                                                                        (() => {
                                                                            if(single_invoice_details.get_customer) {
                                                                                if (single_invoice_details.get_customer.customer_address_line_2 == "null")
                                                                                    return single_invoice_details.get_customer ? single_invoice_details.get_customer.customer_first_name + ' ' + single_invoice_details.get_customer.customer_last_name + ',  ' + single_invoice_details.get_customer.customer_address_line_1 + ', ' + single_invoice_details.get_customer.customer_city + ', ' + single_invoice_details.get_customer.get_countries.nicename : ''
                                                                                else
                                                                                    return single_invoice_details.get_customer ? single_invoice_details.get_customer.customer_first_name + ' ' + single_invoice_details.get_customer.customer_last_name + ', ' + single_invoice_details.get_customer.customer_address_line_1 + ', ' + single_invoice_details.get_customer.customer_address_line_2 + ', ' + single_invoice_details.get_customer.customer_city + ', ' + single_invoice_details.get_customer.get_countries.nicename : ''
                                                                            }
                                                                        })()
                                                                    }</li>

                                                                    <li><i className="fas fa-phone"></i>{single_invoice_details.get_customer?single_invoice_details.get_customer.customer_phone_number:''}</li>
                                                                    <li><i className="fas fa-at"></i>{single_invoice_details.get_customer?single_invoice_details.get_customer.customer_email:''}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 rhs">
                                                        <p>Invoice Number</p>
                                                        <h4>{single_invoice_details.invoice_id}</h4>

                                                        <p>Total Payable</p>
                                                        <h4><NumberFormat
                                                            value={total_sum.toFixed(2)}
                                                            displayType={'text'} thousandSeparator={true} prefix={single_invoice_details.currency_id==5?'LKR ':'USD '}/></h4>
                                                        {single_invoice_details.payment_status==0?
                                                            <a href={myConfig.laravelWeb+'invoice_detail/'+sessionStorage.getItem("merchant_id")+'|'+sessionStorage.getItem("encrypted_merchant_id")+'|'+single_invoice_details.hashed_id} className="p-btn" target="_blank">Pay Now &nbsp;&nbsp;<i
                                                                className="fas fa-credit-card"></i></a>
                                                            :''}
                                                    </div>
                                                    <div className="col-sm-12 rht img-box p-0">

                                                        <div className='rg-container'>
                                                            <table className='transactions-table invoice-table' summary='Hed'>
                                                                <thead>
                                                                <tr>
                                                                    <th className=''>#</th>
                                                                    <th className=''>Title & Description</th>
                                                                    <th className=''>Rate/ITEM</th>
                                                                    <th className=''>Qty</th>
                                                                    <th className=''>total</th>

                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                            <tr className=''>
                                                                                <td className='' data-title='#'>1</td>
                                                                                <td className='' data-title='Title & Description'>
                                                                                 Product 1
                                                                                </td>
                                                                                <td className='' data-title='Rate'>
                                                                                    LKR 200.00
                                                                                </td>
                                                                                <td className='' data-title='qty'>10</td>
                                                                                <td className='' data-title='total'>
                                                                                    LKR 2000.00
                                                                                </td>

                                                                            </tr>


                                                                <tr className=''>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''>Subtotal</td>
                                                                    <td className='' data-title=''>LKR 2000.00</td>

                                                                </tr>


                                                                            <tr className=''>
                                                                                <td className='' data-title=''></td>
                                                                                <td className='' data-title=''></td>
                                                                                <td className='' data-title=''></td>
                                                                                <td className='' data-title=''>Tax 1</td>
                                                                                <td className='' data-title=''>LKR 200.00</td>

                                                                            </tr>



                                                                <tr className=''>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''>Total Payable</td>
                                                                    <td className='' data-title=''>LKR. 2200.00</td>

                                                                </tr>

                                                                </tbody>
                                                            </table>
                                                           <div className="tac">
                                                                    <h6>Customer Notes</h6>
                                                                    <p>New customer notes</p>
                                                                </div>
                                                            <div className="tac">
                                                                <h6>Terms & Conditions</h6>
                                                                <p>New terms and conditions</p>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                {/*Invoice Detail product Modal*/}
                <Modal
                    show={this.state.modalShowAddInvoice}
                    onHide={this.state.setModalShowAddInvoice}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="ani-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.modalCloseInvoice}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh">Add Product/Service</h3>

                                    <div className="col-12 tdm-in p-0">
                                        <h6>Product Name</h6>
                                        <Autosuggest
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            onSuggestionSelected={this.onSuggestionSelected}
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            inputProps={inputProps}
                                        />
                                        {/*  <Autocomplete
                                         style={{marginTop:"-32px",marginBottom: "10px"}}
                                         options={this.state.product_data}
                                         getOptionLabel={(option) => option.item_name}
                                         renderInput={(params) => <TextField {...params} label="Title & Description"
                                                                             margin="normal" value={this.state.product_name}

                                                                             label={"Product Name " + this.state.product_name}
                                         />}
                                         freeSolo
                                         noOptionsText={''}
                                         defaultValue={this.state.product_name}
                                         //value={this.state.product_name}
                                         onInputChange={this.onChangesAuto}
                                         onChange={
                                             (event, newValue) => {
                                             if (newValue) {
                                                 this.setState({
                                                     product_rate: newValue.product_price,
                                                     option_index:event.target.getAttribute('data-option-index')
                                                 });
                                                // this.getUserData(newValue.description);
                                             }
                                         }}
                                     />*/}


                                        <span className="error" style={{color: "red"}}>{this.state.errors_cus["product_name"]}</span>
                                        {/*  <h6>Title & Description</h6>
                                     <div className="form-group">
                                         <input type="text" name="product_name" value={this.state.product_name} className="form-control" placeholder="Enter Product Name" onChange={this.onChanges} />
                                     </div>*/}

                                        <h6>Rate/Item</h6>
                                        <div className="form-group">
                                            <input type="text" name="product_rate" value={this.state.product_rate} className="form-control" placeholder="Enter Product Rate" onChange={this.onChangeNumber}/>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["product_rate"]}</span>
                                        </div>

                                        <h6>Qty</h6>
                                        <div className="form-group">
                                            <input type="text" name="product_quantity" value={this.state.product_quantity} className="form-control" placeholder="Enter Quantity" onChange={this.onChangeNumber}/>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["product_quantity"]}</span>
                                        </div>

                                    </div>

                                    <button type="button" className="nxt-btn" onClick={this.state.mangeProducts}>Add New Product &nbsp;&nbsp;<i
                                        className="fas fa-save"></i></button>
                                    {/* <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                     Help with the System?</a>*/}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/*Invoice Detail Additional Details*/}
                <Modal
                    show={this.state.modalShowAdditionalCharges}
                    onHide={this.state.setModalShowAdditionalCharges}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="ani-modal-additional"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.closeAdditionalChargeModal}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 cd-frm">
                                    <h3 className="tdh">Add additional charge</h3>

                                    <div className="col-12 tdm-in p-0">

                                        <div className="form-group">
                                            <label htmlFor="">Additional Charge</label>
                                            <div className="slct">
                                                <div className="form-group">
                                                    <select name="additional_charge_name" id="" className="form-control" onChange={this.getSingleCharge}>

                                                        <option value="" >Select Additional Charge</option>
                                                        <option value="100" >Tax 1</option>
                                                        <option value="200" >Tax 2</option>

                                                    </select>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["additional_charge_name"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.tax_type=="P"?
                                            (
                                                <React.Fragment>
                                                    <h6>Additional Charge</h6>
                                                    <div className="form-group">
                                                        <input type="text" name="additional_charge" value={this.state.additional_charge} className="form-control" placeholder="" onChange={this.onChanges} readOnly="readOnly"/>
                                                        <span className="error" style={{color: "red"}}>{this.state.errors_cus["additional_charge"]}</span>
                                                    </div>
                                                </React.Fragment>
                                            ):(
                                                <React.Fragment>
                                                    <h6>Additional Charge</h6>
                                                    <div className="form-group">
                                                        <input type="text" name="additional_charge" value={this.state.additional_charge} className="form-control" placeholder="" onChange={this.onChanges}/>
                                                        <span className="error" style={{color: "red"}}>{this.state.errors_cus["additional_charge"]}</span>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                    </div>

                                    <button type="button" className="nxt-btn" onClick={this.state.manageAdditionalCharges}>Add Additional Charge &nbsp;&nbsp;<i
                                        className="fas fa-save"></i></button>
                                    {/*<a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                     Help with the System?</a>*/}
                                </div>

                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                {/*Invoice Detail Bulk Invoice*/}
                <Modal
                    show={this.state.modalShowBulkInvoiceDetail}
                    onHide={this.state.setModalShowBulkInvoiceDetail}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="activation-modal"
                >
                    <Modal.Body className="modal-type-2">
                        <div className="colse-mdl" data-dismiss="modal" aria-label="Close" onClick={this.closeModalBulkInvoice}>X</div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 lft cd-frm">
                                    <h3 className="tdh">Customer Details</h3>

                                    <div className="col-12 tdm-in">
                                        <h6>Currency Type</h6>
                                        <div className="form-group">
                                            <select name="currency_type_bulk_invoice"  id="" className="form-control ctt" onChange={this.onChangesCurrency} >
                                                {this.state.lkr_available?(<option value="5">LKR</option>):('')}
                                                {this.state.usd_available?(<option value="2">USD</option>):('')}
                                            </select>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["currency_type_bulk_invoice"]}</span>
                                        </div>
                                        <h6>Customer Group </h6>
                                        <div className="form-group">
                                            <select name="customer_group_id" id="" className="form-control" onChange={this.onChanges}>
                                                <option value="">Select Customer Group</option>
                                                {customer_group_list && customer_group_list.length?(
                                                    customer_group_list.map((list, key) =>
                                                    {
                                                        return (
                                                            <option value={list.id}>{list.customer_group}</option>
                                                        )
                                                    })
                                                ):('')
                                                }

                                            </select>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["customer_group_id"]}</span>
                                        </div>

                                        <h6>Invoice Number</h6>
                                        <div className="form-group">
                                            <input type="text" className="form-control"
                                                   placeholder="Enter or Generate Invoice #" name="invoice_number" value={this.state.invoice_number} onChange={this.onChanges}/>
                                            <button type="button" className="icn" onClick={this.generateInvoice}><i className="fas fa-sync-alt"></i>
                                            </button>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["invoice_number"]}</span>
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <h6>Date of Issuance</h6>
                                                <div className="form-group">
                                                    {/*<input type="text" className="form-control" placeholder="Enter Date" />*/}

                                                    <DateRangePicker  initialSettings={{  singleDatePicker: true,
                                                        showDropdowns: true,
                                                        locale: {
                                                            'format': 'DD-MM-YYYY'
                                                        },
                                                        parentEl: "#activation-modal .modal-body",
                                                        autoApply:true,
                                                        autoUpdateInput:false

                                                    }}
                                                                      onApply={this.handleEvent}
                                                                      startDate={this.state.date_of_issuance}
                                                                      onHideCalendar={this.handleEvent}
                                                                      onCancel={this.handleEvent}
                                                                      onHide={this.handleEvent}
                                                    >


                                                        <input type="text" className="form-control" placeholder="Select Date" name="date_of_issuance" value={this.state.date_of_issuance} />

                                                    </DateRangePicker>
                                                    <button type="button" className="icn"><i
                                                        className="far fa-calendar-alt"></i></button>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["date_of_issuance"]}</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <h6>Date of Expiry</h6>
                                                <div className="form-group">
                                                    <DateRangePicker  initialSettings={{  singleDatePicker: true,
                                                        showDropdowns: true,

                                                        locale: {
                                                            'format': 'DD-MM-YYYY'
                                                        },
                                                        parentEl: "#activation-modal .modal-body",
                                                        autoApply:true,
                                                        autoUpdateInput:false
                                                    }}
                                                                      onApply={this.handleEventEnd}
                                                                      endDate={this.state.date_of_expiry}
                                                    >

                                                        <input type="text" className="form-control" placeholder="Select Date" name="date_of_expiry" value={this.state.date_of_expiry}/>

                                                    </DateRangePicker>
                                                    <button type="button" className="icn"><i
                                                        className="far fa-calendar-alt"></i></button>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["date_of_expiry"]}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <h6 className="mt-4">Total Invoice Value</h6>
                                        <h3 className="tdh mb-0"><NumberFormat
                                            value={(Math.round(total_amount * 100) / 100).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true} prefix={this.state.currency_type_bulk_invoice+' '}/></h3>
                                    </div>

                                    <button type="button" className="ext-btn" onClick={this.saveBulkInvoice}>Save Bulk Invoice &nbsp;&nbsp;<i
                                        className="fas fa-save"></i></button>
                                    {/* <a href="#" className="help"><i className="far fa-life-ring" aria-hidden="true"></i> Need
                                     Help with the System?</a>*/}
                                </div>
                                <div className="col-md-8 rht cd-il">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <h3 className="tdh cup" data-toggle="modal" data-target="#create-template-modal"
                                                data-dismiss="modal" onClick={this.viewModalInvoice}>Add Invoice Line Items &nbsp;&nbsp;<i
                                                className="fas fa-folder-plus"></i></h3>
                                        </div>
                                        <div className="col-md-5 stmp">
                                            <div className="form-group">

                                                <select name="invoice_template" id="" className="form-control" onChange={this.getTemplateSingle}>

                                                    <option value="" >Select Template</option>
                                                    {template_data && template_data.length?(
                                                        template_data.map((list, key) =>
                                                        {
                                                            return (
                                                                <option value={list.template_detail.id} >{list.template_detail.template_name}</option>
                                                            )
                                                        })
                                                    ):('')
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 details-box d-inv-box">
                                            <div className="col-12 dbx-in">

                                                <div className="row row_clr dit-in">
                                                    <div className="col-12 rht img-box p-0">

                                                        <div className='rg-container'>
                                                            <table className='transactions-table invoice-table' summary='Hed'>
                                                                <thead>
                                                                <tr>
                                                                    <th className=''>#</th>
                                                                    <th className=''>Title & Description</th>
                                                                    <th className=''>Rate/ITEM</th>
                                                                    <th className=''>Qty</th>
                                                                    <th className=''>Total</th>
                                                                    <th className=''></th>

                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {invoice_array && invoice_array.length?(
                                                                    invoice_array.map((list, key) =>
                                                                    {
                                                                        return (
                                                                            <tr className=''>
                                                                                <td className='' data-title='#'>{key+1}</td>
                                                                                <td className='' data-title='Title & Description'>{list.product_name}
                                                                                </td>
                                                                                <td className='' data-title='Rate/ITEM'>
                                                                                    <NumberFormat
                                                                                        value={(Math.round(list.product_rate * 100) / 100).toFixed(2)}
                                                                                        displayType={'text'} thousandSeparator={true} prefix={this.state.currency_type_bulk_invoice+' '}/>
                                                                                </td>
                                                                                <td className='' data-title='qty'>{list.product_quantity}</td>
                                                                                <td className='' data-title='qty'> <NumberFormat
                                                                                    value={(Math.round(list.sub_total * 100) / 100).toFixed(2)}
                                                                                    displayType={'text'} thousandSeparator={true} prefix={this.state.currency_type_bulk_invoice+' '}/></td>
                                                                                <td className='' data-title=''><i
                                                                                    className="fas fa-pencil-alt" onClick={()=>this.loadEditInvoice(key)}></i>
                                                                                    &nbsp;&nbsp;&nbsp;
                                                                                    <i className="fas fa-trash" onClick={()=>this.removeItem(key)}></i>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                ):(
                                                                    <tr className=''>
                                                                        <td colSpan="5" className='' data-title='#'></td>
                                                                    </tr>

                                                                )
                                                                }

                                                                {additional_charge_array && additional_charge_array.length?(
                                                                    additional_charge_array.map((list, key) =>
                                                                    {
                                                                        return (
                                                                            <tr className=''>
                                                                                <td className='' data-title=''></td>
                                                                                <td className='' data-title=''></td>
                                                                                <td className='' data-title=''></td>
                                                                                <td className=''
                                                                                    data-title=''>{list.additional_charge_name}</td>
                                                                                <td className=''
                                                                                    data-title=''>{this.state.currency_type_bulk_invoice} {list.additional_charge}</td>
                                                                                <td className='' data-title=''><i
                                                                                    className="fas fa-trash" onClick={()=>this.deleteAdditionalCharge(list.additional_charge_id)}></i>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                ):(
                                                                    <tr className=''>
                                                                        <td colSpan="5" className='' data-title='#'></td>
                                                                    </tr>

                                                                )
                                                                }

                                                                <tr className=''>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title='' onClick={this.showAdditionalChargeModal}>Add Additional Charges</td>
                                                                    <td className='' data-title='' onClick={this.showAdditionalChargeModal}><i
                                                                        className="fas fa-plus-square"></i></td>
                                                                </tr>

                                                                <tr className=''>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''></td>
                                                                    <td className='' data-title=''>Total Payable</td>
                                                                    <td className='' data-title=''> <NumberFormat
                                                                        value={(Math.round(total_amount * 100) / 100).toFixed(2)}
                                                                        displayType={'text'} thousandSeparator={true} prefix={this.state.currency_type_bulk_invoice+' '}/></td>
                                                                    <td className='' data-title=''></td>
                                                                </tr>



                                                                </tbody>
                                                            </table>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <span className="error" style={{color: "red"}}>{this.state.errors_cus["invoice_array"]}</span>
                                            <div className="col-12 p-0 stmp-bot">
                                                <p>Customer Notes</p>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name="customer_notes"
                                                           placeholder="Add Customer Notes" onChange={this.onChanges} />
                                                </div>
                                                <p>Terms & Conditions</p>
                                                <div className="form-group">
                                                    <select id="" className="form-control" name="terms_condition_id" onChange={this.onChanges}>
                                                        <option value="">Add or Select Terms & Conditions</option>
                                                        <option value="Test Terms">Test Terms</option>

                                                    </select>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["terms_condition_id"]}</span>
                                                </div>
                                                {/* <p>Taxable</p>*/}
                                                <div className="form-group" style={{visibility:"hidden"}}>
                                                    <select id="" className="form-control" name="taxable_status" onChange={this.onChanges}>
                                                        <option value="">Select</option>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                    <span className="error" style={{color: "red"}}>{this.state.errors_cus["taxable_status"]}</span>
                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                <div id="plus-button" onClick={this.addRemoveActive}>
                    <i className="fas fa-plus"></i>
                </div>

                <div id="plus-menu">
                    <h4><i className="fas fa-plus"></i> Create New</h4>
                    <div className="pm-in">
                        <ul>
                            <li onClick={this.viewModal} className="l-item">
                                <div className="icn"><i className="fas fa-file-invoice-dollar"></i></div>
                                <p className="tp">Create New Invoice</p>
                                <p className="bp">Create a New Invoice to Get Paid!</p>
                            </li>
                        </ul>
                        <ul>
                            <li onClick={this.viewModalBulkInvoice} className="l-item">
                                <div className="icn"><i className="fas fa-file-invoice-dollar"></i></div>
                                <p className="tp">Create New Invoice Bulk</p>
                                <p className="bp">Create a New Invoice to Get Paid!</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <NavigationSandbox pathname="all-invoices-sandbox"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBarSandbox pathname="settlements"/>
                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link className="nav-link active" id="tt-tab" data-toggle="tab" to="../all-invoices-sandbox" role="tab"
                                          aria-controls="tt" aria-selected="true"><span className="tt">All Invoices</span><span
                                        className="amt">LKR 100.00 | USD 100.00</span></Link>
                                </li>
                            {/*    <li className="nav-item">
                                    <Link className="nav-link" id="tr-tab" data-toggle="tab" to="../invoice-product-service" role="tab"
                                          aria-controls="tr" aria-selected="false"><span
                                        className="tt">Products/Services</span><span
                                        className="amt">{total_items} Items</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" id="tp-tab" data-toggle="tab" to="../invoice-templetes" role="tab"
                                          aria-controls="tr" aria-selected="false"><span
                                        className="tt">Templates</span><span className="amt">{total_templates} Templates</span></Link>
                                </li>*/}
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tt" role="tabpanel" aria-labelledby="tt-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <form className="col-md-8 col-12 tt-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <label htmlFor="">Invoice ID</label>
                                                            <input type="text" className="form-control" name="search_invoice_id" value={this.state.search_invoice_id}
                                                                   placeholder="Enter Invoice ID" onChange={this.onChanges}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Status</label>
                                                            <div className="slct">
                                                                <select name="payment_status_search" id="c1" className="form-control" value={this.state.payment_status_search} onChange={this.onChanges}>
                                                                    <option value="">-- Select Payment --</option>
                                                                    <option value="1">Payment Pending</option>
                                                                    <option value="2">Paid</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Invoice Value</label>
                                                            <input type="text" className="form-control" name="search_invoice_value" value={this.state.search_invoice_value}
                                                                   placeholder="Enter Invoice Value" onChange={this.onChanges}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Customer</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Customer Name" name="search_customer_name" value={this.state.search_customer_name} onChange={this.onChanges}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn" >Search</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form className="col-md-4 col-12 tr-form inv-tr">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn" >Clear</button>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Filters & Reports</label>
                                                            <div className="in table-datepicker">
                                                                <DateRangePicker
                                                                    onEvent={this.handleEvent} onCallback={this.handleCallback}
                                                                    initialSettings={{
                                                                        startDate:moment(this.state.startDate).format('M/DD/YYYY'),
                                                                        endDate:moment(this.state.endDate).format('M/DD/YYYY'),
                                                                        maxDate: moment().format('M/DD/YYYY'),
                                                                        autoApply:true
                                                                    }}
                                                                >
                                                                    <button type="button" className="date-btn"> <i className="far fa-calendar-alt"></i>  {this.state.dayCount} Days <i
                                                                        className="fas fa-caret-down" aria-hidden="true"></i> </button>
                                                                </DateRangePicker>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">



                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="none"
                                                                                 id="all-invoices-download-dropdown">
                                                                    <i className="fas fa-download"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <CSVLink className="dropdown-item" data={this.state.csvData} style={{textDecoration: "none !important",color:"#000"}} filename={"InvoiceReport.csv"}>
                                                                        <i
                                                                            className="fas fa-download"></i> CSV Download
                                                                    </CSVLink>

                                                                    {/*<Dropdown.Item onClick={() => this.exportPDF()}>  <i
                                                                         className="fas fa-download"></i> PDF Download
                                                                     </Dropdown.Item>*/}
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className='rg-container'>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th className=''>#</th>
                                                        <th className=''>Invoice ID</th>
                                                        <th className=''>Invoice Created Date</th>
                                                        <th className=''>Amount</th>
                                                        <th className=''>Customer</th>
                                                        <th className=''>Transaction Reference</th>
                                                        {/*<th className=''>Payment Date</th>*/}
                                                        {/* <th className=''>Payment Link</th>*/}
                                                        <th className=''>Status</th>
                                                        <th className=''></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className="">
                                                        <td className="" data-title="#">1</td>
                                                        <td className="" data-title="Invoice ID">1234</td>
                                                        <td className="" data-title="Created Date">Jul 2, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 300.00</span>
                                                        </td>
                                                        <td className="" data-title="Customer">Krishan Samarasinghe</td>
                                                        <td className="" data-title="Payment Date"></td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false" onClick={()=>this.showModalInvoiceDetail(1)}><i className="fas fa-eye"
                                                                                                 aria-hidden="true"></i>
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-bell" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-copy" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-download"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">2</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16250-43892
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 30, 2021</td>
                                                        <td className="" data-title="Deductions">
                                                            <span>LKR 11,500.00</span></td>
                                                        <td className="" data-title="Customer">Yuga Navaa</td>
                                                        <td className="" data-title="Payment Date"></td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false"><i className="fas fa-eye"
                                                                                                 aria-hidden="true"></i>
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-bell" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-copy" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-download"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">3</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16250-43705
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 30, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 11.00</span>
                                                        </td>
                                                        <td className="" data-title="Customer">Shuaib Mohamed</td>
                                                        <td className="" data-title="Payment Date"></td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false"><i className="fas fa-eye"
                                                                                                 aria-hidden="true"></i>
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-bell" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-copy" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-download"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">4</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16250-27389
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 30, 2021</td>
                                                        <td className="" data-title="Deductions">
                                                            <span>LKR 1,220.00</span></td>
                                                        <td className="" data-title="Customer">Yuga Navaa</td>
                                                        <td className="" data-title="Payment Date"></td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false"><i className="fas fa-eye"
                                                                                                 aria-hidden="true"></i>
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-bell" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-copy" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-download"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">5</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16250-26394
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 30, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 50.00</span>
                                                        </td>
                                                        <td className="" data-title="Customer">hmmm hmmm</td>
                                                        <td className="" data-title="Payment Date"></td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false"><i className="fas fa-eye"
                                                                                                 aria-hidden="true"></i>
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-bell" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-copy" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-download"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">6</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16246-44454
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 25, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 2.00</span>
                                                        </td>
                                                        <td className="" data-title="Customer">shuhib mohamed</td>
                                                        <td className="" data-title="Payment Date">T7784392021I251619
                                                        </td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot green"><i className="fas fa-circle"
                                                                                     aria-hidden="true"></i></span>Paid
                                                        </td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false"><i className="fas fa-eye"
                                                                                                 aria-hidden="true"></i>
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-bell" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-copy" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-download"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">7</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16246-44141
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 25, 2021</td>
                                                        <td className="" data-title="Deductions"><span>LKR 1.00</span>
                                                        </td>
                                                        <td className="" data-title="Customer">shuhib mohamed</td>
                                                        <td className="" data-title="Payment Date">T7784342021I251618
                                                        </td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot green"><i className="fas fa-circle"
                                                                                     aria-hidden="true"></i></span>Paid
                                                        </td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false"><i className="fas fa-eye"
                                                                                                 aria-hidden="true"></i>
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-bell" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-copy" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-download"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="">
                                                        <td className="" data-title="#">8</td>
                                                        <td className="" data-title="Invoice ID">INV-3114-16245-19465
                                                        </td>
                                                        <td className="" data-title="Created Date">Jun 24, 2021</td>
                                                        <td className="" data-title="Deductions">
                                                            <span>LKR 30,000.00</span></td>
                                                        <td className="" data-title="Customer">Yuga Navaa</td>
                                                        <td className="" data-title="Payment Date"></td>
                                                        <td className="" data-title="Payment Date"><span
                                                            className="dot yellow"><i className="fas fa-circle"
                                                                                      aria-hidden="true"></i></span> Payment
                                                            Pending
                                                        </td>
                                                        <td className="" data-title="">
                                                            <div className="dropdown">
                                                                <button className="btn dropdown-toggle dot-drop"
                                                                        type="button" id="dropdownMenuButton"
                                                                        data-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false"><i className="fas fa-eye"
                                                                                                 aria-hidden="true"></i>
                                                                </button>
                                                                <div className="dropdown-menu table-drop"
                                                                     aria-labelledby="dropdownMenuButton">
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-bell" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-copy" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-share" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button className="dropdown-item"><i
                                                                        className="fas fa-download"
                                                                        aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    </tbody>
                                                </table>

                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={1}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        onPageChange={this.handlePageClick}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                                        activeClassName={"active"}/>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />

                    </div>
                </div>
            </>

        );
    }

}


