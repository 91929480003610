import React, {PureComponent} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, Navigate} from "react-router-dom";
import NumberFormat from "react-number-format";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DataTable from "@bit/adeoy.utils.data-table";
import {Navigation} from "../Navigation";

import {
    PieChart, Pie, Sector, Cell, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import {myConfig} from "../../config";
import ReactPaginate from "react-paginate";
import {TopBar} from "../TopBar";
import {PostCompnentWebx} from "../PostCompnent";
import {CSVLink} from "react-csv";
import {Footer} from "../Footer";
import {SupportButton} from "../SupportButton";
import {NavigationSandbox} from "./NavigationSandbox";
import {TopBarSandbox} from "./TopBarSandbox";


export class SettlementsDueSandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(30, 'days').format('ll'),
            endDate: moment().format('ll'),
            //due list
            due_lists: [],
            due_lists_filters: [],
            due_lists_render: [],
            offsetDues: 0,
            perPageDues: 10,
            currentPageDues: 0,
            pageCountDues: 0,
            due_payment_status: '',
            due_type: '',
            settlement_lists: [],
            csvData: [],
            transaction_id_search: '',
            store_reference_id: '',
            transaction_amount: '',
        };
        this.onChanges = this.onChanges.bind(this);
        //due filter
        this.filterListDues = this.filterListDues.bind(this);
        this.clearListDues = this.clearListDues.bind(this);
        this.GenerateDueList = this.GenerateDueList.bind(this);
        this.loadTableDue = this.loadTableDue.bind(this);
        this.handlePageClickDues = this.handlePageClickDues.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
    }


    handleCallback(start, end, label) {
        this.setState({
            startDate: moment(start).format('ll'),
            endDate: moment(end).format('ll'),
        });
        let start_dts = sessionStorage.getItem('session_st_date');
        let end_dts = sessionStorage.getItem('session_en_date');
        if (start_dts) {
            sessionStorage.removeItem("session_st_date");
        }
        if (end_dts) {
            sessionStorage.removeItem("session_en_date");
        }
        sessionStorage.setItem('session_st_date', moment(start).format('ll'));
        sessionStorage.setItem('session_en_date', moment(end).format('ll'));
        this.GenerateDueList(moment(start).format('ll'), moment(end).format('ll'));
    }

    onChanges(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleCloseProgress = () => {
        this.setState({setOpenProgress: false});
        this.setState({openprogress: false});
    };
    handleToggleProgress = () => {
        this.setState({setOpenProgress: true});
        this.setState({openprogress: true});
    };

    //Due Report
    GenerateDueList(start, end) {
        var a = moment(end);
        var b = moment(start);
        let days_count = a.diff(b, 'days')   // =1
        days_count = parseInt(days_count) + parseInt(1);

        if (end && start) {
            this.handleToggleProgress();

            let formData = new FormData();    //formdata object

            let startd = moment(start).format('YYYY-MM-DD');

            let endd = moment(end).format('YYYY-MM-DD');


            formData.append('filter_date_start', startd);   //append the values with key, value pair
            formData.append('filter_date_end', endd);

            PostCompnentWebx("getStoreSettlement", formData).then(response => {
                this.handleCloseProgress();

                this.setState({
                    settlement_lists: response.data[0],
                })

            });

            PostCompnentWebx("getMerchantDueTransactionsAndRefunds", formData).then(response => {
                this.handleCloseProgress();
                const csvData = [
                    ["Transaction Date", "Store Reference", "Transaction ID", "Customer Name", "Processing Currency", "Transaction Amount", "Gateway Charge", "Net Transaction", "Refund Charges", "Settlement Currency", "Settlement Amount", "Payment Gateway"]
                ];
                response.data.map((list, index) => {
                    csvData.push([moment(list.transaction_date).format('ll'), list.store_order_reference, list.order_reference, list.first_name + ' ' + list.last_name, list.processing_currency, list.transaction_amount, list.gateway_charge, list.transaction_net, list.refund_charge, list.payment_currency, list.payment_amount, list.payment_gateway]);

                })
                this.setState({
                    csvData: csvData,
                    due_lists_filters: response.data,
                    due_lists: response.data,
                    dayCount: days_count
                }, () => {
                    this.loadTableDue()
                });
            });

        }
    }

    loadTableDue() {

        const list_data = this.state.due_lists_filters;

        const slice = list_data.slice(this.state.offsetDues, this.state.offsetDues + this.state.perPageDues);
        const trans = list_data && list_data.length ? (slice.map(list => <tr className=''>
            <td className='' data-title='Transaction Date'>{moment(list.transaction_date).format('ll')}
            </td>
            <td className='' data-title='Order Reference No'>{list.store_order_reference}
            </td>
            <td className='' data-title='Order Reference No'>{list.order_reference}
            </td>
            <td className='' data-title='Name'>{list.first_name} {list.last_name}
            </td>


            <td className='' data-title='Payment Currency'>{list.processing_currency}
            </td>
            <td className='' data-title='Payment Currency'>
                <NumberFormat
                    value={(Math.round(list.transaction_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={' '}/>
            </td>


            <td className='' data-title='Refund Charge'>
                <NumberFormat
                    value={(Math.round(list.refund_charge * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={'LKR '}/>
            </td>
            <td className='' data-title='Payment Amount'>
                <NumberFormat
                    value={(Math.round(list.payment_amount * 100) / 100).toFixed(2)}
                    displayType={'text'} thousandSeparator={true} prefix={list.payment_currency + ' '}/>


            </td>


        </tr>)) : (
            <tr className=''>
                <td colSpan="14" className='' data-title='#'><strong>No results found, for the selected date range</strong></td>
            </tr>
        );
        this.setState({
            pageCountDues: Math.ceil(list_data.length / this.state.perPageDues),
            due_lists_render: trans
        });
    }

    handlePageClickDues(e) {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPageDues;
        this.setState({
            currentPageDues: selectedPage,
            offsetDues: offset
        }, () => {
            this.loadTableDue();
        });
    }

    filterListDues() {

        this.handleToggleProgress();

        let transaction_id_search = this.state.transaction_id_search;
        let store_reference_id = this.state.store_reference_id;
        let transaction_amount = this.state.transaction_amount;
        let formData = new FormData();    //formdata object


        formData.append('order_refference_number', transaction_id_search);   //append the values with key, value pair
        formData.append('gateway_order_id', store_reference_id);   //append the values with key, value pair
        formData.append('total_amount ', transaction_amount);   //append the values with key, value pair


        PostCompnentWebx("getMerchantDueTransactionsAndRefunds", formData).then(response => {
            this.handleCloseProgress();
            const csvData = [
                ["Transaction Date", "Store Reference", "Transaction ID", "Customer Name", "Processing Currency", "Transaction Amount", "Gateway Charge", "Net Transaction", "Refund Charges", "Settlement Currency", "Settlement Amount", "Payment Gateway"]
            ];
            response.data.map((list, index) => {
                csvData.push([moment(list.transaction_date).format('ll'), list.store_order_reference, list.order_reference, list.first_name + ' ' + list.last_name, list.processing_currency, list.transaction_amount, list.gateway_charge, list.transaction_net, list.refund_charge, list.payment_currency, list.payment_amount]);
            });

            this.setState({
                csvData: csvData,
                due_lists_filters: response.data,
                due_lists: response.data,

            }, () => {
                this.loadTableDue()
            });

        });

    }

    clearListDues() {
        this.handleToggleProgress();
        let formData = new FormData();    //formdata object
        PostCompnentWebx("getMerchantDueTransactionsAndRefunds", formData).then(response => {
            this.handleCloseProgress();
            const csvData = [
                ["Transaction Date", "Store Reference", "Transaction ID", "Customer Name", "Processing Currency", "Transaction Amount", "Gateway Charge", "Net Transaction", "Refund Charges", "Settlement Currency", "Settlement Amount", "Payment Gateway"]
            ];
            response.data.map((list, index) => {
                csvData.push([moment(list.transaction_date).format('ll'), list.store_order_reference, list.order_reference, list.first_name + ' ' + list.last_name, list.processing_currency, list.transaction_amount, list.gateway_charge, list.transaction_net, list.refund_charge, list.payment_currency, list.payment_amount]);
            });

            this.setState({
                csvData: csvData,
                due_lists_filters: response.data,
                due_lists: response.data,
                transaction_id_search: '',
                store_reference_id: '',
                transaction_amount: ''
            }, () => {
                this.loadTableDue()
            });

        });
    }

    componentWillMount() {

        if (sessionStorage.getItem("userToken") || localStorage.getItem("userToken")) {

            var userToken = sessionStorage.getItem("userToken") || localStorage.getItem("userToken");

            this.GenerateDueList(this.state.startDate, this.state.endDate);
            this.setState({userToken: userToken});


        } else {
            this.setState({redirect: true});
        }
    }


    render() {
        let settlement_sum = 0;
        let due_list_sum = 0;

        if (this.state.settlement_lists.length) {
            settlement_sum = this.state.settlement_lists.reduce(function (prev, current) {
                return prev + +current.lkr_total_amount
            }, 0);
        }
        if (this.state.due_lists) {
            due_list_sum = this.state.due_lists.reduce(function (prev, current) {
                return prev + +current.payment_amount
            }, 0);
        }

        const transaction_lists = this.state.transaction_lists;
        const due_lists_render = this.state.due_lists_render;
        if (this.state.redirect) {
            return <Navigate to='/login'/>
        }
        return (
            <div>
                <Backdrop className="backdrop-loading" open={this.state.openprogress}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <SupportButton/>
                <div id="outer-container" className="container-fluid color-orange outer-container-inside-page">
                    <div id="turn-box"></div>
                    <NavigationSandbox pathname="settlement-sandbox"/>
                    <div id="main-content" className="row row_clr dashboard-live-mode">
                        <TopBarSandbox pathname="settlements"/>
                        <div className="col-12 transaction-top">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="../settlement-sandbox" className="nav-link " id="tt-tab" data-toggle="tab"
                                          role="tab"
                                          aria-controls="tt" aria-selected="true"><span
                                        className="tt">Total Settlements</span>

                                        <span className="amt">
                                            LKR 20.00
                                              </span>


                                    </Link>


                                </li>
                                <li className="nav-item">
                                    <Link to="../settlement-due-sandbox" className="nav-link active" id="tt-tab"
                                          data-toggle="tab" role="tab"
                                          aria-controls="tr" aria-selected="false"><span
                                        className="tt">Total Due</span>


                                        <span className="amt">
                                             LKR 20.00
                                           </span>
                                    </Link>
                                </li>
                            </ul>

                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tr" role="tabpanel"
                                     aria-labelledby="tr-tab">
                                    <div className="row row_clr">
                                        <div className="col-12">
                                            <div className="row row_clr tt-ttp">
                                                <form className="col-md-8 col-12 tt-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group">
                                                            <label htmlFor="">Transaction ID</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Transaction ID"
                                                                   name="transaction_id_search"
                                                                   value={this.state.transaction_id_search}
                                                                  />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Store Reference ID</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Reference ID"
                                                                   name="store_reference_id"
                                                                   value={this.state.store_reference_id}
                                                                  />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor=""> Transaction Amount</label>
                                                            <input type="text" className="form-control"
                                                                   placeholder="Enter Value" name="transaction_amount"
                                                                   value={this.state.transaction_amount}
                                                                  />
                                                        </div>
                                                        {/*<div className="form-group">
                                                            <label htmlFor="">Status</label>
                                                            <div className="slct">
                                                                <select name="" id="c1" className="form-control" name="status" onChange={this.onChanges}>
                                                                    <option value="LKR &amp; USD">All</option>
                                                                </select>
                                                            </div>
                                                        </div>*/}
                                                        {/*Due Report*/}

                                                        {/*                     <div className="form-group" style={{visibility:"hidden"}}>
                                                                <label htmlFor="">Payment Status</label>
                                                                <select name="due_payment_status" id="c1" className="form-control" onChange={this.onChanges}>
                                                                    <option value="">Select Status</option>
                                                                    <option value="Paid">Paid</option>
                                                                    <option value="Declined">Declined</option>
                                                                    <option value="Pending">Pending</option>
                                                                </select>
                                                            </div>



                                                            <div className="form-group" style={{visibility:"hidden"}}>
                                                                <label htmlFor="">Type</label>
                                                                <select name="due_type" id="c1" className="form-control" onChange={this.onChanges}>
                                                                    <option value="">Select Status</option>
                                                                    <option value="Order">Order</option>
                                                                    <option value="Refund">Refund</option>
                                                                </select>
                                                            </div>*/}

                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn"
                                                                    >Search
                                                            </button>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="button" className="srch-btn clear-btn"
                                                                    >Clear
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <form className="col-md-4 col-12 tr-form">
                                                    <div className="row row_clr">
                                                        <div className="form-group">

                                                        </div>
                                                        <div className="form-group" style={{visibility: "hidden"}}>
                                                            <label htmlFor="">Filters & Reports</label>
                                                            <div className="in table-datepicker">
                                                                <DateRangePicker
                                                                    onEvent={this.handleEvent}
                                                                    onCallback={this.handleCallback}
                                                                >
                                                                    <button type="button" className="date-btn"><i
                                                                        className="far fa-calendar-alt"></i> {this.state.dayCount} Days <i
                                                                        className="fas fa-caret-down"
                                                                        aria-hidden="true"></i></button>
                                                                </DateRangePicker>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                          {/*  <CSVLink data={this.state.csvData} style={{
                                                                textDecoration: "none !important",
                                                                color: "#000"
                                                            }} filename={"Due Report.csv"}>
                                                                <i className="fas fa-download"></i>
                                                            </CSVLink>*/}

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className={this.state.visibleClassDue + ' rg-container'}>
                                                <table className='transactions-table' summary='Hed'>
                                                    <thead>
                                                    <tr>
                                                        <th>Transaction Date</th>
                                                        <th>Store Reference</th>
                                                        <th>Transaction ID</th>
                                                        <th>Customer Name</th>
                                                        <th>Processing Currency</th>
                                                        <th>Transaction Amount</th>
                                                        <th>Refund Charges</th>
                                                        <th>Payment Amount</th>


                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                         <tr>
                                                             <td className="" data-title="Settlement Date">Jul 6, 2021</td>
                                                             <td className=""
                                                                 data-title="Settlement ID">3754-202107061421-1
                                                             </td>
                                                             <td className=""
                                                                 data-title="Settlement ID">T12121212545454
                                                             </td>
                                                             <td className="" data-title="Account Deposited">Customer 1</td>
                                                             <td className="" data-title="Account Deposited">LKR</td>
                                                             <td className="" data-title="Account Deposited">10.00</td>
                                                             <td className="" data-title="Account Deposited">5.00</td>
                                                             <td className="" data-title="Account Deposited">5.00</td>
                                                         </tr>
                                                         <tr>
                                                             <td className="" data-title="Settlement Date">Jul 6, 2021</td>
                                                             <td className=""
                                                                 data-title="Settlement ID">3754-202107061421-1
                                                             </td>
                                                             <td className=""
                                                                 data-title="Settlement ID">T12121212545454
                                                             </td>
                                                             <td className="" data-title="Account Deposited">Customer 1</td>
                                                             <td className="" data-title="Account Deposited">LKR</td>
                                                             <td className="" data-title="Account Deposited">10.00</td>
                                                             <td className="" data-title="Account Deposited">5.00</td>
                                                             <td className="" data-title="Account Deposited">5.00</td>
                                                         </tr>
                                                         <tr>
                                                             <td className="" data-title="Settlement Date">Jul 6, 2021</td>
                                                             <td className=""
                                                                 data-title="Settlement ID">3754-202107061421-1
                                                             </td>
                                                             <td className=""
                                                                 data-title="Settlement ID">T12121212545454
                                                             </td>
                                                             <td className="" data-title="Account Deposited">Customer 1</td>
                                                             <td className="" data-title="Account Deposited">LKR</td>
                                                             <td className="" data-title="Account Deposited">10.00</td>
                                                             <td className="" data-title="Account Deposited">5.00</td>
                                                             <td className="" data-title="Account Deposited">5.00</td>
                                                         </tr>
                                                    </tbody>
                                                </table>
                                                    <ReactPaginate
                                                        previousLabel={"PREV"}
                                                        nextLabel={"NEXT"}
                                                        breakLabel={"..."}
                                                        breakClassName={"break-me"}
                                                        pageCount={1}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        containerClassName={"pagination"}
                                                        subContainerClassName={"pages pagination"}
                                               />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer/>

                    </div>
                </div>
            </div>

        );
    }

}


